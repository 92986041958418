import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import SendbirdChat, {
  SendbirdChatWith,
  User,
  SendbirdError,
} from '@sendbird/chat';
import { 
  UserMessageCreateParams, 
  UserMessage, 
  PreviousMessageListQueryParams,
  PreviousMessageListQuery,
  FileMessageCreateParams,
  FileMessage,
  } from "@sendbird/chat/message";
import {
  GroupChannelModule,
  SendbirdGroupChat,
  GroupChannel,
  Member,
  GroupChannelHandler
} from "@sendbird/chat/groupChannel";
import React, { createRef, RefObject } from "react";

import storage from "../../../framework/src/StorageProvider.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { userProfile } from "./assets";

export const SendBird_Info = { appId: "7802B526-0F84-4589-85F1-88B87B8ECFB2"}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

interface IMember {
  user_id: string;
  nickname: string;
  profile_url: string;
  require_auth_for_profile_image: boolean;
  metadata: {};
  is_online: boolean;
  last_seen_at: number;
  state: string;
  is_active: boolean;
  role: string;
  is_muted: boolean;
  muted_end_at: number;
  muted_description: string;
}
interface IChannel {
  ignore_profanity_filter: boolean;
  has_ai_bot: boolean;
  has_bot: boolean;
  id: number;
  created_by: null;
  is_access_code_required: boolean;
  is_created: boolean;
  message_survival_seconds: number;
  last_message: {
    message: string;
    created_at: number;
  } | null;
  is_distinct: boolean;
  is_super: boolean;
  is_broadcast: boolean;
  is_public: boolean;
  is_discoverable: boolean;
  freeze: boolean;
  is_ephemeral: boolean;
  unread_message_count: number;
  unread_mention_count: number;
  channel_url: string;
  name: string;
  cover_url: string;
  data: string;
  member_count: number;
  joined_member_count: number;
  max_length_message: number;
  created_at: number;
  custom_type: string;
  channel: {
    channel_url: string;
    name: string;
    cover_url: string;
    data: string;
    created_at: number;
    custom_type: string;
    max_length_message: number;
    member_count: number;
  },
  disappearing_message: {
    is_triggered_by_message_read: boolean;
    message_survival_seconds: number;
  },
  sms_fallback: {
    wait_seconds: number;
    exclude_user_ids: never[];
  },
  members: IMember[],
  operators: {
    user_id: string;
    nickname: string;
    profile_url: string;
    require_auth_for_profile_image: boolean;
    metadata: {};
    is_online: boolean;
    last_seen_at: number;
    state: string;
    is_active: boolean;
  }[]
}
interface IUserMessage {
  id: number,
  message: string,
  senderId: string,
  createdAt: number,
  profileUrl: string,
}

interface IUserList {
  user_id: string;
  nickname: string;
  profile_url: string;
  require_auth_for_profile_image: boolean;
  metadata: {};
  created_at: number;
  is_hide_me_from_friends: boolean;
  is_online: boolean;
  last_seen_at: number;
  is_active: boolean;
  has_ever_logged_in: boolean;
  preferred_languages: never[];
  discovery_keys: never[];
  phone_number: string;
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

enum EChatConStatus {
  noMessages = "no-messages",
  loading = "loading",
  addNewChannel = "add-new-channel",
  messages = "messages"
}

interface ITab {
  tabName: string;
  tabId: string;
  count: number;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  activeMessageTab: string;
  user: User | null;
  channel: GroupChannel | null;
  userId: string;
  receiverId: string;
  messages: (UserMessage | FileMessage)[];
  input: string;
  error: string;
  dezinerToken: string;
  userList: IUserList[];
  channelUrl: string;
  messagesDateWise: Record<string, IUserMessage[]>;
  channelList: IChannel[];
  contractChannelList: IChannel[];
  inquiresChannelList: IChannel[];
  query: null | PreviousMessageListQuery;
  loading: boolean,
  accessToken: string;
  isNewChannelCreated: boolean;
  newlyCreateChannel: null | IChannel;
  receiverData: null | Member;
  fileUploadAnchorEl: null | HTMLElement;
  deleleClearAnchorEl: null | HTMLElement;
  messagesConStatus:  EChatConStatus;
  tabList: ITab[];
  previewMessage: FileMessage | null;
  userListNext:string;
  isUserListLoading: boolean;  
  nickname: string;
  channelListNext: string,
  isChannelListLoading: boolean,
  channelNameInput: string;
  isMessagesLoading: boolean;
  buyerOnline: boolean;
  navigatedUserData: null | IUserList;
  unreadChannelCount: Record<string,string>;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  private suggestedUsersId: string = "";
  private suggestedUsersByNextId: string = "";
  private channelListId: string = "";
  private channelListByNextId: string = "";
  private refreshChannelListId: string = "";
  private specificChannelListId: string = "";
  private createChannelId: string = "";
  private navigatedUserId: string = "";
  private remaingChannelsListId: string = "";
  private createNewNavigatedChannelId: string = "";
  private unreadChannelCountId: string = "";
  lastMessageRef: RefObject<HTMLDivElement> = createRef();
  chatHistoryConRef: RefObject<HTMLDivElement> = createRef();
  userListConRef: RefObject<HTMLDivElement> = createRef();
  channelListConRef: RefObject<HTMLDivElement> = createRef();
  channelListReferenceRef: RefObject<HTMLDivElement> = createRef();
  private limit = 15;
  private userListObserver?: IntersectionObserver;
  private channelListObserver?: IntersectionObserver;
  private debounceTimeout: NodeJS.Timeout | null = null;
  private unreadCountTimeout: NodeJS.Timeout | null = null;
  private debouceTime = 350;
  private refreshTimeInteral: NodeJS.Timeout | null = null;
  private refreshTimeOfChannel = 15000;
  private channelUpdatingIndex: number = 0;
  private newChannelUserDetails: IUserList | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      activeMessageTab: configJSON.tabsData[0].tabId,
      user: null,
      channel: null,
      userId: "",
      receiverId: "",
      channelUrl: "",
      messages: [],
      input: "",
      error: "",
      dezinerToken: "",
      userList: [],
      channelList: [],
      inquiresChannelList: [],
      contractChannelList: [],
      messagesDateWise: {},
      query: null,
      loading: false,
      fileUploadAnchorEl: null,
      deleleClearAnchorEl: null,
      isNewChannelCreated: true,
      accessToken: "",
      newlyCreateChannel: null,
      receiverData: null,
      messagesConStatus: EChatConStatus.loading,
      tabList: configJSON.tabsData,
      previewMessage: null,
      userListNext: "",
      isUserListLoading: false,
      nickname: "",
      channelListNext: "",
      isChannelListLoading: true,
      channelNameInput: "",
      isMessagesLoading: true,
      buyerOnline: false,
      navigatedUserData: null,
      unreadChannelCount: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.initializeSendBird();
    this.addUserListObserver();
    this.addChannelListObserver();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    this.handleMessages(message);
  }

  handleActiveTab = (tabName:string) => {
    this.setState({activeMessageTab: tabName})
  }

  isActiveTab = (tabId: string) => {
    return this.state.activeMessageTab === tabId ? "activeTab" : ""
  }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const navigatedUser = await getStorageData("navigateUserId");
    const tokenParseData = JSON.parse(tokenDetail);
    const parsedNavigateUserData = JSON.parse(navigatedUser);
    let token: string = "";
    let userId: string = "";
    let accessToken: string = "";
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);
      (tokenParseData.meta.id) && (userId = tokenParseData.meta.sendbird_user_id);
      (tokenParseData.meta.id) && (accessToken = tokenParseData.meta.sendbird_access_token);
      this.connectingUserToSendbird(`${userId}`,accessToken);
      
      this.setState({ dezinerToken: token, userId: `${userId}`, accessToken }, () => {
     
        this.unreadCountTimeout = setTimeout(() => {
          this.getUnreadChannelCount()
        },100)
       
        if(!!parsedNavigateUserData){
          this.setState({navigatedUserData: parsedNavigateUserData},() => {
            this.getNavigatedUserChannel();
          })   
        }else {
          this.getChannelsList();
        }
      })
    }
  
    const chatContainer = this.chatHistoryConRef.current;
    if(chatContainer){
      chatContainer.addEventListener("scroll", this.handleScroll);
    }
  }

  getUnreadChannelCount = () => {
    const message = this.createMessage(configJSON.unreadCountEndPoint, configJSON.getApiMethod)
    this.unreadChannelCountId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getNavigatedUserChannel = () => {
    const endpoint = `${configJSON.channelListEndPoint}?members_exactly_in=${this.state.navigatedUserData?.user_id}`
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.navigatedUserId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }

  loadMessages = async () => {
    this.setState({loading: true});
    const chatConHeight = this.chatHistoryConRef.current;
    if(!!this.state.query && !! chatConHeight){
      if(this.state.query.hasNext){
        const messages: unknown[] = await this.state.query.load();
        const newMessages = messages as UserMessage[];
        const oldHeight = chatConHeight.scrollHeight;
        this.setState(previous => {
          return{
            messages: [...newMessages, ...previous.messages],
            isMessagesLoading: false,
          }
        },() => {
          const newChatHeight = this.chatHistoryConRef.current;
          if(!!newChatHeight){
            const newHeight = newChatHeight.scrollHeight;
            newChatHeight.scrollTop = newHeight - oldHeight
          }
          
        })
      }
    }
  }

  handleScroll = async () => {
    const chatContainer = this.chatHistoryConRef.current;
    if(chatContainer){
      if(chatContainer.scrollTop === 0 && !this.state.loading){
       this.loadMessages();
      }
    }
  }

  getSuggestedUser = () => {
    let endpoint:string = `${configJSON.listUserEndPoint}?limit=${this.limit}&nickname=${this.state.nickname}`;
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.suggestedUsersId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }

  getSuggestedUserByNextSearch = () => {
    let endpoint:string = `${configJSON.listUserEndPoint}?limit=${this.limit}&next=${this.state.userListNext}&nickname=${this.state.nickname}`;
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.suggestedUsersByNextId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }

  getChannelsList = () => {
    const endpoint = `${configJSON.channelListEndPoint}?limit=${this.limit}&show_empty=true`
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.channelListId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }

  refreshChannelsList = () => {
    const endpoint = `${configJSON.channelListEndPoint}?limit=${this.limit}&show_empty=true&members_nickname_contains=${this.state.channelNameInput}`
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.refreshChannelListId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }

  getChannelsListByNext = () => {
    const endpoint = `${configJSON.channelListEndPoint}?limit=${this.limit}&next=${this.state.channelListNext}`;
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.channelListByNextId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }

  handleChatInputChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ input: event.target.value })
  }

  handleChatInputKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.sendMessageToChannel()
    }
  }

  handleChannelInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ channelNameInput: event.target.value })
  }

  resetTheChannelName = () => {
    this.setState({channelNameInput: ""},this.refreshChannelsList)
  }

  handleChanneInputKeyDown = () => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.refreshChannelsList();
    }, this.debouceTime);
  };

  connectingUserToSendbird = async (userId: string,accessToken: string) => {
    if (!!this.sendBird) {
      try {
        const user = await this.sendBird.connect(userId, accessToken);
        this.setState({ user: user });
      }
      catch (error) {
        if (error instanceof SendbirdError) {
          this.setState({ error: error.message });
        } else {
          this.setState({ error: configJSON.errorMessage });
        }
      }
    }
  }

  initializeSendBird = async () => {
    const sendBirdChat = SendbirdChat.init({
      appId: SendBird_Info.appId,
      localCacheEnabled: false,
      modules: [
        new GroupChannelModule()
      ]
    }) as SendbirdGroupChat

      if(!!sendBirdChat){
        this.sendBird = sendBirdChat;
        this.getTokenFromLocalStorage();
      }
  }


    connectChannel = async () => {
    if (!!this.sendBird && !!this.state.channelUrl) {

      try {
        const channel = await this.sendBird.groupChannel.getChannel(this.state.channelUrl);
        if(!!channel){
          this.setState({ channel, messagesConStatus: EChatConStatus.messages }, () => {
            this.updateReceiver();
            this.refreshTheChannel();
            this.getMessages()
          });
          const groupChannelHandler = new GroupChannelHandler({
            onMessageReceived: (channel, message) => {
             this.handleRefreshWhenMessageReceived()
              if (this.state.channel) {
                if (channel.url === this.state.channel.url) {
                  this.setState(prev => ({ messages: [...prev.messages, message as UserMessage] }),() => {
                    setTimeout(this.scrollToLastMessage,this.debouceTime)
                  })
                  this.state.channel.markAsRead();
                }
              }
            },
            onChannelDeleted: () => {
              this.getChannelsList();
            },
          });

          this.sendBird.groupChannel.addGroupChannelHandler("UNIQUE_HANDLER_ID", groupChannelHandler);
        }

      } catch (error) {
        this.setState({ error: configJSON.errorMessage });
      }
    }
  }

  handleRefreshWhenMessageReceived = () => {
    if (!!this.unreadCountTimeout) {
      clearTimeout(this.unreadCountTimeout);
      this.unreadCountTimeout = null;
    }
    this.unreadCountTimeout = setTimeout(() => {
      this.refreshChannelsList();
      this.getUnreadChannelCount()
    }, 200)
  }

  getReceiverList = (list: Member[]) => {
    return list.find(member => member.userId !== this.state.userId)
  }

  updateReceiver =  () => {
    if(this.state.channel){
      const receiverData = this.getReceiverList(this.state.channel.members)
      if(!!receiverData)
      this.setState({receiverData: receiverData})
    }
  }

  refreshTheChannel = () => {
    if(!!this.refreshTimeInteral){
      clearInterval(this.refreshTimeInteral);
    }
    this.refreshTimeInteral = setInterval(async () => {
      if(this.state.channel){
        await this.state.channel.refresh()
        this.updateReceiver() 
      }
      },this.refreshTimeOfChannel)
  }

  retriveMessageFromChannel = async (channel: GroupChannel) => {
    const params: PreviousMessageListQueryParams = {
      limit: 20,
      reverse: false,
      includeReactions: true,
    };

    const query = channel.createPreviousMessageListQuery(params);
    const messages = await query.load();
    return { messages, query };
  }

  getMessages = async () => {
    if (!!this.state.channel) {
      const { messages, query } = await this.retriveMessageFromChannel(this.state.channel);
      this.setState({ messages: messages as UserMessage[],isMessagesLoading: false, query },this.scrollToLastMessage);
      this.state.channel.markAsRead();
      this.restMarkReadCount(this.state.channelUrl);
    }
  }

  restMarkReadCount = (channelUrl: string) => {
    this.setState(prev => {
      const helperFunction = (channel: IChannel) => {
        if (channel.channel_url === channelUrl) {
          return { ...channel, unread_message_count: 0 }
        }
        return channel;
      }
      const channelList = prev.channelList.map(helperFunction);
      const contractChannelList = prev.contractChannelList.map(helperFunction);
      const inquiresChannelList = prev.inquiresChannelList.map(helperFunction);
      return ({
        channelList,
        contractChannelList,
        inquiresChannelList
      })
    })
  }


  clearChatHistory = async () => {
    if (!!this.state.channel) {
      try {
        await this.state.channel.resetMyHistory();
        this.setState({messages: []})
        this.handleDeleteClose();
      }
      catch (error) {
        this.setState({ error: configJSON.errorMessage });
      }
    }
  }

  deleteChannel = async () => {
    try {
      if (this.state.channel) {
        await this.state.channel.delete();
        this.handleDeleteClose();
        this.getChannelsList();
      }
    } catch (error) {
        this.setState({ error: configJSON.errorMessage });
    }

  }

  sendMessageToChannel = () => {
    if (this.state.input.trim().length > 0 && this.state.messagesConStatus === EChatConStatus.messages) {
      this.sendMessage(this.maskSecuredData(this.state.input));
    }
  }

  maskSecuredData = (inputData: string) => {
    const matchFunction = (matchedData: string) => {
      return matchedData.slice(0, 3) + configJSON.starText.repeat(matchedData.length - 3)
    }

    const emailMatchFumction = (matchedData: string) => {
      let [headEmail, tailEmail] = matchedData.split("@");
      headEmail = headEmail.slice(0, 1) + configJSON.starText.repeat(headEmail.length - 1);
      return headEmail + "@" + tailEmail;
    }

    const matchedZoomFunction = (matchedData: string) => {
      return matchedData.slice(0, 5) + configJSON.starText.repeat(matchedData.length - 7) + matchedData.slice(matchedData.length - 2)
    }

    const matchedFunction = (matchedData: string) => {
      return matchedData.slice(0, 2) + configJSON.starText.repeat(matchedData.length - 4) + matchedData.slice(matchedData.length - 2)
    }

    const totalMask = (matchedData: string) => {
      return configJSON.starText.repeat(matchedData.length)
    }

    const termsMasking = (inputData:string) => {
      let sanitizedData = inputData;
      configJSON.secureKeywords.map( (secureKeyword:string) => {
        if(sanitizedData.toLowerCase().includes(secureKeyword.toLowerCase())){
            const regex = new RegExp(secureKeyword,"gi");
            sanitizedData = maskedLoop(sanitizedData, regex, totalMask);
            
        }
      })
      return sanitizedData;
    }

    const maskUnWantedTextRegex = (inputData: string) => {
      let sanitizedData: string = inputData;
      sanitizedData = termsMasking(sanitizedData);
      sanitizedData = sanitizedData.split(" ").map(word => {
        
        let count = 0;

          if(word.search(configJSON.specialCharRegex) !== -1){
            count++;          
          } 
          if(word.search(configJSON.digitsRegex) !== -1){
            count++;          
          } 
          if(word.search(configJSON.alphaRegex) !== -1){
            count++;          
          } 
      
        return count > 1 ? totalMask(word) : word
      }).join(" ");
      sanitizedData = maskedLoop(sanitizedData, configJSON.paymentCardRegex,totalMask);
      sanitizedData = maskedLoop(sanitizedData, configJSON.nondisclosureRegex,totalMask);
      sanitizedData = maskedLoop(sanitizedData, configJSON.emailNameRegex, emailMatchFumction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.zoomLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.googleMeetlinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.instagramRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.twitterLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.linkdinLinkRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.paypalRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.stripePaymentRegex, matchedZoomFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.phoneNumberRegex, matchFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.IAFCRegex, matchedFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.bankAccontRegex, matchedFunction);
      sanitizedData = maskedLoop(sanitizedData, configJSON.pancardRegex, matchedFunction);
      return sanitizedData;    
    }

    const maskedLoop = (inputData: string, regexp: RegExp, maskedFuntion: (substring: string, ...args: string[]) => string) => {
      const matches = Array.from(inputData.matchAll(regexp));
      let maskedData = inputData;
      for (const match of matches) {
        if(!!match && !!match[0] && match.index !== undefined){
          maskedData = maskedData.slice(0, match.index) + match[0].replace(regexp, maskedFuntion) + maskedData.slice(match.index + match[0].length);
        }
      }

      return maskedData;
    }
    return maskUnWantedTextRegex(inputData)

  }

  sendMessage = (message: string) => {
    if (message.length > 0 && !!this.state.channel) {
      const params: UserMessageCreateParams = {
        message: message,
      }
      this.state.channel.sendUserMessage(params)
        .onSucceeded((message) => {
          const newMessage = message as UserMessage;
          this.setState(previous => {
            const chattingChannel = previous.channelList.splice(this.channelUpdatingIndex, 1)
            return ({
              messages: [...previous.messages, newMessage],
              channelNameInput: "",
              input: "",
              channelList: [...chattingChannel, ...previous.channelList]
            })
          }, () => {
            this.channelUpdatingIndex = 0;
            this.scrollToLastMessage();
            this.scrollChannelListToTop();
            this.refreshChannelsList()
            }
          )
        })
    }
  }

  getISTTime = (timeStamp: number) => { 
    const dateObj = new Date(timeStamp);

    const currentOffset = dateObj.getTimezoneOffset();

    const ISTOffset = 330;

    const ISTTime = new Date(dateObj.getTime() + (ISTOffset + currentOffset)*60000);
    return ISTTime;
  }

  getDateString = (timeStamp: number) => {
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const ISTTime = this.getISTTime(timeStamp)
    return `${ISTTime.getDate()}/${ISTTime.getMonth()+1}/${ISTTime.getFullYear()} ${weekdays[ISTTime.getDay()]}`
  }

  getTime = (timeStamp: number) => {
    const dateObject = this.getISTTime(timeStamp);
    const hoursValue = dateObject.getHours() % 12;
    const hours = (hoursValue === 0 ? 12 : hoursValue).toString().padStart(2,"0");
    const minutes = (dateObject.getMinutes()).toString().padStart(2,"0");
    const isAm = dateObject.getHours() >= 12 ? 'PM' : 'AM';
    return (`${hours}:${minutes} ${isAm}`);   
  }

  getLastMessageTime = (timeStamp: number | undefined) => {
    if (timeStamp !== undefined) {
      const dateString = this.getDateString(timeStamp).split(" ")[0];
      const todayDateString = this.getDateString(Date.now()).split(" ")[0];
      const yesterdayDateString = this.getDateString(Date.now() - (1000*60*60*24)).split(" ")[0];
      const timeHours = this.getTime(timeStamp);
      if (dateString === todayDateString) {
        return timeHours;
      }
      if (yesterdayDateString === dateString) {
        return configJSON.yesetdayText
      }
      return dateString;
    } else {
      return "";
    }
  }

  handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = userProfile;
  }


  toggleNewChannel = () => {
    this.setState(prev => ({ messagesConStatus: EChatConStatus.addNewChannel, isUserListLoading: true }),this.getSuggestedUser)
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    return ({ requestCallId, response })
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  handleMessages = (message: Message) => {
    const { requestCallId,  } = this.getResponseFromMessage(message);
    if([this.suggestedUsersId, this.suggestedUsersByNextId].includes(requestCallId)){
      this.handleUserList(message);
      return;
    }

    if(requestCallId === this.createChannelId){
      this.handleCreateNewChannel(message);
      return;
    }

    if ([
        this.channelListId,
        this.specificChannelListId, 
        this.channelListByNextId,
        this.refreshChannelListId
      ].includes(requestCallId)) {
      this.handleChannelList(message);
      return;
    }

    if(requestCallId === this.navigatedUserId){
      this.handleNavigateUsersChannels(message);
      return;
    }

    if(requestCallId === this.remaingChannelsListId){
      this.handleRemaingChannelList(message);
      return;
    }

    if(requestCallId === this.unreadChannelCountId){
      this.handleUnreadMessageCount(message)
    }

    if (requestCallId === this.createNewNavigatedChannelId) {
     this.handleCreateNewNavigatedChannel(message);
    }
  }

  handleCreateNewNavigatedChannel = (message:Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (!!response.data) {
      this.setState(() => ({
        newlyCreateChannel: response,
        channelUrl: response.data.channel_url,
        messagesConStatus: EChatConStatus.messages,
      }), () => {
        this.getRemaingChannels();
        this.connectChannel();
      });
    }
  }

  handleUnreadMessageCount = (message: Message) => {
    let { response } = this.getResponseFromMessage(message);
    if(response.data){
      const newCount = Object.create({});
      (response.data.all) && (response.data.all.unread_count) && (newCount["all"] = response.data.all.unread_count);
      (response.data.contracts) && (response.data.contracts.unread_count) && (newCount["contracts"] = response.data.contracts.unread_count);
      (response.data.inquries) && (response.data.inquries.unread_count) && (newCount["inquiries"] = response.data.inquries.unread_count);
      this.setState({unreadChannelCount: newCount})
    }
  }

  handleRemaingChannelList =  (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (response.data && response.data.channels && Array.isArray(response.data.channels)) {
      this.removeNavigatedUser();
      this.updateChannelListState(message)
    }
  }

  removeNavigatedUser =  async () => {
      await storage.remove("navigateUserId");
  }

  handleNavigateUsersChannels = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (response.data && response.data.channels && Array.isArray(response.data.channels) && response.data.channels.length > 0) {
      const { channelUrl } = this.getChannelData(response.data.channels[0])
      if (channelUrl) {
        this.setState({ channelList: response.data.channels, channelUrl }, () => {
          this.connectChannel()
          this.getRemaingChannels();
        })
      }
    } else {
      const message = this.createMessage(
        `${configJSON.createChannelEndpoint}?user_id=${this.state.navigatedUserData?.user_id}`,
        configJSON.postApiMethod)
      this.createNewNavigatedChannelId = message.messageId;
      runEngine.sendMessage(message.id, message)
    }
  }

  getRemaingChannels = () => {
    const endpoint = `${configJSON.channelListEndPoint}?limit=${this.limit}&show_empty=true`
    const message = this.createMessage(endpoint,configJSON.getApiMethod)
    this.remaingChannelsListId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }


  handleUserList = (message: Message) => {
    const { response, requestCallId } = this.getResponseFromMessage(message);
    if (response.data && response.data.users && Array.isArray(response.data.users)) {
      this.setState(prev => {
        const filterFunction = (user: IUserList) => user.user_id !== prev.userId;
        return ({
          userList: requestCallId === this.suggestedUsersId ?
            [...response.data.users].filter(filterFunction) :
            [...prev.userList, ...response.data.users].filter(filterFunction),
          userListNext: response.data.next,
        })
      }, () => {
          if (this.state.userList.length === 0 && !!this.state.userListNext) {
            this.getSuggestedUserByNextSearch();
          } else {
            this.setState({
              isUserListLoading: false,
            })
          }
        })
    } else {
      this.setState({
        isUserListLoading: false,
      })
    }
  }

  handleChannelList = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (response.data && response.data.channels && Array.isArray(response.data.channels)) {
      this.updateChannelListState(message)
    }else{ 
      this.setState(
        {
          messagesConStatus: EChatConStatus.noMessages,
          isChannelListLoading: false,
        }
      )
    }
  }

  updateChannelListState = (message: Message) => {
    const { response, requestCallId } = this.getResponseFromMessage(message);

    this.setState(prev => {
      const object = Object.create({});
      if(this.channelListId === requestCallId) {
        object["messagesConStatus"] = response.data.channels.length > 0 ?
        EChatConStatus.messages : EChatConStatus.noMessages
      }
      let channelList = [this.channelListId, this.refreshChannelListId, this.navigatedUserId].includes(requestCallId) ? 
      response.data.channels: 
      [...prev.channelList,...response.data.channels];
      const inquiresChannelList = channelList.filter((channel:IChannel) => channel.custom_type !== "contract");
      const contractChannelList = channelList.filter((channel:IChannel) => channel.custom_type === "contract");
       channelList = [...new Map(channelList.map((item:IChannel) =>
        [item["channel_url"], item])).values()];
      return  ({
        isChannelListLoading: false,
        channelListNext: response.data.next,
        channelList,
        inquiresChannelList,
        contractChannelList,
        ...object
      })
      },() => {
        if(this.remaingChannelsListId !== requestCallId){
          this.handleSandBird(requestCallId)
        }
      }
     )
  }

  handleCreateNewChannel = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if(!!response.data){
      this.setState(prev => ({ 
        newlyCreateChannel: response,
        channelUrl: response.data.channel_url,
        messagesConStatus: EChatConStatus.messages,
      }), () => { 
        this.refreshChannelsList();
        this.connectChannel();
      });
      return;
    }
    if(!!response.error){
      if(response.error.code == 400202) {
        const channelCallback = (channel: IChannel) => {
          return channel.members.find( user => user.user_id === this.newChannelUserDetails?.user_id)
        }
        const channel = this.state.channelList.find(channelCallback);
        const channelIndex = this.state.channelList.findIndex(channelCallback);
       if(!!channel){
        this.updateChattingChannel(channel,channelIndex);
       }else {
            const endpoint = `${configJSON.channelListEndPoint}?members_exactly_in=${this.newChannelUserDetails?.user_id}`
            const message = this.createMessage(endpoint,configJSON.getApiMethod)
            this.specificChannelListId = message.messageId;
            runEngine.sendMessage(message.id,message);
       }       
      }
    }
  }

  previewTheImageAndVideo = (file:FileMessage) => {
    this.setState({previewMessage: file})
  }

  handleCloseModal = () => {
    this.setState({previewMessage: null})
  }

  handleVideoDownload = (downloadLink:string) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = downloadLink;
    anchor.download = downloadLink;
    anchor.click();
    document.body.removeChild(anchor)
  }

  addNewChannel = (user: IUserList) => {
    this.newChannelUserDetails = user;
    const message = this.createMessage(
      `${configJSON.createChannelEndpoint}?user_id=${user.user_id}`,
      configJSON.postApiMethod)
    this.createChannelId = message.messageId;
    runEngine.sendMessage(message.id, message)
  }

   getChannelData = (channel:IChannel) => {
    const channelUrl = channel.channel_url;
    const receiverData = this.getReceiversId(channel.members)
     return ({ channelUrl, receiverData })
  }

  handleSandBird = (requesterMessageId: string) => {
    if (this.state.channelList.length > 0 && 
      [this.channelListId, this.specificChannelListId].includes(requesterMessageId)) {
        const idIndex = requesterMessageId === this.channelListId ? 0 : this.state.channelList.length - 1
      const { channelUrl } = this.getChannelData(this.state.channelList[idIndex])
      if (!!channelUrl) {
        this.setState({ channelUrl }, this.connectChannel);
      }
    }
  }

  updateChattingChannel = (channel: IChannel,idIndex:number) => {
    const { channelUrl } = this.getChannelData(channel);
    this.channelUpdatingIndex = idIndex;
    if (!!channelUrl) {
      clearInterval(this.refreshTimeInteral as NodeJS.Timeout);
      this.setState({ channelUrl, 
        messagesConStatus: EChatConStatus.messages,
        isMessagesLoading: true,
        messages: [] }, this.connectChannel);
        if(!!this.unreadCountTimeout){
          clearTimeout(this.unreadCountTimeout);
          this.unreadCountTimeout = null;
        }
        this.unreadCountTimeout = setTimeout(()=>{
          this.getUnreadChannelCount();
        },700)
    }
  }

  filterChannelList = () => {
    if(this.state.activeMessageTab === "all") return this.state.channelList;
    if(this.state.activeMessageTab === "inquiries") {
      return this.state.inquiresChannelList;
    }
    return this.state.contractChannelList;
  }

  handleFilesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ fileUploadAnchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ fileUploadAnchorEl: null })
  }

  isDocumentType = (type: string) => {
    const documentMimeTypes = [
      'application/pdf',               
      'application/msword',            
      'application/vnd.ms-excel',       
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain'
    ];
    return documentMimeTypes.includes(type)
  }

  handleVideoImagesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files) {
      const file = event.target.files[0];

      if (file && (file.type.startsWith('image/') || file.type.startsWith('video/')) || this.isDocumentType(file.type)) {
        if (this.state.channel) {
          const params: FileMessageCreateParams = {
            file: file,
            fileName: file.name,
            fileSize: file.size,
            thumbnailSizes: [{ maxWidth: 200, maxHeight: 300 }, { maxWidth: 400, maxHeight: 600 }],
            mimeType: file.type,         
          };
          this.state.channel.sendFileMessage(params)
            .onSucceeded((message) => {
              const newMessage = message as (UserMessage | FileMessage);
              this.setState(previous => ({ messages: [...previous.messages, newMessage], input: "", fileUploadAnchorEl: null }))
              this.channelUpdatingIndex = 0;
              this.handleClose();
              this.scrollToLastMessage();
              this.scrollChannelListToTop();
              this.refreshChannelsList();
            });
        }
      }
    }
  }

  handleDisconnect = async () => {
    if(this.sendBird){
      this.sendBird.removeConnectionHandler("UNIQUE_HANDLER_ID")
      this.sendBird.disconnect();
      clearInterval(this.refreshTimeInteral as NodeJS.Timeout)
    }
    const chatContainer = this.chatHistoryConRef.current;
    if(chatContainer){
      chatContainer.removeEventListener("scroll", this.handleScroll);
    }
  }

  async componentWillUnmount()  {
    this.handleDisconnect();
  }

  scrollToLastMessage = () => {
    if(this.lastMessageRef.current){
      this.lastMessageRef.current.scrollIntoView({ behavior: "smooth" })
    } 
  }

  scrollChannelListToTop = () => {
    if(this.channelListReferenceRef.current){
      this.channelListReferenceRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  handleKeyPress = () => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.getSuggestedUser();
    }, this.debouceTime);
  };

  handleNickName = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ nickname: event.target.value });
  }

  resetNickName = () => {
    this.setState({nickname: ""},this.getSuggestedUser)
  }

  addUserListObserver = () => {
    if(this.userListObserver){
      this.userListObserver.disconnect();
    }
    const userListCallBack = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if(entry.isIntersecting && !!this.state.userListNext){
        this.setState({ isUserListLoading: true });
        this.getSuggestedUserByNextSearch();
      }
    }
    this.userListObserver = new IntersectionObserver(userListCallBack, { threshold: 0 });
    if(this.userListConRef.current){
      this.userListObserver.observe(this.userListConRef.current)
    }
  }

  addChannelListObserver = () => {
    if(this.channelListObserver){
      this.channelListObserver.disconnect();
    }
    const callback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if(entry.isIntersecting && !!this.state.channelListNext){
        this.setState({ isChannelListLoading: true });
        this.getChannelsListByNext();
      }
    }
    this.channelListObserver = new IntersectionObserver(callback, { threshold: 0 });
    if(this.channelListConRef.current){
      this.channelListObserver.observe(this.channelListConRef.current)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const chatContainer =  this.chatHistoryConRef.current
    if(prevState.messages.length === 0 && this.state.messages.length > 0){
      if(chatContainer){
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
    if(prevState.userList.length !== this.state.userList.length){
      this.addUserListObserver();
    }
    if(prevState.channelList.length !== this.state.channelList.length){
      this.addChannelListObserver();
    }
  }

  handleMoreVertIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      deleleClearAnchorEl: event.currentTarget
    })
  }
  handleDeleteClose = () => {
    this.setState({
      deleleClearAnchorEl: null
    })
  }

  getReceiversId = (membersList: IMember[]) => {
    return (membersList.find((each:IMember) => each.user_id !== this.state.userId))
  }

  getCountOfUnreadChannels = (tabId:string) => {
    return this.state.unreadChannelCount[tabId] || 0;
  }
  
  // Customizable Area End
}
