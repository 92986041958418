import React from 'react';
import { BlockComponent } from 'framework/src/BlockComponent'
import { userProfile } from "../../blocks/chat/src/assets"
import {
    Box,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import StarIcon from '@material-ui/icons/Star';

const config = require("../../framework/src/config.js");

const typoWhite = "#FFF2E3"

const fixToOneLine = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical" as "vertical",
    wordBreak: "break-word" as "break-word",
}

const CustomBox = styled(Box)({
    width: "100%",
    aspectRatio: "1.02/1",
    borderRadius: "12px",
    boxSizing: "border-box",
    backgroundColor: "#0D0D0D",
    border: "1.15px solid #171717",
    paddingBottom: "2rem",
    cursor: "pointer",
    "& *": {
        boxSizing: "border-box"
    },
    "& .clientCardCoverImageCon": {
        background: `black`,
        width: 'calc(100% + 2.3px)',
        marginLeft: "-1.15px",
        height: "85px",
        borderTopRightRadius: "inherit",
        borderTopLeftRadius: "inherit",
        position: "relative",
        paddingTop: ".5rem",
        paddingRight: ".75rem",
        display: "flex",
    },
    "& .clientCardProfilePhoto": {
        width: "89.35px",
        height: "89.35px",
        borderRadius: "50%",
        objectFit: "cover",
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 10,
    },
    "& .clientCardProfileName": {
        color: typoWhite,
        fontSize: "1.25rem",
        fontWeight: 700,
        lineHeight: "24.2px",
        textAlign: "left",
        fontFamily: "Inter-SemiBold, sans-serif",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        height: "24.5px"
    },
    "& .clientCardDescription": {
        color: typoWhite,
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "16.94px",
        textAlign: "left",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        height: "17px",
    },
    "& .clientCardCaption": {
        color: "#7D7D7D",
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: "14.52px",
        textAlign: "left",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        display: 'block',
    },
    "& .clientCardIcon": {
        color: typoWhite,
        width: "11.85px",
        height: "11px",
    },
    "& .clientCardRatingCon": {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
    },
    "& .clientCardInfoCon": {
        backgroundColor: "#171717",
        padding: ".5rem",
        display: "flex",
        alignItems: "center",
        justityContent: "center",
        flexDirection: "column",
        gap: ".5rem",
        borderRadius: "3px",
        minWidth: "83px",
        minHeight: "49px",
    },
    "& .clientCardRatingJobsCon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
    },
    "& .clientCardDetailsCon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    "& .clientCardPadding": {
        padding: "0 .75rem",
        width: "50%",
        "&:first-child": {
            borderRight: "1.12px solid #545454",
            textAlign: "right"
        }
    },
    "& .clientCardHeadingCon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: ".5rem",
        paddingTop: "60px",
        marginBottom: "1rem",
    },
    "& .clientCardCoverImage": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderTopRightRadius: "inherit",
        borderTopLeftRadius: "inherit",
        position: "absolute",
        top: 0,
        left: 0,
    },
    "& .clientCardNewOpportunityCon": {
        display: "flex",
        backgroundColor: "#363636",
        padding: "6px 16px",
        borderRadius: "24px",
        maxWidth: "60%",
        alignSelf: "flex-start",
        marginLeft: "auto",
        zIndex: 2,
    },
    "& .clientCardBlue": {
        color: "#A8E5EC",
    }
})

interface ICardProps {
    id: string;
    type: string;
    attributes: {
        user_name: string;
        cover_image: string;
        profile_photo: string;
        company_name: string;
        location: string;
        full_name: string;
        rating: number;
        job_posted_count: number;
        background_color: string;
        new_opportunity_count: number;
    };
  }

interface Props {
    cardData: ICardProps;
    handleNavigateUserProfile: (card:ICardProps) => void;
}

interface S {
    baseURL: string;
    isCoverImageUnloaded: boolean;
    isProfileImageLoaded: boolean;
}

interface SS {

}



class ClientCard extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        
      }

    state: S = {
        baseURL: config.baseURL,
        isCoverImageUnloaded: true,
        isProfileImageLoaded: true,
    }

    handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
        if(this.props.cardData.attributes?.background_color){
            this.setState({isProfileImageLoaded: false})
            return;
        }
        event.currentTarget.src = userProfile;
    }

    handleCovverImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
        this.setState({ isCoverImageUnloaded: false })
    }

    render() {
        let { cardData } = this.props;
        return (
            <CustomBox className='clientCardOptionalCon' onClick={this.props.handleNavigateUserProfile.bind(this,cardData)}>
                <Box className="clientCardCoverImageCon">
                    {(this.state.isCoverImageUnloaded && !!cardData?.attributes?.cover_image) ?
                        <img
                            className='clientCardCoverImage'
                            onError={this.handleCovverImageError}
                            src={this.state.baseURL + cardData?.attributes?.cover_image}
                            alt="cover image"
                        />
                        : null}
                    {cardData?.attributes?.new_opportunity_count > 0 ? 
                        <Box className='clientCardNewOpportunityCon'>
                            <Typography variant='caption' style={fixToOneLine} className='clientCardCaption clientCardBlue'>
                                {cardData?.attributes?.new_opportunity_count} {cardData?.attributes?.new_opportunity_count === 1 ? "new opportunity" : "new opportunities"}
                            </Typography>
                            </Box>
                    : null}
                    {
                        this.state.isProfileImageLoaded ?
                            <img
                                src={this.state.baseURL + cardData?.attributes?.profile_photo}
                                alt="profile photo"
                                className='clientCardProfilePhoto'
                                onError={this.handleErrorImage}
                            /> : 
                            <Box 
                                style={{backgroundColor :cardData?.attributes?.background_color}} 
                                className='clientCardProfilePhoto'
                            />
                    }
                </Box>
                <Box className='clientCardHeadingCon'>
                    <Typography variant='h4' style={fixToOneLine} className='clientCardProfileName'>
                        {cardData?.attributes?.full_name}
                    </Typography>
                    <Box className='clientCardDetailsCon'>
                        <Typography variant='body2' style={fixToOneLine} className='clientCardDescription clientCardPadding'>
                            {cardData?.attributes?.company_name}
                        </Typography>
                        <Typography variant='body2' style={fixToOneLine} className='clientCardDescription clientCardPadding'>
                            {cardData?.attributes?.location}
                        </Typography>
                    </Box>
                </Box>
                <Box className='clientCardRatingJobsCon'>
                    <Box className='clientCardInfoCon'>
                        <Typography variant='caption' className='clientCardCaption'>
                            Jobs posted
                        </Typography>
                        <Typography variant='body2' style={fixToOneLine} className='clientCardDescription'>
                            {cardData?.attributes?.job_posted_count}
                        </Typography>
                    </Box>
                    <Box className='clientCardInfoCon'>
                        <Typography variant='caption' className='clientCardCaption'>
                            Ratings
                        </Typography>
                        <Box className='clientCardRatingCon'>
                            <Typography variant='body2' style={fixToOneLine} className='clientCardDescription'>
                                {cardData?.attributes?.rating}
                            </Typography>
                            <StarIcon className='clientCardIcon' />
                        </Box>
                    </Box>
                </Box>
            </CustomBox>
        )
    }
}


export default ClientCard