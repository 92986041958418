
import { Box, styled,Typography,Grid,Chip,Divider} from '@material-ui/core';
import {avatar} from "../../blocks/catalogue/src/assets"
import StarIcon from '@material-ui/icons/Star';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'
const mainlogo=require('./mainlogo.png')
const config = require("../../framework/src/config.js");
const eyeIcon = require("./ShowIcon.png")
const LikeIcon = require('./HeartIcon.png')
const SaveIcon = require('./ListIcon.png')
const delete_icon = require('./DeleteIcon.png')
export interface Props {
    data:any,
    deleteIcon:boolean,
    handledeletefn:() => void,
}

interface Data {
    'attributes' :{
        account_id: number;
        background_color: string;
        collection_name: string;
        cover_image_url:string;
        description:string;
        end_date: null
        is_liked: false;
        is_saved: false;
        likes: number;
         profile_photo: string;
        project_images: any [];
       project_name: string;
        start_date: null;
        username:string;
        
      }
    
}

interface S {

baseUrl:string;
skillList:boolean
}

interface SS {
}

export default class ProjectPortfolioExpCard extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props)

    this.state = {
      baseUrl:config.baseURL,
      skillList : false,

    }

  }

  
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .card_container":{
        width:"100%", height:'100%',
        maxHeight:'300px'
      },
      "& .card_image":{
        height:"260px", width:"100%", borderRadius:10, cursor: "pointer"
      }, "& .title_container":{
        display: "flex", justifyContent: "space-between",marginTop:"10px"
      },
      '& .project-title': {
        maxWidth: "100px",
        paddingRight: "6px"
      },
      '& .card-label': {
        fontSize: "12px",
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
      },
      '& .card-text': {
        fontSize: "12px"
      },
      '&.card-label, & .card-text': {
        lineHeight: "16px"
      },
      '& .manage-gap': {
        columnGap: "10px"
      },
      '& .manage-space ' :{
        display:"flex",
        alignItems: "center",
        gap:"5px"
      },
     "& .cover_image_blank":{
    backgroundColor:'black',
    borderRadius:"11.54px 11.54px 0px 0px"
},
'& .deleteIcon' :{
  position: 'absolute',
  top:"15px",
  right:"15px",
  cursor:"pointer"

},
[theme.breakpoints.down(1635)]: {
 
 "& .skills_container": {
   
  },
  
  }
  }));

  render() {
    const {data}=this.props;
    
    return (
      <this.MainWrapper style={{width:"100%"}}>
        <Box style={{maxWidth:'300px',minWidth:'250px',width:'300px'}}>
      
                    <Box className="card_container">
                      {this.props.deleteIcon &&
                      (
                      <Box onClick={()=>this.props.handledeletefn()}>
                      <img  src={delete_icon} className='deleteIcon' alt='deleteIcon'/>
                    </Box>
                    )
                      }
                      
                      <Box>
                        
                        <img
                          className="card_image"
                          src={`${this.state.baseUrl}${data?.cover_image}`}
                          alt="project cover image"
                        />
                      </Box>
                      <Box className="title_container">
                        <Box className="project-title">
                          <Typography
                            className="card-label"
                            title={data.project_title}
                          >
                            {data.project_title}
                          </Typography>
                          <Typography className="card-text">
                            {data.user_name}
                          </Typography>
                        </Box>
                        <Box
                          className="manage-gap"
                          style={{ display: "flex", height: "24px" }}
                        >
                          <Box className="manage-space">
                            <span className="card-text">
                              {data.view_count}
                            </span>
                            <img
                              className="eyeIcon"
                              src={eyeIcon}
                              alt="eye"
                            />
                          </Box>
                          <Box className="manage-space">
                            <span className="card-text">
                              {data.like_count}
                            </span>
                            <img
                              className="likeIcon"
                              src={LikeIcon}
                              alt="like"
                            />
                          </Box>
                          <Box className="manage-space manage-space-feature">
                            <span className="card-text">
                              {data.save_count}
                            </span>
                            <img
                              className="featureIcon"
                              src={SaveIcon}
                              alt="feature"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  

        </Box>
      </this.MainWrapper>
    )
  }
}
