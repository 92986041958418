// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import {
  getStorageData,
  setStorageData,
  removeStorageData
} from '../../../framework/src/Utilities';
import { getToken } from "../../../components/src/Base64Conversion";
import { workOpportunity } from "../../../components/src/OpportunityCard.web";
import {CreativeField} from '../../../components/src/CreativeFields.web'
import storage from "../../../../packages/framework/src/StorageProvider";
export interface Props {
  navigation: any;
  id: string;
}

export interface DropDownElement {
  id: number
  name: string
  color: string
  value: string
}


interface RowInt {
  id: string | number, 
  name: string,
  value:string
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


export interface SortingBySideBar {
  name:string;
  id:number;
  value:string;
}


export interface SortingByWorkType {
  name:string;
  id:number;
  value:string;
  tooltip:string;
}

export interface JobListDataTypeattributes{
  address: string, 
  addresses: [], 
  company_headquarter: string, 
  company_name: string, 
  company_page_id: number, 
  company_photo: string,
    company_photo_url: string, 
    country: string, 
    created_at: string, 
    employment_type: null, 
    employment_type_id: number,
    industry_id: number,
    industry_name: string, 
    job_description: string, 
    job_function: string, 
    job_title: string, 
    job_video: string, 
    job_video_url: string, 
    location: string, 
    other_skills: Array<string>, 
    preffered_location: Array<string>, 
    profile_id: number, 
    question_answer_id: Array<string>, 
    question_answers: Array<object>, 
    remote_job: boolean, 
    salary: string, 
    seniority_level: string, 
    skill_id: Array<number>, 
    skill_name: Array<string>, 
    sub_emplotyment_type: null, 
    total_inteview_rounds: number,
    image_url: string,
    followers_count: number
}
export interface JobListDataType {
    attributes:JobListDataTypeattributes, 
      id: string, 
      type: string
}

export interface DropDownMain {
  data: DropDownData[]
  total_work_opportunity_count: number
}

export interface DropDownData {
  id: number
  name: string
  value: string
  color: string
  work_opportunity_count: number
}


export interface JobListIOSDataType {
  item: {
    attributes: JobListDataTypeattributes, 
      id: string, 
      type: string
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isCreativeFieldChanged:boolean;
  first: boolean;
  searchText: string;
  savedListing:string;
  jobArray: JobListDataType[];
  allCategories:RowInt[];
  selectedCategories:number[];
  isSearched:boolean;
  accordianPop:boolean
  recent: Array<string>;
  openOppertunity:boolean;
  selectedOpportunityId:number | string;
  lastSearch: string;
  loading: boolean
  followIndex: number,
  token: string;
  tabValues:number;
  count:number;
  page:number;
  perPage:number;
  accordianToggle:boolean[];
  isSortOpen:boolean;
  isSkillsOpen:boolean;
  sortSelected:string;
  workDurtationSelected:string[];
  workTypeSelected:string[];
  experienceSelected:string[];
  hoursPerWeekSelected:string[];
  location: string | null;
  selectedLocation: string[];
  openLocation: boolean;
  rateMin:number;
  rateMax:number;
  findWorkSearch:string;
  currentNav:string;
  proposalTab:number
  userType:string;
  activeTab:string;
  openDropDown:boolean;
  SelectedDropDownColor:string;
  SelectedDropDownName:string;
  SelectedDropDownID:number;
  selectedOpportunity: string;
  workOpportunityData:workOpportunity[];
  locationListingData:string[];
  myWorkOpportinuity:object[];
  isRemotLocationSelected:boolean;
  isOtherLocationSelected:boolean;
  creativeFields:CreativeField[];
  isSliderChanged:boolean;
  isApplyDisable:boolean;
  totalWorkOpportunity: number;
  submitData: {
    sort?: string;
    "budget[min]"? : number;
    "budget[max]"? : number;
    skill_category_ids?: number[];
    location?: string[];
    work_duration?: string[];
    experience_level?: string[];
    required_hours_per_week?: string[];
    work_type?: string[];
    search?: string;
    saved_listing?: string;
  };
  emptyScreen: boolean;
  emptyProposoal: boolean;
  selectedSort:{
    name:string;
    value:string;
  };
  activeId: string;
  selectedOpportunityIdProposal:number | string;
  dropDown: DropDownData[]
  workOppertunityData:any;
  open:boolean;
  message:string;
  action:string;
  route:string;
}


interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = ""
  searchWorkApiCallId: string = ""
  locationListApiCallId: string = ""
  searchCategoryApiCallId: string = ""
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";
  openWorkOppurtunityCallId: string = "";
  openWorkOppurtunityLocalCallId: string = '';
  allOpportunityApiCallId: string = '';
  openWorkOppurtunityScrollCallId: string = "";
  pendingProposalAPIid:string="";
  pendingProposalDetailsAPIid:string="";
  jobSendProposalApiId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: '',
      route:"",
      open:false,
      loading: false,
      isSearched:false,
      openOppertunity:false,
      selectedOpportunityId:0,
      isCreativeFieldChanged:false,
      txtSavedValue: 'A',
      accordianPop:false,
      enableField: false,
      savedListing:'false',
      isApplyDisable:true,
      locationListingData:[],
      myWorkOpportinuity:[],
      isSliderChanged:false,
      first: true,
      emptyScreen: false,
      emptyProposoal: false,
      workOppertunityData:[],
      searchText: '',
      jobArray: [],
      recent: [],
      lastSearch: '',
      followIndex: -1,
      token:'',
      tabValues:0,
      allCategories:[],
      selectedCategories:[],
      page:1,
      perPage:18,
      count:5,
      accordianToggle:[true , true , true , true, true, true],
      isSortOpen:false,
      isSkillsOpen:false,
      sortSelected:"Recently listed",
      workDurtationSelected:[],
      workTypeSelected:[],
      experienceSelected:[],
      hoursPerWeekSelected:[],
      location:null,
      selectedLocation:[],
      openLocation:false,
      rateMax:5000,
      rateMin:100,
      findWorkSearch:'',
      currentNav:'find_work',
      proposalTab:0,
      userType:"client",
      activeTab:"",
      openDropDown:false,
      SelectedDropDownName:"Open opportunity",
      selectedOpportunity: 'open_opportunity',
      SelectedDropDownColor:"#59BDD3",
      isRemotLocationSelected: false,
      isOtherLocationSelected:false,
      SelectedDropDownID: 1,
      workOpportunityData: [],
      totalWorkOpportunity: 0,
      selectedSort:{
        name: "Recently Listed",
        value:'recently_listed'
      },
      creativeFields:[],
      submitData: {
        sort:'recently_listed',
        saved_listing: "false",
      },
      activeId: "",
      dropDown: [],
  selectedOpportunityIdProposal:0,
  message:"",
  action:""


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId==this.jobSendProposalApiId){
        if(responseJson?.message==="You can create proposal"){  this.handleNavigation("Cfproposalmanagement")
                  }
                  else{
                    this.setState(
                      {
                        action: "danger",
                        message: responseJson?.error,
                        open: true,
                        openOppertunity:false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            open: false,
                            openOppertunity:false,
                            message: "",
                            action: "danger"
                          });
                        }, 2000);
                      }
                    );
                    
                  }
      }
      if (apiRequestCallId === this.searchWorkApiCallId) {
        this.setState({
          workOpportunityData: responseJson?.data || [],
          loading: false
        });
        this.handleCalculatePageCount(responseJson?.work_opportunity_count || 0);
      }
      
      if (apiRequestCallId === this.searchCategoryApiCallId) {
        this.setState({
          creativeFields: responseJson?.data || []
        });
      }
      
      if (apiRequestCallId === this.locationListApiCallId) {
        this.setState({
          locationListingData: responseJson?.locations || []
        });
      }
        this.hanleApiResposne(apiRequestCallId,responseJson)
        this.hanleApiResposneNew(apiRequestCallId, message)
      
    }

  }

  handleLocalMuOpprtinitySet = async (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const jobListingPage = await getStorageData("jobListingPage")
    const CardId = await getStorageData("CardId")
    if (Number(jobListingPage)) {
      this.setState({
        page: Number(jobListingPage)
      })
    }

    if (responseJson.data) {
      this.setState({
        myWorkOpportinuity: responseJson.data,
        totalWorkOpportunity: responseJson.opportunities_count,
        emptyProposoal: false
      }, () => {
        if (CardId) {
          this.setState({
            activeId: CardId
          })
        } else {
          if (responseJson.data) {
            this.setState({
              activeId: responseJson.data[0].id
            })
          }
        }
      });
    }
    if (responseJson.error == "work opportunity not found") {
      this.setState({ emptyProposoal: true, myWorkOpportinuity: [], totalWorkOpportunity: 0 })
    }
  }

  hanleApiResposneNew = (apiRequestCallId: any, message: Message) => {
    if (apiRequestCallId === this.openWorkOppurtunityLocalCallId) {
      this.handleLocalMuOpprtinitySet(message)
    }
  }

  hanleApiResposne = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.openWorkOppurtunityCallId) {
      this.setState({
        page: 1
      })
      const CardId = await getStorageData("CardId")
      if (responseJson.data) {
        this.setState({
          myWorkOpportinuity: responseJson.data,
          totalWorkOpportunity: responseJson.opportunities_count,
          emptyProposoal: false
        }, () => {
          this.setActiveId(CardId, responseJson)
        });
      }
      if (responseJson.error == "work opportunity not found") {
        this.setState({ emptyProposoal: true, myWorkOpportinuity: [], totalWorkOpportunity: 0 })
      }
    }
    if (apiRequestCallId === this.openWorkOppurtunityScrollCallId){
      this.setState((prevState) => ({
        myWorkOpportinuity: [...prevState.myWorkOpportinuity, ...responseJson.data]
      }), async () => {
        await setStorageData("jobListingPage", this.state.page)
      })
    }
    if (apiRequestCallId === this.allOpportunityApiCallId) {
      this.setAllOpportunity(responseJson)
    }
  }

  setActiveId = (CardId: string, responseJson: { data: [{ id: string }] }) => {
    if (CardId) {
      this.setState({
        activeId: CardId
      })
    } else {
      if (responseJson.data) {
        this.setState({
          activeId: responseJson.data[0].id
        })
      }
    }
  }

  setAllOpportunity = (response: DropDownMain) => {
    if (response.total_work_opportunity_count == 0) {
      this.setState({
        emptyScreen: true
      })
    } else {
      this.setState({
        emptyScreen: false
      })
    }
    if (response.data.length) {
      const arr1 = response.data
      arr1.forEach(item => {
        const match = configJSON.dropDownDataStatic.find((el: DropDownElement) => el.value === item.value);
        if (match) {
          item.color = match.color;
        }
      });
      const filteredData = arr1.filter(item => {
        return !(item.value === "deleted_post" && item.work_opportunity_count === 0);
      });
      this.setState({ dropDown: filteredData })
    }
  }
  handleCloseToast=()=>{
    this.setState({open:false})
  }
  handleDisableSearch = () =>{
    if(this.state.findWorkSearch.length === 0){
      return true
    } else{
      return false
    }
  }
  handleNavigateFind =(e:any,data:any) =>{
    e.stopPropagation()
    let localdata = {
      account_id: data.account_id,
      user_role : "client"
    }
        setStorageData("PublicId",JSON.stringify(localdata));
      
    const message = new Message(getName(MessageEnum.NavigationMessage));
message.addData(getName(MessageEnum.NavigationTargetMessage), "PublicUserProfile");
message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(message);
  }
  handleResetClick = () =>{
    this.setState({
    selectedSort:{
      name: "Recently Listed",
      value:'recently_listed'
    },
    selectedCategories:[],
    workDurtationSelected:[],
    workTypeSelected:[],
    experienceSelected:[],
    hoursPerWeekSelected:[],
    findWorkSearch:'',
    rateMax:5000,
    isSliderChanged:false,
    rateMin:100,
    isRemotLocationSelected: false,
    isOtherLocationSelected:false,},()=>this.handleApplyFilterButton())
  }
  handleScroll = (event: any) => {
    const scrollableDiv = event;
    if(Math.abs(scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight) < 5){
      this.handleScrollApi();
    };
  }

  handleScrollApi = () => {
    const {page} = this.state
    this.setState({ loading: true });
    let token = this.state.token;

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openWorkOppurtunityScrollCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.workOpportunitiesEndPoint}?page=${page+1}&per_page=${4}&filter=${this.state.selectedOpportunity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({
      page: this.state.page +1
    })

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  debounceHandle = (tergetDiv: any) => {
    this.handleScroll(tergetDiv)
  }

  clearText = () =>{
    this.setState({findWorkSearch:'',isSearched:false},()=>this.handleApplyFilterButton())
  }

  handleDisableResetButton = () =>{
    const {selectedCategories,workDurtationSelected,workTypeSelected,experienceSelected,hoursPerWeekSelected, findWorkSearch, isOtherLocationSelected,isRemotLocationSelected, selectedSort, isSliderChanged } = this.state

    if(selectedCategories.length || workDurtationSelected.length || workTypeSelected.length || experienceSelected.length || hoursPerWeekSelected.length || findWorkSearch.length || isSliderChanged || isRemotLocationSelected || isOtherLocationSelected || selectedSort.value === "recommended" || selectedSort.value === "client_rating"){
      return false
    }else{
      return true
    }
  }

  searchWork = () => {
    const {page, perPage, submitData} = this.state
    this.setState({ loading: true });
    let token = this.state.token;

    const header = {
      token: token
    };

    let query = this.toQueryParams(submitData)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchWorkApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchWorkApiEndPoint + page + configJSON.searchWorkApiQueryMidPoint + perPage + "&" + query
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  myWorkOpportiunity = () => {
    this.setState({ loading: true });
    let token = this.state.token;

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openWorkOppurtunityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.workOpportunitiesEndPoint}?page=${1}&per_page=${4}&filter=${this.state.selectedOpportunity}`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  myWorkOpportiunityLocalStorage = (page: number) => {
    this.setState({ loading: true });
    let token = this.state.token;

    const header = {
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openWorkOppurtunityLocalCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.workOpportunitiesEndPoint}?page=${1}&per_page=${page * 4}&filter=${this.state.selectedOpportunity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id,
      requestMessage);
    return true;
  }

  allOpportiunity = () => {
    this.setState({ loading: true });
    let token = this.state.token;

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allOpportunityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dropDownApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleModalClose = (value:boolean) =>{
    if(value){
      this.handleApplyFilterButton()
    }
  }

  searchCategory = () => {
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchCategoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  locationList = () => {
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.locationListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  getTokenData = async () => {
    const token = await getToken();
    this.setState({token:token})
  };

  async componentDidMount() {

    await this.getTokenData();
    this.searchWork()
    this.searchCategory()
    this.locationList()
    storage.get("userInfo").then((res:any) => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.data) {
        this.setState({ userType: storeData.data.attributes.role_name, activeTab: "client"});
      }
    });
    this.allOpportiunity()
    this.setDropDownLocalStorege()
  }

  setDropDownLocalStorege = async () => {
    const dropdown = await getStorageData("dropdown")
    const jobListingPage = await getStorageData("jobListingPage")
    const CardId = await getStorageData("CardId")
    const final = JSON.parse(dropdown) as DropDownElement
    if (final) {
      this.setState({
        SelectedDropDownName: final.name,
        SelectedDropDownColor: final.color,
        selectedOpportunity: final.value,
        openDropDown: false,
        SelectedDropDownID: final.id,
      }, () => {
        if (Number(jobListingPage) > 1) {
          this.myWorkOpportiunityLocalStorage(Number(jobListingPage))
        } else {
          this.myWorkOpportiunity()
        }
      })
    } else {
      if (Number(CardId)) {
        this.myWorkOpportiunityLocalStorage(1)
      } else {
        this.myWorkOpportiunity()
      }
    }
  }
  
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setSearchText(inputValue)
    }
    this.setState({ lastSearch: inputValue.trim(), searchText: inputValue, loading: true });
  };

  setSearchText = (inputValue: string) => {
  };

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData('recent', JSON.stringify(this.state.recent));
    });
  }
  dropDownViewData = (element: DropDownElement) => {
    this.setState({
      SelectedDropDownName: element.name,
      SelectedDropDownColor: element.color,
      selectedOpportunity: element.value,
      openDropDown: false,
      SelectedDropDownID: element.id,
      activeId: ''
    }, async () => {
      this.myWorkOpportiunity()
      await setStorageData("dropdown", JSON.stringify(element))
      await setStorageData("jobListingPage", 1)
      await setStorageData("proposalListingPage", 1)
      await setStorageData("tabDetail", 'pending')
      await removeStorageData('CardId')
    })

  }
  handleRemoteWorkLocation = () =>{
    this.setState({isRemotLocationSelected:!this.state.isRemotLocationSelected,isApplyDisable:false})
  }

  handleWorkLocationSelected = () =>{
    this.setState({isOtherLocationSelected:!this.state.isOtherLocationSelected},()=>{
      if(this.state.isOtherLocationSelected === false){
        this.setState({selectedLocation:[]})
      }
    })
  }

  handleDisableApplyFilterButton = () =>{
    const {isSliderChanged,isCreativeFieldChanged, isApplyDisable} = this.state

    if(isCreativeFieldChanged || !isApplyDisable){
      return false
    }else{
      return true
    }
  }

  goToJobDetailPage = (jobId: string, companyName: string, companyLocation: string, companyLogo: string) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(getName(MessageEnum.NavigationTargetMessage), "JobDetailsPage");

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId
    });
    jobMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(jobMessage);
  };
  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleApplyFilterButton();
    }
  };
  handleApplyFilterButton = () =>{

    if(this.state.findWorkSearch){
      this.setState({isSearched:true})
    }else{
      this.setState({isSearched:false})
    }

    const {rateMax,rateMin, selectedCategories, workDurtationSelected, workTypeSelected, hoursPerWeekSelected, experienceSelected, findWorkSearch, selectedLocation, isRemotLocationSelected, isOtherLocationSelected, selectedSort, isSliderChanged, savedListing } = this.state

    let location : string[]  = []
    if(isRemotLocationSelected) location = ['remote']
    if(isOtherLocationSelected) location = [...location, ...selectedLocation]

    const submitData = {
      ...(selectedSort && { sort: selectedSort.value }),
      ...(rateMin !== null && isSliderChanged && { "budget[min]": rateMin}),
      ...(rateMax !== null && isSliderChanged && { "budget[max]": rateMax}),
      ...(selectedCategories.length > 0 && { skill_category_ids: selectedCategories }),
      ...(location.length > 0 && { location }),
      ...(workDurtationSelected.length > 0 && { work_duration: workDurtationSelected }),
      ...(experienceSelected.length > 0 && { experience_level: experienceSelected }),
      ...(hoursPerWeekSelected.length > 0 && { required_hours_per_week: hoursPerWeekSelected }),
      ...(workTypeSelected.length > 0 && { work_type: workTypeSelected }),
      ...(findWorkSearch && { search: findWorkSearch }),
      saved_listing: savedListing,
    };

  this.setState({page:1,submitData:submitData,isApplyDisable:true,isCreativeFieldChanged:false},()=>this.searchWork())

  }

  toQueryParams(obj:any) {
    const queryParams = Object.keys(obj).map(key => {
      const value = obj[key];
      
      if (Array.isArray(value)) {
        return value.map(v => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`).join('&');
      }
      
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    });
  
    return queryParams.join('&');
  }

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };

  handleAccordianToggle = (index: number) => {
    this.setState((prevState) => ({
      accordianToggle: prevState.accordianToggle.map((value, i) => (
        i === index ? !value : value
      )),
    }));
  };

  handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValues: newValue, savedListing: newValue === 0 ? "false" : "true",count:0  },()=>{
      this.handleResetClick()
      this.handleApplyFilterButton()
    });
  };

  handleToggleSortSelect = (value: string) => {
    this.setState({ sortSelected: value })
  }

  handleCalculatePageCount(result_count:number) {
    const pageCount = Math.ceil(result_count / this.state.perPage);
    this.setState({count:pageCount})
  }

  handleToggleSortBy = () => {
    this.setState({ isSortOpen: !this.state.isSortOpen })
  }

  handleToggleMultiSkillSelect = (value: string) => {
    this.setState({ sortSelected: value })
  }

  handleToggleMultiSkill = () => {
    this.setState({ isSkillsOpen: !this.state.isSkillsOpen })
  }

  handleWorkDurtationClick = (value:string , index:number) =>{
    const {workDurtationSelected} = this.state
    let findValue = workDurtationSelected.includes(value)

    if(!findValue){
      this.setState({workDurtationSelected: [...workDurtationSelected, value]})

    }else{
      let removedValue = workDurtationSelected.filter((ele) => ele !== value)
      this.setState({workDurtationSelected: removedValue})
    }

    this.setState({isApplyDisable:false})

  }

  handleWorkTypeClick = (value:string) =>{
    const {workTypeSelected} = this.state
    let findValue = workTypeSelected.includes(value)

    if(!findValue){
      this.setState({workTypeSelected: [...workTypeSelected, value]})
    }else{
      let removedValue = workTypeSelected.filter((ele) => ele !== value)
      this.setState({workTypeSelected: removedValue})
    }
    this.setState({isApplyDisable:false})
  }

  handleExperienceClick = (value:string) =>{
    const {experienceSelected} = this.state
    let findValue = experienceSelected.includes(value)

    if(!findValue){
      this.setState({experienceSelected: [...experienceSelected, value]})
    }else{
      let removedValue = experienceSelected.filter((ele) => ele !== value)
      this.setState({experienceSelected: removedValue})
    }
    this.setState({isApplyDisable:false})
  }

  handleHoursPerWeekClick = (value:string) =>{
    const {hoursPerWeekSelected} = this.state
    let findValue = hoursPerWeekSelected.includes(value)

    if(!findValue){
      this.setState({hoursPerWeekSelected: [...hoursPerWeekSelected, value]})
    }else{
      let removedValue = hoursPerWeekSelected.filter((ele) => ele !== value)
      this.setState({hoursPerWeekSelected: removedValue})
    }
    this.setState({isApplyDisable:false})
  }

  locationSelectedElm = (data: string) => {
    return this.state.location ? (this.state.location === data): false;
  }

  toggleLocation = (event: any) => {
    this.setState({ openLocation: !this.state.openLocation });
  }

  locationTitle = () => {
    return this.state.selectedLocation || "Select location";
  }

  handleClose = (value:boolean) =>{
    this.setState({openOppertunity:false},()=>this.handleModalClose(value))
  }

  handleOpenOpportunity = (id : number  | string) =>{
    this.setState({selectedOpportunityId:id},()=>this.setState({openOppertunity:true}))
  }
  handleNavigation = async (route: string) => {     
    await setStorageData("sendPraposalID", this.state.selectedOpportunityId)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleRangeSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) =>{
    const newValue = value as number[]
    this.setState({ rateMin: newValue[0], rateMax:newValue[1], isSliderChanged:true, isApplyDisable:false })
  }

  handleFindWorkSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({findWorkSearch:event.target.value})
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) =>{
    this.setState({page:page},()=>this.searchWork())
  }

  handleLocationApplyFn = (selectedLocations:string[]) : void =>{
    this.setState({selectedLocation:selectedLocations,isApplyDisable:false},()=>{
      this.setState({isOtherLocationSelected: this.state.selectedLocation.length > 0})
    }) 
  }

  handleCreativeFieldApply = (value:number[]) =>{
    this.setState({selectedCategories:value,isCreativeFieldChanged:true})
  }

  handleSortSelect = (name:string,value:string) =>{
    this.setState({selectedSort:{name,value},isApplyDisable:false})
  }

  handleChangeCurrentNavData = () =>{
    const {currentNav} = this.state
    if (currentNav === 'find_work'){
      this.setState({currentNav : "praposals"})
    }else{
      this.setState({currentNav : "find_work"})
    }
  }
   handleChange = (event:any, newValue:number) => {
    this.setState({proposalTab:newValue});
  };

   handleChangeIndex = (index:number) => {
    this.setState({proposalTab:index});

  };
  a11yProps23(index:number, label:string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: label,
    };
  }

  handleNavigationPostOpporunity = () => {     
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PostOpportunity");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigationExploreMore = () => {     
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ExploreMore");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  viewOffered = () => {
    this.setState({
      SelectedDropDownName: "Open opportunity",
      SelectedDropDownColor: "#59BDD3",
      selectedOpportunity: "open_opportunity",
      SelectedDropDownID: 1,
      activeId: ''
    }, () => { this.myWorkOpportiunity() }) 

  }

  handleProposal=(route:string)=>{
    this.setState({route:route})
    this.checkProposalVaild(this.state.selectedOpportunityId);
  }
  checkProposalVaild = async (id:number|string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobSendProposalApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/proposals/check_proposal?work_opportunity_id=${id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resetProposalLocalStorage = async() => {
    await setStorageData("proposalListingPage", 1)
    await setStorageData("tabDetail", 'pending')
    this.findProposalIdById(this.state.activeId)
  }

  findProposalIdById = (id: string|number) => {
    if (this.state.myWorkOpportinuity.length) {
    const opportunity:any = this.state.myWorkOpportinuity.find(
      (opportunity:any) => opportunity?.id == (id)
    );

    return opportunity ? opportunity.attributes.proposal_id : null;
  }
  };

}




// Customizable Area End
