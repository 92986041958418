import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
interface AddressAttributes {
  address: string;
  state: string;
  city: string;
  pincode: string;
  mark_default: boolean;
}

interface Address {
  id: string;
  type: string;
  attributes: AddressAttributes;
}
interface Meta {
  message: string;
  address_count: number;
  page: number;
}

interface AddressListResponse {
  data: Address[];
  meta: Meta;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pincodeError:string
  addressMsgError:string
  editLocationId:string
  selectedLocation: string,
  isOpenDialog:boolean
  dialogType:string
  address:string
  removeModel:boolean
  isDefaultAddress:number
  addressList:AddressListResponse
  city:string
  pincode:string
  locationState:string
  removeLocationId:string
  open:boolean
  action:string
  message:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLocationApiCallId :string = ""
  removeLocationApiCallId: string = ""
  setDefaultLocationApiCallId:string = ""
  addLocationApiCallId:string=""
  getLocationDetailCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      addressMsgError:"",
      editLocationId:"",
      pincodeError:"",
      selectedLocation: "",
      message:"",
      action:"",
      open:false,
      isOpenDialog: false,
      address: "",
      dialogType: "",
      removeModel: false,
      isDefaultAddress: 0,
      city: "",
      pincode: "",
      locationState: "",
      removeLocationId:"",
      addressList: {
        "data": [{
          "id": "",
          "type": "",
          "attributes": {
            "address": "",
            "state": "",
            "city": "",
            "pincode": "",
            "mark_default": false,
          }
        }],
        "meta": {
          "message": "List of addresses",
          "address_count": 0,
          "page": 1
        }
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 === this.getLocationApiCallId) {
      
      if (!responseJson1.error) {
        this.setState({addressList:responseJson1})
      } 
    }
    if(apiRequestCallId1===this.removeLocationApiCallId){
      if (!responseJson1.error) {
        this.setState({
          removeModel:false,
          open: true,
          action: "success",
          message: "Location removed successfully",
        })
        this.handleGetLocation()
      } 
    }
    if(apiRequestCallId1===this.setDefaultLocationApiCallId){
      if (!responseJson1.error) {
        this.handleGetLocation()
      } 
    }
    if(apiRequestCallId1 === this.addLocationApiCallId){
      if (!responseJson1.errors) {
        this.handleGetLocation()
        this.setState({
          isOpenDialog:false,
          selectedLocation:"",
          address:"",
          pincode:"",
          locationState:"",
          pincodeError:"",
          addressMsgError:""
        })
      }
    }
    if(apiRequestCallId1 === this.getLocationDetailCallId){
      if(!responseJson1.error){
        this.setState({
          selectedLocation:responseJson1.data.attributes.city,
          address:responseJson1.data.attributes.address,
          pincode:responseJson1.data.attributes.pincode,
          locationState:responseJson1.data.attributes.state,
        })
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.handleGetLocation()
  }
  handleChangeAddress = (value: string) => {
    if (value.length < 12 && value.length !==0) {
      this.setState({addressMsgError: "Address must be at least 12 characters long" });
    } else if (value.length > 100) {
      this.setState({addressMsgError: "Address cannot exceed 100 characters" });
    } else {
      this.setState({addressMsgError: "" });
    }
    this.setState({address: value,})
  };
  handleAddLocation = () =>{
    this.setState({isOpenDialog:!this.state.isOpenDialog,selectedLocation:"",
      address:"",
      pincode:"",
      pincodeError:"",
      locationState:"",
      addressMsgError:""})
  }
  isButtonDisabled = (): boolean => {
    const { address, selectedLocation, pincode, pincodeError, addressMsgError } = this.state;
    return (
      address !== "" &&
      selectedLocation !== "" &&
      pincode !== "" &&
      pincodeError === "" &&
      addressMsgError === ""? true : false
    );
    
  };
  handleLocation = async (id:string) =>{
      const tokens = await getStorageData("userInfo");   
      let newToken = JSON.parse(tokens)
      const { meta: { token } } = newToken; 
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const body = {
        "address": {
          "address": this.state.address,
          "city": this.state.selectedLocation,
          "state": this.state.locationState,
          "pincode": this.state.pincode
      }
      }
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        id ? `/bx_block_settings/addresses/${id}`:"/bx_block_settings/addresses"
      )
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        id ? "PUT" :"POST"
      );
      this.addLocationApiCallId = getValidationsMsg.messageId
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleAddLocationPopUp = (type:string) =>{
    this.setState({isOpenDialog:!this.state.isOpenDialog,dialogType:type,
      selectedLocation:"",
      address:"",
      pincode:"",
      pincodeError:"",
      locationState:"",
      addressMsgError:"",
    })
  }
  handleEditDialog = (id:string)=>{
    this.getLocationById(id)
    this.setState({isOpenDialog:!this.state.isOpenDialog,dialogType:"edit",editLocationId:id})
    
  }
  getLocationById = async (id:string) =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_settings/addresses/${id}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
    this.getLocationDetailCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleRemoveModel = (id:string) =>{
    this.setState({removeModel:!this.state.removeModel,removeLocationId:id})
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleRemoveAddress = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_settings/addresses/${this.state.removeLocationId}`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "DELETE"
    );
    this.removeLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleRemoveModelClose = () =>{
    this.setState({removeModel:!this.state.removeModel})
  }

  handleGetLocation= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/addresses/?page=1&per_page=30"
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.getLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleSetDefaultLocation= async(id:string) =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_settings/addresses/${id}/update_mark_default`
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );
    this.setDefaultLocationApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      event.target.value = value;
    } else {
      event.preventDefault();
      event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
    }
  };
  handleChangeLocationText = (data: string) => {
    this.setState({ selectedLocation: data})
  };
  handleChangeLocation = (data: string) => {
    const [selectedLocation, locationState] = data.split(',');
    this.setState({ selectedLocation, locationState });
  };
  handlePincode = (data:string) =>{
    if (data.length !== 6 && data.length !==0) {
      this.setState({pincodeError:"Pincode must be exactly 6 digits."})
    }else{
      this.setState({pincodeError:""})
    }
    this.setState({pincode:data})
  }
  // Customizable Area End
}
