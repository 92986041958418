// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message"; 
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData} from "framework/src/Utilities";


interface Service {
  id: string; 
  type: string; 
  attributes: ServiceAttributes; 
}

interface ServiceAttributes {
  id: number;
  service_name: string; 
  duration: string | null; 
  min_timeline: string; 
  max_timeline: string; 
  work_setting: string; 
  city: string; 
  minimum_rate: number; 
  maximum_rate: number; 
  description: string; 
  links: string[]; 
  created_at: string; 
  updated_at: string; 
  account_details: AccountDetails; 
  skills: Skill[]; 
  cover_image: string; 
  files: any[]; 
  deliverables: Deliverable[]; 
  projects: Collection[]; 
}
interface AccountDetails {
  name: string; 
  email: string; 
  id: number; 
  profile_photo: string | null; 
  background_color: string; 
}

interface Skill {
  id: number; 
  name: string; 
  created_at: string; 
  updated_at: string; 
  category_id: number; 
}

interface Deliverable {
  id: number; 
  name: string; 
  created_at: string; 
  updated_at: string; 
}

interface Collection {
  collection_id: number; 
  collection_name: string; 
  projects: Project[]; 
}
interface Project {
  id: number; 
  project_title: string; 
  cover_image: string; 
  user_name: string; 
  profile_image: string | null; 
  background_color: string; 
  like_count: number; 
  view_count: number; 
  save_count: number; 
}

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End

}

interface S {

  // Customizable Area Start
  services:Service[],
  isLoader:boolean
  // Customizable Area End

}

interface SS {
  id: any;
}

export default class ServicesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getAllServicesApiId :string = ""
  isPublic:boolean=false
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      services:[],
      isLoader:true
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      if (apiRequestCallId1 === this.getAllServicesApiId){
        if (!responseJson1.error){
          this.setState({services:responseJson1.data})
        }
        this.setState({isLoader:false})
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    this.isPublic = await getStorageData("isPublicProfile",true)
    this.handleGetService()

  }
  handleActiveTab(path: string) {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigateViewService = (item: Service) => {
    this.props.navigation.navigate("ViewService", { id: item.id })
  }

  handleGetService = async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const PublicId = await getStorageData("PublicId",true);
    const apiEndPoint = PublicId ? `${configJSON.getServiceListEndPoint}&account_id=${PublicId.account_id}` :configJSON.getServiceListEndPoint
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllServicesApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End

}
