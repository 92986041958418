import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
const twitterIcon = require("./twitterIcon.png")
const subLogo = require("./sublogo.png")
const mainLogo = require("./mainlogo.png")
const fbIcon = require("./fbicon.png")
const igIcon = require("./idicon.png")
const linkedinIcon = require("./linkedinicon.png")

interface Props {
}

interface S {
}

interface FooterSectionProps {
    width?: string;
    cursor?:string
}

const FooterContainer = styled(Box)({
  padding: "0 66px",
  boxSizing: "border-box",
    backgroundColor: "#171717", height: "500px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center"
  })
  
  const FooterHeadLogo = styled(Box)({
    display: "flex", gap: "26px", flexDirection: "column"
  })
  const FooterLogo = styled("img")({
    width: "200px", height: "60.51px"
  })
  const FooterLogoText = styled(Box)({
    color: "#C7C7C7", fontSize: "20px", lineHeight: "24px", fontWeight: 600,fontFamily:"inter"
  })
  const FooterHeaderContainer = styled(Box)({
    display: "flex", justifyContent: "space-between", width: "100%"
  })
  
  const FooterHeader = styled(Box)({
    width: "min(100%,1632px)",
    margin: "0 auto",
    boxSizing: "border-box",
    marginTop: "70px",
    marginBottom: "45px",
  })
  
  const FooterHeadSection = styled(Box)({
    display: "flex", gap: "16px", flexDirection: "column"
  })

  const FooterSection = styled(Typography)<FooterSectionProps>(({ width }) => ({
    borderBottom: "2px solid transparent",
    borderImageSource: "linear-gradient(271.26deg, #A8E5EC -32.48%, #1CBAE0 -6.29%, #6C98EE 19.89%, #AB9EF5 55.1%, #CF8CCC 88.51%, #FFB9C7 111.09%, #FFC29F 140.88%)",
    backgroundImage: "linear-gradient(271.26deg, #A8E5EC -32.48%, #1CBAE0 -6.29%, #6C98EE 19.89%, #AB9EF5 55.1%, #CF8CCC 88.51%, #FFB9C7 111.09%, #FFC29F 140.88%)",
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    borderImageSlice: 1,
    borderImageRepeat: "round",
    fontFamily: "DM Serif Display",
    fontSize: "21.33px",
    fontStyle: "italic",
    fontWeight: "bold",
    width: width
  }));
  
  const FooterSection2 = styled(Box)({
    display: "flex", gap: "16px", flexDirection: "column", marginRight: "11%"
  })
  
  const FooterSection2Text = styled(Typography)({
    fontSize: "16px", marginTop: "20px", color: "#C7C7C7", fontWeight: 500, fontFamily: "inter"
  })
  
  const FooterSectionText = styled(Typography)<FooterSectionProps>(({cursor})=>({
    fontSize: "21.33px", color: "white", fontWeight: 400, cursor: cursor, fontFamily: "inter"
  }))
  
  const FooterDivider = styled(Box)({
    borderBottom: "2px solid transparent",
    borderImageSource: "linear-gradient(271.26deg, #A8E5EC -32.48%, #1CBAE0 -6.29%, #6C98EE 19.89%, #AB9EF5 55.1%, #CF8CCC 88.51%, #FFB9C7 111.09%, #FFC29F 140.88%)",
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    borderImageSlice: 1,
    width: "100%",
    maxWidth: "1632px",
    margin: "0 auto"
  })
  
  const MainFooter = styled(Box)({
    display: "flex", 
    width: "100%",
    maxWidth: "1632px",
    margin: "0 auto", 
    justifyContent: "space-between", alignItems: "center", marginTop: "30px", marginBottom: "54px"
  })
  
  const MainFooterContainer = styled(Box)(({theme}) =>({
    width: "65%",
     display: "flex", alignItems: "center", justifyContent: "space-between",
     [theme.breakpoints.up(1420)]: {
      width: "60%",
     },
     [theme.breakpoints.up(1520)]: {
      width: "55%",
     }
  }))
  
  const FooterIconContainer = styled(Box)({
    display: "flex", gap: "20px"
  })
  
  const FooterIconStyle = styled('img')({
    width: "32px", height: "31px",cursor:"pointer"
  })
  const FooterLogoIcon = styled('img')({
    width: "100px", height: "31px", marginLeft: "10px"
  })
  const MainFooterSection = styled(Box)({
    display: "flex", gap: "10px"
  })
  const MainFooterSectionText = styled(Box)<FooterSectionProps>(({cursor}) => ({
    fontSize: "16px", fontWeight: 400, fontFamily: "inter", color: "#C7C7C7", cursor:cursor
  }))
  const FooterCopyRight = styled(Typography)({
    fontSize: "16px", fontWeight: 400, fontFamily: "inter", color: "#FFFFFF"
  })

class Footer extends Component<Props, S>{
  constructor(props:Props) {
    super(props);
    this.state = {};
  }

  handleLinkClick = (url: string,target:boolean) => {
    const link = document.createElement("a");
    link.href = url;
    if(target) link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };
  render() {
    
    return (
        <FooterContainer>
        <FooterHeader>
        <FooterHeaderContainer>
          <FooterHeadLogo>
            <FooterLogo src={mainLogo} alt="logo"/>
            <FooterLogoText>
              <Typography style={{fontFamily:"inter"}}>Your one stop marketplace for exclusive</Typography>
              <Typography style={{fontFamily:"inter"}}>design resource</Typography>
            </FooterLogoText>
          </FooterHeadLogo>
          <FooterHeadSection>
            <FooterSection width={"60%"}>
              For Business
            </FooterSection>
            <FooterSectionText>Post an Opportunity</FooterSectionText>
            <FooterSectionText>Project management</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Why us?</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>FAQs</FooterSectionText>
          </FooterHeadSection>
          <FooterHeadSection>
            <FooterSection width={"78%"}>
              For Designers
            </FooterSection>
            <FooterSectionText>Create a portfolio</FooterSectionText>
            <FooterSectionText>Freelancing</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Why us?</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>FAQs</FooterSectionText>
          </FooterHeadSection>
          <FooterSection2>
            <FooterSectionText>
              Community
            </FooterSectionText>
            <FooterSection2Text>Company</FooterSection2Text>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>About us</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>FAQs</FooterSectionText>
            <FooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Contact us</FooterSectionText>
          </FooterSection2>
        </FooterHeaderContainer>
        </FooterHeader>
        <FooterDivider></FooterDivider>
        <MainFooter>
          <MainFooterContainer> 
            <MainFooterSection>
              <MainFooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Terms & Conditions</MainFooterSectionText>
              <MainFooterSectionText> | </MainFooterSectionText>
              <MainFooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Privacy Policy</MainFooterSectionText>
              <MainFooterSectionText> | </MainFooterSectionText>
              <MainFooterSectionText cursor='pointer' onClick={() => this.handleLinkClick("/TermsConditions",false)}>Code of conduct</MainFooterSectionText>
            </MainFooterSection>
            <CopyRightBox>
              <FooterCopyRight>© 2022-Dec-10-1 DEZIGNER’S KNOT</FooterCopyRight>
            </CopyRightBox>   
          </MainFooterContainer>
          <FooterIconContainer>
          <FooterIconStyle src={linkedinIcon} alt="linkedInIcon" onClick={() => this.handleLinkClick("https://www.linkedin.com/",true)}/>
          <FooterIconStyle src={igIcon} alt="igIcon" onClick={() => this.handleLinkClick("https://www.instagram.com/",true)}/>
          <FooterIconStyle src={twitterIcon} alt="twitterIcon" onClick={() => this.handleLinkClick("https://x.com/",true)}/>
          <FooterIconStyle src={fbIcon} alt="fbIcon" onClick={() => this.handleLinkClick("https://www.facebook.com/",true)}/>
          <FooterLogoIcon src={subLogo} alt="subLogo" />
        </FooterIconContainer>
        </MainFooter>
      </FooterContainer>
    );
  }
}

const CopyRightBox = styled(Box)(({theme}) =>({
  [theme.breakpoints.up(1340)]: {
    marginLeft: "-1rem !important",
  }
}))



export default Footer;
