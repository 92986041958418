import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import dayjs, { Dayjs } from "dayjs";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
let tokenData = "";

type DateInput = string | number | Date | Dayjs | null;

interface IdMap {
  [key: string]: number;
};

export interface FinalMain {
  proposal?: FinalProposal
  contract_status?: string
  activate_on?: string
  project_timeline?: number
  project_timeline_type?: string
  overall_budget?: number
  milestones_details?: FinalMilestonesDetail[]
}

export interface FinalProposal {
  data?: FinalProposalData
}

export interface FinalProposalData {
  id: string
  type: string
  attributes: FinalProposalDataAttributes
}

export interface FinalProposalDataAttributes {
  id: number
  created_at: string
  updated_at: string
  service_rate: number
  give_your_pitch: string
  profile_photo: string
  background_color: any
  location: string
  user_name: string
  likes_count: number
  view_count: number
  rating: number
  received_time_ago: string
}

export interface FinalMilestonesDetail {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  deliverables: string[]
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId: string;
  dropDownId: number;
  proposalId: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  contractDtailsContract: FinalMain
  contractDtailsProposal: FinalProposal
  contractMilestone: FinalMilestonesDetail[]
  mileStoneExpandData: { 
    expandedTerms: IdMap 
  },
  moreMilestone: boolean
  contentType: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ActiveContractController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardid = this.props.cardId;
  proposalId = this.props.proposalId;
  apiWorkOpportunityContract: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      contractDtailsContract: {},
      contractDtailsProposal: {},
      contractMilestone: [],
      mileStoneExpandData: {
        expandedTerms: {}
      },
      moreMilestone: false,
      contentType: "awaiting",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiWorkOpportunityContract) {
        this.manageFinalData(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    this.getContarctDataProposal()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if ((this.props.cardId !== prevProps.cardId)) {
      this.cardid = this.props.cardId;
      this.proposalId = this.props.proposalId;
      if (this.props.cardId && this.props.proposalId) {
        this.getContarctDataProposal()
      }
    }
  }

  manageFinalData = (responseJson: FinalMain) => {
    if (responseJson.proposal && responseJson.milestones_details) {
      this.setState({
        contractDtailsProposal: responseJson.proposal,
        contractDtailsContract: responseJson,
        contractMilestone: responseJson.milestones_details
      })
    }
  }
  

  toggleTerm = (index: number) => {
    this.setState((prevState) => ({
      mileStoneExpandData: {
        ...prevState.mileStoneExpandData,
        expandedTerms: {
          ...prevState.mileStoneExpandData.expandedTerms,
          [index]: !prevState.mileStoneExpandData.expandedTerms[index],
        }
      }
    }));
  };
  
  formatDateShort = (dateString: DateInput | undefined) => {
    if (dateString) {
      const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
      const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString('en-GB', options);
    }
  }

  checkMoreMilestone = () => {
    this.setState({ moreMilestone: true })
  }

  navigateViewWork = () => {
    this.props.navigation.navigate("ViewWorkDetail",{id: this.props.cardId, dropdownId: this.props.dropDownId})
  }

  displayAllProposal = () => {
    this.setState({
      contentType: 'proposalList'
    })
  }

  getContarctDataProposal = () => {
    const headerObj = {
      "token": tokenData,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiWorkOpportunityContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateContractAPiEndPoint + `?data[attributes][work_opportunity_id]=${this.cardid}&data[attributes][proposal_id]=${this.proposalId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToContract = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ContractPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  displayAwaiting = () => {
    this.setState({
      contentType: 'awaiting',
    })
  }
  // Customizable Area End
}