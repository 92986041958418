import {
  withStyles,
  Switch,
  CircularProgress,
  Box,
  Typography
} from "@material-ui/core";
import React from "react";

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    padding: 0,
    height: 26,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#8C8C8C',
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#757575',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#4f4f4f',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

interface CustomCircularProgressProps {
  value: number;
  size: number;
  thickness: number;
  color: string;
  text: string;
  amount: string
}

export const CustomCircularProgress = withStyles(() => ({
  root: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    strokeLinecap: "round",
  },
}))(({ value, size, thickness, color, text, amount }: CustomCircularProgressProps) => (
  <Box
    position="relative"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    style={{position: 'relative'}}
  >
    <CircularProgress
      variant="determinate"
      value={100}
      size={size}
      thickness={thickness}
      style={{ color: "gray", opacity: 0.2, transform: "rotate(90deg)" }}
    />
    <CircularProgress
      variant="determinate"
      value={value}
      size={size}
      thickness={thickness}
      style={{ color, position: "absolute", strokeLinecap: "round", }}
    />
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gridGap={24}
    >
      <Typography
        variant="caption"
        component="div"
        style={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', color: '#8C8C8C' }}
      >
        {text}
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{ fontFamily: 'Inter', fontSize: '42px', fontWeight: 600, lineHeight: '28.49px', letterSpacing: '0.45px', color: '#FFF2E3' }}
      >
        {amount}
      </Typography>
    </Box>
  </Box>
));

interface GradientCircularProgressProps {
  value: number;
  size: number;
  thickness: number;
  amount: number;
}

export const GradientCircularProgress: React.FC<GradientCircularProgressProps> = ({
  value,
  size,
  thickness,
  amount
}) => {
  const radius = (size - thickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = (1 - (value) / 100) * circumference; 
  return (
    <Box
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      marginTop={8}
    >
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="rgba(255, 242, 227, 1)"
          strokeWidth={thickness-5}
          fill="none"
          opacity={1}
        />
        <defs>
          <linearGradient
            id="gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          
          >
            <stop offset="0%" stopColor="#FFC29F" />
            <stop offset="16.15%" stopColor="#FFB9C7" />
            <stop offset="33.85%" stopColor="#CF8CCC" />
            <stop offset="50.52%" stopColor="#AB9EF5" />
            <stop offset="67.71%" stopColor="#6C98EE" />
            <stop offset="83.85%" stopColor="#1CBAE0" />
            <stop offset="100%" stopColor="#A8E5EC" />
          </linearGradient>
        </defs>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="url(#gradient)"
          strokeWidth={thickness}
          fill="none"
          style={{transform: 'translateX(0%) translateY(100%) rotate(-90deg)'}}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap={24}
      >
        <Typography
          variant="subtitle2"
          style={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', color: '#8C8C8C' }}
        >
          Total Contracts
        </Typography>
        <Typography
          variant="h4"
          style={{
            color: "rgba(255, 255, 255, 1)",
            fontFamily: 'Inter',
            fontSize: '42px',
            fontWeight: 600,
            lineHeight: '28.49px',
            letterSpacing: '0.45px'
          }}
        >
          {amount}
        </Typography>
      </Box>
    </Box>
  );
};