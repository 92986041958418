Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "proposalgeneration";
exports.labelBodyText = "proposalgeneration Body";

exports.btnExampleTitle = "CLICK ME";
exports.proposalEndpoint = "bx_block_proposal_generation/proposal_generations";
exports.deleteMethod = "DELETE";
exports.updateMethod = "PUT";
exports.formDataContent = "application/json";
exports.postMethod= "POST";
exports.shareProposalEndpoint = "bx_block_proposal_generation/proposal_generations/share_proposal";

exports.allProposalText = "All proposals";
exports.revisedText = "Recieved an hour ago";
exports.workpostNameText = "Work post name";
exports.image = "image";
exports.rupeeSymbol = "₹ ";
exports.prPerHourText = "pr/hr";
exports.viewProfileText = "View profile";
exports.messageText = "Message";
exports.shortListText = "Shortlist";
exports.archiveText = "Archive";
exports.offerContractText = "Offer contract";
exports.resendofferContractText = "Re-send offer";
exports.viewText = "Views";
exports.likesText = "Likes";
exports.submittedRateText = "Submitted rate";
exports.ratings = "Ratings";

exports.submittedWorkText = "Submitted work";
exports.labelName = "Label name";
exports.pitchText = "Pitch";
exports.hourlyRateText = "Hourly rate";
exports.hourlyRateDescription = "( Your offer: Rs.";
exports.closingBracket = ")";
exports.overAllEstText = "Overall project est.";
exports.hoursPerWeekText = "hours a week";
exports.startDateText = "Starting date";
exports.additionalTextHeading = "Additional files";
exports.unShortListText = "Remove from Shortlists";
exports.unArchiveText = "Unarchive";
exports.rupeeText = "Rs.";
exports.newText = "New";
exports.noProjectsAvailable = "No projects available.";
exports.httpRegex = /https?:\/\/(www\.)?/g;
exports.validationApiContentType = "application/json";
exports.proposalUserEndPoint = "/bx_block_joblisting/proposals/";
exports.updateProposalStatusEndPoint = "/bx_block_joblisting/proposals/update_proposal_status"
exports.getApiMethodType = "GET";
exports.putApiMethodType = "PUT";
exports.baseURL = require('../../../framework/src/config');
// Customizable Area End