import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from "../../../framework/src/StorageProvider.web";
import React, { RefObject } from "react";
import {getStorageData} from "../../../framework/src/Utilities"
import jwtDecode from "jwt-decode";
import { setStorageData, removeStorageData } from "../../../framework/src/Utilities";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

export interface ResponseForStorage {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      photo: string | null;
      background_color: string;
    };
  };
}

export interface ResponseForLinkedinData {
    sub: string;
    email_verified: boolean;
    name: string;
    locale: {
      country: string;
      language: string;
    };
    given_name: string;
    family_name: string;
    email: string;   

    picture?: string; }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  name: string;
  image: File[];
  imageUrl:string;
  conditionsCheckBox:boolean;
  getGoogleApiKey:string | undefined;
  lastName: string;
  username: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  isCardOpen: boolean,
  userType: string,
  imageSelected: string,
  avatarDefaultColor: string,
  isSubmit: boolean,
  isLocationAdded: boolean,
  open: boolean,
  message: string,
  action: string,
  suggestions: string[],
  address:string,
  location: string,
  userID: number | string,
  successMsg: string;
  accountData: { [key: string]: string };
  Country:string;
  signUpLoader:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: Message | string = "";
  validationApiCallId: string = "";
  apiEmailSignUpCallId: string = "";
  apiProfileCallId: string = "";
  apiLocationCallId: string = "";
  getDetailsOfLinkedinUserApiCallId: string = "";
  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  refElm: RefObject<HTMLInputElement>;
  getGoogleSignUpCallId: string = "";
  sendBirdRegistrationId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      conditionsCheckBox:false,
      signUpLoader:false,
      getGoogleApiKey:process.env.REACT_APP_GOOGLE_API,
      image:[],
      imageUrl:"",
      name: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      isCardOpen: false,
      userType: "",
      imageSelected: "",
      avatarDefaultColor: "",
      isSubmit: false,
      isLocationAdded: false,
      open: false,
      message: "",
      action: "",
      suggestions: [],
      address:'',
      location: "",
      userID: 0,
      successMsg: "",
      accountData: {},
      Country:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.refElm = React.createRef<HTMLInputElement>();
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.handleValidationResponse(message);
        } else if (apiRequestCallId === this.apiEmailSignUpCallId) {
          this.handleSignupResponse(message);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiProfileCallId) {
          this.handleProfileResPonseData(message);
        } else if (apiRequestCallId === this.apiLocationCallId) {
          this.handleLocationResPonseData(message);
        } else if (apiRequestCallId === this.getDetailsOfLinkedinUserApiCallId) {
          this.onSuccessfullyFetchingTheDetails(responseJson.user_data)
        } 
        this.handleSendbirdRegistration(responseJson,apiRequestCallId);
        
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleValidationResponse = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.arrayholder = responseJson.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleProfileResPonseData = async (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.sendBirdRegistration();
    if (!responseJson.errors) {
      await this.handleSetInStorage(responseJson)
      storage.set("userProfile", JSON.stringify(responseJson));
    } else {
      this.setState( {
        open: true,
        action: "danger",
        message:responseJson.errors[0]
      })
    }
  }

  handleSetInStorage = async (responseJson:ResponseForStorage) =>{
    const userInfo = await getStorageData('userInfo')
      let jsonData = JSON.parse(userInfo)

      if(responseJson.data.attributes.photo !== null){
        jsonData.data.attributes.profile_image = responseJson.data.attributes.photo
      }

      jsonData.data.attributes.background_color = responseJson.data.attributes.background_color

      storage.set("userInfo", JSON.stringify(jsonData));
  }

  handleLocationResPonseData = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const data = responseJson.data.json();
      const listData = data.predictions.map((item: {
        description : string,
        matched_substrings : {length: number, offset: number}[],
        place_id : string,
        reference : string,
        structured_formatting : 
        {
          main_text : string,
          main_text_matched_substrings : {length: number, offset: number}[],
          secondary_text : string
        },
        terms : {offset: number, value: string}[],
        types : string[]
      }) => item.structured_formatting.main_text);
      this.setState({ suggestions: [...listData] });
    } else {
      this.setState( {
        open: true,
        action: "danger",
        message: Object.values(responseJson.errors[0]).join()
      })
    }
  }

  handleSignupResponse = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      this.setState({ isCardOpen: true, otpAuthToken: responseJson.meta.token, userID: responseJson.data.id});
      storage.set("userInfo", JSON.stringify(responseJson));
    } else {
      this.setState({
        signUpLoader:false,
        open: true,
        message: Object.values(responseJson.errors[0]).join(),
        action: "danger"
      })
    }
  }

  handleCloseToast() {
    this.setState({
      open: false,
      message: ""
    })
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;
    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
    },
    secureTextEntry: true
  };

  handleAvatarDefaultColor = (value: string) => {
    this.setState({
      avatarDefaultColor: value,
      imageUrl: ""
    })
  };

  handleSkipBtn = () => {
    this.sendBirdRegistration()
  };

  sendBirdRegistration = () => {
    const header = {
      token: this.state.otpAuthToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendBirdRegistrationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendBirdRegistrationEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleSendbirdRegistration = async (response: Record<string, string>, apiRequestCallId: string) => {
    if (apiRequestCallId === this.sendBirdRegistrationId) {
      if (response.message === "User created successfully" && response.data) {
        this.setState({
          message: response.message + configJSON.sendbirdSuccessMessage,
          open: true,
        })

        const dataNew = response.data as unknown as {access_token: string,user_id: string}

        const userInfo = await getStorageData('userInfo')
        let jsonData = JSON.parse(userInfo)
  
        if(!!dataNew.access_token && !!dataNew.user_id){
          jsonData.meta.sendbird_user_id = dataNew.user_id;
          jsonData.meta.sendbird_access_token = dataNew.access_token;
        }

        await storage.set("userInfo", JSON.stringify(jsonData));

        this.handleDoneBtn();
      } else {
        this.sendBirdRegistration();
      }
    }
  }

  handleDoneBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleProfileData() {
    const formData = new FormData();
    formData.append("profile[city]", this.state.location);
    formData.append("profile[address]", this.state.address);
    formData.append("profile[country]", this.state.Country);
    formData.append("profile[postal_code]", "211002");
    if (this.state.image.length) {
      this.state.image.forEach(file => {
        formData.append("profile[photo]", file);
      })
    } else {
      formData.append("profile[background_color]", this.state.avatarDefaultColor);
    }

    const header = {
      token: this.state.otpAuthToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createProfile}/${this.state.userID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleToggle = (value: number) => {
    if (value === 1) {
      this.setState({ userType: "1" })
    }
    else if (value === 2) {
      this.setState({ userType: "2" })
    }
  };

  handleAcceptConditions = ()=>{
    this.setState({conditionsCheckBox:!this.state.conditionsCheckBox});
  }

  handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const tempFile = event.target.files[0];
      if(!this.validFileFormat(tempFile)) {
        if (this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const maxFileSizeMB = 2;
      if (tempFile.size > maxFileSizeMB * 1024 * 1024) {
        if (this.refElm.current) {
          this.refElm.current.value = '';
        }
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        return;
      }
      this.setState({
        image: [tempFile],
        imageUrl: URL.createObjectURL(tempFile),
        avatarDefaultColor: ""
      });
      if (this.refElm.current) {
        this.refElm.current.value = '';
      }
    }
  };

  handleOnSignup(): boolean {
    if (
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.emailError
      );
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      return false;
    }

    if (this.state.username === "" || this.state.username === null) {
      return false;
    }

    if (this.state.name === "" || this.state.name === null) {
      return false;
    }

    if (this.state.userType === "" || this.state.userType === null) {
      return false;
    }

    if(this.state.conditionsCheckBox === false){
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      full_name: this.state.name[0],
      first_name:this.state.name[0],
      email: this.state.email[0],
      password: this.state.password[0],
      user_name: this.state.username[0],
      role_id: this.state.userType,
    };
    this.setState({signUpLoader:true})

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleLoginClick = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  handleLocationData = (data: string) => {
    this.setState({location: data});
  };

  suggestionLocationData = async (data: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${data}&types=(cities)&components=country:in&key=AIzaSyAr4npdtKgtN4ghvTUj_DeOCmCGnHrq7K0`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeLocation = (data: string) => {
      let values = data.split(',')
      this.setState({address:data, location:values[0].toLowerCase(),Country: values[values.length - 1]})
  }

  handleChangeLocationText = (data: string) => {
    this.setState({address:data})
  }

  handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) =>{
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleChangeLocationTextBlur = () => {
    if(!this.state.location){
      this.setState({address:""})
    }
  }

  validFileFormat = (fileData: File) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validImageTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid image file (jpg, png).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  handleFileSelect() {
    if (this.refElm.current) {
      this.refElm.current.click();
    }
  }

  fetchUserDetailFromLinkedin = (code: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const redirectURl = `${window.location.origin}/linkedin`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailsOfLinkedinUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsOfLinkedInUserApiEndPoint + "?code=" + code
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSuccess = (code:string) =>{
    this.fetchUserDetailFromLinkedin(code)
  }

  onError = ({ error, errorMessage, }: { error: string; errorMessage: string; }) =>{
  }

  onSuccessfullyFetchingTheDetails = (data:ResponseForLinkedinData) =>{
    setStorageData("linkedinData", JSON.stringify(data))
    setStorageData("isLinkedinData", JSON.stringify(true))
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "PostEmailAccountRegistration");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navMes);
  }

  onFailure = () => {
   this.showAlert(configJSON.errorText,configJSON.errorMessage);
  };

  onSuccessResponse = (response: string)=> {
    this.setState({successMsg: response})
  };

  successMessage = (credential: string )=> {
    this.setState({accountData: jwtDecode(credential)},()=>{
      this.redirectToGoogleScreen();
    })
  };



  redirectToGoogleScreen = () => {
    setStorageData("googleData", JSON.stringify(this.state.accountData));
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "PostEmailAccountRegistration");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navMes);
  };

  async componentWillUnmount() {
    removeStorageData("CardId")
    removeStorageData("dropdown")
    removeStorageData("jobListingPage")
    removeStorageData("proposalListingPage")
    removeStorageData("tabDetail")
  }

  // Customizable Area End
}
