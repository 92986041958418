import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Slider,
  Modal,
  Button,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, styled } from "@material-ui/core/styles";
import { Rating } from "@material-ui/lab";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import MultiSelect from "../../../components/src/MultiSelect.web";
import SingleSelect from "../../../components/src/SingleSelect.web";
import ProfileCard from "../../../components/src/ProfileCard.web"
import ClientCard from "../../../components/src/ClientCard.web"
import ProjectDetails from "../../landingpage/src/discoverproject/ProjectDeatils.web";
import SelectLocation from "../../../components/src/SelectLocation.web";
import CustomRadioGroup from "../../../components/src/CustomRadioGroup.web"
import MultipleCardlap from "../../../components/src/MultipleCardlap.web";
import ToastMSG from "../../../components/src/ToastMSG.web";

import { 
  leftIcon, 
  searchText, 
  searchIcon, 
  unCheckIcon, 
  dropIcon, 
  checkIcon,
  musicIcon, 
  noResultsFound,
  eyeIcon, 
  likeIcon, 
  featureIcon,
  closeIcon, 
  followIcon,
  rightBackIcon,
  unfollowIcon,
} from "./assets";

const typoWhite = "#FFF2E3"

interface IProjectAttribute {
  id: number;
  project_name: string;
  username: string;
  account_id: number;
  cover_image_url: string;
  likes: number;
  views: number;
  saved_count: number;
}

interface IIDData {
  id: string;
  type: string;
}

interface IProjectList extends IIDData {
  attributes: IProjectAttribute
}

const theme = createTheme({
  palette: {
    primary: {
      main: typoWhite,
      contrastText: "#8C8C8C",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

const CustomModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  "& .close_button": {
    display: 'flex', 
    justifyContent: 'flex-end'
  },
  "& .close_button_icon": {
    color: 'white', 
    right: -1,
    position: "absolute",
    marginBottom: 10,
    fontSize: "18px"
  }, 
  "& .backgroundColor": {
    backgroundColor: "#222222"
  }
})
// Customizable Area End

import AdvancedSearchController, { Props , configJSON } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start
  renderRatingFilter = () => {
    const { classes } = this.props;
    const { ratingObject } = this.state;
    return (
      <div ref={this.onClickAwayRef} className={`${classes.paperItemCon} ${classes.menuInnerCon}`}>
        <Box className="newResponsive bottomBorder">
          <Typography data-testid="ratingHeading" variant="body2" className="subHeading">{configJSON.ratingText}&#58;</Typography>
        </Box>
        <Box className="newResponsiveTwo">
        {configJSON.ratingsData.map((rateItem: { count: number, keyValue: string }) => {
          return (
            <Box className={classes.ratingCountCon} key={rateItem.count}>
              <IconButton 
                data-testid={`rating${rateItem.keyValue}`}
                onClick={()=>{this.handleRatingFilter(rateItem.count)}}>
                <img loading="lazy" src={this.isRatingSelected(rateItem.count) ? checkIcon :unCheckIcon} />
              </IconButton>
              <Rating
                defaultValue={rateItem.count}
                readOnly
                icon={<StarIcon style={styles.starIcon} />}
                emptyIcon={<StarBorderIcon style={styles.emptyStarIcon} fontSize="inherit" />}
              />
              <Typography className="count">{ratingObject[rateItem.keyValue] || configJSON.zeroCount }</Typography>
            </Box>
          )
        })}
        </Box>
      </div>
    )
  }

  renderRateComponent = () => {
    const { classes } = this.props;
    return (
      <div ref={this.onClickAwayRef} className={`${classes.paperItemCon} ${classes.menuInnerCon}`}>
        <Box className="newResponsive bottomBorder">
          <Typography data-testid="rateHeading" variant="body2" className="subHeading">{configJSON.rateText}&#58;</Typography>
        </Box>
        <Box className="newResponsive">

        <Box className={classes.sliderCon}>
          <Box className="musicImageSliderCon">

            <img loading="lazy" src={musicIcon} className="musicImage" />
            <Slider
              value={[this.state.rateMin, this.state.rateMax]}
              onChange={this.handleRangeSliderChange}
              min={100}
              max={5000}
              valueLabelDisplay="auto"
              className="slider"
              data-testid="slider"
            />
          </Box>
          <Box className="toMinMaxCon">
            <Box className="minMaxCon">
              <Typography variant="caption">{configJSON.minPerHour}</Typography>
              <Typography variant="body1">{this.state.rateMin}</Typography>
            </Box>
            <Typography>{configJSON.toText}</Typography>
            <Box className="minMaxCon">
              <Typography variant="caption">{configJSON.maxPerHour}</Typography>
              <Typography variant="body1">{this.state.rateMax}</Typography>
            </Box>
          </Box>
        </Box>
        </Box>
      </div>
    )
  }

  renderCircularProgressBar = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.progressCon}>
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        {this.renderCircularProgressBar()}
      </Box>
    )
  }

  renderApplyButton = () => {
    return (
      <Button
        data-testid="applyButton"
        onClick={this.handleFilterApply}
        disabled = {this.state.isFiltersAlreadyApplied}
        className={`apply_button ${this.state.isFiltersAlreadyApplied ? "disabled-btn" : ""}`}
        size="small">
        {configJSON.applyButton}
      </Button>
    )
  }

  renderResetButton = () => {
    return(
      <Button
            data-testid="resetButton"
            onClick={this.handleResetClick}
            disabled={this.handleDisableResetButton()}
            className={`apply_button ${ this.handleDisableResetButton() ? "disabled-btn" : ""}`}
            size="small">
            {configJSON.resetButton}
          </Button>
    )
  }

  renderDesingerData = () => {
    const { classes } = this.props;
    return (
      <Box>
        <Box className={classes.filterListCon}>
          <Box className="multiCon">
            <MultiSelect
              toggleState={this.toggleMultiCreativeFeild}
              singleColumn={false}
              open={this.state.openMulitCreateFeild}
              title={configJSON.creativeFeildHeading}
              data={this.state.allCategory}
              selected={this.state.selectedCreativeFeilds}
              onChange={this.handleChangeCreativeFeilds}
              data-testid="creativeField"
            />
          </Box>
          {
            this.state.userRole !== "designer" &&
            <Box className="multiConSkills">
              <MultiSelect
                data-testid="skillsFilter"
                toggleState={this.toggleMultiSkills}
                singleColumn={false}
                open={this.state.openMulitSkills}
                title={configJSON.skillsHeading}
                data={this.state.allSkillsList}
                selected={this.state.selectedSkills}
                onChange={this.handleChangeSkills} />
            </Box>
          }
          <Box
            data-testid="rateFilter"
            className={classes.ratingDropDownCon}
            onClick={this.handleRateMenuOpen}>
            <Typography variant="body2">{configJSON.rateText}</Typography>
            <IconButton>
              <img loading="lazy" src={dropIcon} />
            </IconButton>
            {this.state.rateOpen &&
              this.renderRateComponent()}
          </Box>
          <Box
            className={classes.ratingDropDownCon}
            data-testid="ratingFilter"
            onClick={this.handleRatingMenuOpen}>
            <Typography variant="body2">{configJSON.ratingText}</Typography>
            <IconButton>
              <img loading="lazy" src={dropIcon} />
            </IconButton>
            {this.state.ratingOpen && this.renderRatingFilter()}
          </Box>
          <SingleSelect
            data-testid="singleSelect"
            toggleState={this.toggleSingle}
            open={this.state.openSingle}
            title={configJSON.sortTitle}
            data={
              this.state.userRole === "client" ?
                configJSON.clientSortData :
                configJSON.designerSortData
            }
            selected={this.state.selectedElm}
            onChange={this.handleSelectChange} />
          {this.renderApplyButton()}
          {this.renderResetButton()}
        </Box>
        {this.renderProfileCards()}
      </Box>
    )
  }

  renderSkillList = () => {
    const { classes } = this.props;

    return (
      <Box  
        className={classes.skillsListCon}>
      {this.state.allSkillsList.map(skill => (
        <Box 
          key={skill.id} 
          data-testid="skillItem"
          className={`skillCon ${this.isSkillSelected(skill) && "activeCon"}`} 
          onClick={this.handleSkillsSelect.bind(this, skill)}>
          {this.isSkillSelected(skill) && 
            <img loading="lazy" src={closeIcon} className="image" />}
          <Typography variant="body2">{skill.name}</Typography>
        </Box>
      ))}
    </Box>
    )
  }

  renderProjects = (collection:IProjectList,idIndex:number) => {
    return (
      <div
      data-testid="projectList"
      onClick={this.handleSelectedProject.bind(this, collection)}
      className={this.props.classes.collectionCard} ref={
        this.state.projectsList.length === (idIndex + 1) ?
          this.designerCardRef :
          null}>
      <img loading="lazy" className={this.props.classes.coverImage} src={this.baseUrlStirng + collection.attributes?.cover_image_url} alt={configJSON.image} />
      <Box className={this.props.classes.projectNameCon}>
        <Typography className={this.props.classes.projectName}>{collection.attributes?.project_name}</Typography>
        <Box className={this.props.classes.iconsCon}>
          <Box className={this.props.classes.iconCon}>
            <img loading="lazy" src={eyeIcon} alt={collection.id} />
            <Typography className={this.props.classes.iconInfo} variant="caption">{collection.attributes?.views}</Typography>
          </Box>
          <Box className={this.props.classes.iconCon}>
            <img loading="lazy" src={likeIcon} alt={configJSON.image} />
            <Typography className={this.props.classes.iconInfo} variant="caption">{collection.attributes?.likes}</Typography>
          </Box>
          <Box className={this.props.classes.iconCon}>
            <img loading="lazy" src={featureIcon} alt={configJSON.image} />
            <Typography className={this.props.classes.iconInfo} variant="caption">{collection.attributes?.saved_count}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
    )
  }

  renderProjectsData = () => {
    const { classes } = this.props;

    return (
    <Box>
      <Box className={classes.filterListCon}>
          <Box className="multiCon">
            <MultiSelect
              toggleState={this.toggleMultiCreativeFeild}
              singleColumn={false}
              open={this.state.openMulitCreateFeild}
              title={configJSON.creativeFeildHeading}
              data={this.state.allCategory}
              selected={this.state.selectedCreativeFeilds}
              onChange={this.handleChangeCreativeFeilds}
              data-testid="creativeField"
            />
          </Box>
          <SingleSelect
            data-testid="singleSelect"
            toggleState={this.toggleSingle}
            open={this.state.openSingle}
            title={configJSON.sortTitle}
            data={configJSON.projectsSingleSelect}
            selected={this.state.selectedElm}
            onChange={this.handleSelectChange} />
          {this.renderApplyButton()}
          {this.renderResetButton()}
        </Box>
      {this.renderSkillList()}
      {
        this.state.projectsList.length === 0 &&
          !this.state.initialLoading ?
          this.renderEmptyState() :
          <Grid container spacing={4} className={this.props.classes.gridConTwo}>
            {this.state.projectsList.map((collection, idIndex) => (
              <Grid item xs={4} sm={12} lg={3} key={collection.id}>
                {this.renderProjects(collection,idIndex)}
              </Grid>
      ))}
    </Grid>
      }
    </Box>)
  }


  renderEmptyState = () => {
    const {classes} = this.props;
    return(
      <Box className={classes.emptyResultsCon}>
          <img alt="image" loading="lazy" src={noResultsFound} />
          <Typography variant="h4" className="emptyResultHeading">{configJSON.noResultsFoundHeading}</Typography>
          <Typography data-testid="errorMessageText">
            {`${this.state.search.trim().length > 0 ? "`"+this.state.search+"` "+configJSON.searchNoResultFoundDes : "" } ${configJSON.noResultsFoundDescription}`}
          </Typography>
        </Box>
    )
  }

  renderProfileCards = () => {
    if ((this.state.desingerProfileList.length === 0 && (!this.state.initialLoading)) || (this.state.activeTab !== "designer")) {
      return (
        this.renderEmptyState()
      )
    }

    const cards = this.state.desingerProfileList.map(
      (designer, idIndex) => {
        return (
          <div ref={
            this.state.desingerProfileList.length === (idIndex + 1) ? 
            this.designerCardRef :
            null
          }
           >
            <ProfileCard
              completedSkills={[]}
              handleNavigation={this.handleNavigations}
              data={designer.attributes}
              handleOpen={this.handleOpen}
              isOpen={this.state.isOpen}
              index={idIndex}
              data-testid="profileCard"
              outerClassName = "profileCard"
            />
           </div>
        )
      }
    )
    return (<Box className={this.props.classes.gridCon}>
      {cards}
    </Box>)
  }

  renderClientCard = () => {
    if ((this.state.clientCardList.length === 0 && (!this.state.initialLoading))) {
      return (
        this.renderEmptyState()
      )
    }
  
    const cards = this.state.clientCardList.map(
      (client, idIndex) => {
        return (
          <div ref={
            this.state.clientCardList.length === (idIndex + 1) ? 
            this.designerCardRef :
            null
          }
          key={client.id}
           >
            <ClientCard
              data-testid="clientCard"
              cardData={client}
              handleNavigateUserProfile={this.handleNavigatePublicProfileScreen}
            />
           </div>
        )
      }
    )
    return (
      <Box className={this.props.classes.clientGridCon}>
      {cards}
    </Box>
    )
  }

  renderClientsData = () => {
    const { classes } = this.props;

    return (
      <Box>
         <Box className={classes.filterListCon}>
          <CustomRadioGroup 
            groupList={this.getOpportinuties()} 
            isMenuOpened={this.state.opportunitiesOpen} 
            selectedValue = {this.state.selectedOpportunity}
            handleOpenMenu = {this.handleOpportunitiesMenuOpen}
            handleOnChange={this.handleOpportunitiesChange}
            data-testid="radioGroup"
            
          />
          <SelectLocation 
            fields={this.state.locationListingData}
            selectedFields={this.state.selectedLocation}
            handleValue={this.handleLocationApplyFn}
            title={configJSON.locationTitle}
            buttonClassName={`${classes.ratingDropDownCon} ${classes.ratingDropDowConTwo}`}
            icon={dropIcon}
          />
          <Box
            className={classes.ratingDropDownCon}
            data-testid="ratingFilter"
            onClick={this.handleRatingMenuOpen}>
            <Typography variant="body2">{configJSON.ratingText}</Typography>
            <IconButton>
              <img loading="lazy" src={dropIcon} />
            </IconButton>
            {this.state.ratingOpen && this.renderRatingFilter()}
          </Box>
          <SingleSelect
            data-testid="singleSelect"
            toggleState={this.toggleSingle}
            open={this.state.openSingle}
            title={configJSON.sortTitle}
            data={configJSON.clientSingleSelect}
            selected={this.state.selectedElm}
            onChange={this.handleSelectChange} />
         {this.renderApplyButton()}
         {this.renderResetButton()}
        </Box>
          {this.renderClientCard()}
      </Box>
    )
  }

  renderInspirationToggleInfo = () => {
    return (
      !!this.state.insprirationBoardToggleDetails ?
        <Box>
          <Box className="flexCon">
            <Typography
              variant="h1"
              data-testid="inspirationHeading"
              style={webStyles}>
              {this.getInspirationToggleData("inspirationName")}
            </Typography>
          </Box>
          <Box className="flexCon gapOne">
            <Typography variant="body1">
              {`${this.getInspirationToggleData("projects")} ${this.getInspirationToggleData("projects") === 1 ? configJSON.projectTitle : configJSON.projectsTitle}`}
            </Typography>
            <Typography variant="body1">
              {`${this.getInspirationToggleData("followers")} ${this.getInspirationToggleData("followers") === 1 ? configJSON.followerTitle : configJSON.followersTitle}`}
            </Typography>
            <Box
              data-testid="followButton"
              onClick={this.getInspirationToggleData("isFollowing") ? this.handleUnFollowInspiration : this.handleFollowBtn.bind(this, this.state?.insprirationBoardToggleDetails?.inspirationId)}
              className="flexCon cursor">
              <IconButton>
                <img
                  className="followImage"
                  src={this.getInspirationToggleData("isFollowing") ? followIcon : unfollowIcon} alt='follow icon' />
              </IconButton>
              <Typography
                data-testid="followTextButton"
                className={`viewProfile ${this.getInspirationToggleData("isFollowing") && "followingCard"}`}>
                {this.getInspirationToggleData("isFollowing") ? configJSON.unfollowText : configJSON.followText}
              </Typography>
            </Box>
          </Box>
        </Box>
        :
        null
    )
  }

  renderInpirationToggleData = () => {
    const { classes } = this.props;
    return (
      !!this.state.insprirationBoardToggleDetails ?
      <Box className={classes.inspirationInfoCon}>
      {this.state.isInspirationProjectsLoading ? this.renderBackDrop() :
       <Box>
        <Box className="flexCon">
          <IconButton 
            data-testid="toggleButton"
             className="toggleButton" onClick={this.handleInspirationCardClose}>
            <img src={rightBackIcon} className="arrowImage" alt="arrow" />
          </IconButton>
          <Box className="flexCon justifyCon gapOne flexGrow">
            {this.renderInspirationToggleInfo()}
            <Box className="flexCon gapOne flexShrink">
              {
                !!this.state.backgroundColor ?
                  <Box 
                    data-testid="profileBackground"
                    style={{ backgroundColor: this.state.backgroundColor }} className="userProfile" /> :
                  <img
                    data-testid="profileImage"
                    onError={this.handleImageError}
                    src={configJSON.baseURL.baseURL + this.getInspirationToggleData("profilePhoto") as string}
                    alt="user"
                    className="userProfile"
                  />
              }
              <Box className="viewProrileUserCon">
                <Typography>{this.getInspirationToggleData("userName")}</Typography>
                <Typography
                  data-testid="viewProfile"
                  className="viewProfile"
                  onClick={this.handleNavigateUserProfile.bind(this, this.state.insprirationBoardToggleDetails)}>
                  {configJSON.viewProfileText}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {
          this.state.inspirationProjects.length === 0 ?
            <Typography variant="h4" className="noProjectsContent">
              {configJSON.noProjectsFoundText}
            </Typography> :
            <Box className="displayGrid">
              {this.state.inspirationProjects.map(this.renderProjects)}
            </Box>
        }
      </Box>
      }
    </Box> 
    : 
    null
    )
  }

  renderInspirationCards = () => {
    const { classes } = this.props;
    if ((this.state.inspirationCardList.length === 0 && (!this.state.initialLoading))) {
      return (
        this.renderEmptyState()
      )
    }
    return(
      <Box 
        className={ `${!!this.state.insprirationBoardToggleDetails && classes.inspirationGridAndDetailsCon}`}>
        <Box className={`${classes.inspirationGridCon} ${!!this.state.insprirationBoardToggleDetails && classes.inspirationToogleGridCon}`}>
          {this.state.inspirationCardList.map((inspiration, idIndex) => {
            return (
              <div
                key={inspiration.id}
                data-testid="inspirationCard"
                ref={
                  this.state.inspirationCardList.length === (idIndex + 1) ?
                    this.designerCardRef :
                    null
                }
                onClick={this.handleInspirationCardClick.bind(this, inspiration)}
              >
                <MultipleCardlap data={inspiration} />
              </div>
            )
          })}
        </Box>
        {this.renderInpirationToggleData()}
      </Box>
    )
  }

  renderInspirationData = () => {
    const { classes } = this.props;
    return (
      <Box>
        <div 
          ref = {this.inspirationsConRef}
          className={classes.filterListCon}>
          <Box className="multiCon">
            <MultiSelect
              toggleState={this.toggleMultiCreativeFeild}
              singleColumn={false}
              open={this.state.openMulitCreateFeild}
              title={configJSON.creativeFeildHeading}
              data={this.state.allCategory}
              selected={this.state.selectedCreativeFeilds}
              onChange={this.handleChangeCreativeFeilds}
              data-testid="creativeField"
            />
          </Box>
          <SingleSelect
            data-testid="singleSelect"
            toggleState={this.toggleSingle}
            open={this.state.openSingle}
            title={configJSON.sortTitle}
            data={configJSON.inspirationSingleSelectData}
            selected={this.state.selectedElm}
            onChange={this.handleSelectChange} />
          {this.renderApplyButton()}
          {this.renderResetButton()}
        </div>
        {this.renderSkillList()}

        {this.renderInspirationCards()}
      </Box>
    )
  }

  renderUI = () => {
    if(this.state.activeTab == "designer") {
      return this.renderDesingerData();
    }

    if(this.state.activeTab == "projects") {
      return this.renderProjectsData();
    }

    if(this.state.activeTab == "client") {
      return this.renderClientsData();
    }
   
    return this.renderInspirationData()
    
  }
 
// Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Box 
        style={styles.newResponseCon}
        data-testid="outsideMultiClick"
        onClick={this.handleClickOutSideOfMultiSelect}>
        <NavigationMenu {...this.props} />
        <ThemeProvider theme={theme}>
         <Box className={classes.container}>
            <img loading="lazy" src={searchText} className="searchImage" alt="search client" />
            <Box className={classes.responsiveCon}>
              <Box className={classes.backButtonCon}>
                <IconButton data-testid="backButton" onClick={this.handleNavigateToLandingPage}>
                  <img alt="image" src={leftIcon} />
                </IconButton>
                <Typography variant="h1" data-testid="searchHeading">{configJSON.searchResultText}</Typography>
              </Box>
              <Box className={classes.searchTabsCon}>
                <Box className={classes.searchCon}>
                  <img loading="lazy" src={searchIcon} className="searchIcon" alt="search icon" />
                  <input
                    data-testid="searchBar"
                    className="searchBar"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.search}
                  />
                  {this.state.search.length > 0 && <IconButton data-testid="closeButton" onClick={this.handleCloseButton}>
                    <CloseIcon className="closeIcon" />
                  </IconButton>}
                  <Box className="searchTextCon">
                    <Typography 
                      className="searchText"  
                      data-testid="searchText"
                      onClick={this.handleSearchButtonClick}>
                        {configJSON.searchText}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.tabsCon}>
                  {this.state.tabsList.map(tabItem => (
                    <Box className={this.getActiveTabBorder(tabItem.tabId)} key={tabItem.tabId}>
                      <Box 
                        className={this.getActiveTabBackground(tabItem.tabId)} 
                        data-testid={"tabItem"+tabItem.tabName}
                        onClick={() => this.handleTabChange(tabItem.tabId)}>
                        <Typography className={this.getActiveTabFont(tabItem.tabId)}>{tabItem.tabName}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              {this.renderUI()}
              <CustomModal
                open={this.state.isOpen}
                className="modal"
                data-testid="modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Box className="modal_main_container">
                  <Box>
                    <Box className="close_button">
                      <IconButton onClick={this.handleClose} data-testid="profile_modal_cross">
                        <CloseIcon className="close_button_icon" />
                      </IconButton>
                    </Box>
                    {
                      this.state.openedData?.skills.length ?
                        <ProfileCard
                          handleNavigation={this.handleNavigations}
                          completedSkills={this.state.openedData.skills}
                          data={this.state.openedData}
                          handleOpen={this.handleOpen}
                          isOpen={this.state.isOpen}
                          index={0} /> :
                        null
                    }

                  </Box>
                </Box>
              </CustomModal>
              {this.state.showDetails && 
                <ProjectDetails 
                  handlenavigation={this.handleNavigateProfileScreen}
                  navigateTo={this.handleNavigation} 
                  token={this.state.dezinerToken} 
                  selectedProject={this.state.selectedProject} 
                  open={this.state.showDetails} 
                  closeModal={this.closeModal} 
                  allprojectList={this.state.specificProjectList} 
                  data-testid="projectDetails" />}

              {this.state.initialLoading && this.renderBackDrop() }
            </Box>
            <ToastMSG
            data-testid="toastMessage"
            close={this.handleCloseToast}
            message={this.state.message}
            open={this.state.open}
            action={this.state.action}
          />
         </Box>
        </ThemeProvider>
      </Box>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak: "break-word" as "break-word"
}
const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    minHeight: "200px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  progressCon: {
    width: "100%",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .circular": {
      color: "#fff"
    },
    margin: "auto",
  },
  newResponseCon: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#111111",
  },
  container: {
    width: '100%',
    paddingTop: "76px",
    backgroundColor: "#111111",
    display: "flex",
    flexDirection: "column" as "column",
    "& .apply_button": {
      backgroundColor: "#FFF2E3", 
      fontFamily: "Inter , sans-serif",
      textTransform: 'none',
      height: "28px"
    },
    "& .disabled-btn": {
      backgroundColor: 'rgb(157, 155, 153)',
      color: "black"
    }, 
    "& .searchImage": {
      width: "100%",
      maxWidth: "calc(1920px - 96px)",
      height: "307px",
      objectFit: "cover",
      margin: "0 auto"
    },
    "& .profileCard": {
      width: "100% !important",
      height: "100%"
    },
    "& .select-btn": {
      width: "fit-content !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingLeft: "16px",
      paddingRight: "15px",
      "& .title-elm": {
        display: "inline-block",
        marginLeft: "-16px",     
      }
    },
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1920px)",
    margin: "0 auto",
    padding: "1rem 3rem",
  },
  backButtonCon: {
    display: "flex",
    alignItems: "center", 
    marginBottom: "2.5rem",
    marginTop: "1rem", 
  },
  searchCon: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#252525",
    borderRadius: "24px",
    padding: "0 24px",
    height: "48px",
    "& .searchIcon": {
      width: "16px",
      height: "16px",
      objectFit: "contain",
      flexShrink: 0,
      marginRight: ".5rem",
    },
    "& .searchBar": {
      outline: "none",
      border: "none",
      fontFamily: "Inter",
      flexGrow: 1,
      height: "100%",
      backgroundColor: "transparent",
      color: typoWhite,
      "&::placeholder": {
        color: "#676767",
        fontFamily: "Inter",
      },
    },
    "& .searchTextCon": {
      borderLeft: "1px solid #434343",
      paddingLeft: "2rem",
      paddingRight: "1rem",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .searchText": {
      textDecoration: "underline",
      fontFamily: "Inter-SemiBold",
      cursor: "pointer",
    }
  },
  searchTabsCon: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    alignItems: "center",
    gap: "2rem",
    "& .closeIcon": {
      color: typoWhite,
      fontSize: "1rem",
    }
  },
  tabsCon: {
    backgroundColor: "#0D0D0D",
    padding: "8px 16px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    justifyContent: "space-between",
    "& .tabOuterCon": {
      border: '1px solid transparent',
      borderRadius: "2rem",
      height: "3.75rem",
      width: "fit-content",
      cursor: "pointer",
    },
    "& .gradientCon": {
        backgroundImage: 'linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)',    
    },
    "& .activeTab": {
      backgroundColor: "#313131",
    },
    "& .tab": {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "11px 22px"
    },
    "& .activeFont": {
      fontFamily: "Inter-SemiBold"
    },
    "& .inActiveFont": {
      color: "#8C8C8C"
    }
  },
  filterListCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    marginTop: "3rem",
    position: "relative" as "relative",
    "& .multiCon": {
      width: "145px",
    },
    "& .multiConSkills": {
      width: "83px",
    },
    "& *": {
      boxSizing: "content-box",
    }
  },
  menuInnerCon: {
    position: "absolute" as "absolute",
    top: -2,
    left: -1,
    zIndex: 100,
    "& .subHeading": {
      fontFamily: "Inter-SemiBold",
      color: "#ffffff"
    },
    "& .newResponsive": {
      padding: "1.25rem 2rem 1.25rem 2rem",
    },
    "& .newResponsiveTwo": {
      padding: "1.25rem 2rem 1.25rem calc(2rem - 12px)",
    },
    "& .bottomBorder": {
      borderBottom: "1px solid #5F5F5F",
    },
    "& .count": {
      marginLeft: "1.5rem",
      color: "#ffffff"
    }
  },
  ratingCountCon: {
    display: "flex",
    alignItems: "center",
  },
  starIcon: {
    color: "#BFC06F", 
    marginRight: ".4rem"
  },
  emptyStarIcon: {
    color: "#535353",
    marginRight: ".4rem"
  },
  ratingDropDownCon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: `1px solid #66625c !important`,
    width: "fit-content",
    padding: ".2rem 0.5rem .2rem 1.5rem",
    borderRadius: "6px",
    height: 40,
    boxSizing: "border-box" as "border-box",
    position: "relative" as "relative",
  },
  ratingDropDowConTwo: {
    padding: ".2rem 1.1rem .2rem 1.5rem",
  },
  paperItemCon: {
    backgroundColor: "#111111",
    border: "1px solid #5F5F5F",
    borderRadius: "10px",
    padding: 0,
  },
  menuMenuItemCon: {
    padding: 0,
  },
  sliderCon: {
    "& *": {
      boxSizing: "border-box",
    },
    "& .toMinMaxCon": {
      display: "flex",
      gap: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2.5rem",
    },
    "& .minMaxCon": {
      backgroundColor: "#222222",
      padding: ".8rem",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
      width: "fit-content",
      "& span": {
        color: "#797979"
      }
    },
    "& .musicImageSliderCon": {
      width: "329px",
      height: "62px",
      position: "relative",
      "& .musicImage": {
        width: "100%",
        height: "100%",
        objectFit: "fill",
       
      },
      "& .slider": {
        position: "absolute",
        bottom: -6,
        left: 0,
      }
    },
    
    '& .MuiSlider-track': {
      border: 'none',
      height: 8,
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    "& .MuiSlider-root": {
      width: "97%",
    },
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid #171717',
      marginTop: -6.5,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      height: 8,
      opacity: 1,
      backgroundColor: '#bfbfbf',
      borderRadius: "10px",
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
  },
  gridCon: {
    width: "100%",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "2.5rem",
    [theme.breakpoints.up(1635)]: {
      gridTemplateColumns: "repeat(4,1fr)",
    }
  },
  clientGridCon: {
    width: "100%",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gap: "1.5rem",
    [theme.breakpoints.up(1530)]: {
      gridTemplateColumns: "repeat(5,1fr)",
    },
    [theme.breakpoints.up(1830)]: {
      gridTemplateColumns: "repeat(6,1fr)",
    }
  },
  emptyResultsCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem",
    "& .emptyResultHeading": {
      marginBottom: "2rem",
    }
  },
  gridConTwo: {
    margin: "1.5rem 0",
    padding: 0,
    marginLeft: "-1rem",
  },
  collectionCard: {
    width: "min(100%,413.84px)",
    cursor: "pointer",
  },
  coverImage: {
    width: "100%",
    height: "285.89px",
    objectFit: "cover" as "cover",
    borderRadius: "10px",
  },
  projectNameCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  projectName: {
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  iconsCon: {
    display: "flex",
    gap: "1rem",
  },
  iconCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: ".25rem",
    alignItems: "center",
    "& img": {
      width: "26.59px",
      height: "18.13px",
      objectFit: "contain" as "contain",
    }
  },
  iconInfo: {
    fontSize: "0.625rem",
  },
  skillsListCon: {
    width: "100%",
    overflowX: "auto" as "auto",
    scrollbarWidth: "none" as "none",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
    "& .skillCon": {
      border: `.5px solid #FFF2E3`,
      borderRadius: "3rem",
      padding: "11px 22px",
      display: "flex",
      alignItems: "center",
      gap: ".5rem",
      flexShrink: 0,
      height: "37px",
      cursor: "pointer",
      "& p": {
        flexGrow: 1,
        width: "100%"
      },
      "& .image": {
        objectFit: "contain",
        width: "10px",
        height: "10px"
      }
    },
    "& .activeCon": {
      border: `1px solid #FFF2E3`,
      backgroundColor: "#323232",
      "& p": {
        fontFamily: "Inter-SemiBold",
      }
    }
  },
  inspirationGridAndDetailsCon:{
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    gap: "3rem",
    marginTop: "2rem",
  },
  inspirationGridCon: {
    marginTop: "2rem",
    paddingBottom: "4rem",
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    columnGap: "1rem",
    rowGap: "3rem",
    [theme.breakpoints.up(1530)]: {
      gridTemplateColumns: "repeat(5,1fr)",
    },
    [theme.breakpoints.up(1830)]: {
      gridTemplateColumns: "repeat(6,1fr)",
    }
  },

  inspirationToogleGridCon: {
    gridTemplateColumns: "repeat(2,1fr)",
    width: "40%",
    rowGap: "2rem",
    columnGap: "3rem",
    marginTop: "0rem",
    [theme.breakpoints.up(1530)]: {
      rowGap: "3rem",
    },
    [theme.breakpoints.up(1830)]: {
      rowGap: "3.5rem",
    }

  },
  inspirationInfoCon: {
    backgroundColor: "#171717",
    borderRadius: "16px",
    width: "60%",
    minHeight: "300px",
    padding: "2rem 1rem",
    "& .icon": {
      color: typoWhite,
      fontSize: "1.8rem",
    },
    "& .flexCon": {
      display: "flex",
      alignItems: "center",
    },
    "& .justifyCon": {
      justifyContent: "space-between",
    },
    "& .gapOne": {
      gap: ".5rem"
    },
    "& .flexGrow": {
      flexGrow: 1,
    },
    "& .userProfile": {
      width: "76px",
      height: "76px",
      objectFit: "cover",
      borderRadius: "50%",
    },
    "& .viewProfile": {
      textDecoration: "underline",
      fontFamily: "Inter-Semibold",
      cursor: "pointer",
    },
    "& .viewProrileUserCon": {
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
      justifyContent: "center",
    },
    "& .toggleButton": {
      alignSelf: "flex-start",
      marginTop: "-.8rem",
      transform: "rotateZ(180deg)"
    },
    "& .flexShrink": {
      flexShrink: 0,
    },
    "& .displayGrid": {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: "1rem",
      marginTop: "2.5rem",
      [theme.breakpoints.up(1750)]: {
        gridTemplateColumns: "repeat(3,1fr)",
      }
    },
    "& .noProjectsContent": {
      textAlign: "center",
      marginTop: "2.5rem",
    },
    "& .cursor": {
      cursor: "pointer"
    },
    "& .followImage": {
      width: "27px",
      height: "27px",
      objectFit: "cover",
    },
    '& .followingCard': {
      position: "relative",
      color: 'transparent',
      fontWeight: 600,
      textDecoration: 'underline',
      borderWidth: ' 0px 0px 2px',
      backgroundColor: 'transparent',
      backgroundImage: 'linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)',
      fontFamily: 'Inter-Semibold, sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
      width: 'max-content',
      textAlign: 'left',
      backgroundClip: 'text',
      alignContent: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        height: '2px',
        background: 'linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)', // Color of the underline
      },

    },

  }
}
const NewAdvancedSearch = withStyles(styles)(AdvancedSearch);
export { NewAdvancedSearch }
// Customizable Area End
