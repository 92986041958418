import React from "react";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles"
import { Box, Dialog, Typography } from "@material-ui/core";
import LocationController, { Props } from "./LocationController";
import { editIcon } from "../assets";
import RemoveAddressModel from "../../../../components/src/DeleteProjectModel.web"
import ToastMSG from '../../../../components/src/ToastMSG.web';
import PlacesAutocomplete from 'react-places-autocomplete';
import { CustomTextField } from "../Settings2.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
const PlaceAutoCompleteWrapper = styled(Box)({
    "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#FFF2E3', fontSize: "14px", lineHeight: "16.94px" },
    "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: 450 },
    "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
    "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
});
// Customizable Area End

export default class Location extends LocationController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
        <Box style={webStyle.subTextContainer}>
            <Box style={webStyle.mainContainer}>
                <Typography style={webStyle.locationTitle}>Location</Typography>
                {this.state.addressList.meta.address_count <= 3 && 
                <Typography data-test-id="add-location-id" 
                onClick={() => this.handleAddLocationPopUp("add")} 
                style={webStyle.locationAddBtn} >
                    Add Location
                </Typography>}
            </Box>

            <Box style={webStyle.locationContainer}>
              {this.state.addressList.meta.address_count === 0 && 
              <Typography style={webStyle.noLocationStyle}>No Location Added</Typography>}
          {this.state.addressList.meta.address_count > 0 && this.state.addressList.data.map((item) => (
            <Box style={webStyle.locationBox}>
              <Box style={{ ...webStyle.mainContainer, width: "550px" }}>
                <Typography style={webStyle.addressTitle}>Address</Typography>
                <img data-test-id="edit-btn-id" 
                    onClick={()=>this.handleEditDialog(item.id)} 
                    style={webStyle.editButtonStyle} 
                    src={editIcon} alt="edit" />
              </Box>
              <Box style={webStyle.addressHeaderStyle}>
                <Typography>{item.attributes.address},</Typography>
                <Typography>{item.attributes.city} {item.attributes.pincode}</Typography>
              </Box>
              <Box style={{ justifyContent: item.attributes.mark_default ? "space-between" : "flex-end",...webStyle.defaultBtnStyle }}>
                {item.attributes.mark_default ?
                  <Typography style={webStyle.defaultBtn}>Default</Typography> :
                  <Typography data-test-id="set-as-default-id" 
                  onClick={()=>this.handleSetDefaultLocation(item.id)} 
                  style={webStyle.defaultSetBtn}>
                    Set As Default
                </Typography>
                }
                <Typography data-test-id="remove-btn-id" 
                onClick={()=>this.handleRemoveModel(item.id)} 
                style={webStyle.removeBtnStyle}>
                    Remove
                </Typography>
              </Box>
            </Box>
          ))}
                
            </Box>
            <Dialog open={this.state.isOpenDialog} onClose={this.handleAddLocation}>
                <Box style={webStyle.addLocationContainer}>
                    <Typography style={webStyle.addLocationText}>{this.state.dialogType === "add" ?"Add Address":"Edit Address"}</Typography>
                    <Box style={webStyle.addLocationBox}>
                        <Typography style={webStyle.addLocationText}>Address<span style={webStyle.requireMark}>*</span></Typography>
                        <CustomTextField
                            type="text"
                            placeholder="Enter address"
                            data-test-id="input-data-address"
                            value={this.state.address}
                            style={webStyle.dialogTextBox}
                            onChange={(event: any) => { this.handleChangeAddress(event.target.value); }} />
                        <Typography style={webStyle.dialogError}>{this.state.addressMsgError}</Typography>
                    </Box>
                    <Box style={webStyle.addLocationContent}>
                        <Box>
                        <Typography style={webStyle.addLocationText}>City<span style={webStyle.requireMark}>*</span></Typography>
                        <PlaceAutoCompleteWrapper>
                          <PlacesAutocomplete
                            value={this.state.selectedLocation}
                            onChange={this.handleChangeLocationText}
                            onSelect={this.handleChangeLocation}
                            data-testId="selectLocationTestId"
                            searchOptions={{
                              types: ['locality']
                            }}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                              <Box>
                                <CustomTextField
                                  {...getInputProps()}
                                  placeholder='Select City'
                                  data-testId="locationTestId"
                                  fullWidth
                                  style={webStyle.dialogTextBox}
                                  inputProps={{
                                    onChange: this.handleInputChange
                                  }}
                                />
                                <Box className="autocomplete-dropdown-container suggestion_box">
                                  {suggestions.map((suggestion, index) => {
                                    return (
                                      <Box className="suggestion_container"
                                        {...getSuggestionItemProps(suggestion, { key: { index } })}

                                      >
                                        <Typography key={index} style={{ width: "50%" }} className="suggestion">{suggestion.description}</Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                            )}
                          </PlacesAutocomplete>
                        </PlaceAutoCompleteWrapper>
                        </Box>
                        <Box>
                        <Typography style={webStyle.addLocationText}>Pincode<span style={webStyle.requireMark}>*</span></Typography>
                        <CustomTextField
                            type="text"
                            placeholder="Enter pincode"
                            data-test-id="input-data-address"
                            value={this.state.pincode}
                            style={webStyle.dialogTextBox}
                            onChange={(event: any) => {
                              const inputValue = event.target.value;
                              if (/^\d*$/.test(inputValue)) 
                                  this.handlePincode(inputValue);
                              }
                          } />
                        <Typography style={webStyle.dialogError}>{this.state.pincodeError}</Typography>
                        </Box>
                    </Box>
                    <Box style={webStyle.stateContainer}>
                        <Typography style={webStyle.addLocationText}>State</Typography>
                        <Typography style={webStyle.stateLocationText}>{this.state.locationState}</Typography>
                    </Box>
                    <Box style={webStyle.dialogButStyle}>
                    <Typography onClick={this.handleAddLocation} 
                    style={webStyle.cancelBtnStyle}>Cancel</Typography>
                    <Typography data-test-id="save-btn-id" onClick={this.isButtonDisabled() ? ()=>this.handleLocation(this.state.dialogType === "edit" ? this.state.editLocationId :"") : undefined} 
                    style={{ cursor: this.isButtonDisabled()?"pointer":"default",...webStyle.saveButtonStyle  }}>Save</Typography>
                    </Box>
                </Box>
            </Dialog>
            <RemoveAddressModel 
                open={this.state.removeModel} 
                onClose={this.handleRemoveModelClose} 
                onDelete={this.handleRemoveAddress} 
                title="Are you Sure?" 
                content="Please confirm if you want to remove this location?" 
                button1Text="Cancel" 
                button2Text="Remove"
                data-test-id="removeModel"/>
            <ToastMSG open={this.state.open} close={this.handleClose} message={this.state.message} action={this.state.action} data-test-id="tost-msg-dialog"/>
        </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    dialogButStyle:{
        marginTop:"24px",display:"flex",gap:"24px"
    },
    removeBtnStyle:{
        cursor: "pointer", padding: "14px 24px 14px 24px", backgroundColor: "#171717", color: "#FF7878", fontFamily: "Inter", fontSize: "16px", fontWeight: 500, borderRadius: "7px"
    },
    addLocationText:{
        color:"#FFF2E3",fontFamily:"Inter",fontSize:"20px",fontWeight:600
    },
    stateLocationText:{
        fontFamily: "Inter", fontWeight: 600, fontSize: "16px", color: "#848181"
    },
    addLocationBox:{
        width: "80%",marginTop:"30px"
    },
    addLocationContent:{
        width: "80%",marginTop:"24px",display:"flex",gap:"24px"
    },
    cancelBtnStyle:{
        cursor: "pointer", 
        padding: "14px 24px 14px 24px", 
        backgroundColor: "#0D0D0D", 
        color: "#FFF2E3", 
        fontFamily: "Inter", fontSize: "16px", 
        fontWeight: 500, borderRadius: "7px"
    },
    addLocationContainer: {
        width: "572px", height: "maxContent",
        padding: "30px 0px",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#222222",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        zIndex: 1000,
        position: "fixed" as "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column" as "column"
    },
    subTextContainer: {
        marginLeft: "60px", marginTop: "100px", marginRight: "60px"
    },
    mainContainer:{
        display: "flex", justifyContent: "space-between"
    },
    addressTitle:{
        fontFamily: "inter", fontSize: "16px", fontWeight: 600, color: "#FFF2E3"
    },
    editButtonStyle:{
        width: "18px", height: "18px", cursor: "pointer"
    },
    saveButtonStyle:{
        padding: "14px 30px 14px 30px", backgroundColor: "#FFF2E3", 
        color: "#121212", fontFamily: "Inter", fontSize: "16px", 
        fontWeight: 500, borderRadius: "7px"
    },
    addressHeaderStyle:{
        fontFamily: "Inter", color: "#777777", fontSize: "16px", fontWeight: 400, marginTop: "24px"
    },
    defaultBtnStyle:{
        display: "flex",  gap: "16px", alignItems: "center", marginTop: "16px"
    },
    defaultBtn:{
        background: "linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent", fontFamily: "Inter", fontSize: "16px", fontWeight: 600,
    },
    locationTitle:{
        color: "#FFF2E3", fontFamily: "Inter", fontSize: "36px", fontWeight: 600,
    },
    locationAddBtn:{
        cursor: "pointer", padding: "14px 24px 14px 24px", backgroundColor: "#171717", color: "#61CA78", fontFamily: "Inter", fontSize: "16px", fontWeight: 500, borderRadius: "7px"
    },
    locationContainer:{
        display: "flex", gap: "30px",overflowX:"auto" as "auto",scrollbarWidth:"none" as "none"
    },
    noLocationStyle:{
      fontFamily:"Inter",fontSize:"16px",fontWeight:500,color:"#535353",marginTop:"40px"
    },
    locationBox:{
        marginTop: "30px", borderRadius: "16px", border: "#535353 1px solid", padding: "24px 32px"
    },
    dialogTextBox:{
        marginTop:"16px",
        width: "100%",backgroundColor:"#171717",borderRadius:"9px",border: 'none',
        paddingLeft: '15px',
        paddingRight: '27px',
        fontFamily: 'Inter, sans-serif',
        fontSize: '15px',
        height: '56px',
        outline: 'none',
        lineHeight: '18.15px',
      },
      dialogError:{
        color:"#FF7878",fontFamily:"Inter",fontWeight:400,fontSize:"14px",letterSpacing:0
      },
      stateContainer:{
        width: "80%",marginTop:"24px"
      },
      requireMark:{color:"red"},
      defaultSetBtn:{
        cursor: "pointer", padding: "14px 24px 14px 24px", backgroundColor: "#171717", color: "#FFF2E3", fontFamily: "Inter", fontSize: "16px", fontWeight: 500, borderRadius: "7px"
    },
}
// Customizable Area End
