import React from "react";
// Customizable Area Start
import ExpoloreMoreOpportunityController, {
  Props,
  SortingByWorkType,
  SortingBySideBar,
  TabPanelProps
} from "./ExpoloreMoreOpportunityController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { dancingBar, searchImage } from "../../joblisting/src/assets";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Checkbox, CircularProgress, createTheme, Divider, FormControlLabel, Grid, IconButton, withStyles, InputAdornment, MenuItem, MenuList, Paper, Slider, styled, Tabs, TextField, ThemeProvider, Tooltip, Typography } from "@material-ui/core";
import CreativeFields from "../../../components/src/CreativeFields.web"
import SelectLocation from "../../../components/src/SelectLocation.web"
import OpportunityCard, { workOpportunity } from "../../../components/src/OpportunityCard.web";
import SortBy from "../../../components/src/SortBy.web";
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const configJSON = require("../../joblisting/src/config");
import { Pagination } from "@material-ui/lab";
import WorkOppertunityModal from "../../landingpage/src/workopportunity/WorkOppertunityModal.web"
import {
  leftArrowIcon
} from '../../dashboard/src/assets';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1611,
      xl: 1980,
    },
  },
});
// Customizable Area End

export class ExpoloreMoreOpportunity extends ExpoloreMoreOpportunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderWorkTypeData = () => {
    return configJSON.workTypeData.data.map((element: SortingByWorkType) => {
      return <FormControlLabel key={element.id}
        data-test-id="WorkType"
        control={<Checkbox
          checked={this.state.workTypeSelected.includes(element.value)}
          className="gray_color" id={`work_type_${element.id}`}
          onChange={() => this.handleWorkTypeClick(element.value)}
          color="default" />}
        label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            className="gray_color">
            {element.name}
          </Box>
          <Box>
            <Tooltip title={element.tooltip} placement="right-start">
              <IconButton className="gray_color" style={{ color: '#5C5C5C', border: '1px solid #5C5C5C', borderRadius: '30', fontSize: 10, padding: "2px 7px", marginLeft: 4 }}>
                i
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        }
      />
    })
  }

  renderExperienceLevelData = () => {
    return configJSON.experienceLevelData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id} data-test-id="ExperienceLevel"
        control={<Checkbox
          checked={this.state.experienceSelected.includes(element.value)}
          onChange={() => this.handleExperienceClick(element.value)}
          className="gray_color" id={`experience_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderHoursPerWeekData = () => {
    return configJSON.hoursPerWeekData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id} data-test-id="HoursPerWeek"
        control={<Checkbox
          checked={this.state.hoursPerWeekSelected.includes(element.value)}
          className="gray_color" id={`hour_per_week_${element.id}`}
          onChange={() => this.handleHoursPerWeekClick(element.value)} color="default"
        />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderSection = () => {
    return <>
      <CustomTextField
        variant="outlined"
        size="small"
        id="find_work_search"
        data-test-id="find-text-elm"
        fullWidth
        onChange={this.handleFindWorkSearch}
        value={this.state.findWorkSearch}
        onKeyDown={(event) => this.handleKeyPress(event)}
        placeholder="Type here"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIconStyled />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {this.state.isSearched && <IconButton onClick={this.clearText} id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
                x
              </IconButton>}
              <SearchButton
                onClick={this.handleApplyFilterButton}
                disabled={this.handleDisableSearch()}
              >
                Search
              </SearchButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  }

  renderOpportunitiesData = () => {
    const { loading, workOpportunityData } = this.state;
    if (loading) {
      return (
        <Box
          style={{ width: '100%', marginTop: '10%' }}
        >
          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress className="gray_color" size={25} />
          </Box>
          <Typography
            className="gray_color"
            style={{ marginTop: '20px', textAlign: 'center' }}>
            Searching...
          </Typography>
        </Box>
      );
    } else if (workOpportunityData.length > 0) {
      return (
        <Grid container spacing={3}>
          {workOpportunityData.map((element: workOpportunity, index: number) => (
            <Grid
              item
              xs={6} lg={4}
              key={index}>
              <OpportunityCard data-test-id="opprtunitycard" handlenavigate={() => { '' }} opportunity={element} handleOpenOpportunity={this.handleOpenOpportunity} />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <Box
          style={webStyle.noresultBox as React.CSSProperties}>
          <img src={searchImage} alt="search icon" height={84.39} width={171} />
          <Typography style={{ fontStyle: "Inter", fontSize: 20, fontWeight: 600, color: '#FFF2E3' }}>No work results found</Typography>
          <Typography style={{ fontStyle: "Inter", fontWeight: 400, color: '#FFF2E3', width: 550, textAlign: 'center', marginTop: "5%" }}>Sorry! We could not find any relevant job opportunities with your selected filters. Please try again by removing or applying different filters</Typography>
        </Box>
      );
    }
  };

  a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: index === 0 ? "Find Work" : "Saved Work",
      className: "tabs_css",
    };
  }

  renderWorkDurationData = () => {
    return configJSON.workDurationData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id} data-test-id="WorkDurtation"
        control={<Checkbox onChange={() => this.handleWorkDurtationClick(element.value, element.id)} checked={this.state.workDurtationSelected.includes(element.value)} className="gray_color" id={`work_duration_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <MainWrapper>
        <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
          <NavigationMenu {...this.props} activeTab="find work" />
          <div style={webStyle.mainClientWrap as React.CSSProperties}>
            <Box className="find_work_main_container">
              <Box className="find_work_side_bar_container">

                <Box style={webStyle.revisedAllPropsCon}>
                  <Box data-testid="allProposals"
                    onClick={() => this.navigateFun("MyWork")}
                    style={{ ...webStyle.allPropsCon, ...webStyle.cursonPointer }}
                  >
                    <img src={leftArrowIcon} alt="left-icon" />
                    <Typography style={{ ...webStyle.title, marginLeft: '5px' }}>Back</Typography>
                  </Box>
                  <CustomBox className='discard-container'>
                    <Typography style={webStyle.mainTitle} className='discard-text'
                    >All work opportunities</Typography>

                  </CustomBox>
                </Box>

                <Box className="sidebar_menu_box">
                  <Paper >
                    <MenuList className="sidebar_menu_list">
                      <MenuItem className="sidebar_menu_item">
                        <Accordion className="find_work_side_bar_container_menu">
                          <AccordionDetails>
                            <Box className="accordian_details" >
                              <Box>
                                <SortBy data-test-id="sort" selectedSort={this.state.selectedSort} handleApply={this.handleSortSelect} />
                              </Box>
                              <Box >
                                <Button
                                  data-test-id="resetFilter"
                                  onClick={this.handleResetClick}
                                  disabled={this.handleDisableResetButton()}
                                  className={`apply_button ${this.handleDisableResetButton() ? "disabled-btn" : ""}`}
                                  size="small" style={{ textTransform: 'none', marginRight: 10, fontFamily: "Inter , sans-serif", }}>
                                  Reset
                                </Button>
                                <Button
                                  data-test-id="applyFilter"
                                  onClick={this.handleApplyFilterButton}
                                  disabled={this.handleDisableApplyFilterButton()}
                                  className={`apply_button ${this.handleDisableApplyFilterButton() ? "disabled-btn" : ""}`}
                                  size="small" style={{ textTransform: 'none', fontFamily: "Inter , sans-serif", }}>
                                  Apply
                                </Button>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion className="find_work_side_bar_container_menu">
                          <AccordionDetails>
                            <Box className="accordian_details">
                              <Box>
                                <Typography className="gray_color font_weight_600">Skill categories</Typography>
                              </Box>
                              <Box >
                                <CreativeFields
                                  data-test-id="CreativeFields"
                                  fields={this.state.creativeFields}
                                  selectedFields={this.state.selectedCategories}
                                  onApply={this.handleCreativeFieldApply} />
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion
                          expanded={this.state.accordianToggle[0]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="budget"
                            data-test-id="AccordionSummary"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(0)}
                          >
                            Budget
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">

                              <Box>
                                <img src={dancingBar} className="edit_profile_disco_bar" />
                                <Box className="slider_container">
                                  <Slider
                                    data-test-id="Slider"
                                    onChange={this.handleRangeSliderChange}
                                    min={100}
                                    max={5000}
                                    value={[this.state.rateMin, this.state.rateMax]}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                  />
                                </Box>
                                <Box className="budget_count_box">
                                  <Box className="budget_min_max_box">
                                    <Typography className="budget_min_max_color">
                                      Min (per hour)
                                    </Typography>
                                    <Typography className="budget_count_color">{this.state.rateMin}</Typography>
                                  </Box>
                                  <Typography className="budget_min_max_color to_padding">to</Typography>
                                  <Box className="budget_min_max_box">
                                    <Typography className="budget_min_max_color">
                                      Max (per hour)
                                    </Typography>
                                    <Typography className="budget_count_color">{this.state.rateMax}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider
                          className="divider" />
                        <Accordion
                          expanded={this.state.accordianToggle[1]}
                          className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            onClick={() => this.handleAccordianToggle(1)}
                            id="location"
                            className="gray_color font_weight_600"
                            data-test-id="AccordionSummary"
                          >
                            {configJSON.locationTitle}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">
                              <FormControlLabel
                                data-test-id="btnToggle10"
                                value="Remote"
                                id="remote_work"
                                control={<Checkbox
                                  checked={this.state.isRemotLocationSelected}
                                  className="gray_color" />}
                                label={<Typography className="gray_color">Remote</Typography>}
                                onClick={this.handleRemoteWorkLocation}
                              />
                              <FormControlLabel
                                data-test-id="btnToggle11"
                                value={this.state.selectedLocation}
                                id="other_location"
                                control={<Checkbox
                                  onClick={this.handleWorkLocationSelected}
                                  className="gray_color"
                                  checked={this.state.isOtherLocationSelected}
                                />}
                                label={<Box>
                                  <SelectLocation fields={this.state.locationListingData}
                                    handleValue={this.handleLocationApplyFn}
                                    selectedFields={this.state.selectedLocation}
                                    locationKey={"FindWorkKey"}
                                  />
                                </Box>
                                }
                              />

                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[2]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            id="work_duration"
                            aria-controls="panel1-content"
                            className="gray_color font_weight_600"
                            data-test-id="AccordionSummary"
                            onClick={() => this.handleAccordianToggle(2)}
                          >
                            {configJSON.workDurationData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">{this.renderWorkDurationData()}</Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[3]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            id="work_type"
                            className="gray_color font_weight_600"
                            aria-controls="panel1-content"
                            data-test-id="AccordionSummary"
                            onClick={() => this.handleAccordianToggle(3)}
                          >
                            {configJSON.workTypeData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">{this.renderWorkTypeData()}</Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[4]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            className="gray_color font_weight_600"
                            data-test-id="AccordionSummary"
                            id="experience_level"
                            onClick={() => this.handleAccordianToggle(4)}
                          >
                            {configJSON.experienceLevelData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">{this.renderExperienceLevelData()}</Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[5]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="hours_per_week" className="gray_color font_weight_600"
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            data-test-id="AccordionSummary"
                            onClick={() => this.handleAccordianToggle(5)}
                          >
                            {configJSON.hoursPerWeekData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">{this.renderHoursPerWeekData()}</Box>
                          </AccordionDetails>
                        </Accordion>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Box>

              </Box>
              <Box className="find_work_right_main_container">

                <Box style={{
                  display: 'flex'
                }}
                >
                  <CustomTabPanel value={this.state.tabValues} index={0}>
                    {this.renderSection()}
                  </CustomTabPanel>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '50%'
                  }}>
                    <Box className="pagination">{
                      this.state.count > 1 && <Pagination data-test-id="Pagination" onChange={this.handlePageChange} count={this.state.count} variant="text" defaultValue={this.state.page} shape="rounded" />
                    }
                    </Box>
                  </Box>
                </Box>
                <ThemeProvider theme={theme}>
                  <Grid className="right_side_grid_box" container spacing={2}>
                    {this.renderOpportunitiesData()}
                  </Grid>
                </ThemeProvider>
              </Box>
            </Box>
          </div>
        </Box>
        {this.state.openOppertunity && (
          <WorkOppertunityModal
            id="WorkOppertunityModal"
            selectedOpportunityId={this.state.selectedOpportunityId}
            handleClose={this.handleClose}
            openOppertunity={this.state.openOppertunity}
            handleNavigate={() => { '' }}
            hideButton={false}
          />
        )}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '50%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </Box>
  );
}

const CustomTextField = styled(TextField)({
  backgroundColor: '#1c1c1c',
  margin: "0px 0px 20px 0",
  fontSize: 14,
  fontFamily: "Inter , sans-serif",
  borderRadius: 20,
  '& .MuiOutlinedInput-root': {
    overflow: "hidden",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    color: '#fff',
    fontSize: 14,
    '&:placeholder': {
      textDecoration: 'italic',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#66625C',
  }
});

const SearchIconStyled = styled(SearchIcon)({
  color: '#66625C',
  fontSize: 18
});

const SearchButton = styled(IconButton)({
  color: '#fff',
  textTransform: 'none',
  fontSize: 14,
  textDecoration: 'underline',
  fontFamily: "Inter , sans-serif",
  borderLeft: '1px solid #434343',
  borderRadius: '0px',
  '&:disabled': {
    color: '#888',
    textDecoration: 'none',
    opacity: 0.5,
  },
});

const MainWrapper = styled(Box)(({ theme }) => ({
  "& .find_work_side_bar_container_menu": {
    backgroundColor: "#171717",
    width: "100%",
    margin: 0,
  },
  "& .find_work_main_container": {
    display: "flex",
    backgroundColor: "#111111",
    width: '100%',
  },
  "& .find_work_sidebar_accordian": {
    flexDirection: 'column',
    display: "flex",
    width: "100%"
  },
  "& .edit_profile_disco_bar": {
    marginLeft: '-17.5rem',
    marginBottom: 20,
    width: '100%',
  },
  "& .find_work_side_bar_container": {
    border: 1,
    width: "25%",
    backgroundColor: "#111111",
    padding: 20,
  },
  "& .find_work_right_main_container": {
    border: 1,
    width: "75%",
    padding: 20,
    marginTop: '162px'
  },
  "& .find_work_grid_section": {
    width: "98%",
  },
  "& .find_work_search_field": {
    borderRadius: 20, outline: "none", marginBottom: 10, width: "95%", marginTop: 45, backgroundColor: '#171717', "::placeholder": { color: '#66625C' }, color: '#66625C'
  },
  '&:focus, &:hover': {
    outline: 'none',
  },
  "& .gray_color": {
    fontFamily: "Inter , sans-serif",
    color: '#FFF2E3',
  },
  "& .right_side_grid_box": {
    padding: 2,
    width: "95%",
    marginTop: '45px'
  }, "& .main_container_search_button": { color: "#FFF2E3" },
  "& .tabs_css": {
    fontWeight: 700,
    color: "#FFF2E3",
    fontFamily: "Inter,sans-serif",
    textTransform: 'none',
    fontSize: 16
  },
  "& .apply_button": {
    backgroundColor: "#FFF2E3",
    fontFamily: "Inter , sans-serif",
  },
  '& .MuiTabs-indicator': {
    height: '3px',
    backgroundColor: "#FFF2E3",
    widht: "40% !important",
    marginLleft: '10%'
  },
  '& .pagination .MuiPaginationItem-root': {
    color: '#8C8C8C',
  },
  '& .pagination .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#F5F5DC',
    borderRadius: '50%',
    color: '#121212',
  },
  '& .find_work_search_field::placeholder': {
    color: '#66625C',
  },
  '& .find_work_search_field:focus': {
    outline: 'none',
  },
  "& .find_work_and_praposals_button": { display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: 6, backgroundColor: '#0D0D0D', borderRadius: 50, marginBottom: 20 }
  , "& .praposal_text": { color: '#8C8C8C', fontFamily: "Inter,sans-serif", fontSize: '20px', padding: '5% 10%', cursor: 'pointer' },
  "& .sidebar_menu_box": { backgroundColor: "#111111", padding: 2, marginTop: '60px' }, "& .sidebar_menu_list": { padding: 0 }, "& .sidebar_menu_item": { display: "flex", flexDirection: 'column', padding: 0 }, "& .accordian_details": { display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '12px' }, "& .divider": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 }, "& .divider_for_tabs": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 },
  '& .MuiSlider-track': {
    height: 8,
    border: 'none',
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
  },
  "& .MuiSlider-root": {
    width: "97%",
  },
  '& .MuiSlider-thumb': {
    height: 20,
    backgroundColor: '#fff',
    width: 20,
    border: '2px solid #171717',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    height: 8,
    backgroundColor: '#bfbfbf',
    opacity: 1,
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
  '& .budget_min_max_box': {
    backgroundColor: '#222222', padding: '10 15', borderRadius: '5%'
  },
  '& .budget_count_box': {
    display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '35px'
  }, "& .budget_min_max_color": {
    color: '#797979', fontFamily: "Inter , sans-serif",
  }, '& .budget_count_color': {
    color: '#FFF2E3', fontFamily: "Inter , sans-serif",
  }, '& .to_padding': {
    padding: 10
  }, "& .MuiTab-wrapper": {
    display: 'flex',
    justifyContent: "start",
  }, "& ..MuiTab-root": {
    minWidth: 0,
  }, "& .font_weight_600": {
    fontWeight: 600
  }, "& .disabled-btn": {
    backgroundColor: 'rgb(157, 155, 153)',
    color: "black"
  }, "& .slider_container": {
    display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", marginTop: -41
  },
  "& .opportunity_card_main_container": {
    padding: "22px 20px 22px 20px",
    borderRadius: 13,
    gap: 24,
    border: "1px solid #3E3E3E",
    cursor: "pointer",
  },
  "& .scroll::-webkit-scrollbar": {
    display: "none",
  },
  "& .opportunity_card_main_containerChangeColor": {
    cursor: "pointer",
    background: '#313131',
    position: 'relative',
    borderRadius: '13px',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "Inter,sans-serif",
    padding: '22px 20px 22px 20px',
    fontSize: '20px',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '13px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMaskComposite: 'xor',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'exclude',
    }

  },
  "& .opportunity_card_person_city": {
    fontSize: "14px",
    color: "#FFF2E3",
    fontFamily: "Inter,sans-serif",
    fontWeight: 400,
  },
  "& .opportunity_card_price_duration": {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    fontFamily: "Inter,sans-serif",
  },
  "& .opportunity_card_price_color": {
    color: "#1CBAE0"
  }, "& .opportunity_card_duration_color": {
    marginLeft: 15,
    color: "#FFB9C7",
  },
  "& .opportunity_card__description": {
    fontSize: "12px",
    fontFamily: "Inter,sans-serif",
    height: 50,
    fontWeight: '400',
    color: "#FFF2E3",
    flexGrow: 1,
  },
  "& .opportunity_card_price_duration_box": {
    display: 'flex'
  }, "& .only_2_line": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
    maxHeight: "3em",
  },
  "& .opportunity_card_title_box": {
    borderColor: '#3E3E3E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "stretch",
    fontFamily: "Inter , sans-serif",
    gap: 14,
    justifyContent: "space-between",
  },
  "& .opportunity_card_divider_color": {
    borderColor: '#3E3E3E'
  },
  "& .opportunity_card_hour": {
    color: "#66625C",
    fontWeight: 400,
    fontSize: "11px",
    fontFamily: "Inter,sans-serif",
  },
  '& .MoreVectorIcon': {
    paddingBottom: '20px',
    textAlign: 'center',
  },
  '& .MoreVectorIcon svg': {
    height: "50px",
    width: "50px",
    background: "#171717",
    borderRadius: "50px",
    color: '#fff'
  },
  "& .opportunity_card_title": {
    fontSize: "20px",
    fontFamily: "Inter,sans-serif",
    fontWeight: 600,
    color: "#FFF2E3",
    height: 40
  },
  "& .opportunity_card_person_name": {
    fontSize: "14px",
    fontFamily: "Inter,sans-serif",
    fontWeight: 600,
    color: "#FFF2E3",
  }, "& .opportunity_card_person_detain_box": {
    display: "flex",
    alignItems: "baseline",
    justifyContent: 'space-between',
    marginTop: 10,
  },
}));

const CustomBox = withStyles((theme) => ({
  root: {
    '&.discard-container': {
      display: 'flex',
      alignItems: 'center',
    },
    '&.discard-container .discard-text': {
      fontSize: '16px',
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '19.36px',
      color: '#fff2e3',
      width: 'max-content',
      marginTop: '25px',
    },
  }
}))(Box);

const webStyle = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  mainClientWrap:{
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%, 1920px)',
    margin: 'auto',
  },
  revisedAllPropsCon: {
    marginTop: '116px',
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3',
    borderBottom: '1px solid #fff2e3',
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start",
    width: 'max-content'
  },
  cursonPointer: {
    cursor: "pointer",
  },
  mainTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '43.57px',
  },
  noresultBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '15%',
    gap: 2,
  }
}


export default ExpoloreMoreOpportunity
// Customizable Area End