Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.editProjectApiMethodType = "PUT"
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.createCollectionEndPoint = "bx_block_projectportfolio/create_collection";
exports.getCollectionEndPoint = "bx_block_projectportfolio/get_user_collections";
exports.getCategoryEndPoint = "bx_block_projectportfolio/get_categories";
exports.getSkillEndPoint = "bx_block_projectportfolio/get_skills";
exports.createProject = "bx_block_projectportfolio/projectportfolios";
exports.searchSkillEndPoint = "bx_block_projectportfolio/search_skill";
exports.createWorkOpportunity = "bx_block_landingpage2/work_opportunities";
exports.discoverList="bx_block_landingpage2/projects";
exports.getBoardsEndPoint="bx_block_dashboard/inspirations"
exports.InspirationFollowingBoards='bx_block_dashboard/inspirations/user_followings_inspirations?';
exports.baseURL = require('../../../framework/src/config');
exports.test = "testNew"
exports.workTypeArray = [
  {
    name:'Concept creation',
    value: 'Concept creation',
    details:'The designer will do only concept creation and rest of the design work will be handled by the client’s own team.'
  },
  {
    name:'Adaptation',
    value: 'Adaptation',
    details:'Develop and design the whole project based on a starting design concept given by client.'
  },
  {
    name:'Improvisation/ Rework',
    value: 'Improvisation',
    details:'Implement some design changes and improvisations in an existing design project of thee client.'
  },
]

exports.experienceArray = [
  {
    name:'Entry level',
    value: 'Entry level'
  },
  {
    name:'Intermediate level',
    value: 'Intermediate level'
  },
  {
    name:'Expert level',
    value: 'Expert level'
  },
]
exports.dashboarApiPostMethodType = "POST";
exports.addDezinersText = "+ Add from Deziner’s knot";
exports.savedraftApi = "bx_block_landingpage2/work_opportunities/save_to_draft"
// Customizable Area End