import React from "react";

// Customizable Area Start
import { Container, Box, Button, Typography, styled, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  deleteIcon,
  leftArrowIcon
} from '../../dashboard/src/assets';
import UpdatedBarChart from "../../../components/src/BarChart";
import { CustomCircularProgress, GradientCircularProgress } from "../../../components/src/SmallComponent.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderButtons = () => {
    const { currentNav } = this.state;
    return currentNav === 'Contracts' ? (
      <Box style={webStyle.find_work_and_praposals_button}>
        <StyledButton variant="contained">
          Contracts
        </StyledButton>
        <Typography id="praposals_button" onClick={this.handleChangeCurrentNavData} style={webStyle.praposal_text}>
          Earnings
        </Typography>
      </Box>
    ) : (
      <Box style={webStyle.find_work_and_praposals_button}>
        <Typography id="find_work_button" onClick={this.handleChangeCurrentNavData} style={webStyle.praposal_text}>
          Contracts
        </Typography>
        <StyledButton variant="contained">
          Earnings
        </StyledButton>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
          <NavigationMenu {...this.props} />
          <MainOuterWrap>
            <Box data-testid="allProposals"
              style={webStyle.allPropsCon}
            >
              <img src={leftArrowIcon} data-test-id="goback" onClick={this.goBack} style={{ cursor: 'pointer' }} alt="left-icon" />
              <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>My Insights</Typography>
            </Box>
            <Box style={webStyle.marginTop45}>
              <Grid container spacing={3}>
                <Grid lg={4} md={3} sm={12} item>
                  <Box style={webStyle.lightGrayBackground}>
                    <Box style={webStyle.padding40alll}>
                      <Typography style={webStyle.font20White}>Total Earnings</Typography>
                      <Box style={webStyle.marginTop60}>
                        <CustomCircularProgress
                          text="Total Earnings"
                          value={75}
                          size={263}
                          thickness={2}
                          color="#FFF2E3"
                          amount="5460$"
                        />
                        <Box style={webStyle.flexRow}>
                          <Box style={webStyle.flexCenter}>
                            <Box style={webStyle.whiteCircle19}></Box>
                            <Typography
                              style={webStyle.font20White}
                            >
                              Wallet
                            </Typography>
                            <Typography
                              style={webStyle.font32White}
                            >
                              10K
                            </Typography>
                          </Box>
                          <Box style={webStyle.flexCenter}>
                            <Box style={webStyle.grayCircle19}></Box>
                            <Typography
                              style={webStyle.font20Gray}
                            >
                              Credited
                            </Typography>
                            <Typography
                              style={webStyle.font32White}
                            >
                              4K
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid lg={8} md={9} sm={12} item>
                  <Box style={webStyle.lightGrayBackground}>
                    <Box style={webStyle.padding4030}>
                      <Grid container>
                        <Grid item lg={3} md={3} sm={12}>
                          <Typography style={webStyle.font20White}>Contracts</Typography>
                          <Box style={webStyle.borderRightBlack}>
                            <Box style={webStyle.marginTop0}>
                              <GradientCircularProgress value={55} size={263} thickness={15} amount={194} />
                              <Box style={webStyle.completeBorder}>
                                <Box style={webStyle.flexRow}>
                                  <Box style={webStyle.flexSpaceBetween}>
                                    <Box style={webStyle.displayFlex}>
                                      <Box style={webStyle.circleStyleMultiColor26}></Box>
                                      <Typography
                                        style={webStyle.font20Gray}
                                      >
                                        Completed
                                      </Typography>
                                    </Box>
                                    <Typography
                                      style={webStyle.font32White}
                                    >
                                      10K
                                    </Typography>
                                  </Box>
                                  <Box style={webStyle.flexSpaceBetween}>
                                    <Box style={webStyle.displayFlex}>
                                      <Box style={webStyle.whiteCircle26}></Box>
                                      <Typography
                                        style={webStyle.font20Gray}
                                      >
                                        Ongoing
                                      </Typography>
                                    </Box>
                                    <Typography
                                      style={webStyle.font32White}
                                    >
                                      4K
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={9} md={9} sm={12}>
                          <Box style={{ padding: '0 20px' }}>
                            {this.renderButtons()}
                            {this.state.currentNav === 'Contracts' ?
                            <UpdatedBarChart
                              labels={configJSON.monthLabel}
                              datasets={[
                                {
                                  label: "Contracts ",
                                  data: [90, 78, 65, 11, 22, 33, 44, 55, 66],
                                  backgroundColor: "rgba(83, 83, 83, 1)",
                                  borderRadius: 6,
                                  hoverBackgroundColor: 'rgba(255, 242, 227, 1)'
                                },
                              ]}
                              barThickness={30}
                              xTickColor="rgba(83, 83, 83, 1)"
                              yTickColor="rgba(83, 83, 83, 1)"
                              viewingMonth="No. of Contracts"
                              stepSize={5}
                              label= "Contracts"
                            />
                            : 
                            <UpdatedBarChart
                              labels={configJSON.monthLabel}
                              datasets={[
                                {
                                  label: "",
                                  data: [7500, 7008, 6005, 1001, 2200, 3300, 4400, 5500, 6600],
                                  backgroundColor: "rgba(83, 83, 83, 1)",
                                  borderRadius: 6,
                                  hoverBackgroundColor: 'rgba(255, 242, 227, 1)'
                                },
                              ]}
                              barThickness={30}
                              xTickColor="rgba(83, 83, 83, 1)"
                              yTickColor="rgba(83, 83, 83, 1)"
                              viewingMonth="Earnings ($)"
                              stepSize={1000}
                              label= ""
                            />
  }
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </MainOuterWrap>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  overflowY: 'auto',
                  boxShadow: "none",
                  overflowX: "hidden",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});

const MainOuterWrap = styled(Box)({
  margin: '120px auto',
  backgroundColor: '#0D0D0D',
  width: 'min(100%,1845px)',
  "@media (max-width:1775px)": {
    margin: '120px 35px',
  },
  "@media (max-width:1280px)": {
    margin: '120px 35px',
  },
})

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  borderRadius: '50px',
  background: '#313131',
  color: 'white',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: "Inter,sans-serif",
  overflow: 'hidden',
  fontSize: '20px', padding: '1% 17%',
  zIndex: 1,
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '50px',
    padding: '2px',
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
  '&:hover': {
    background: '#313131',
  },
  [theme.breakpoints.down(1340)]: {
    fontSize: '17px',
    padding: '5% 10%',
  },

}));

const dataOld = [
  ["Name", "Popularity"],
  ["Cesar", 250],
  ["Rachel", 4200],
  ["Patrick", 2900],
  ["Eric", 8200],
];

const dataNew = [
  ["Name", "Popularity"],
  ["Cesar", 370],
  ["Rachel", 600],
  ["Patrick", 700],
  ["Eric", 1500],
];

export const diffdata = {
  old: dataOld,
  new: dataNew,
};

const webStyle: Record<string, React.CSSProperties> = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#0D0D0D",
    minHeight: '100vh'
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '43.57px',
    color: '#FFF2E3'
  },
  marginTop45:{
    marginTop: '45px'
  },
  lightGrayBackground :{
    backgroundColor: '#111'
  },
  padding40alll:{
    padding:'40px'
  },
  font20White: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  font20Gray: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.2px',
    color: 'rgba(119, 119, 119, 1)'
  },
  font32White: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.38px',
    color: 'rgba(255, 242, 227, 1)'
  },
  marginTop60: {
    marginTop: '60px',
    display: 'flex',
    alignItems:'center',
    gap: '32px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginTop0: {
    display: 'flex',
    alignItems:'center',
    gap: '32px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  whiteCircle19:{
    width:'19px',
    height:'19px',
    backgroundColor: "rgba(255, 242, 227, 1)",
    borderRadius: '50%'
  },
  grayCircle19:{
    width:'19px',
    height:'19px',
    backgroundColor: "rgba(83, 83, 83, 1)",
    borderRadius: '50%'
  },
  flexCenter:{
    display:'flex',
    alignItems:'center',
    gap: '12px'
  },
  flexJusCenter:{
    display:'flex',
    alignItems:'center',
    gap: '12px',
    justifyContent: 'center'
  },
  flexRow:{
    display: 'flex',
    flexDirection: 'column',
    gap: "30px"
  },
  completeBorder:{
    padding: '16px',
    border: '1px solid rgba(83, 83, 83, 1)',
    borderRadius: '16px',
    margin: '25px 0',
    maxWidth: '230px',
    width: '100%',
  },
  circleStyleMultiColor26: {
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    background: 'linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%)',
  },
  whiteCircle26:{
    width:'26px',
    height:'26px',
    backgroundColor: "rgba(255, 242, 227, 1)",
    borderRadius: '50%'
  },
  displayFlex:{
    display:'flex',
    gap:'10px'
  },
  flexSpaceBetween:{
    display:'flex',
    justifyContent:'space-between'
  },
  borderRightBlack:{
    borderRight: '1px solid rgba(34, 34, 34, 1)'
  },
  praposal_text: { 
    color: '#8C8C8C',
    fontFamily: "Inter,sans-serif",
    fontSize: '20px',
    padding: '2% 17%',
    cursor: 'pointer'
  },
  find_work_and_praposals_button: { 
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: "center", 
    padding: 6, 
    backgroundColor: '#0D0D0D', 
    borderRadius: 50, 
    marginBottom: 20
  },
  padding4030:{
    padding:'40px 0 40px 30px'
  }
};
// Customizable Area End
