import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
let tokenData = "";

import { CreativeField } from '../../../components/src/CreativeFields.web'
import { workOpportunity } from "../../../components/src/OpportunityCard.web";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface SortingByWorkType {
  name: string;
  id: number;
  value: string;
  tooltip: string;
}

export interface SortingBySideBar {
  name: string;
  id: number;
  value: string;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface CategoryResponse {
  message: string
  data: CreativeField[]
}

export interface LocationResponse {
  locations: string[]
}

export interface SearchMain {
  data: SearchMainData[]
  work_opportunity_count: number
}

export interface SearchMainData {
  id: string
  type: string
  attributes: SearchMainAttributes
}

export interface SearchMainAttributes {
  id: number
  title: string
  description: string
  rate_type: string
  rate_amount: number
  project_timeline: number
  project_timeline_type: string
  created_at: Date
  updated_at: Date
  photo: string | null,
  user_name: string
  full_name: string
  background_color_code: string | null
  posted_time_ago: string
  location: string
  account_id: number
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  currentNav: string;
  accordianToggle: boolean[];
  tabValues: number;
  selectedOpportunityId: number | string;
  proposalTab: number
  selectedSort: {
    name: string;
    value: string;
  };
  isApplyDisable: boolean;
  savedListing: string;
  count: number;
  creativeFields: CreativeField[];
  selectedCategories: number[];
  isCreativeFieldChanged: boolean;
  rateMin: number;
  rateMax: number;
  isSliderChanged: boolean;
  isRemotLocationSelected: boolean;
  selectedLocation: string[];
  isOtherLocationSelected: boolean;
  locationListingData: string[];
  workDurtationSelected: string[];
  page: number;
  loading: boolean
  workOpportunityData: workOpportunity[];
  perPage: number;
  submitData: {
    sort?: string;
    "budget[min]"?: number;
    "budget[max]"?: number;
    skill_category_ids?: number[];
    location?: string[];
    work_duration?: string[];
    experience_level?: string[];
    required_hours_per_week?: string[];
    work_type?: string[];
    search?: string;
    saved_listing?: string;
  };
  findWorkSearch: string;
  isSearched: boolean;
  workTypeSelected: string[];
  hoursPerWeekSelected: string[];
  experienceSelected: string[];
  openOppertunity:boolean;
  route: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ExpoloreMoreOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchCategoryApiCallId: string = ""
  locationListApiCallId: string = ""
  searchWorkApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      currentNav: '',
      accordianToggle: [true, true, true, true, true, true],
      tabValues: 0,
      selectedOpportunityId: 0,
      proposalTab: 0,
      selectedSort: {
        name: "Recently Listed",
        value: 'recently_listed'
      },
      isApplyDisable: true,
      savedListing: 'false',
      count: 5,
      creativeFields: [],
      selectedCategories: [],
      isCreativeFieldChanged: false,
      rateMin: 100,
      rateMax: 5000,
      isSliderChanged: false,
      isRemotLocationSelected: false,
      selectedLocation: [],
      isOtherLocationSelected: false,
      locationListingData: [],
      workDurtationSelected: [],
      page: 1,
      loading: false,
      workOpportunityData: [],
      perPage: 18,
      submitData: {
        sort: 'recently_listed',
        saved_listing: "false",
      },
      findWorkSearch: '',
      isSearched: false,
      workTypeSelected: [],
      hoursPerWeekSelected: [],
      experienceSelected: [],
      openOppertunity: false,
      route: ''
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.searchCategoryApiCallId:
          this.handleCategory(responseJson);
          break;

        case this.locationListApiCallId:
          this.handleLocation(responseJson);
          break;

        case this.searchWorkApiCallId:
          this.handleMainData(responseJson)
          break;

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }

    this.searchCategory()
    this.locationList()
    this.searchWork()
  }

  handleCategory = (responseJson: CategoryResponse) => {
    if (responseJson.data) {
      this.setState({
        creativeFields: responseJson.data
      });
    } else {
      this.setState({
        creativeFields: []
      });
    }
  }

  handleLocation = (responseJson: LocationResponse) => {
    if (responseJson.locations) {
      this.setState({
        locationListingData: responseJson.locations
      });
    } else {
      this.setState({
        locationListingData: []
      });
    }
  }

  handleMainData = (responseJson: SearchMain) => {
    if (responseJson.data) {
      this.setState({
        workOpportunityData: responseJson?.data,
        loading: false
      });
      this.handleCalculatePageCount(responseJson?.work_opportunity_count);
    }
    else {
      this.setState({
        workOpportunityData: [],
        loading: false
      });
      this.handleCalculatePageCount(0);
    }
  }

  navigateFun = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSortSelect = (name: string, value: string) => {
    this.setState({ selectedSort: { name, value }, isApplyDisable: false })
  }

  handleCreativeFieldApply = (value: number[]) => {
    this.setState({ selectedCategories: value, isCreativeFieldChanged: true })
  }

  handleAccordianToggle = (index: number) => {
    this.setState((prevState) => ({
      accordianToggle: prevState.accordianToggle.map((value, i) => (
        i === index ? !value : value
      )),
    }));
  };

  handleRangeSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    const newValue = value as number[]
    this.setState({ rateMin: newValue[0], rateMax: newValue[1], isSliderChanged: true, isApplyDisable: false })
  }

  handleRemoteWorkLocation = () => {
    this.setState({ isRemotLocationSelected: !this.state.isRemotLocationSelected, isApplyDisable: false })
  }

  handleWorkLocationSelected = () => {
    this.setState({ isOtherLocationSelected: !this.state.isOtherLocationSelected }, () => {
      if (this.state.isOtherLocationSelected === false) {
        this.setState({ selectedLocation: [] })
      }
    })
  }

  handleLocationApplyFn = (selectedLocations: string[]): void => {
    this.setState({ selectedLocation: selectedLocations, isApplyDisable: false }, () => {
      this.setState({ isOtherLocationSelected: this.state.selectedLocation.length > 0 })
    })
  }

  handleWorkDurtationClick = (value: string, index: number) => {
    const { workDurtationSelected } = this.state
    let findValue = workDurtationSelected.includes(value)

    if (!findValue) {
      this.setState({ workDurtationSelected: [...workDurtationSelected, value] })

    } else {
      let removedValue = workDurtationSelected.filter((ele) => ele !== value)
      this.setState({ workDurtationSelected: removedValue })
    }

    this.setState({ isApplyDisable: false })

  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ page: page }, () => {
      this.searchWork()
    })
  }

  toQueryParams(obj: any) {
    const queryParams = Object.keys(obj).map(key => {
      const value = obj[key];

      if (Array.isArray(value)) {
        return value.map(v => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`).join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    });

    return queryParams.join('&');
  }

  handleCalculatePageCount(result_count: number) {
    const pageCount = Math.ceil(result_count / this.state.perPage);
    this.setState({ count: pageCount })
  }

  handleFindWorkSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ findWorkSearch: event.target.value })
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleApplyFilterButton();
    }
  };

  clearText = () => {
    this.setState({ findWorkSearch: '', isSearched: false }, () => {
      this.handleApplyFilterButton()
    })
  }

  handleDisableSearch = () => {
    if (this.state.findWorkSearch.length === 0) {
      return true
    } else {
      return false
    }
  }

  handleWorkTypeClick = (value: string) => {
    const { workTypeSelected } = this.state
    let findValue = workTypeSelected.includes(value)
    if (!findValue) {
      this.setState({ workTypeSelected: [...workTypeSelected, value] })
    } else {
      let removedValue = workTypeSelected.filter(
        (ele) => ele !== value
      )
      this.setState({ workTypeSelected: removedValue })
    }
    this.setState({ isApplyDisable: false })
  }

  handleExperienceClick = (value: string) => {
    const { experienceSelected } = this.state
    let findValue = experienceSelected.includes(value)
    if (!findValue) {
      this.setState({
        experienceSelected: [...experienceSelected, value]
      })
    } else {
      let removedValue = experienceSelected.filter((ele) => ele !== value)
      this.setState({ experienceSelected: removedValue })
    }
    this.setState({ isApplyDisable: false })
  }

  handleHoursPerWeekClick = (value: string) => {
    const { hoursPerWeekSelected } = this.state
    let findValue = hoursPerWeekSelected.includes(value)
    if (!findValue) {
      this.setState({ hoursPerWeekSelected: [...hoursPerWeekSelected, value] })
    } else {
      let removedValue = hoursPerWeekSelected.filter((ele) => ele !== value)
      this.setState({ 
        hoursPerWeekSelected: removedValue 
      })
    }
    this.setState({ isApplyDisable: false })
  }

  handleApplyFilterButton = () => {

    if (this.state.findWorkSearch) {
      this.setState({ 
        isSearched: true 
      })
    } else {
      this.setState({ isSearched: false })
    }

    const { rateMax, rateMin, selectedCategories, workDurtationSelected, workTypeSelected, hoursPerWeekSelected, experienceSelected, findWorkSearch, selectedLocation, isRemotLocationSelected, isOtherLocationSelected, selectedSort, isSliderChanged, savedListing } = this.state
    let location: string[] = []
    if (isRemotLocationSelected) location = ['remote']
    if (isOtherLocationSelected) location = [...location, ...selectedLocation]
    const submitData = {
      ...(selectedSort && { sort: selectedSort.value }),
      ...(rateMax !== null && isSliderChanged && { "budget[max]": rateMax }),
      ...(rateMin !== null && isSliderChanged && { "budget[min]": rateMin }),
      ...(selectedCategories.length > 0 && { skill_category_ids: selectedCategories }),
      ...(location.length > 0 && { location }),
      ...(experienceSelected.length > 0 && { experience_level: experienceSelected }),
      ...(workDurtationSelected.length > 0 && { work_duration: workDurtationSelected }),
      ...(hoursPerWeekSelected.length > 0 && { required_hours_per_week: hoursPerWeekSelected }),
      ...(workTypeSelected.length > 0 && { work_type: workTypeSelected }),
      ...(findWorkSearch && { search: findWorkSearch }),
      saved_listing: savedListing,
    };
    this.setState({ page: 1, submitData: submitData, isApplyDisable: true, isCreativeFieldChanged: false }, () => this.searchWork())
  }

  handleResetClick = () => {
    this.setState({
      selectedSort: {
        name: "Recently Listed",
        value: 'recently_listed'
      },
      selectedCategories: [],
      workDurtationSelected: [],
      workTypeSelected: [],
      experienceSelected: [],
      hoursPerWeekSelected: [],
      findWorkSearch: '',
      rateMax: 5000,
      isSliderChanged: false,
      rateMin: 100,
      isRemotLocationSelected: false,
      isOtherLocationSelected: false,
    }, () => this.handleApplyFilterButton())
  }

  handleDisableResetButton = () => {
    const { selectedCategories, workDurtationSelected, workTypeSelected, experienceSelected, hoursPerWeekSelected, findWorkSearch, isOtherLocationSelected, isRemotLocationSelected, selectedSort, isSliderChanged } = this.state

    if (selectedCategories.length || workDurtationSelected.length || workTypeSelected.length || experienceSelected.length || hoursPerWeekSelected.length || findWorkSearch.length || isSliderChanged || isRemotLocationSelected || isOtherLocationSelected || selectedSort.value === "recommended" || selectedSort.value === "client_rating") {
      return false
    } else {
      return true
    }
  }

  handleDisableApplyFilterButton = () => {
    const { isCreativeFieldChanged, isApplyDisable } = this.state

    if (isCreativeFieldChanged || !isApplyDisable) {
      return false
    } else {
      return true
    }
  }

  searchCategory = () => {
    let token = tokenData;
    const header = {
      token: token,
      'Content-Type': configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchCategoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClose = () =>{
    this.setState({openOppertunity:false})
  }

  handleOpenOpportunity = (id : number  | string) =>{
    this.setState({selectedOpportunityId:id},()=>this.setState({openOppertunity:true}))
  }

  locationList = () => {
    let token = tokenData;
    const header = {
      token: token,
      'Content-Type': configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.locationListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationList);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  searchWork = () => {
    const { page, perPage, submitData } = this.state
    this.setState({ 
      loading: true 
    });
    let token = tokenData;
    const header = {
      token: token
    };
    let query = this.toQueryParams(submitData)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchWorkApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchWorkApiEndPoint + page + configJSON.searchWorkApiQueryMidPoint + perPage + "&" + query
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}