import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import dayjs, { Dayjs } from "dayjs";

let tokenData = "";
let cardId = "";
let offerType = '';

export interface LoadDataMain {
  data: LoadData
}

export interface LoadData {
  initial_state: InitialState
  work_opportunity: WorkOpportunity
  proposal: Proposal
}

export interface InitialState {
  project_timeline: number
  end_date: string
  total_budget: number
}

export interface WorkOpportunity {
  id: number
  title: string
  rate_type: string
  rate_amount: number
  project_timeline: number
  project_timeline_type: string
  description: string
  posted_at: string
  contract_id: number
}

export interface Proposal {
  data: ProposalData
}

export interface ProposalData {
  id: string
  type: string
  attributes: ProposalDataAttributes
}

export interface ProposalDataAttributes {
  id: number
  created_at: string
  updated_at: string
  service_rate: number
  give_your_pitch: string
  profile_photo: string
  background_color: string
  location: string
  user_name: string
  likes_count: number
  view_count: number
  rating: number
  received_time_ago: string
}

export interface MileStoneData {
  title: string
  description: string
  deliverables: string[],
  amount: string
  dueDate: Dayjs | null
}

interface IdMap {
  [key: string]: number;
};

export interface SavedMilestone {
  milestones: SavedMilestoneMilestone[]
}

export interface SavedMilestoneMilestone {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string | Dayjs
  deliverables: SavedMilestoneDeliverable[]
}

export interface SavedMilestoneDeliverable {
  id: number
  deliverable: string
}

export interface SavedMilestoneMain {
  id: number
  start_date: string
  edited_start_date: any
  proposal_id: number
  account_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  created_at: string
  updated_at: string
  contract_overall_budget: number
  milestones: SavedMilestone[]
}

export interface SavedMilestone {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string
  deliverables: SavedMilestoneDeliverable[]
}

export interface SavedMilestoneDeliverable {
  id: number
  deliverable: string
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openCalender: boolean;
  openCalenderEdit: boolean;
  openCalenderStart: boolean;
  dateData: Dayjs | null;
  staticDate: Dayjs;
  conditionChecked1: boolean;
  conditionChecked2: boolean;
  deliverableList: string[];
  deliverableCountList: number[];
  expanded: boolean[]
  initialState: InitialState
  workOpportunity: WorkOpportunity
  proposal: ProposalDataAttributes
  title: string
  description: string
  amount: string
  milestoneList: MileStoneData[]
  startDate: Dayjs
  mileStoneExpandData: { 
    expandedTerms: IdMap 
  },
  editMileStone: boolean
  editMileStoneId:number;
  editMileStoneAllData: MileStoneData
  BudgetErrorMsg:number | string;
  open: boolean;
  message: string;
  action: string;
  descCountCharacter: number;
  nameCountCharacter: number;
  editdescCountCharacter: number;
  editnameCountCharacter: number;
  editdeliverableCountList: number[];
  savedSwitch: boolean
  savedMilestone: SavedMilestoneMilestone[]
  openCalenderSaved: boolean[]
  offerType: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfdesignersidecontractmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLoadOfferData: string = ''
  apiCreateContract: string = ''
  apiLoadSavedMilestone: string = ''
  apiResendContract: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openCalender: false,
      openCalenderEdit: false,
      openCalenderStart: false,
      dateData: null,
      staticDate: dayjs(),
      conditionChecked1: false,
      conditionChecked2: false,
      deliverableList: [""],
      deliverableCountList: [0],
      expanded: [],
      initialState: {
        end_date: "",
        project_timeline: 0,
        total_budget: 0,
      },
      workOpportunity: {
        id: 0,
        title: "",
        rate_type: "",
        rate_amount: 0,
        project_timeline: 0,
        project_timeline_type: "",
        description: "",
        posted_at: "",
        contract_id: 0
      },
      proposal: {
        id: 0,
        created_at: "",
        updated_at: "",
        service_rate: 0,
        give_your_pitch: "",
        profile_photo: "",
        background_color: '',
        location: "",
        user_name: "",
        likes_count: 0,
        view_count: 0,
        rating: 0,
        received_time_ago: "",
      },
      title: '',
      description: '',
      amount: '',
      milestoneList: [],
      startDate: dayjs(),
      mileStoneExpandData: {
        expandedTerms: {}
      },
      editMileStone: false,
      editMileStoneId: 0,
      editMileStoneAllData: {
        amount: '',
        deliverables: [],
        description: '',
        dueDate: null,
        title: ''
      },
      BudgetErrorMsg: '',
      open: false,
      message: '',
      action: '',
      descCountCharacter: 0,
      nameCountCharacter: 0,
      editdescCountCharacter: 0,
      editnameCountCharacter: 0,
      editdeliverableCountList: [],
      savedSwitch: false,
      savedMilestone: [],
      openCalenderSaved: [],
      offerType: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiLoadOfferData) {
          this.handleLoadData(responseJson, message);
        } else if (apiRequestCallId === this.apiCreateContract) {
          this.handleContactApi(responseJson, message);
        } else if (apiRequestCallId === this.apiLoadSavedMilestone) {
          this.handleMiletone(responseJson, message);
        } else if (apiRequestCallId === this.apiResendContract) {
          this.handleContactApi(responseJson, message);
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    cardId = this.props.navigation.getParam("id")
    offerType = this.props.navigation.getParam("offer")
    this.loadData();

    if (offerType == '1') {
      this.setState({ offerType: true });
    }else{
      this.setState({ offerType: false });
    }
  }

  manageCalender = () => {
    this.setState({
      openCalender: !this.state.openCalender
    });
  }

  manageCalenderEdit = () => {
    this.setState({
      openCalenderEdit: !this.state.openCalenderEdit
    });
  }

  manageCalenderStart = () => {
    this.setState({
      openCalenderStart: !this.state.openCalenderStart
    });
  }

  manageCalenderSaved = (index: number) => {
    this.setState((prevState) => {
      const updatedArray = [...prevState.openCalenderSaved];
      if (index >= 0 && index < updatedArray.length) {
        updatedArray[index] = true;
      }
      return { openCalenderSaved: updatedArray };
    });
  }

  handleCurrentDateStart = (): Dayjs => {
    return dayjs();
  }

  handleMaxDateStart = (): Dayjs => {
    if (this.state.milestoneList.length > 0) {
      const testOld = this.state.milestoneList[0]?.dueDate
      if (testOld) {
        return testOld;
      }
    }
    if (this.state.savedMilestone.length > 0) {
      const testOld = this.state.savedMilestone[0]?.due_date
      if (dayjs(testOld)?.isBefore(dayjs())) {
        return dayjs("2099-12-31");
      } else {
        return dayjs(testOld);
      }
    }
    return dayjs("2099-12-31");
  }

  handleCurrentDate = (): Dayjs => {
    if (this.state.staticDate?.isAfter(this.state.startDate)) {
      if (this.state.milestoneList.length > 0) {
        const testOld = this.state.milestoneList[this.state.milestoneList.length - 1]?.dueDate
        if (testOld) {
          return testOld;
        } else {
          return this.state.staticDate;
        }
      } else {
        return this.state.startDate;
      }
    } else {
      return this.state.startDate;
    }
  }

  handleMinDateEdit = (): Dayjs => {
    if (this.state.milestoneList.length > 0) {
      const testOld = this.state.milestoneList[this.state.editMileStoneId - 1]?.dueDate
      if (testOld) {
        return testOld;
      }
    }
    if (this.state.milestoneList.length === 1) {
      return this.state.startDate;
    }
    return this.state.startDate;
  }

  handleMinDateSaved = (index: number): Dayjs => {
    if (this.state.savedMilestone.length > 0) {
      const testOld = this.state.savedMilestone[index - 1]?.due_date
      if (testOld) {
        if (dayjs(testOld).isBefore(dayjs(), 'day')) {
          return this.state.startDate;
        } else {
          return dayjs(testOld);
        }
      }
    }
    if (this.state.savedMilestone.length === 1) {
      return this.state.startDate;
    }
    return this.state.startDate;
  }

  handleMaxDateEdit = (): Dayjs => {
    if (this.state.milestoneList.length > 0) {
      const testNew = this.state.milestoneList[this.state.editMileStoneId + 1]?.dueDate
      if (testNew) {
        return testNew;
      }
    }
    return dayjs("2099-12-31");
  }

  handleMaxDateSaved = (index: number): Dayjs => {
    if (this.state.savedMilestone.length > 0) {
      const testNew = this.state.savedMilestone[index + 1]?.due_date
      if (testNew) {
        if (dayjs(testNew).isBefore(dayjs(), 'day')) {
          return dayjs("2099-12-31");
        } else {
          return dayjs(testNew);
        }
      }
    }
    return dayjs("2099-12-31");
  }

  handleDate = (data: Dayjs | null) => {
    if (data) {
      this.setState({ dateData: data, staticDate: data })
    }
  }

  handleDateEdit = (data: Dayjs | null) => {
    if (data) {
      this.setState((prevState) => ({
        editMileStoneAllData: {
          ...prevState.editMileStoneAllData,
          dueDate: data
        }
      }))
    }
  }

  handleDateSaved = (data: Dayjs | null, index: number) => {
    if (data) {
      this.setState((prevState) => {
        const updatedMilestones = [...prevState.savedMilestone];
        if (index >= 0 && index < updatedMilestones.length) {
          updatedMilestones[index] = {
            ...updatedMilestones[index],
            due_date: data,
          };
        }
        return { savedMilestone: updatedMilestones };
      });
    }
  }

  handleDateStart = (data: Dayjs | null) => {
    if (data) {
      this.setState({ startDate: data })
    }
  }

  toggleCondition1 = () => {
    this.setState({ conditionChecked1: !this.state.conditionChecked1 })
  }

  toggleCondition2 = () => {
    this.setState({ conditionChecked2: !this.state.conditionChecked2 })
  }

  handleCheckBg1 = () => {
    return this.state.conditionChecked1 ? "#d9d9d9" : "#111";
  }

  handleCheckBg2 = () => {
    return this.state.conditionChecked2 ? "#d9d9d9" : "#111";
  }

  checkAllDates = (): boolean => {
    if (this.state.savedMilestone.length > 0) {
      return this.state.savedMilestone.every((item) => {
        const dueDate = dayjs(item.due_date);
        return dueDate.isSame(dayjs(), 'day') || dueDate.isAfter(dayjs(), 'day');
      });
    }
    return false;
  };

  checkAllDatesForError = () => {
    if (this.state.savedSwitch) {
      if (this.state.savedMilestone.length > 0) {
        return this.state.savedMilestone.every((item) => {
          const dueDate = dayjs(item.due_date);
          return dueDate.isSame(dayjs(), 'day') || dueDate.isAfter(dayjs(), 'day');
        });
      }
      return false;
    }
    return true;
  };
  

  enablePostWorkOpportunity = () => {
    if (!this.state.savedSwitch)
      if (
        this.state.conditionChecked1
        && this.state.conditionChecked2
        && this.state.milestoneList.length > 0
      ) {
        return true
      } else {
        return false
      } else {
      if (this.state.savedMilestone.length > 0) {
        const check = this.checkAllDates()
        if (
          this.state.conditionChecked1
          && this.state.conditionChecked2
          && this.state.savedMilestone.length > 0
          && check
        ) {
          return true
        } else {
          return false
        }
      }
    }
  }

  enablebuttonText = () => {
    return this.enablePostWorkOpportunity() ? "secondary-btn" : "disabled-btn";
  }

  addDeliverable = () => {
    this.setState({
      deliverableList: [...this.state.deliverableList, ""],
      deliverableCountList: [...this.state.deliverableCountList, 0]
    })
  }

  addDeliverableEdit = () => {
    this.setState((prevState) => ({
      editMileStoneAllData: {
        ...prevState.editMileStoneAllData,
        deliverables: [...this.state.editMileStoneAllData.deliverables, ""]
      }
    }))
  }

  handleQuestionData = (data: string, index: number) => {
    const indexData = [...this.state.deliverableList];
    indexData[index] = data;
    this.setState({
      deliverableList: [...indexData]
    }, () => {
      this.handleDeliverableCount(data, index)
    })
  }

  handleDeliverableCount = (data: string, index: number) => {
    const characterCount = typeof data === 'string' ? data.length : 0;
    const indexCountData = [...this.state.deliverableCountList];
    indexCountData[index] = characterCount;

    this.setState({
      deliverableCountList: [...indexCountData]
    })
  }

  handleQuestionEditData = (data: string, index: number) => {
    const indexData = [...this.state.editMileStoneAllData.deliverables];
    indexData[index] = data;
    this.setState((prevState) => ({
      editMileStoneAllData: {
        ...prevState.editMileStoneAllData,
        deliverables: [...indexData]
      }
    }), () => {
      this.handleDeliverableEditCount(data, index)
    })
  }

  handleDeliverableEditCount = (data: string, index: number) => {
    const characterCount = typeof data === 'string' ? data.length : 0;
    const indexCountData = [...this.state.editdeliverableCountList];
    indexCountData[index] = characterCount;

    this.setState({
      editdeliverableCountList: [...indexCountData]
    })
  }

  removeQuestion = (index: number) => {
    this.setState({
      deliverableList: this.state.deliverableList.filter((item, index1) => index1 !== index)
    }, () => {
      if (this.state.deliverableList.length === 0) {
        this.setState({
          deliverableList: [...this.state.deliverableList, ""]
        })
      }
    })
  }

  removeQuestionEdit = (index: number) => {
    this.setState((prevState) => ({
      editMileStoneAllData: {
        ...prevState.editMileStoneAllData,
        deliverables: this.state.editMileStoneAllData.deliverables.filter((item, index1) => index1 !== index)
      }
    }), () => {
      if (this.state.editMileStoneAllData.deliverables.length === 0) {
        this.setState((prevState) => ({
          editMileStoneAllData: {
            ...prevState.editMileStoneAllData,
            deliverables: [...this.state.editMileStoneAllData.deliverables, ""]
          }
        }))
      }
    })
  }

  loadData = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': 'application/json'
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLoadOfferData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.offerContractApiEnd + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLoadData = (responseJson: LoadDataMain, message: Message) => {
    if (responseJson.data) {
      this.setState({
        initialState: responseJson.data.initial_state,
        workOpportunity: responseJson.data.work_opportunity,
        proposal: responseJson.data.proposal.data.attributes
      })
    } else { this.handleErrorMSg(message); }
  }

  handleContactApi = async(responseJson: { "data": {} }, message: Message) => {
    if (responseJson.data) {
      this.setState({
        open: true,
        message: "Contract sent successfully",
        action: "success",
      }, () => {
        setTimeout(() => {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyWork");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
        }, 2000);
      });      
    } else { this.handleErrorMSg(message); }
  }

  handleMiletone = (responseJson: SavedMilestone, message: Message) => {
    if (responseJson.milestones) {
      this.setState({
        savedMilestone: responseJson.milestones
      })
    }
    else { this.handleErrorMSg(message); }
  }

  handleErrorMSg = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      open: true,
      message: responseJson.errors[0].error,
      action: "danger"
    });
  }

  handleTitleChange = (data: string) => {
    this.setState({ title: data }, () => {
      this.handlenameCharacterCount(data)
    })
  }

  handlenameCharacterCount = (e: string) => {
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ nameCountCharacter: characterCount });
  }

  handleDescriptionChange = (data: string) => {
    this.setState({ description: data }, () => {
      this.handleCountCharacter(data);
    })
  }

  handleCountCharacter = (e: string) => {
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ descCountCharacter: characterCount });
  }

  isnumericVal = (data: string) => data === "" || /^[0-9\b]+$/.test(data);

  handleAmountChange = (data: string) => {
    if (this.isnumericVal(data)) {
      if (Number(data) > 9999999) {
        this.setState({
          BudgetErrorMsg: 'Overall budget must be less than 99,999,99'
        })
      } else {
        this.setState({ amount: data, BudgetErrorMsg: '' })
      }
    }
  }

  handleTitleEditChange = (name: string, data: string) => {
    this.setState((prevState) => ({
      editMileStoneAllData: {
        ...prevState.editMileStoneAllData,
        [name]: data
      }
    }))
    const characterCount = typeof data === 'string' ? data.length : 0;
    if (name == "title") {
      this.setState({ editnameCountCharacter: characterCount })
    } else if (name == "description") {
      this.setState({ editdescCountCharacter: characterCount })
    }
  }

  enableAddMilestone = () => {
    let count = 0;
    this.state.deliverableList.forEach((item) => {
      if (item !== '') {
        count = count + 1
      }
    })
    if (
      this.state.title
      && this.state.description
      && count > 0
      && this.state.amount
      && this.state.dateData
    ) {
      return true
    } else {
      return false
    }
  }

  addMilestone = () => {
    const check = this.enableAddMilestone()
    if (check) {
      const Milestone: MileStoneData = {
        title: this.state.title,
        description: this.state.description,
        amount: this.state.amount,
        deliverables: this.state.deliverableList,
        dueDate: this.state.dateData
      }
      this.setState(prevState => ({
        milestoneList: [...prevState.milestoneList, Milestone],
        title: '',
        description: '',
        amount: '',
        deliverableList: [''],
        dateData: null,
        BudgetErrorMsg: '',
        nameCountCharacter: 0,
        descCountCharacter: 0,
        deliverableCountList: [0]
      }))
    }
  }

  toggleTerm = (index: number) => {
    this.setState((prevState) => ({
      mileStoneExpandData: {
        ...prevState.mileStoneExpandData,
        expandedTerms: {
          ...prevState.mileStoneExpandData.expandedTerms,
          [index]: !prevState.mileStoneExpandData.expandedTerms[index],
        }
      }
    }));
  };

  MileStonePoPuP = (id?: number) => {
    if (typeof id === 'number' && (id === 0 || id >= 0)) {
      this.setState({ editMileStoneId: id }, () => { this.setEditMileStoneData() })
    }
    this.setState({ editMileStone: !this.state.editMileStone, openCalenderEdit: false })
  }

  setEditMileStoneData = () => {
    const newdata = this.state.milestoneList[this.state.editMileStoneId]
    const deliverableLengths = newdata.deliverables.map(item => item.length);
    this.setState({
      editMileStoneAllData: newdata,
      editnameCountCharacter: newdata.title.length,
      editdescCountCharacter: newdata.description.length,
      editdeliverableCountList: deliverableLengths,
    })
  }

  isFormValid = () => {
    const { editMileStoneAllData } = this.state;

    const { title, description, amount, deliverables, dueDate } = editMileStoneAllData;
    if (
      !title || !description || !amount || !dueDate ||
      deliverables.length < 1
    ) {
      return false;
    }

    return true;
  };

  editUpdate = () => {
    this.setState((prevState) => {
      const updatedMilestoneList = [...prevState.milestoneList];
      updatedMilestoneList[this.state.editMileStoneId] = {
        ...updatedMilestoneList[this.state.editMileStoneId],
        ...this.state.editMileStoneAllData,
      };
      return {
        milestoneList: updatedMilestoneList,
        editMileStone: false
      };
    });
  }

  Contract = () => {
    if(this.state.offerType) {
      this.createReSendContract()
    } else {
      this.createContract()
    }
  }

  createContract = () => {
    const enable = this.enablePostWorkOpportunity()
    if (enable) {
      const headerObj = {
        "token": tokenData,
        'Content-Type': 'application/json'
      };
      const newMileStoneArray = this.state.milestoneList.map(item => ({
        name: item.title,
        description: item.description,
        deliverables: item.deliverables,
        amount: parseInt(item.amount, 10),
        due_date: item.dueDate && item.dueDate.format("YYYY-MM-DD")
      }));
      const bodyObj = {
        "contract": {
          "proposal_id": cardId,
          "decline_contract": "false",
          "terms_and_condition_accept": "true",
          "start_date": this.state.startDate.format("YYYY-MM-DD")
        },
        "milestones": newMileStoneArray
      }
      const headerData = JSON.stringify(headerObj);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCreateContract = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createContractApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headerData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyObj)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  formatDateResendApiData = (date: string | Dayjs): string => {
    if (dayjs.isDayjs(date)) {
      return date.format('YYYY-MM-DD');
    } else {
      return dayjs(date).format('YYYY-MM-DD');
    }
  };

  createReSendContract = () => {
    const enable = this.enablePostWorkOpportunity()
    if (enable) {
      const headerObj = {
        "token": tokenData,
        'Content-Type': 'application/json'
      };
      const newMileStoneArraySaved = this.state.savedMilestone.map(item => ({
        name: item.name,
        description: item.description,
        deliverables: item.deliverables.map(deliverable => deliverable.deliverable),
        amount: item.amount,
        due_date: this.formatDateResendApiData(item.due_date)
      }));
      const bodyObjSaved = {
        "use_previous_milestones": "false",
        "contract": {
          "contract_id": this.state.workOpportunity.contract_id,
          "proposal_id": cardId,
          "decline_contract": "true",
          "terms_and_condition_accept": "true",
          "start_date": this.state.startDate.format("YYYY-MM-DD")
        },
        "milestones": newMileStoneArraySaved
      }

      const newMileStoneArray = this.state.milestoneList.map(item => ({
        name: item.title,
        description: item.description,
        deliverables: item.deliverables,
        amount: parseInt(item.amount, 10),
        due_date: item.dueDate && item.dueDate.format("YYYY-MM-DD")
      }));
      const bodyObj = {
        "use_previous_milestones": "false",
        "contract": {
          "contract_id": this.state.workOpportunity.contract_id,
          "proposal_id": cardId,
          "decline_contract": "false",
          "terms_and_condition_accept": "true",
          "start_date": this.state.startDate.format("YYYY-MM-DD")
        },
        "milestones": newMileStoneArray
      }
      const headerData = JSON.stringify(headerObj);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiResendContract = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendContractApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headerData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        this.state.savedSwitch ? JSON.stringify(bodyObjSaved) : JSON.stringify(bodyObj)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.put
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  formatDateShort = (dateString: string | number | Date | Dayjs | null) => {
    if (dateString) {
      const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
      const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  }

  formatDateShortWeek = (dateString: string | number | Date | Dayjs | null): Date | null => {
    if (dateString) {
      const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
      return date;
    }
    return null;
  };

  actualTotalCount = () => {
    let count = 0;
    if (!this.state.savedSwitch) {
      this.state.milestoneList.forEach(element => {
        count = count + Number(element.amount)
      })
    } else {
      this.state.savedMilestone.forEach(element => {
        count = count + Number(element.amount)
      })
    }
    return count;
  }

  actualEndDate = () => {
    let endDate: string | undefined = "-";
    if (!this.state.savedSwitch) {
      if (this.state.milestoneList.length > 0) {
        endDate = this.formatDateShort(this.state.milestoneList[this.state.milestoneList.length - 1].dueDate)
      }
    } else {
      if (this.state.savedMilestone.length > 0) {
        endDate = this.formatDateShort(this.state.savedMilestone[this.state.savedMilestone.length - 1].due_date)
      }
    }
    return endDate;
  }

  actualWeekCount = () => {
    let weekCount: string | number = "-";
    let dueDate: any = dayjs()
    if (!this.state.savedSwitch) {
      if (this.state.milestoneList.length > 0 && this.state.milestoneList[this.state.milestoneList.length - 1]?.dueDate) {
        if (this.state.milestoneList[this.state.milestoneList.length - 1].dueDate) {
          dueDate = this.state.milestoneList[this.state.milestoneList.length - 1].dueDate
        }
        const endDate: any = this.formatDateShortWeek(dueDate);
        const startDate: any = this.formatDateShortWeek(this.state.startDate);
        const timeDifference = endDate?.getTime() - startDate?.getTime();
        const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        const week = Math.ceil(totalDays / 7)
        weekCount = week + " weeks"
      }
    } else {
      if (this.state.savedMilestone.length > 0 && this.state.savedMilestone[this.state.savedMilestone.length - 1]?.due_date) {
        if (this.state.savedMilestone[this.state.savedMilestone.length - 1].due_date) {
          dueDate = this.state.savedMilestone[this.state.savedMilestone.length - 1].due_date
        }
        const endDate: any = this.formatDateShortWeek(dueDate);
        const startDate: any = this.formatDateShortWeek(this.state.startDate);
        const timeDifference = endDate?.getTime() - startDate?.getTime();
        const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        const week = Math.ceil(totalDays / 7)
        weekCount = week + " weeks"
      }
    }
    return weekCount
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  savedSwichChange = () => {
    this.setState({ savedSwitch: !this.state.savedSwitch }, () => {
      if (this.state.savedSwitch) {
        this.loadSavedMilestone()
      } else {
        this.setState({
          mileStoneExpandData: {
            expandedTerms: {}
          },
          savedMilestone: []
        })
      }
    })
  }

  setCalenderOpenState = () => {
    this.setState({
      openCalenderSaved: Array(this.state.openCalenderSaved.length).fill(false)
    })
  }

  loadSavedMilestone = () => {
    const headerObj = {
      "token": tokenData,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLoadSavedMilestone = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMilestoneAPiEndPoinf + this.state.workOpportunity.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
