// Customizable Area Start
import React from "react";
import { insta, linkdin, dribble, share,  profileImg, rating, expertise, rightArrow,userLogo} from "../assets"; 
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import "../styles.css";

import PublicUserProfileController, { Props,ProfileDetails,configJSON } from "./PublicUserProfileController.web";
import { Box, Typography,Grid } from "@material-ui/core";
import PortfolioManagement from "../../../portfoliomanagement/src/PortfolioManagement.web"
import OpportunityClient from "../../../joblisting/src/OpportunityClients/OpportunityClient.web";
interface Skill {
    id: number;
    select: boolean;
    name: string;
  }

const logoBox = (profile_details:ProfileDetails)=>{ 
    return (
    <div className="social-media-container">
        {profile_details.dribble ? <a target="_blank" href={profile_details.dribble}><img src={dribble} alt="go to dribble" /></a> : ""}
        {profile_details.instagram ? <a target="_blank" href={profile_details.instagram}><img src={insta} alt="go to instagram" /></a> : ""}
        {profile_details.linkedin ?  <a target="_blank" href={profile_details.instagram}><img src={linkdin} alt="go to linkdin" /></a> : ""}
    </div>)
}

class PublicUserProfile extends PublicUserProfileController {
    constructor(props: Props) {
        super(props);
    }

    imgProfile =(profilePic:string | null ,backgroundColor:string | null) =>{
      if(profilePic){
        return (
        <div  style={webStyle.Imgprofiles}>

          <img style={webStyle.photoStyle} src={this.baseUrlStirng + profilePic}/>
        </div>)
    } else if(backgroundColor){

        return(
          <div style={webStyle.Imgprofiles}>

            <Box style={{...webStyle.profileImageBoxes,backgroundColor:`${backgroundColor}`}}></Box>
          </div>
        )
    } else {
        return(
          <div style={webStyle.Imgprofiles}>
              <img style={webStyle.photoStyle}  src={userLogo}/>
          </div>
        ) 
    }
   }

   forClientDetails = () =>{
        return(
            <div>
                <Box className="forClientDetails">
                    <Box className="Keysforthelist"   >Company Name</Box>
                    <Box style={{fontSize:'16px',color:'#757171',textAlign:'end',fontWeight:500}}>{this.CompanyName()}</Box>
                </Box>
                <Box className="forClientDetails forClientDetails-designation">
                    <Box className="Keysforthelist">Designation</Box>
                    <Box className="CompanyDetails" >{this.showDesignation()}</Box>
                </Box>
                <Box className="forClientDetails">
                    <Box className="Keysforthelist" >Industry</Box>
                    <Box className="CompanyDetails">{this.showIndustry()}</Box>
                </Box>
            </div>
        )
    }

    userInfoContainer = () =>{
        return(
            <div className="info-container-public">
                {this.state.userRole === 'designer' ? 
                (
                    <>
                        <div className="part-data border-right">
                            <div className="info-data">
                                <div className="label-text">Profile Views</div>
                                
                                <div className="value-text">{this.profileView()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right">
                            <div className="info-data">
                                <div className="label-text">Likes</div>
                                <div className="value-text">{this.showLike()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right p-0">
                            <div className="info-data">
                                <div className="label-text">Avg paid rate</div>
                                <div className="value-text">{this.avgRate()}</div>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <div className="part-data-client border-right">
                            <div className="info-data">
                                <div className="label-text">Jobs Posted</div>
                                
                                <div className="value-text">{this.JobsPosted()}</div>
                            </div>
                        </div>
                )}
                
                <div className={`${this.state.userRole === 'designer' ? 'part-data ':'part-data-client'} left-text`}>
                    <div className="info-data">
                        <div className="label-text">Ratings</div>
                        <div className="value-text">
                            <div className="rating-text">4.5<img src={rating} alt="rating"/></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleLanguages = () => {
      return (
        <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-16 p-16">
                      <div className="text-elm heading-data">Languages</div>
                      <div className="Languages-flex d-flex w-100 g-8 row-gap-12">
                        {this.state.profileImageData.profile_details
                          .languages &&
                        this.state.profileImageData.profile_details.languages
                          .length > 0 ? (
                          this.state.profileImageData.profile_details.languages.map(
                            (language:any, index:any) => (
                              <div key={index} className="skill-chip">
                                {language}
                              </div>
                            )
                          )
                        ) : (
                          <div style={webStyle.defaultText}>
                            No Languages added
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
      )
    } 
    handleUserFollowers =()=>{
      return (
        <>
        
        {this.state.userRole === "designer" && (
          <>
            <div className="group-container p-0">
              <div
                className="rate-container"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="text-elm heading-data">Rate</div>
                <div className="rate-chip">
                  {this.state.profileImageData.profile_details
                    ?.min_per_hour &&
                  this.state.profileImageData.profile_details
                    ?.max_per_hour ? (
                    `${this.state.profileImageData.profile_details.min_per_hour} - ${this.state.profileImageData.profile_details.max_per_hour} ₹/hr`
                  ) : (
                    <div style={webStyle.defaultText}>--</div>
                  )}
                </div>
              </div>
            </div>
            <div className="group-container d-flex flex-column w-100 h-auto p-0">
              <div className="d-flex flex-column g-16 p-16 pb-0">
                <div className="text-elm heading-data expertise-heading d-flex">
                  <img src={expertise} alt="Expertise" />
                  Expertise
                </div>
                <div className="d-flex w-100 g-10 py-11 " style={{flexWrap:'wrap'}}>
                  {this.state.profileImageData.profile_details
                    .expertise_category &&
                  this.state.profileImageData.profile_details
                    .expertise_category.length > 0 ? (
                    this.state.profileImageData.profile_details.expertise_category.map(
                      (expertise:any, index:any) => (
                        <div key={index} className="expertise-data">
                          {expertise}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>--</div>
                  )}
                </div>
                <hr className="p-0 line-elm" />
              </div>
              <div className="d-flex flex-column g-16 p-16 pt-0">
                <div className="text-elm heading-data">Skills</div>
                <div
                  className=""
                  style={{
                    ...webStyle.skillContainer,
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.profileImageData.profile_details
                    .skills &&
                  this.state.profileImageData.profile_details.skills
                    .length > 0 ? (
                    this.state.profileImageData.profile_details.skills.map(
                      (skill: any, index:any) => (
                        <div
                          key={index}
                          className="skill-chip"
                          style={webStyle.skillChip}
                        >
                        
                          {skill.name ?skill.name: skill}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>--</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) }
        </>
      )
    }
    render() {         
      return (     
        <Box
          style={webStyle.newResponseCon}
          data-testid="outsideMultiClick">
          <NavigationMenu {...this.props} activeTab="user" />
          <Box sx={webStyle.container}>
        <div className="user-profile-container">    
          <div className="user-profile-header">
          </div>

          <div className="body-container">
            <Grid container xs={12}>
            <Grid item xs={12} sm={4} md={4} lg={4}>

            <div className="flex-column profile-section ">
              <div className="profile-bg-container">
              <div
                className="ImgprofileResp"
                  style={{
                    maxWidth: "100%",
                    width: "32vw",
                    borderRadius: "24px 24px 0 0",
                    backgroundImage: `url(${this.imageUrl()})`,
                    height: "220px",
                    backgroundSize: "cover",
                    objectFit: "cover",
                    backgroundPosition: "center",
                  }}
                >
                   <img
                    src={this.imageUrl()}
                    alt="BackgroundImg"
                    style={{
                      top: 0,
                      borderRadius: "24px 24px 0 0", 
                      left: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", 
                    }}
                  />
                  
                </div>
                <div className="icon-elm-container">
                  <div className="header-icon-container">
                    {logoBox(this.state.profileImageData.profile_details)}
                    <img src={share} alt="share profile" />
                  </div>
                  
                </div>
              </div>
              <div className="user-basic-info flex-column">
                {this.imgProfile(this.state.profileImageData.profile_details.photo,this.state.profileImageData.profile_details.background_color)}

                <div className="profile-data flex-column">
                  <div className="max-data text-elm name">
                   
                    {this.state.profileImageData.profile_details.name}
                  </div>
                  <div className="max-data mt-12 text-elm site-data">
                    {this.websideShow()}
                  </div>
                  <div className="max-data mt-8 text-elm member-data">
                    {this.memberSince()}
                  </div>
                  <div className="max-data mt-16 text-elm location-data">
                    {" "}
                    {this.showLoaction()}
                  </div>
                  <div className="Btngroup">
                  <button className={this.state.profileImageData.is_followed ? "FollowedBtn" : "FollowBtn"} onClick={this.handleFollowAndUnfollow}>{this.state.profileImageData.is_followed ? "Following" : "Follow"}</button>
                  <button className="MessageBtn" data-testid="messageButton" onClick={this.handleNavigationToChatScreen}>{configJSON.messageText}</button>
                  </div>
                </div>
              </div>
              <div style={{width:'98%'}} className="details-section flex-column page-color" >
                <div
                  className="text-elm"
                  style={{ display: "flex", width: "100%" }}
                >
                  <div
                    className={
                      this.state.userRole === "designer"
                        ? "profile-details"
                        : "profile-details-client"
                    }
                  >
                    {this.state.userRole === "designer" && "Profile Details"}
                    
                  </div>
                </div>
                <div
                  className=" flex-column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    alignItems:"center",
                    width:'100%'
                  }}
                >
                  <div className="group-container flex-column" style={{width:"90%"}}>
                    <Box>
                      <div className="text-elm heading-data">
                        {this.aboutRoleShow()}
                      </div>
                      <div className="text-elm about-data mt-16">
                        {this.showAboutInfo()}
                      </div>
                      {this.state.userRole === "client" &&
                        this.forClientDetails()}
                    </Box>
                  </div>
                  {this.userInfoContainer()}

                    {this.handleUserFollowers()}
                  <div className="group-container d-flex w-100 h-auto p-0 mt-16">
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Followers</div>
                      <div className="text-elm heading-data text-secondary" style={webStyle.pointerCursorStyle}>
                        {this.followers()}
                      </div>
                    </div>
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Following</div>
                      <div className="text-elm heading-data text-secondary" style={webStyle.pointerCursorStyle}>
                        {this.showFollowing()}
                      </div>
                    </div>
                  </div>
                  {this.state.userRole === 'client' && (
                    <div className="ActiveOpportunity">
                    <Box className="ActiveOpportunity-box">
                    <Box  className="ActiveOpportunity-content">
                   <Typography className="ActiveOpportunity-content">Active Opportunities</Typography>
                   </Box>
                   <Box >
                       <Typography className="ActiveOpportunity-content">{this.activeOpportunities()}</Typography>
                   </Box>
                    </Box>
                  
               </div>
                  )}
                  <div className="w-100 h-auto group-container p-0">
                    <div className="d-flex flex-column experience-head g-40 p-16">
                      <div className="d-flex w-100 g-16 heading-space-data text-elm heading-data">
                        <div className="text-elm heading-data g-24">
                          Ratings
                          <div className="value-text">
                            <div className="rating-text">
                              4.5
                              <img alt="rating" src={rating} />
                            </div>
                          </div>
                        </div>
                        236 Reviews
                      </div>
                      <div className="d-flex w-100 g-24 flex-column">
                        <div className="d-flex g-16 flex-column">
                          <div className="rating-experience-text">
                            Efficient to work with Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, tempor
                            incididunt ut labpr...
                          </div>
                          <div className="experience-left-text justify-left">
                            <img src={profileImg} alt="user experience" />
                            Anil Mishra (Senior UI/UX Designer)
                          </div>
                        </div>
                        <hr className="p-0 line-elm" />
                        <div className="d-flex g-16 flex-column">
                          <div className="rating-experience-text">
                            Efficient to work with Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt labpr...
                          </div>
                          <div className="experience-left-text justify-left">
                            <img src={profileImg} alt="user experience" />
                            Anil Mishra (Senior UI/UX Designer)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.userRole === "designer" && (
                    <>
                      <div className="group-container w-100 p-0 h-auto">
                        <div className="d-flex flex-column g-40 p-16">
                          <div className="d-flex experience-head g-16">
                            <div className="heading-data text-elm">
                              Work Experience ({this.state.profileImageData.profile_details.professional_experience.professional_experiences_count})
                            </div>
                            {this.state.profileImageData.profile_details
                              .professional_experience.latest_record.length > 0 && (
                              <div className="view-all">
                                <div className="text-data">view all</div>

                                <img
                                  src={rightArrow}
                                  alt="view more work experience"
                                />
                              </div>
                            )}
                          </div>
                          <div className="d-flex w-100 g-24 flex-column">
                            <div className="d-flex g-16 flex-column">
                              {this.state.profileImageData.profile_details
                                .professional_experience.latest_record.length > 0 ? (
                                this.state.profileImageData.profile_details.professional_experience.latest_record.map(
                                  (workexp:any, index:any) => {
                                    return (
                                      <Box key={index}>
                                        <div className="flex-header">
                                          <div style={{width:"376px" , textAlign:"start", overflow:"hidden"}}>
                                            {workexp.company_name}
                                          </div>
                                          <div className="experience-right-text" style={{textAlign:"end"}}>
                                            <span>
                                              {workexp.starting_from_year} - {workexp.ending_in_year}
                                            </span>
                                            <span  style={{padding:"0px 5px"}}>
                                              &bull;
                                            </span>
                                            <span>
                                              {workexp.calculate_years}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="experience-text" style={{padding:"16px 0px"}}>
                                          {workexp.position}
                                        </div>
                                        <hr className="p-0 line-elm" />
                                      </Box>
                                    );
                                  }
                                )
                              ) : (
                                <div style={webStyle.defaultText}>
                                  {" "}
                                  No Work Experience added
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-container d-flex flex-column w-100 h-auto p-0">
                        <div className="d-flex flex-column g-16 p-16">
                          <div className="text-elm heading-data">Education</div>
                          {this.state.profileImageData.educations &&
                          this.state.profileImageData.educations.length > 0 ? (
                            this.state.profileImageData.educations.map(
                              (education:any, index:any) => (
                                <div key={index}>
                                  {education != null && (
                                        <div className="education-data">
                                        <div
                                          style={webStyle.educationDescription}
                                          className="education-Description"
                                        >
                                          {education.description}
                                        </div>
    
                                        <div
                                        style={webStyle.educationQualification}
                                        className="education-qualification"
                                         
                                        >
                                          {education.qualification}{" "}
                                          {this.formatYears(
                                            education.year_from,
                                            education.year_to
                                          )}{" "}
                                        </div>
                                      </div>
                                  )}
                                
                                </div>
                              )
                            )
                          ) : (
                            <div style={webStyle.defaultText}>
                              No Education added
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {this.handleLanguages()}
                  <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-8">
                      <div className="education-data history-data p-16">
                        <div className="text-elm heading-data text-secondary normal-weight">
                          View Activity
                        </div>
                        <button className="view-details">
                          <img src={rightArrow} alt="view history" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Grid>
            <Grid item xs={12} sm ={8}  md={8} lg={8}  className="PortfolioContainer" style={{paddingLeft:"25px"}}>
            {
              (this.state.userRole == "designer" && this.state.profileImageData.profile_details.account_id) && (

                <PortfolioManagement Isedit={false} navigation={this.props.navigation} id={this.state.profileImageData.profile_details.account_id} />
              )}
              {this.state.userRole == 'client' && (
                <>
                <OpportunityClient navigation={this.props.navigation} id={this.state.profileImageData.profile_details.account_id} isEditable={false} />
                </>
              )}
              </Grid>
            </Grid>
          </div>
        </div>
        </Box>
        </Box>
      );
    }
}

export default PublicUserProfile;

const webStyle ={
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh',
    "& *": {
      boxSizing: "border-box",
    }
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    boxSizing: "border-box",
    "& .ImgprofileResp": {
      maxWidth: "619px !important"
    },
    "& .body-container": {
      margin: "120px 50px 40px 50px !important",
    }, 
  },
  Imgprofiles : {
    position:"relative",
    marginTop:"-90px"
  } as React.CSSProperties,
  photoStyle:{
    width:"156px",height:"156px" ,borderRadius:"50%"
  },
  educationQualification:{
    width:'70%',
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Inter",
    lineHeight: "16px",
    color: "#666666",
    textAlign:"end"
  }as React.CSSProperties,
  educationDescription :{
    width:"30%",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '20px'
  },
      profileImageBoxes :{
        height:"156px",
        width:"156px",
        borderRadius:"75px",
      } as any,
      
      imageBackgrounds:{
        width:"600px",
        borderRadius:"24px 24px 0px 0px",
        heigth:"220px",
        minWidth:"400px"
      },
      editIcon:{
        cursor:"pointer"
      },
      skillContainer:{
        gap: '8px',
        display: 'flex',
        width: '100%',        
      },
      skillChip:{
          minWidth: 'max-content',        
      },
      defaultText:{
        fontSize: "16px",
        fontFamily: "Inter",     
        fontWeight: 500,
        linehHeigth: "24px",
        color: '#535353',        
      },
      pointerCursorStyle: {
        cursor: "pointer",
      }
}
// Customizable Area End



    
