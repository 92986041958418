import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ToastMSG from "../../../components/src/ToastMSG.web"
import ProjectDetails from "../../landingpage/src/discoverproject/ProjectDeatils.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { 
  locationIcon,
  eyeIcon,
  featureIcon,
  likeIcon
} from "./assets"


interface IProject {
  id: number;
  project_title: string;
  cover_image: string;
  user_name: string;
  profile_image: null;
  background_color: string;
  like_count: number;
  view_count: number;
  save_count: number;
}

interface ICollection {
  collection_id: number;
  collection_name: string;
  projects: IProject[]
}
const typoWhite = "#FFF2E3"

const theme = createTheme({
  palette: {
    primary: {
      main: typoWhite,
      contrastText: "#8C8C8C",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});
// Customizable Area End

import ViewServiceController, { Props , configJSON } from "./ViewServiceController";

export class ViewService extends ViewServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start

  renderEmptyMessages = () => {
    const { classes } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <Box className={classes.contentCon}>
          {this.renderBackDrop()}
        </Box>
      )
    }
    return (
      <Box className={classes.contentCon}>
        <Box className={classes.emptyMessagesCon}>
          <Typography variant="h4">{configJSON.yourServiceHubText}</Typography>
          <Typography variant="body1" className="description">{configJSON.yourServiceHubDes}</Typography>
        </Box>
      </Box>
    )
  }

renderCircularProgressBar = () => {
  const { classes } = this.props;
  return (
    <Box className={classes.progressCon}>
      <CircularProgress className="circular" />
    </Box>
  )
}


renderBackDrop = () => {
  return (
    <Box
      className={this.props.classes.backdrop} >
      {this.renderCircularProgressBar()}
    </Box>
  )
}

  renderProjects = (project: IProject, collection: ICollection) => {
    const { baseURL } = this.state;
    return (
      <div
        data-testid="projectList"
        onClick={this.handleSelectedProject.bind(this,project,collection)}
        className={this.props.classes.collectionCard}
      >
        <img className={this.props.classes.coverImage} src={baseURL + project?.cover_image} alt={configJSON.image} />
        <Box className={this.props.classes.projectNameCon}>
          <Typography className={this.props.classes.projectName}>{project?.project_title}</Typography>
          <Box className={this.props.classes.iconsCon}>
            <Box className={this.props.classes.iconCon}>
              <img src={eyeIcon} alt={project?.project_title} />
              <Typography className={this.props.classes.iconInfo} variant="caption">{project?.view_count}</Typography>
            </Box>
            <Box className={this.props.classes.iconCon}>
              <img  src={likeIcon} alt={configJSON.image} />
              <Typography className={this.props.classes.iconInfo} variant="caption">{project?.like_count}</Typography>
            </Box>
            <Box className={this.props.classes.iconCon}>
              <img  src={featureIcon} alt={configJSON.image} />
              <Typography className={this.props.classes.iconInfo} variant="caption">{project?.save_count}</Typography>
            </Box>
          </Box>
        </Box>
      </div>
    )
  }

  renderSkillsContent = () => {
    return (
      <Box className="workDescriptionResCon">
        <Typography variant="h4">{configJSON.skillTagText}</Typography>
        <Box className="skillsCon marginTopOne">
          {Array.isArray(this.state.response?.skills) && this.state.response?.skills.map(
            (skill, idIndex) =>
            (<Typography
              variant="body2"
              className="responsivePaddingOne borderRadius skillItem semiBold"
              key={skill.id + idIndex}>
              {skill.name}
            </Typography>)
          )}
        </Box>
      </Box>
    )
  }

  renderDeliverbableContent = () => {
    return (
      <Box className="workDescriptionResCon">
        <Typography variant="h4">{configJSON.deliverableText}</Typography>
        <Box className="deliverableCon marginTopOne">
          {Array.isArray(this.state.response?.deliverables) &&
            this.state.response?.deliverables.map(
              (delivery, idIndex) =>
              (<Typography
                className="viewServiceDeliverable"
                key={delivery.id + idIndex}>
                {delivery.name}
              </Typography>)
            )}
        </Box>
      </Box>
    )
  }

  renderWorkDetaislCon = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.workDescriptionSkillTimeCon}>
        <Box>
          <Box className="contentCon">
            <Box className="workDescriptionResCon">
              <Typography variant="h4">{configJSON.workDescriptionText}</Typography>
              <Typography className="marginTopOne">{this.state.response?.description}</Typography>
            </Box>
          </Box>
          <Box className="contentCon">
            {this.renderSkillsContent()}
          </Box>
          <Box className="contentCon">
            {this.renderDeliverbableContent()}
          </Box>
        </Box>
        <Box className="viewServiceLocationRateTimeCon borderBottomGutter">
          <Box className="viewServieceRateCon">
            <Box className={"flexConHorizontal"}>
              <IconButton className="iconButton">
                <img src={locationIcon} alt="location" />
              </IconButton>
              <Typography variant="h4" className="padding">{configJSON.locationText}</Typography>
            </Box>
            <Typography
              variant="body2"
              className="responsivePaddingOne borderRadius semiBold marginTopOne" >
              {this.getLocationText()}
            </Typography>
          </Box>
          <Box className="viewServieceRateCon">
            <Typography variant="h4">{configJSON.timelineText}</Typography>
            <Typography
              variant="body2"
              className="responsivePaddingTwo purpleColor borderRadius semiBold marginTopOne" >
              {this.getTimeLineText()}
            </Typography>
          </Box>
          <Box className="viewServieceRateCon">
            <Typography variant="h4">{configJSON.rateText}</Typography>
            <Typography
              variant="body2"
              className="responsivePaddingTwo blueColor borderRadius semiBold marginTopOne" >
              {this.getRateText()}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  renderReferenceLinksContent = () => {
    const { classes } = this.props;
    return (
      <Box className={`${classes.referenceLinkCon} marginTopOne`}>
        {Array.isArray(this.state.response?.links) && 
         this.state.response?.links.map((link, idIndex) => (
          <Typography
            className="skyBuleColor"
            variant="h4"
            key={link + idIndex}>
            {link}
          </Typography>
        ))}
      </Box>
    )
  }

  renderCarouselContent = () => {
    const { classes } = this.props;
    const { activeCarouselId, stepCarouselId, baseURL,files } = this.state;
    return (
      <Box className={classes.carouselCon}>
        <Box className="carouselActiceCon">
          <Box className="corouselIconButton blackColor">
            {
              activeCarouselId !== 0 &&
              <IconButton
                data-testid="leftArrowButton"
                disabled={activeCarouselId === 0}
                onClick={this.handleLeftArrowClick}
                className="corouselIconButton">
                <ArrowBackIosIcon className="carouselIcon" />
              </IconButton>
            }
          </Box>
          <Box className="carouselActiveImage">
            <img src={baseURL+files[activeCarouselId]} alt={"carousel"} className="carouselImage" />
          </Box>
          <Box className="corouselIconButton blackColor">
            {
              activeCarouselId !== files.length - 1 &&
              <IconButton
                data-testid="rightArrowButton"
                disabled={activeCarouselId === files.length - 1}
                onClick={this.handleRightArrowClick}
                className="corouselIconButton ">
                <ArrowForwardIosIcon className="carouselIcon carouselRightIcon" />
            </IconButton>
          }    
        </Box>
        </Box>
        <Box className="carouselCardCon flexConHorizontal">
          {this.getArrayData().map(
              (card, idIndex) => (
                <Box 
                  onClick={this.handleActiveImage.bind(this,idIndex)}
                  key={card + idIndex}
                  data-testid={`carouselCard-${idIndex}`}
                  className={`carouselCard ${(idIndex + stepCarouselId) === activeCarouselId && "activeCard"}`}>
                  <img src={baseURL + card} alt={card} className="carouselImage" />
                </Box>
              )
            )}
        </Box>
      </Box>
    )
  }

  renderViewServiceContent = () => {
    const { classes } = this.props;
    const {serviceUserId, activeUserId,response} = this.state;
    return (
      <Box className={classes.contentCon}>
        <Box className="editContentCon">
          <Typography variant="h4">{response?.service_name}</Typography>
          {activeUserId === serviceUserId && 
          <IconButton 
            data-testid="editIcon"
            onClick={this.handleNaviagateToEditPage}>
            <EditOutlinedIcon className="icon" />
          </IconButton>}
        </Box>
        {this.renderCarouselContent()}
        {this.renderWorkDetaislCon()}
        <Typography variant="h4" className="marginTopTwo">{configJSON.referenceLinksText}</Typography>
        {this.renderReferenceLinksContent()}
        <Typography variant="h4" className="marginTopTwo">{`${configJSON.projectsFromText} `}</Typography>       
        {
          Array.isArray(this.state.response?.projects) &&
          this.state.response?.projects.map(collection => (
            <Box key={collection.collection_id}>
              <Box className="marginTopTwo borderBottomGutter collectionTextCon">
                <Typography variant="h4" className="brownColor">{`${collection.collection_name} `}</Typography>
              </Box>
              <Grid container spacing={4} className="marginTopOne">
                {Array.isArray(collection.projects) &&
                  collection.projects.map((project, idIndex) => (
                    <Grid item xs={12} lg={4} key={project.id + idIndex}>
                      {this.renderProjects(project,collection)}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))
        }
      </Box>
    )
  }
// Customizable Area End

  render() {
    // Customizable Area Start 
    const { classes } = this.props;
    const { open, message, action } = this.state;
    return (
      <Box className={classes.conatiner}>
        <NavigationMenu {...this.props} />
        <ThemeProvider theme={theme}>
          <Box className={classes.reposiveCon}>
            <Box className={"flexConHorizontal backButtonCon"}>
              <IconButton 
                data-testid="backButton"
                className="iconButton" 
                onClick={this.handleNavigateToLandingPage}>
                <ArrowBackIosIcon className="icon" />
              </IconButton>
              <Typography data-testid="text" variant="h1">{configJSON.viewServiceText}</Typography>
            </Box>
            {
              !this.state.response ?
                this.renderEmptyMessages() :
                this.renderViewServiceContent()
            } 
            <ToastMSG
              open={open}
              message={message}
              action={action}
              close={this.handleCloseToast}
              data-testid="toastMessage"
            />
            {
              this.state.showDetails &&
              <ProjectDetails
                handlenavigation={this.handleNavigateProfileScreen}
                navigateTo={this.handleNavigate}
                token={this.state.token}
                selectedProject={this.state.selectedProject}
                open={this.state.showDetails}
                closeModal={this.closeModal}
                allprojectList={this.state.specificProjectList}
                data-testid="projectDetails" />
            }
          </Box>
        </ThemeProvider>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  conatiner: {
    backgroundColor: "#111111",
    width: "100%",
    minHeight: "100vh",
    boxSizing: "border-box" as "border-box",
    overflow: "auto",
    scrollbarWidth: "none" as "none",
    msOverflowStyle: 'none' as "none",
    display: "flex",
    flexDirection: "column" as "column",
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
    "& .semiBold": {
      fontFamily: "Inter-SemiBold",
    },
    "& .borderRadius": {
      borderRadius: "3rem",
    },
    "& *": {
      boxSizing: "border-box" as "border-box"
    },
    "& .iconButton": {
      padding: "0",
    },
    "& .icon": {
      color: "#FFF2E3",
      fontSize: "1.5rem",
    },
    "& .padding": {
      paddingLeft: ".5rem",
    },
    "& .backButtonCon": {
      marginTop: "3rem",
      marginBottom: "2rem",
      [theme.breakpoints.up(1690)]: {
        marginLeft: "3rem",
      }
    },
    "& .flexConHorizontal": {
      display: "flex",
      alignItems: "center",
    },
    "& .borderBottomGutter": {
      borderBottom: `.97px solid #545454`,
    },
    "& .marginTopOne": {
      marginTop: "1rem"
    },
    "& .marginTopTwo": {
      marginTop: "2rem"
    },
    "& .brownColor": {
      color: "#8C8C8C"
    },
    "& .blackColor": {
      backgroundColor: "transparent !important"
    }
  },
  reposiveCon: {
    paddingTop: "76px",
    paddingBottom: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    width: "100%",
    height: "100%",
    maxWidth: "1920px",
    margin: "0 auto",
    overflow: "auto",
    scrollbarWidth: "none" as "none",
    msOverflowStyle: 'none' as "none",
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0',
    }
  },

  contentCon: {
    width: "100%",
    maxWidth: "1490px",
    minHeight: "65vh",
    margin: "1rem auto",
    backgroundColor: "#222222",
    paddingLeft: "min(4.5rem,4.8%)",
    paddingRight: "min(4.5rem,4.8%)",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    borderRadius: "12px",
    "& .collectionTextCon": {
      paddingBottom: "1rem",
    },
    "& .editContentCon": {
      display: "flex",
      justifyContent: "space-between",
      gap: "1rem",
      alignItems: "center",
    }
  },
  carouselCon: {
    marginTop: "2rem",
    marginBottom: "4rem",
    "& .carouselCard": {
      width: "169.16px",
      height: "167.13px",
      opacity: ".2",
      cursor: "pointer",
    },
    "& .carouselCardCon": {
      width: "879px",
      gap: ".5rem",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2rem",
    },
    "& .carouselImage": {
      width: "100%",
      height: "100%",
      objectFit: "fill",
    },

    "& .activeCard": {
      opacity: "1",
    },
    "& .carouselActiceCon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "2rem"
    },
    "& .corouselIconButton": {
      backgroundColor: "#8C8C8C",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "53px",
      height: "53px",
    },
    "& .carouselIcon": {
      color: typoWhite,
      fontSize: "2rem",
      position: "absolute",
      top: "50%",
      left: "59%",
      transform: "translate(-50%,-50%)",
    },
    "& .carouselRightIcon": {
      left: "56%",
    },
    "& .carouselActiveImage": {
      width: "879px",
      height: "562px",
      overflow: "hidden",
    }
  },
  workDescriptionSkillTimeCon: {
    display: "grid",
    gridTemplateColumns: "4fr 1fr",
    "& .purpleColor": {
      color: "#FFB9C7",
    },
    "& .blueColor": {
      color: "#1CBAE0",
    },
    "& .responsivePaddingOne": {
      padding: "11px 22px",
      display: "inline-block",
      backgroundColor: "#323232",
    },
    "& .responsivePaddingTwo": {
      padding: "6px 17px",
      display: "inline-block",
      backgroundColor: "#2F2F2F",
    },
    "& .skillsCon": {
      display: "flex",
      gap: "1rem",
      flexWrap: "wrap" as "wrap",
      maxHeight: "166px",
      overflow: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        width: '0',
        height: '0',
      }
    },
    "& .skillItem": {
      border: `1px solid #FFF2E3`,
    },
    "& .contentCon": {
      "&:not(:first-child)": {
        paddingTop: "2rem",
      },
      paddingBottom: "2rem",
      paddingRight: "1rem",
      borderColor: "#545454",
      borderStyle: "solid",
      borderBottomWidth: `.97px`,
      borderTopWidth: `0px`,
      borderLeftWidth: `0px`,
      borderRightWidth: `.97px`,
      width: "100%",

    },
    "& .deliverableCon": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },

    "& .viewServiceDeliverable": {
      position: "relative",
      paddingLeft: "1.5rem",
      "&::before": {
        content: "''",
        width: ".25rem",
        height: ".25rem",
        borderRadius: "50%",
        backgroundColor: typoWhite,
        position: "absolute",
        top: "50%",
        left: "0.5rem",
        transform: "translateY(-50%)"
      }
    },
    "& .viewServiceLocationRateTimeCon": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      paddingLeft: "2rem",
    },
    "& .workDescriptionResCon": {
      width: "100%",
      maxWidth: "912px",
    }
  },
  referenceLinkCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    "& .skyBuleColor": {
      color: "#498EFF"
    }
  },
  projectsListCon: {
    
  },
  collectionCard: {
    width: "min(100%,413.84px)",
    cursor: "pointer",
  },
  coverImage: {
    width: "100%",
    height: "285.89px",
    objectFit: "cover" as "cover",
    borderRadius: "10px",
  },
  projectNameCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  projectName: {
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  iconsCon: {
    display: "flex",
    gap: "1rem",
  },
  iconCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: ".25rem",
    alignItems: "center",
    "& img": {
      width: "26.59px",
      height: "18.13px",
      objectFit: "contain" as "contain",
    }
  },
  iconInfo: {
    fontSize: "0.625rem",
  },
  emptyMessagesCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    "& .description": {
      maxWidth: "600px",
      textAlign: "center",
    }
  },
  composeTextStyle: {
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    WebkitBackgroundClip: 'text' as "text",
    WebkitTextFillColor: 'transparent',
    fontSize: '24px',
    position: "relative" as "relative",
    cursor: "pointer",
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -2,
      height: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      width: "100%",
      zIndex: 1,
    },
  }, 
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100% - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressCon: {
    width: "100%",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .circular": {
      color: "#fff"
    }
  },
};

export default withStyles(styles)(ViewService)
// Customizable Area End
