

import { Message } from "framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { configJSON } from "../LandingPageController";
import { getStorageData } from "framework/src/Utilities";
const config = require("../../../../framework/src/config.js")

interface AttachedFile {
  file_name: string;
  url: string;
}
interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}
interface AddedProject {
  project_title: string;
  description: string;
  project_cover_image:string,
  user_name:string,
  like_count: number;
  view_count: number;
  saved_count: number;
}
interface WorkOpportunityAttributes {
  id: number;
  title: string;
  is_saved_opportunity?:boolean;
  saved_opportunity_id?:string | number | null;
  description: string;
  work_type: string;
  deliverables: string;
  experience_level: string;
  rate_type: string;
  rate_amount: number;
  required_hours_per_week: number;
  project_timeline: number;
  files_or_links: string;
  question: [];
  start_date: string | null;
  project_timeline_type: string;
  location: string;
  agree_to_terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  added_projects: AddedProject[]; 
  attached_files: AttachedFile[]; 
  skills: Skill[]; 
}
interface WorkOpportunity {
  id: string;
  type: string;
  attributes: WorkOpportunityAttributes;
}
// Customizable Area End

export interface Props {
  id: string;
  // Customizable Area Start
  handleClose: (value:boolean) => void
  selectedOpportunityId:string | number
  openOppertunity: boolean;
  handleNavigate: (route:string)=> void
  hideButton?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  workOppertunityData:WorkOpportunity,
  workId:string | number
  isSaved:boolean;
  isLoading:boolean;
  locations:string[];
  isChanged:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class WorkOppertunityModalController extends BlockComponent<
  Props,
  S,
  SS> {
  // Customizable Area Start
  workOppertunityGetApiCallId:string = "";
  savedWorkApiCallId:string = ""
  unsaveWorkApiCallId:string = ""
  projectPdfFileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.state = {
     workId:this.props.selectedOpportunityId ,
     isSaved:false,
     isLoading:false,
     locations:[],
     isChanged:false,
      workOppertunityData: {
        id: "0",
        type: "",
        attributes: {
            id: 0,
            title: "",
            is_saved_opportunity:false,
            saved_opportunity_id:248,
            description: "",
            work_type: "",
            deliverables: "",
            experience_level: "",
            rate_type: "",
            rate_amount: 0,
            required_hours_per_week: 0,
            project_timeline: 0,
            files_or_links: "",
            question: [],
            start_date: null,
            project_timeline_type: "",
            location: "",
            agree_to_terms_and_conditions: true,
            created_at: "",
            updated_at: "",
            added_projects: [],
            attached_files: [
              {
                file_name: "",
                url: ""
              },
            ],
            skills: []
        }
    }
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getWorkOppertunity()
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.workOppertunityGetApiCallId) {
        this.splitCapitalize(responseJson.data.attributes.location)
         this.setState({
          workOppertunityData: responseJson.data,
        }
        )
      } else if (apiRequestCallId === this.projectPdfFileApiCallId) {
        console.log("repsonseJson => ", responseJson);
        const pdfFile = new File([responseJson], 'pdf-file.pdf');
        console.log("pdfFile => ", pdfFile);
      } else if (apiRequestCallId === this.savedWorkApiCallId ) {
        if(!responseJson.errors){
          let data = this.state.workOppertunityData
          data.attributes.is_saved_opportunity = true
          data.attributes.saved_opportunity_id = responseJson.data.id
          this.setState({workOppertunityData:data,isChanged:true})
        }
        this.setState({isLoading:false})
      } else if (apiRequestCallId === this.unsaveWorkApiCallId ) {
        if(!responseJson.errors){
          let data = this.state.workOppertunityData
          data.attributes.is_saved_opportunity = false
          data.attributes.saved_opportunity_id = ""
          this.setState({workOppertunityData:data,isChanged:true})
        }
        this.setState({isLoading:false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getWorkOppertunity = async () => {
    const tokens = await getStorageData("userInfo");
   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workOppertunityGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workGetOpportunityWithIdEndPoint + `${this.state.workId}` )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  getRateTypeLabel = () => {
    const  rate_types  = this.state.workOppertunityData.attributes.rate_type;
    if (rate_types && rate_types.includes( "hourly")) {
      return "pr/hr";
    }
    return "";
  }

  splitCapitalize(value:string) {
    let newValue =  value
    .split(",") 
    .map(word => word.trim().charAt(0).toUpperCase() + word.trim().slice(1));
    this.setState({locations:newValue})
  }

  handleSaveOrUnsaveWork = () =>{
    if(this.state.workOppertunityData.attributes.is_saved_opportunity){
      this.handleUnsaveWork()
    }else{
      this.handleSaveWork()
    }
  }

  handleSaveWork = async () =>{
    const tokens = await getStorageData("userInfo");
   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };

    const data = {
      data : {
          attributes : {
              work_opportunity_id : this.props.selectedOpportunityId
          }
      }
    }
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savedWorkApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveWorkApiEndPoint)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod 
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleUnsaveWork = async () =>{
    const tokens = await getStorageData("userInfo");
   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveWorkApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveWorkApiEndPoint + `/${this.state.workOppertunityData.attributes.saved_opportunity_id}`)
    
      getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiDeleteMethod 
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}
getProjectTimeLine = () => {
  const timeLine = this.state.workOppertunityData.attributes.project_timeline_type || ""; 
    if (timeLine.includes("Months")) {
      return "Months";
    } else if (timeLine.includes("Weeks")) {
      return "Weeks";
    }
  }
  createObjectArray = () => {
    let worldataObj: { number: number; type: string; fileName?: string[]; fileUrl?: string; link?: any; title?: string; image?: string; username?: string; views?: number; likes?: number; savedCount?: number; }[] = [];
    let counter = 0;  
    if (Array.isArray(this.state.workOppertunityData.attributes.attached_files) && this.state.workOppertunityData.attributes.attached_files.length) {
      this.state.workOppertunityData.attributes.attached_files.forEach(file => {
        counter++;
        worldataObj.push({
          number: counter,
          type: 'fileName',
          fileName: [file.file_name],
          fileUrl: file.url
        });
      });
    }  
   
    if (this.state.workOppertunityData.attributes.files_or_links) {
      this.state.workOppertunityData.attributes.files_or_links.split(',').forEach(link => {
        counter++;
        worldataObj.push({
          number: counter,
          type: 'link',
          link: link
        });
      })
      
    }  
  
    if (Array.isArray(this.state.workOppertunityData.attributes.added_projects) && this.state.workOppertunityData.attributes.added_projects.length) {
      this.state.workOppertunityData.attributes.added_projects.forEach((projectData, index) => {
        counter++;
        worldataObj.push({
          number: counter,
          type: 'project',
          title: projectData.project_title,
          image: configJSON.baseURL.baseURL + projectData.project_cover_image,
          username: projectData.user_name,
          views: projectData.view_count,
          likes: projectData.like_count,
          savedCount: projectData.saved_count
        });
      });
    } 
    return worldataObj;
  };
  
  downloadProjectPdf = async (fileName: string | undefined,pdfUrl: string | undefined) => {
    
    fetch(pdfUrl || "", {method: "GET"}).then(response => response.blob()).then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "pdf-file";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);

    })
  }
  // Customizable Area End
}