import React from "react";
// Customizable Area Start
import DraftOpportunityController, {
    Props,
    WorkType,
    Response,
    ReceiveProject
} from "./DraftOpportunityController.web";
import {
    FormControlLabel,
    withStyles,
    Radio,
    TextField,
    RadioGroup,
    Checkbox,
    Box,
    styled,
    Typography,
    Input,
    Button,
    InputLabel,
    Modal,
    Grid,
    Divider,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import SingleSelect from '../../../components/src/SingleSelect.web';
import DeleteProjectModal from "../../../components/src/DeleteProjectModel.web";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToastMSG from '../../../components/src/ToastMSG.web';
import CircularProgress from '@mui/material/CircularProgress';
const customStyle = require('../../dashboard/src/CustomStyles.js');
import {
    deleteIcon,
    searchIcon,
    closeIcon,
    leftArrowIcon,
    dropDownIcon,
    eyeIcon,
    featureIcon,
    likeIcon,
    checkIcon,
    uncheckIcon,
    likeIcon2,
    location,
} from '../../dashboard/src/assets';
import { editIcon } from "./assets";
export const configJSON = require("./config");
const config = require("../../../framework/src/config.js")

interface ProjectMain {
    id: string
    type: string
    attributes: ProjectAttributes
}

interface BoardProjects {
    attributes: {
        project: {
            data: ProjectMain[];
        }
    };
}

interface ProjectAttributes {
    id: string | number,
    cover_image_url: string,
    likes: string | number,
    views: string | number,
    project_name: string,
    username: string,
    saved_count: string | number,
    board_id?: number,
}
// Customizable Area End

export class DraftOpportunity extends DraftOpportunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

    renderWorkTypeArray = () => {
        return (
            <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='work_type'
                value={this.state.workType}
                onChange={this.handleWorkType}
                style={{ gap: '43px' }}
            >
                {configJSON.workTypeArray.map((elem: WorkType, index: number) => (
                    <FormControlLabel
                        key={index}
                        data-test-id='btnToggle1'
                        style={{ alignItems: 'flex-start', margin: 0 }}
                        value={elem.value}
                        control={
                            <CustomRadio
                                onChange={this.handleWorkType}
                                checked={
                                    this.state.workType
                                        ? this.state.workType.toLowerCase() === elem.value.toLowerCase()
                                        : false
                                }
                                style={{ marginRight: '12px', padding: 0 }}
                            />
                        }
                        label={
                            <CustomBox className='text-container'>
                                <Typography className='default-text secondary-color'>{elem.name}</Typography>
                                <Typography className='hint-text hint-color'>{elem.details}</Typography>
                            </CustomBox>
                        }
                    />
                ))}
            </RadioGroup>
        );
    }


    renderExperienceArray = () => {
        return (
            <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='work_experience'
                onChange={this.handleWorkExperience}
                value={this.state.workExperience}
                style={{ gap: '43px', marginTop: '13px' }}
            >
                {
                    configJSON.experienceArray.map((elem: WorkType, index: number) => (
                        <FormControlLabel
                            key={index}
                            data-test-id='btnToggle2'
                            style={{ alignItems: 'flex-start', margin: 0 }}
                            control={<CustomRadio
                                onChange={this.handleWorkExperience}
                                value={elem.value}
                                checked={
                                    this.state.workExperience
                                        ? this.state.workExperience.toLowerCase() === elem.value.toLowerCase()
                                        : false
                                }
                                style={{ marginRight: '12px', padding: 0 }} />}
                            label={<Typography className='default-text margin secondary-color'>{elem.name}</Typography>}
                        />
                    ))
                }
            </RadioGroup>
        )
    }

    renderElement = (item: any) => {
        return <CustomBox style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CustomBox>
                <Typography 
                    className='card-label' title={item?.project_name}>{item?.project_name}</Typography>
                <Typography className='card-text'>{item?.username}</Typography>
            </CustomBox>
            <CustomBox 
                className='manage-gap' 
                style={{ display: 'flex', height: '24px' }}>
                <CustomBox className='manage-space'>
                    <Typography>{item?.views}
                    </Typography>
                    <img className='eyeIcon' src={eyeIcon} alt='eye icon' />
                </CustomBox>
                <CustomBox 
                    className='manage-space'>
                    <Typography>{item?.likes}</Typography>
                    <img className='likeIcon' src={likeIcon} alt='like icon' />
                </CustomBox>
                <CustomBox className='manage-space-feature'>
                    <Typography>{item?.saved_count}</Typography>
                    <img 
                        className='featureIcon' src={featureIcon} alt='feature icon' />
                </CustomBox>
            </CustomBox>
        </CustomBox>
    }

    renderElementReceive = (item: ReceiveProject) => {
        return <CustomBox style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CustomBox>
                <Typography className='card-label' title={item.project_title}>{item.project_title}</Typography>
                <Typography className='card-text'>{item.user_name}</Typography>
            </CustomBox>
            <CustomBox className='manage-gap' style={{ display: 'flex', height: '24px' }}>
                <CustomBox className='manage-space'>
                    <Typography>{item.view_count}</Typography>
                    <img className='eyeIcon' src={eyeIcon} alt='eye icon' />
                </CustomBox>
                <CustomBox className='manage-space'>
                    <Typography>{item.like_count}</Typography>
                    <img className='likeIcon' src={likeIcon} alt='like icon' />
                </CustomBox>
                <CustomBox className='manage-space-feature'>
                    <Typography>{item.saved_count}</Typography>
                    <img className='featureIcon' src={featureIcon} alt='feature icon' />
                </CustomBox>
            </CustomBox>
        </CustomBox>
    }

    ErrorMsgDisplay = () => {
        return (
            <>{this.state.BudgetErrorMsg && (
                    <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                        {this.state.BudgetErrorMsg}</Typography>
                )}
            </>
        )
    }

    warningMessage = () => (
        <>
            {this.state.warningMessage && !this.state.errorMessage && (
                <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                    {this.state.warningMessage}
                </Typography>
            )}
        </>
    )

    projectData = () => {
        return (
            <>
                {this.state.boardListing.length > 0
                    ?
                    this.state.boardListing.map((data: any, index: number) => (
                        <CustomBox key={index} className='accordian-elm' data-test-id="boardListing" onClick={() => this.handleAccordian(data.attributes.id)}>
                            <CustomBox className='accordian-summary'>
                                <Typography className='but`ton-text'>{data.attributes.name}</Typography>

                                <CustomBox 
                                    className='right-elm'>
                                    <Typography 
                                        className='button-text-count'>{this.selectedProjectCount(data.attributes.id)}</Typography>
                                    <Typography 
                                        className='hind-text secondary-text'></Typography>
                                    <CustomButton 
                                        className='accordian-collapse'>
                                        <img 
                                            className={`toggle-accordian ${this.state.openBoard === data.attributes.id ? 'toggle-accordian-up' : ''}`} src={dropDownIcon} alt='toggle accordian' />
                                    </CustomButton>
                                </CustomBox>
                            </CustomBox>
                            <CustomBox className={`accordian-details ${this.state.openBoard === data.attributes.id ? '' : 'd-none'}`}>
                                <CustomBox 
                                    className='card-container' onClick={(event) => event.stopPropagation()}>
                                    {this.state.boardProjects?.attributes?.project?.data?.map((item: any, index: number) => (<>
                                        <CustomBox key={`card-container${index}`} >
                                            <CustomBox className='card-style' data-test-id={`card-style-${index}`} onClick={() => this.toggleSelectProject(item, data.attributes.id)}>
                                                <img 
                                                    style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={config.baseURL + item.attributes.cover_image_url} alt='project cover image' />
                                                <img 
                                                    className={`check-elm ${this.handleViewCheck(item, data.attributes.id) ? '' : 'd-none'}`} src={checkIcon} alt='project selected' />
                                                <img 
                                                    className={`uncheck-elm ${this.handleViewCheck(item, data.attributes.id) ? 'd-none' : ''}`} src={uncheckIcon} alt='select project' />
                                            </CustomBox>
                                            {this.renderElement(item.attributes)}
                                        </CustomBox>
                                    </>
                                    ))}
                                </CustomBox>
                            </CustomBox>
                        </CustomBox>
                    ))
                    :
                    <CustomBox style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No board created!</CustomBox>
                }
            </>
        )
    }

    ErrorMsgTimeError = () => {
        return (
            <>
                {this.state.ProjectTimeError && (
                    <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                        {this.state.ProjectTimeError}
                    </Typography>
                )
                }
            </>
        )
    }

    selectedProjectCount = (data: number) => {
        if (this.state.projectCounts[data] === 0 || this.state.projectCounts[data] === undefined) {
            return ``
        } else if (this.state.projectCounts[data] === 1) {
            return `${this.state.projectCounts[data]} project selected`
        } else {
            return `${this.state.projectCounts[data]} projects selected`
        }
    }

    timeData = () => {
        const formatDate = (dateString: string | number | Date | Dayjs) => {
            const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
        return (
            <Box>
                {this.state.projectTimeline ? (
                    <>
                        <Typography style={webStyle.titleStyle}>
                            Start date
                        </Typography>
                        <Typography style={webStyle.description}>
                            {this.state.dateData &&
                                formatDate(
                                    this.state.dateData
                                )}
                        </Typography>
                    </>
                ) : null}
            </Box>
        )
    }

    renderPostButton = () => (
        <CustomButton data-test-id='create-work' style={{ width: '194px', marginTop: '45px' }} disabled={!this.enablePostWorkOpportunity() || this.state.postOpportunityLoader} onClick={() => this.handleModel()} className={`button-elm ${this.enablebuttonText()} medium-text`}>{this.state.postOpportunityLoader && <CircularProgress color='inherit' size={25} />}Post opportunity</CustomButton>
    )

    errorMesForLocation = () => {
        return (
            <>
                {this.state.locationError && (
                    <Typography style={{ color: 'red', marginTop: '10px', fontSize: "12px", fontFamily: "Inter" }}>
                        {this.state.locationError}
                    </Typography>
                )}
            </>
        )
    }

    receivedProjectModal = () => {
        return (
            this.state.receivedProjectList && (
                this.state.receivedProjectList.map((item, index) => (
                    <Box style={webStyle.commonBox}>
                        <Typography style={webStyle.indexNumberStyle}>{this.state.linkList.length + this.state.uploadedFile.length + index + 1}.</Typography>
                        <Box style={webStyle.DeliverItem3}  >
                            <Box key={item.id}>
                                <img
                                    style={webStyle.listImg}
                                    src={config.baseURL + item.project_cover_image}
                                    alt={item.project_title}
                                    height="281.67px"
                                    width="415.09px"
                                />
                                <Box
                                    style={webStyle.refContainers}
                                >
                                    <Box>
                                        <Typography
                                            style={webStyle.imgTitle}
                                        >
                                            {item.project_title}
                                        </Typography>
                                        <Box
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                marginTop: "4px",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    ...webStyle.refBtn,
                                                    textTransform: "none",
                                                    fontSize: "11px",
                                                }}
                                            >
                                                club
                                            </Button>
                                            <Typography
                                                style={webStyle.imgUser}
                                            >
                                                {item.user_name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={webStyle.imgeContainers}
                                    >
                                        <Box
                                            style={webStyle.imageIcon}
                                        >
                                            <Typography
                                                style={
                                                    webStyle.iconNumber
                                                }>
                                                {item.view_count}
                                            </Typography>
                                            <img
                                                src={eyeIcon}
                                                height="17.6px"
                                                alt="view"
                                                width="24.2px"
                                            />
                                        </Box>
                                        <Box
                                            style={webStyle.imageIcon} >
                                            <Typography
                                                style={
                                                    webStyle.iconNumber} >
                                                {item.like_count}
                                            </Typography>
                                            <img
                                                src={likeIcon2}
                                                width="18.7px"
                                                height="16.5px"
                                                alt="like"
                                            />
                                        </Box>
                                        <Box
                                            style={webStyle.imageIcon}
                                        >
                                            <Typography
                                                style={webStyle.iconNumber}
                                            >
                                                {item.saved_count}
                                            </Typography>
                                            <img
                                                src={featureIcon}
                                                width="24px"
                                                height="22px"
                                                alt="save"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))
            )
        )
    }

    receivedLinkModal = () => {
        return (
            (this.state.receivedLink && this.state.linkList) ? (
                this.state.linkList.map((link, index) => (
                    <Box style={webStyle.commonBox}>
                        <Typography style={webStyle.indexNumberStyle}>{this.state.uploadedFile.length + index + 1}.</Typography>
                        <Typography
                            key={index}
                            component={"a"}
                            target="_blank"
                            style={{ ...webStyle.listItem2 as React.CSSProperties, display: "block" }}
                        >
                            {link}
                        </Typography>
                    </Box>
                ))
            ) :
                (
                    this.state.linkList.map((link, index) => (
                        <Box style={webStyle.commonBox}>
                            <Typography style={webStyle.indexNumberStyle}>{this.state.files.length + this.state.selectedProjectList.length + index + 1}.</Typography>
                            <Typography
                                key={index}
                                component={"a"}
                                target="_blank"
                                style={{ ...webStyle.listItem2 as React.CSSProperties, display: "block" }}
                            >
                                {link}
                            </Typography>
                        </Box>
                    ))
                )
        )
    }

    postOpportunityModel = () => {
        let newLocation
        if (this.state.multiLocationData.length > 0) {
            newLocation = this.state.multiLocationData.map((value) => {
                if (typeof value === 'string') {
                    let arr = value.split(',');
                    return arr[0].toLowerCase();
                }
                return '';
            });
        }
        return (
            <Modal
                data-test-id="post-opportunity-modal"
                open={this.state.isModelOpen}
                onClose={this.handleModel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={webStyle.modal}>
                    <Box sx={webStyle.closeIconBox}>
                        <Box style={{ height: 40 }}>
                            <img
                                style={webStyle.closeIconcss}
                                src={closeIcon}
                                data-test-id="closeButton"
                                alt="Close"
                                onClick={() => this.handleModel()}
                            />
                        </Box>
                        <Box sx={webStyle.paper}>
                            <Box>
                                <Grid container>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Box style={webStyle.titleBox}>
                                            <Typography 
                                                style={{ ...webStyle.modalTitle, textTransform: "capitalize" }}>{this.state.title}
                                            </Typography>
                                            <Box style={webStyle.buttonBox}>
                                                <Button data-test-id="saveWork"
                                                    onClick={this.handleModel}
                                                    style={{
                                                        ...webStyle.saveBtn,
                                                        textTransform: "none",
                                                    }}
                                                > Cancel
                                                </Button>
                                                <Button
                                                    style={{
                                                        ...webStyle.sendBtn,
                                                        textTransform: "none",
                                                    }}
                                                    data-test-id="postOpportunityButton"
                                                    onClick={() => this.createWorkOpportunity()}
                                                >
                                                    Post opportunity
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividStyle} />
                                    </Grid>
                                    <Grid
                                        md={7}
                                        item
                                        lg={8}
                                        sm={6}
                                        style={{ borderRight: "1px solid rgba(84, 84, 84, 1)" }}
                                        xl={8}
                                        xs={12}
                                    >
                                        <Box style={webStyle.paddingBox}>
                                            <Typography
                                                style={webStyle.descriptionTitle}
                                                data-testId="workdescription"
                                            > {" "} Work description
                                            </Typography>
                                            <Box style={webStyle.container}>
                                                <Typography
                                                    style={{
                                                        wordWrap: "break-word",
                                                        overflowWrap: "break-word",
                                                        whiteSpace: "pre-line",
                                                        ...webStyle.description,
                                                    }}
                                                >
                                                    {this.state.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividStyle} />
                                        <Box
                                            style={{
                                                ...webStyle.btnBox,
                                                boxSizing: "border-box",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography style={{ ...webStyle.skillTag }}>
                                                Skill tags
                                            </Typography>
                                            <Box
                                                style={{ ...webStyle.btnContainer, flexWrap: "wrap" }}
                                            >
                                                {this.state.selectedSkillTags.map(
                                                    (skill) => {
                                                        return (
                                                            <Button
                                                                key={skill.id}
                                                                style={{ ...webStyle.btnStyle, textTransform: "none" }}
                                                            >
                                                                {skill.name.charAt(0).toUpperCase() + skill.name.slice(1).toLowerCase()}
                                                            </Button>
                                                        );
                                                    }
                                                )}
                                            </Box>
                                        </Box>
                                        <Divider style={webStyle.dividStyle} />
                                        <Box style={webStyle.paddingBox}>
                                            <Typography style={webStyle.skillTag}>
                                                Deliverables
                                            </Typography>
                                            <Box
                                                style={{
                                                    ...{
                                                        ...webStyle.DeliverableList,
                                                        ...webStyle.container,
                                                    },
                                                    overflow: "auto",
                                                    height: "auto",
                                                    maxWidth: "900px",
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        ...{
                                                            ...webStyle.listItem,
                                                            wordWrap: "break-word",
                                                            overflowWrap: "break-word",
                                                            whiteSpace: "pre-line",
                                                        },
                                                        width: "100%",
                                                        minHeight: "auto",
                                                        maxHeight: "100%",
                                                        overflowY: "auto",
                                                    }}
                                                />
                                                <ul style={{...webStyle.deliverableUl,whiteSpace: "pre-wrap",wordWrap: "break-word"}}>
                                                    {this.state.deliverables
                                                        .split("\n")
                                                        .filter((line) => line.trim() !== "")
                                                        .map((line, index) => (
                                                            <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
                                                        ))}
                                                </ul>
                                            </Box>
                                        </Box>
                                        {(this.state.files.length > 0 || this.state.linkList.length > 0 || this.state.selectedProjectList.length > 0 || this.state.receivedProjectList.length > 0) && <>
                                            <Divider style={webStyle.dividStyle} />
                                            <Box style={webStyle.paddingBox}>
                                                <Box>
                                                    <Typography style={webStyle.descriptionTitle}>
                                                        Project references
                                                    </Typography>
                                                    <Box
                                                        style={{
                                                            ...webStyle.DeliverableList,
                                                            flexDirection: "column",
                                                            display: "flex",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <Box>
                                                            <Box>
                                                                {this.state.files && (
                                                                    <>
                                                                        {this.state.files.map(
                                                                            (fileName: File, fileIndex: number) => (
                                                                                <Box style={webStyle.commonBox}>
                                                                                    <Typography style={webStyle.indexNumberStyle}>{fileIndex + 1}.</Typography>
                                                                                    <Typography data-test-id="pdfDownload"
                                                                                        key={fileIndex}
                                                                                        style={{
                                                                                            ...webStyle.listItem1,
                                                                                            whiteSpace: "pre-wrap",
                                                                                            wordBreak: "break-word",
                                                                                            marginBottom: "10px"
                                                                                        }}
                                                                                    >
                                                                                        {fileName.name}
                                                                                    </Typography>
                                                                                </Box>
                                                                            )
                                                                        )}

                                                                    </>
                                                                )}
                                                                {this.isFileReceived() &&
                                                                    <>
                                                                        {this.state.uploadedFile.map(
                                                                            (fileName: string, fileIndex: number) => (
                                                                                <Box style={webStyle.commonBox}>
                                                                                    <Typography style={webStyle.indexNumberStyle}>{fileIndex + 1}.</Typography>
                                                                                    <Typography data-test-id="pdfDownload"
                                                                                        key={fileIndex}
                                                                                        style={{
                                                                                            ...webStyle.listItem1,
                                                                                            whiteSpace: "pre-wrap",
                                                                                            wordBreak: "break-word",
                                                                                            marginBottom: "10px"
                                                                                        }}
                                                                                    >
                                                                                        {fileName}
                                                                                    </Typography>
                                                                                </Box>
                                                                            )
                                                                        )}

                                                                    </>
                                                                }
                                                            </Box>
                                                            {this.receivedLinkModal()}
                                                            {this.state.selectedProjectList && (
                                                                this.state.selectedProjectList.map((item, index) => (
                                                                    <Box style={webStyle.commonBox}>
                                                                        <Typography style={webStyle.indexNumberStyle}>{this.state.linkList.length + this.state.files.length + index + 1}.</Typography>
                                                                        <Box style={webStyle.DeliverItem3}  >
                                                                            <Box key={item.id}>
                                                                                <img
                                                                                    style={webStyle.listImg}
                                                                                    alt={item.attributes?.project_name}
                                                                                    width="415.09px"
                                                                                    src={config.baseURL + item.attributes?.cover_image_url}
                                                                                    height="281.67px"
                                                                                />
                                                                                <Box
                                                                                    style={webStyle.refContainers} >
                                                                                    <Box>
                                                                                        <Typography
                                                                                            style={webStyle.imgTitle} >
                                                                                            {item.attributes?.project_name}
                                                                                        </Typography>
                                                                                        <Box
                                                                                            style={{
                                                                                                gap: "10px",
                                                                                                display: "flex",
                                                                                                marginTop: "4px",
                                                                                                alignItems: "center",
                                                                                            }} >
                                                                                            <Button
                                                                                                style={{
                                                                                                    ...webStyle.refBtn,
                                                                                                    fontSize: "11px",
                                                                                                    textTransform: "none",
                                                                                                }}
                                                                                            > club
                                                                                            </Button>
                                                                                            <Typography
                                                                                                style={webStyle.imgUser}
                                                                                            >
                                                                                                {item.attributes?.username}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box
                                                                                        style={webStyle.imgeContainers} >
                                                                                        <Box
                                                                                            style={webStyle.imageIcon} >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber
                                                                                                } >
                                                                                                {item.attributes?.views}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={eyeIcon}
                                                                                                height="17.6px"
                                                                                                alt="view"
                                                                                                width="24.2px"
                                                                                            />
                                                                                        </Box>
                                                                                        <Box
                                                                                            style={webStyle.imageIcon}
                                                                                        >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber
                                                                                                }
                                                                                            >
                                                                                                {item.attributes?.likes}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={likeIcon2}
                                                                                                alt="like"
                                                                                                width="18.7px"
                                                                                                height="16.5px"
                                                                                            />
                                                                                        </Box>
                                                                                        <Box
                                                                                            style={webStyle.imageIcon} >
                                                                                            <Typography
                                                                                                style={
                                                                                                    webStyle.iconNumber }
                                                                                            >
                                                                                                {item.attributes?.saved_count}
                                                                                            </Typography>
                                                                                            <img
                                                                                                src={featureIcon}
                                                                                                height="22px"
                                                                                                alt="save"
                                                                                                width="24px"
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                ))
                                                            )}
                                                            {this.receivedProjectModal()}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>}

                                    </Grid>
                                    <Grid 
                                        item 
                                        xl={4} lg={4} sm={6} xs={12}>
                                        <Box
                                            style={{ ...webStyle.workBox, flexDirection: "column" }}
                                        >
                                            <Box>
                                                <Typography style={webStyle.titleStyle}>
                                                    Work type
                                                </Typography>
                                                <Box style={{ marginTop: "14px" }}>
                                                    <Button
                                                        style={{
                                                            ...webStyle.workBtn,
                                                            minWidth: "142px",
                                                            textTransform: "none",
                                                        }} >
                                                        {this.state.workType}
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box style={webStyle.location}>
                                                    <img src={location} />
                                                    <Typography style={webStyle.titleStyle}>
                                                        Location
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    style={{ ...webStyle.locationContainer, flexWrap: "wrap" }}
                                                >
                                                    {newLocation ?
                                                        newLocation.map((item: string) => (
                                                            <Button style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle, textTransform: "none" }}
                                                            > {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                                            </Button>
                                                        ))
                                                        :
                                                        <Button style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle, textTransform: "none" }}
                                                        > {this.state.location.charAt(0).toUpperCase() + this.state.location.slice(1).toLowerCase()}
                                                        </Button>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography style={webStyle.titleStyle}>
                                                    Experience level
                                                </Typography>
                                                <Typography style={webStyle.description}> {this.state.workExperience}
                                                </Typography>
                                            </Box>
                                            <Typography style={webStyle.titleStyle}>
                                                {this.state.workHour}{" "}
                                                Hours pr/week
                                            </Typography>
                                            <BoxStyle style={webStyle.btnHourBox}>
                                                <Typography style={this.state.rateData ? webStyle.priceHour : webStyle.overallHour}>
                                                Rs. {(Number(this.state.rateData || this.state.overallRate)).toLocaleString('en-IN')}{" "}
                                                    {this.state.rateData ? "pr/hr" : "overall"}
                                                </Typography>
                                                <Typography style={this.state.rateData ? webStyle.month : webStyle.overMonth}>
                                                    {this.state.projectTimeline}{" "}
                                                    {this.state.selectedElm}
                                                </Typography>
                                            </BoxStyle>
                                            {this.timeData()}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
    // Customizable Area End

  render() {
      // Customizable Area Start
      return (
            <ThemeProvider theme={theme}>
                <CustomBox className='post-opportunity-container'>
                <CustomBox className='right-data-elm'>
                        <CustomBox
                            className='discard-container'
                            data-test-id='discard-container'
                            onClick={this.handleDeleteModel}
                        >
                            <img src={`${deleteIcon}`} alt='delete icon' />
                            <Typography className='discard-text'>Discard</Typography>
                        </CustomBox>
                        <Button data-testid="offerLetterButton" style={webStyle.offerContractButton as React.CSSProperties} onClick={this.editClick}><img style={{marginRight: '10px'}} src={`${editIcon}`} alt='delete icon' />Edit</Button>
                    </CustomBox>
                    <CustomBox className={this.handleEditClassess()} style={{ marginTop: '54px' }}>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project Title*</Typography>
                            <CustomTextField
                                className='project-title main-text'
                                type='text'
                                placeholder='Type here'
                                data-test-id='input-data'
                                value={this.state.title}
                                inputProps={{ maxLength: 50
                                }}
                                onChange={(event) => this.handleTitleChange(event.target.value)}
                            />
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project description*</Typography>
                            <CustomTextField
                                className='description-title main-text'
                                placeholder='Type here'
                                value={this.state.description}
                                data-test-id='input-data'
                                inputProps={{ maxLength: 500 }}
                                multiline={true}
                                minRows={5}
                                onChange={(event) => { this.handleDescriptionChange(event.target.value); this.handleCountCharacter(event.target.value) }}
                            />
                            <Typography className='limit-title'>
                                {this.state.descCountCharacter}/500
                            </Typography>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Skills required*</Typography>
                            <CustomBox className='skill-container'>
                                {this.isSkillAdded() && <CustomBox className='active-skill-list-container'>
                                    {this.state.selectedSkillTags.map((list, index) => <CustomBox
                                        data-test-id='selectedSkillTags'
                                        className='tags tags-active'
                                        key={`selectedSkillTags${index}`}
                                        onClick={() => this.handleRemoveSkillTags(list)}
                                    >
                                        <img src={`${closeIcon}`} alt='remove skill' />{list.name}</CustomBox>)}
                                </CustomBox>}
                                <CustomBox className={`search-container`}>
                                    <img src={`${searchIcon}`} alt='search skill tags' />
                                    <CustomTextField
                                        type='text'
                                        className={this.handleClassess()}
                                        placeholder='Search'
                                        value={this.state.searchText}
                                        data-test-id='search-input-data'
                                        disabled={!this.state.skillList.length}
                                        onChange={(event) => this.handleChange(event.target.value)}
                                        endAdornment={
                                            this.state.searchText.length > 0 && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    id="clear-text"
                                                    data-test-id="closeOnSearch"
                                                    style={{
                                                        color: "white",
                                                        fontFamily: "Inter, sans-serif",
                                                    }}
                                                    size="small"
                                                    onClick={this.handleCancel}
                                                >
                                                    x
                                                </IconButton>
                                            </InputAdornment>
                                        )}
                                    />
                                </CustomBox>
                                {this.handleSkillView() ? <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container'>
                                    {this.handleSkillORSearchList().map((list, index) => <CustomBox
                                        data-test-id='skillList'
                                        className='tags'
                                        key={`skillTags${index}`}
                                        onClick={() => this.handleAddSkillTags(list)}
                                    >{list.name}</CustomBox>)}</CustomBox></>
                                    : <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container text-elm fs-16'>No result found</CustomBox></>}
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>
                                Work type*</Typography>
                            <CustomBox className='work-type-container'>
                                {this.renderWorkTypeArray()}
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography 
                                className='label-text'>Deliverables*</Typography>
                            <CustomTextField
                                className='deliverables-title main-text'
                                data-test-id='deliverablesElm'
                                placeholder='Type here'
                                value={this.state.deliverables}
                                inputProps={{
                                    maxLength: 500,
                                    style: {
                                        scrollbarWidth: 'none',
                                    }
                                }}
                                style={{overflow: 'hidden'}}
                                rows={5}
                                multiline={true}
                                onChange={(event) => { this.handleDeliverablesChange(event.target.value); this.handleDeliverableCountCharacter(event.target.value) }}
                            />
                            <Typography 
                                className='limit-title'>
                                {this.state.deliverableCountCharacter}/500
                            </Typography>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>What experience level are you looking*</Typography>
                            <CustomBox className='work-experience-container'>
                                {this.renderExperienceArray()}
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Any additional resource files / links</Typography>
                            <CustomBox className='additional-resource-container'>
                                <CustomBox className='upload-container'>
                                    <InputLabel className='button-elm default-text upload-resource bold-text upload-file-style'>
                                        <CustomTextField
                                            id='cover-image'
                                            hidden
                                            type='file'
                                            inputProps={{ accept: 'image/*, video/*, .doc, .docx, .pdf'
                                            }}
                                            ref={this.refElm}
                                            data-test-id='upload-work-file'
                                            onChange={this.handleResourceFiles}
                                            style={{ display: "none" }}
                                        />
                                        Upload Files
                                    </InputLabel>
                                </CustomBox>
                                <CustomBox className='link-container' style={{ marginBottom: '30px', marginTop: '12px' }}>
                                    <CustomTextField
                                        type='text'
                                        onChange={(event) => this.updateLink(event.target.value)}
                                        className='link-text search-field default-text'
                                        placeholder='Paste link'
                                        value={this.state.files_or_links}
                                        data-test-id='input-data'
                                        onKeyPress={(event) => this.handlelinkData(event)}
                                    />
                                </CustomBox>
                                <CustomButton
                                    onClick={() => this.showModalData()}
                                    data-test-id='add-dezigners' className='button-elm add-resource bold-text'>+ Add from Deziner’s knot</CustomButton>
                            </CustomBox>
                            {this.isProjectSelected() && <CustomBox className='card-container horizontal-scroll'>
                                {this.state.selectedProjectList.map((item: Response, index) => (<CustomBox key={`card-container${item.id}`} style={{ display: 'flex' }}>
                                    <Typography className='list-no hint-text'>{index + 1}.</Typography>
                                    <CustomBox>
                                        <CustomBox className='card-style'>
                                            <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={`${config.baseURL + (item?.attributes && item.attributes.cover_image_url)}`} alt='project cover image' />
                                        </CustomBox>
                                        {this.renderElement(item.attributes)}
                                    </CustomBox>
                                </CustomBox>
                                ))}
                            </CustomBox>}
                            {this.isProjectReceived() && <CustomBox className='card-container horizontal-scroll'>
                                {this.state.receivedProjectList.map((item, index) => (<CustomBox key={`card-container${item.id}`} style={{ display: 'flex' }}>
                                    <Typography 
                                        className='list-no hint-text'>{index + 1}.</Typography>
                                    <CustomBox>
                                        <CustomBox className='card-style'>
                                            <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={`${config.baseURL + (item.project_cover_image)}`} alt='project cover image' />
                                        </CustomBox>
                                        {this.renderElementReceive(item)}
                                    </CustomBox>
                                </CustomBox>
                                ))}
                            </CustomBox>}
                            {this.isFileAdded() && <CustomBox className='flex-data horizontal-scroll'>{this.state.files.map((item, index) => (<CustomBox key={index} className='filename-container horizontal-scroll'>
                                <Typography 
                                    className='list-no hint-text'>{this.state.selectedProjectList.length + index + 1}.</Typography>
                                <CustomBox 
                                className='filename-container-elm hint-text'>{item.name}</CustomBox>
                            </CustomBox>))}</CustomBox>}
                          {this.isFileReceived() && <CustomBox className='flex-data horizontal-scroll'>{this.state.uploadedFile.map((item, index) => (<CustomBox key={index} className='filename-container horizontal-scroll'>
                              <Typography className='list-no hint-text'>{this.state.receivedProjectList.length + index + 1}.</Typography>
                              <CustomBox className='filename-container-elm hint-text'>{item}</CustomBox>
                          </CustomBox>))}</CustomBox>}
                          {this.state.receivedLink ? this.state.linkList.map((item, index) => (
                              <CustomBox style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography className='list-no hint-text'>{this.state.uploadedFile.length + this.state.receivedProjectList.length + index + 1}.</Typography>
                                  <Typography className='link-text-data hint-text word-wrap'>{item}</Typography>
                              </CustomBox>))
                              :
                              this.state.linkList.map((item, index) => (<CustomBox style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography className='list-no hint-text'>{this.state.files.length + this.state.selectedProjectList.length + index + 1}.</Typography>
                                  <Typography className='link-text-data hint-text word-wrap'>{item}</Typography>
                              </CustomBox>))
                          }
                      </CustomBox>
                    </CustomBox>
                    <CustomBox className={this.handleEditClass()}>
                        <Typography 
                        className='label-text'>Add questions</Typography>
                        <Typography 
                        className='hint-text hint-color' style={{ marginTop: '10px' }}>
                            Add a question for the designer to answer as a part of the proposal
                        </Typography>
                        {this.state.questionList.map((item, index) => <CustomBox style={{ width: '100%' }} key={index} className={this.handleAddClass(index)}>
                            <CustomTextField
                                type='text'
                                style={{ width: '100%' }}
                                data-test-id='question-text-elm'
                                value={item}
                                onChange={(event) => this.handleQuestionData(event.target.value, index)}
                            />
                            <img data-test-id='delete-ques' onClick={() => this.removeQuestion(item, index)} className='pointer-cursor' src={deleteIcon} alt='delete question' />
                        </CustomBox>)}
                        <CustomBox>
                            <CustomButton
                                onClick={() => this.addQuestion()}
                                data-test-id='add-question' className='button-elm default-btn add-question medium-text'>+ Add</CustomButton>
                        </CustomBox>
                    </CustomBox>
                    <CustomBox 
                        className={this.handleEditClassess()}>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Add budget*</Typography>
                            <CustomBox style={{ display: 'flex', gap: '25px' }}>
                                <CustomBox>
                                    <Typography className='hint-text' style={{ color: '#fff2e3' }}>Hourly rate</Typography>
                                    <CustomBox className='hours-container'>
                                        <CustomBox className='default-text' style={{ display: 'flex', alignItems: 'center', width: '100px', paddingLeft: '15px', paddingRight: '10px', borderRight: '1px solid #2f2f2f', color: '#fff2e3', gap: '18px' }}>
                                            &#8377;
                                            <CustomTextField
                                                type='text'
                                                placeholder='0.00'
                                                value={this.state.rateData}
                                                data-test-id='input-data'
                                                className='search-field default-text rateData normal-style'
                                                onChange={(event) => this.updateRate(event.target.value)}

                                            />
                                        </CustomBox>
                                        <Typography className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>( pr/hour )</Typography>
                                    </CustomBox>
                                </CustomBox>
                                <Typography style={{ paddingBottom: '14px', alignSelf: 'flex-end', color: '#646464', fontFamily: 'Inter,sans-serif', fontSize: '20px', lineHeight: '24.2px', fontStyle: 'italic' }}>Or</Typography>
                                <CustomBox>
                                    <Typography className='hint-text' style={{ color: '#fff2e3' }}>Overall project budget</Typography>
                                    <CustomBox 
                                    className='hours-container'>
                                        <CustomBox className='default-text' style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '10px', color: '#fff2e3', gap: '18px' }}>
                                            &#8377;
                                            <CustomTextField
                                                type='text'
                                                placeholder='0.00'
                                                value={this.state.overallRate}
                                                data-test-id='input-data'
                                                className='search-field default-text rateData normal-style'
                                                onChange={(event) => this.updateOverallRate(event.target.value)}
                                            />
                                        </CustomBox>
                                    </CustomBox>
                                </CustomBox>
                            </CustomBox>
                            {this.ErrorMsgDisplay()}
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Required work hours per week*</Typography>
                            <CustomBox className='hours-container'>
                                <CustomBox style={{ width: '107px', paddingLeft: '15px', paddingRight: '5px', borderRight: '1px solid #2f2f2f' }}>
                                    <CustomTextField
                                        type='text'
                                        placeholder='Add hours'
                                        value={this.state.workHour}
                                        data-test-id='input-data'
                                        className='search-field default-text rateData'
                                        inputProps={{
                                            maxLength: 5
                                        }}
                                        onBlur={() => this.handleWorkHoursValidation()}
                                        onChange={(event) => this.updateWorkHours(event.target.value)}
                                    />
                                </CustomBox>
                                <Typography className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>
                                    per/week
                                </Typography>
                            </CustomBox>

                            {this.warningMessage()}
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Project timeline*</Typography>
                            <CustomBox 
                                className='hours-container'>
                                <CustomBox style={{ width: '107px', paddingLeft: '15px', paddingRight: '5px', borderRight: '1px solid #2f2f2f' }}>
                                    <CustomTextField
                                        type='text'
                                        className='search-field default-text rateData'
                                        placeholder='Add number'
                                        data-test-id='input-data'
                                        inputProps={{
                                            maxLength: 4
                                        }}
                                        value={this.state.projectTimeline}
                                        onBlur={() => this.handleProjectTimelineValidation()}
                                        onChange={(event) => this.updateProjectTimeline(event.target.value)}
                                    />
                                </CustomBox>
                                <CustomBox className='hint-text' style={{ paddingLeft: '16px', color: '#fff2e3' }}>
                                    <SingleSelect toggleState={this.toggleSingle} open={this.state.openSingle} data-test-id="week-switch" title={this.state.selectedElm ? this.state.selectedElm.toLowerCase() : 'weeks'} data={['Weeks', 'Months']} selected={this.state.selectedElm} onChange={this.handleSelectChange} />
                                </CustomBox>
                            </CustomBox>
                            {this.ErrorMsgTimeError()}
                        </CustomBox>
                        <CustomBox className='formGroup' style={{ marginTop: "15px" }}>
                            <Typography className='label-text'>Select starting date</Typography>
                            <CustomBox className='hours-container'>
                                <CustomBox className='date-container' style={{ width: '206px', paddingLeft: '15px', paddingRight: '5px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            data-test-id='date-picker'
                                            open={this.state.openCalender}
                                            minDate={this.handleCurrentDate()}
                                            value={this.state.dateData ? this.state.dateData : this.handleCurrentDate()}
                                            disablePast={true}
                                            onChange={(val: Dayjs | null) => this.handleDate(val)}
                                            onClose={() => this.closeCalender()}
                                            onAccept={() => this.closeCalender()}
                                            slotProps={{
                                                openPickerIcon: {
                                                    onClick: this.openCalender
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </CustomBox>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text' data-testId="addLocationTestId">Add location*</Typography>
                            <CustomBox className='location-container'>
                                <RadioGroup
                                    aria-labelledby='demo-controlled-radio-buttons-group'
                                    value={this.state.location}
                                    name='work_location'
                                    style={{ gap: '14px', marginTop: '13px' }}
                                    onChange={this.handleWorkLocation}
                                    data-testId="radioButtonTestId"
                                >
                                    <FormControlLabel
                                        data-test-id='btnToggle3'
                                        style={{ alignItems: 'flex-start', margin: 0 }}
                                        value='remote'
                                        control={<CustomRadio checked={this.state.location.toLowerCase() == "remote"} style={{ marginRight: '12px', padding: 0 }} />}
                                        label={<Typography className='default-text secondary-color'>Remote</Typography>}
                                    />
                                    <LocationWrapper>
                                        <FormControlLabel
                                            value="location"
                                            style={{ alignItems: 'center', margin: 0 }}
                                            control={<CustomRadio checked={this.state.location.toLowerCase() == "location"} style={{ marginRight: '12px', padding: 0 }} />}
                                            label={<CustomBox className='default-text secondary-color'>
                                            </CustomBox>}
                                        />
                                        <PlaceAutoCompleteWrapper>
                                            <PlacesAutocomplete
                                                value={this.state.selectedLocation}
                                                onChange={this.handleChangeLocationText}
                                                onSelect={this.handleChangeLocation}
                                                data-testId="selectLocationTestId"
                                                searchOptions={{
                                                    types: ['locality']
                                                }}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                    <Box>
                                                        <TextFieldData
                                                            {...getInputProps()}
                                                            placeholder='Select Location'
                                                            variant='outlined'
                                                            data-testId="locationTestId"
                                                            fullWidth
                                                            disabled={this.state.disableLocationText}
                                                            inputProps={
                                                                { className: "placeAutoComplete", onChange: this.handleInputChange }
                                                            }
                                                        />
                                                        <Box className="autocomplete-dropdown-container suggestion_box">
                                                            {suggestions.map((suggestion, index) => {
                                                                return (
                                                                    <Box className="suggestion_container"
                                                                        {...getSuggestionItemProps(suggestion, { key: { index } })}

                                                                    >
                                                                        <Typography key={index} style={{ width: "50%" }} className="suggestion">{suggestion.description}</Typography>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    </Box>
                                                )}
                                            </PlacesAutocomplete>
                                        </PlaceAutoCompleteWrapper>
                                    </LocationWrapper>
                                </RadioGroup>
                            </CustomBox>
                            {this.errorMesForLocation()}
                            <CustomBox className='active-skill-list-container' data-testId="skillListTestId">
                                {this.state.multiLocationData.map((list, index) => {
                                    return (
                                        <CustomBox
                                            className='tags tags-active'
                                            key={`selectLocation${index}`}
                                            data-testId="removeLocationTestId"
                                            onClick={() => this.handleRemoveLocation(list)}
                                        >
                                            <img src={`${closeIcon}`} alt='remove skill' />
                                            {list}
                                        </CustomBox>
                                    );
                                })}
                            </CustomBox>
                        </CustomBox>
                    </CustomBox>
                    <CustomBox className={this.handleEditClassess()} style={{ borderTop: '2.33px solid #2F2F2F', paddingBottom: '100px' }}>
                        <CustomBox style={{ marginTop: '55px' }}>
                            <CustomBox style={{ display: 'flex', gap: '12px', alignItems: "center" }}>
                                <CustomCheckBox data-test-id='customCheckbox' checked={this.state.conditionChecked} onClick={() => this.toggleCondition()} style={{ backgroundColor: this.handleCheckBg(), padding: '0' }} />
                                <Typography className='default-text' style={{ color: '#fff2e3', display: "flex", alignItems: "center" }}>
                                    I agree to all&nbsp;<Typography className='underline-text'>terms and conditions</Typography>
                                </Typography>
                            </CustomBox>
                            {this.renderPostButton()}
                            {this.postOpportunityModel()}
                        </CustomBox>
                    </CustomBox>
                    {this.state.showModal &&
                        <Dialog
                            data-test-id='modal'
                            open={this.state.showModal}
                            onClose={() => this.closeModal()}
                            fullWidth={true}
                            maxWidth="lg"
                            classes={{ paper: "post-opportunity-modal" }}
                        >
                            <DialogContent
                            >
                                <DialogContentText>
                                    <CustomBox className='project-reference-modal'>
                                        <CustomBox className='project-reference-modal-body' style={{ width: "97%" }}>
                                            <CustomBox style={{ display: 'flex', gap: '24px', width: "100%" }}>
                                                <img data-test-id='modal' onClick={() => this.closeModal()} className='back-btn' src={leftArrowIcon} alt='back btn' />
                                                <CustomBox style={{ width: '100%' }}>
                                                    <CustomBox className='modal-header-data'>
                                                        <Typography className='text-elm bold-text'>Add project references</Typography>
                                                        <CustomButton data-test-id='addProject' onClick={() => this.addProject()} disabled={this.enableProjectAddBtn()} className={`${this.enableProjectAddBG()} button-elm medium-text add-btn`}>Add</CustomButton>
                                                    </CustomBox>
                                                    <CustomBox className='modal-sub-heading'>
                                                        <CustomBox data-test-id='tab-btn' onClick={() => this.handleTab('myBoard')} className={`default-text pointer-cursor ${this.enableTabBG('myBoard')}`}>My boards</CustomBox>
                                                        <CustomBox data-test-id='tab-btn' onClick={() => this.handleTab('followingBoard')} className={`default-text pointer-cursor ${this.enableTabBG('followingBoard')} secondary-text`}>Following boards</CustomBox>
                                                    </CustomBox>
                                                </CustomBox>
                                            </CustomBox>
                                            <CustomBox className='accordian-container'>
                                                {this.projectData()}
                                            </CustomBox>
                                        </CustomBox>
                                    </CustomBox>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    }
                    <ToastMSG data-test-id="toast" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
                </CustomBox>
                <DeleteProjectModal 
                    data-test-id="deleteModel"
                    open={this.state.deleteModal} 
                    onClose={this.handleDeleteModel} 
                    onDelete={this.handleShowDiscard} 
                    title="Delete Project"
                    content="Are you sure you want to delete this project permanently?"
                    button1Text="Cancel"
                    button2Text="Delete"
                />
            </ThemeProvider>
        );
        // Customizable Area End
  }
}

// Customizable Area Start

const CustomBox = withStyles((theme) => ({
    root: {
        '&.project-reference-modal': {
            backgroundColor: '#222',
            borderRadius: '11.97px',
            padding: '53px 63px',
            width: '1072px',
            display: 'flex',
            height: '742px',
            justifyContent: "space-between",
            '& .modal-header-data': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            '& .back-btn': {
                alignSelf: 'flex-start',
                marginTop: '4px',
                cursor: 'pointer'
            },
            '& .secondary-text': {
                color: "#fff2e3"
            },
            '& .disabled-text': {
                color: "#7d7d7d"
            },
            '& .uncheck-elm': {
                right: "14px",
                top: "14px"
            },
            '& .check-elm': {
                right: "10px",
                top: "10px"
            },
            '& .check-elm, & .uncheck-elm': {
                position: "absolute",
                zIndex: 99,
                cursor: "pointer"
            },
            '& .modal-sub-heading': {
                display: 'flex',
                paddingBottom: '10px',
                marginTop: '12px',
                color: '#fff2e3',
                marginRight: '14px',
                gap: '54px',
                borderBottom: '2.33px solid #2F2F2F',
                marginBottom: '32px'
            },
            '& .accordian-elm': {
                borderRadius: '16px',
                border: '1px solid #535353',
                minHeight: '36px',
                backgroundColor: '#171717',
                padding: '16px',
                marginBottom: '10px'
            },
            '& .accordian-summary': {
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
            },
            '& .right-elm': {
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
            },
            '& .accordian-collapse': {
                width: '36px',
                minWidth: '36px',
                height: '36px',
                backgroundColor: '#222',
                borderRadius: '8px',
                border: 'none'
            },
            '& .button-text': {
                fontSize: '16px',
                letterSpacing: '0.38px',
                fontFamily: 'Inter-Medium, sans-serif',
                lineHeight: '24px',
                color: '#fff2e3',
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
            },
            '& .button-text-count': {
                fontSize: '16px',
                lineHeight: '24px',
                color: '#fff2e3',
                letterSpacing: '0.38px',
                fontFamily: 'Inter-Medium, sans-serif',
                alignSelf: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
            },
            '& .toggle-accordian-up': {
                rotate: "180deg"
            },
            '& .add-btn': {
                width: '92px'
            },
        },
        '&.project-reference-modal,&.post-opportunity-container': {
            '& .card-container': {
                marginTop: '32px',
                rowGap: '32px',
                columnGap: '24px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap !important'
            },
            '& .card-style': {
                width: '380px',
                height: '260px',
                marginBottom: '8px',
                position: 'relative',
                borderRadius: '10px',
            },
            '& .manage-space-feature, & .manage-space': {
                display: 'flex',
                fontFamily: 'Inter-SemiBold, sans-serif',
                lineHeight: '13.31px',
                color: '#fff2e3',
                fontSize: '11px',
                alignItems: 'center',
                maxHeight: '22px'
            },
            '& .card-label, & .card-text': {
                width: '165px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: '#fff2e3'
            },
            '& .card-label': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                lineHeight: '16.94px',
                marginBottom: '8px',
                fontSize: '14px',
            },
            '& .manage-space-feature': {
                gap: "2px"
            },
            '& .manage-space': {
                gap: "8px"
            },
            '& .manage-gap': {
                gap: "19px"
            },
            '& .card-text': {
                fontFamily: 'Inter, sans-serif',
                lineHeight: '14.52px',
                fontSize: '12px',
            },
            '& .default-text': {
                fontFamily: 'Inter, sans-serif',
                lineHeight: '19.36px',
                fontSize: '16px',
            },
            '& .text-elm': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '20px',
                lineHeight: '24.2px',
                color: '#fff2e3',
            },
            '& .bold-text': {
                fontFamily: 'Inter-SemiBold, sans-serif !important',
            },
            '& .upload-file-style':{
                cursor : "pointer"
            },
            '& .d-none': {
                display: 'none',
            },
        },
        '&.post-opportunity-container': {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '48px',
            paddingBottom: '50px',
            height: 'auto',
            paddingLeft: '48px',
            marginTop: '76px',
            alignItems: 'center',
            '& .default-radius': {
                borderRadius: "11.97px"
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderBottom: "none !important"
            },
            '& .additional-resource-container': {
                display: 'flex',
                alignItems: 'center',
                paddingTop: '13px',
                borderRadius: '11.97px',
                flexDirection: 'column',
                border: '2.33px dashed #0D0D0D',
                width: '427px',
                height: '276px'
            },
            '& .bg-gray': {
                backgroundColor: "#222"
            },
            '& .d-flex': {
                display: "flex"
            },
            '& .flex-column': {
                display: "flex",
                flexDirection: "column"
            },
            '& .secondary-color': {
                color: '#fff2e3'
            },
            '& .hint-color': {
                color: "#696969"
            },
            '& .add-question': {
                width: "108px",
                marginTop: "30px"
            },
            '& .label-text': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                lineHeight: '24.2px',
                fontSize: '20px',
                color: '#fff2e3',
                padding: 0,
                margin: 0,
            },
            '& .hint-text': {
                fontFamily: 'Inter, sans-serif',
                lineHeight: '16.94px',
                fontSize: '14px',
            },
            "& .word-wrap": {
                overflowWrap: "anywhere"
            },
            '& .main-text': {
                fontFamily: 'Inter, sans-serif',
                lineHeight: '18.15px',
                fontSize: '15px',
                color: '#fff2e3'
            },
            '& .hours-container .option-container': {
                left: '-80px'
            },
            '& .disabled-text': {
                color: '#7d7d7d',
            },
            '& .horizontal-scroll': {
                justifyContent: 'flex-start !important',
                flexWrap: 'nowrap !important',
                overflowX: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            },
            '& .flex-data': {
                display: 'flex',
                gap: '24px',
            },
            '& .filename-container': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .filename-container-elm': {
                color: '#fff',
                width: 'max-content',
                borderRadius: '10px',
                padding: '13px 15px',
                backgroundColor: '#252525',
            },
            '& .list-no': {
                color: '#7d7d7d',
                paddingRight: '12px',
                lineHeight: '24.2px',
                fontSize: '20px',
                fontFamily: 'Inter-SemiBold, sans-serif !important',
            },
            ...customStyle.customStyle.tagsElm
        },
        '&.post-opportunity-container ::placeholder': {
            fontStyle: 'italic',
        },
        '&.post-opportunity-container .main-text::placeholder, &.post-opportunity-container .date-container input::placeholder': {
            color: '#686868',
        },
        '&.post-opportunity-container .h-63': {
            height: '63px',
        },
        '&.post-opportunity-container .link-text::placeholder': {
            color: '#808080',
        },
        '&.post-opportunity-container .rateData::placeholder': {
            color: '#595959',
        },
        '&.post-opportunity-container .question-container': {
            borderTop: '2.33px solid #2F2F2F',
            padding: '55px 60px',
            borderBottom: '2.33px solid #2F2F2F',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .question-container.disabelDiv': {
            borderTop: '2.33px solid #2F2F2F',
            borderBottom: '2.33px solid #2F2F2F',
            backgroundColor: '#171717',
            padding: '55px 60px',
            pointerEvents: 'none',
            opacity: '0.8'
        },
        '&.post-opportunity-container .normal-style input::placeholder': {
            fontStyle: 'normal !important',
        },
        '&.post-opportunity-container .add-resource, &.post-opportunity-container .upload-resource': {
            padding: '0',
            height: 'max-content !important',
            backgroundColor: 'transparent',
            borderBottom: '1px solid #fff2e3',
            borderRadius: '0',
            color: '#fff2e3',
            width: 'max-content',
        },
        '&.post-opportunity-container .upload-resource': {
            marginTop: '38px',
        },
        '&.post-opportunity-container .draft-btn': {
            width: '142px',
        },
        '&.post-opportunity-container .medium-text': {
            fontFamily: 'Inter-Medium, sans-serif !important',
        },
        '&.post-opportunity-container .underline-text': {
            width: 'max-content',
            lineHeight: '1.4 !important',
            textDecoration: 'underline'
        },
        '&.post-opportunity-container .date-container fieldset': {
            outline: 'none',
            border: 'none',
        },
        '&.post-opportunity-container .date-container input': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .date-container button': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .select-btn': {
            width: '209px',
            height: '37px',
            paddingLeft: '16px',
            paddingRight: '16px',
            border: '1px solid #66625c',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: '#fff2e3 !important',
            borderRadius: '6px',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            lineHeight: '14px',
            letterSpacing: '0.38px',
        },
        '&.post-opportunity-container .select-btn img.moveup': {
            rotate: '180deg',
        },
        '&.post-opportunity-container .right-data-elm': {
            display: 'flex',
            gap: '50px',
            width: '100%',
            justifyContent: 'end'
        },
        '&.post-opportunity-container .form-container': {
            padding: '40px 60px',
            backgroundColor: '#171717',
            display: 'flex',
            flexDirection: 'column',
            gap: '100px',
        },
        '&.post-opportunity-container .form-container.disabelDiv': {
            padding: '40px 60px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#171717',
            gap: '100px',
            pointerEvents: 'none',
            opacity: '0.8'
        },
        '&.post-opportunity-container .form-container, &.post-opportunity-container .question-container': {
            maxWidth: '1103px',
            width: '100%',
        },
        '&.post-opportunity-container .search-field': {
            width: '100%',
            height: '49px',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#fff2e3',
        },
        '&.post-opportunity-container .header-container': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '1348px',
            marginLeft: '-71px',
        },
        '&.post-opportunity-container .link-container': {
            width: '405px',
            border: '1.16px solid #3C3C3C',
            borderRadius: '10px',
        },
        '&.post-opportunity-container .link-text': {
            textAlign: 'center',
            height: '57px !important',
            borderRadius: '10px',
            color: '#fff2e3',
        },
        '&.post-opportunity-container .link-text input': {
            textAlign: 'center !important'
        },
        '&.post-opportunity-container .formGroup': {
            flexDirection: 'column',
            display: 'flex',
            gap: '24px',
        },
        '&.post-opportunity-container .hours-container': {
            alignItems: 'center',
            display: 'flex',
            height: '50px',
            backgroundColor: '#222',
            borderRadius: '10px',
            width: '223px',
            marginTop: '10px'
        },
        '&.post-opportunity-container .hours-container .single-select-container .select-btn': {
            padding: '0',
            height: 'max-content',
            width: '63px',
            borderRadius: '0',
            border: 'none',
        },
        '&.post-opportunity-container .hours-container .single-select-container .title-elm': {
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
        },
        '&.post-opportunity-container .hours-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container .single-select-container .select-btn': {
            width: '156px',
        },
        '&.post-opportunity-container .location-container .single-select-container .title-elm': {
            width: 'max-content',
        },
        '&.post-opportunity-container .location-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container': {
            width: 'max-content',
        },
        '&.post-opportunity-container .discard-container': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '&.post-opportunity-container .discard-container .discard-text': {
            fontSize: '16px',
            fontFamily: 'Inter-SemiBold, sans-serif',
            lineHeight: '19.36px',
            color: '#fff2e3',
            borderBottom: '1px solid #fff2e3',
            width: 'max-content',
            marginLeft: '18px',
        },
        '&.post-opportunity-container .project-title, &.post-opportunity-container .description-title, &.post-opportunity-container .deliverables-title': {
            border: 'none',
            backgroundColor: '#222',
            borderRadius: '11.97px',
            paddingLeft: '27px',
            fontFamily: 'Inter, sans-serif',
            paddingRight: '27px',
            fontSize: '15px',
            lineHeight: '18.15px',
        },
        '&.post-opportunity-container .description-title': {
            padding: '27px',
            maxWidth: '952px',
            resize: 'none',
            height: '163px',
        },
        '&.post-opportunity-container .limit-title': {
            maxWidth: '952px',
            color: '#fff2e3',
            textAlign: 'end',
            marginTop: '-20px'
        },
        '&.post-opportunity-container .project-title': {
            height: '77px',
            maxWidth: '466px',
        },
        '&.post-opportunity-container .deliverables-title': {
            maxWidth: '952px',
            padding: '26px 16px !important',
            height: '145px',
            resize: 'none',
        },
        '&.post-opportunity-container .work-type-container': {
            borderRadius: '11.97px',
            backgroundColor: '#222',
            display: 'flex',
            flexDirection: 'column',
            padding: '28px 0px',
            gap: '43px',
            paddingLeft: "37px",
            maxWidth: '915px',
        },
        '&.post-opportunity-container .work-type-container .text-container': {
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            maxWidth: '915px',
        },
        '&.post-opportunity-container .project-title:focus, &.post-opportunity-container .description-title:focus, &.post-opportunity-container .search-field:focus, &.post-opportunity-container .deliverables-title:focus, &.post-opportunity-container .link-text:focus, &.post-opportunity-container .question-data input:focus': {
            outline: 'none',
        },
        '&.post-opportunity-container .upload-container': {
            display: 'flex',
            width: '405px',
            height: '115px',
            justifyContent: 'center',
            backgroundColor: '#222',
            borderRadius: '10px',
        },
        '&.post-opportunity-container .heading-text': {
            fontSize: '36px',
            lineHeight: '43.57px',
            color: '#fff2e3',
            fontFamily: 'Inter-SemiBold, sans-serif',
        },
        '&.post-opportunity-container .option-container-collection .menu-item': {
            color: '#fff2e3',
            cursor: 'pointer',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            borderTop: '1px solid #5f5f5f',
            zIndex: '12',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .option-container-collection .menu-item.active': {
            backgroundColor: '#222',
        },
        '&.post-opportunity-container .option-container-category, &.post-opportunity-container .option-container-collection': {
            borderRadius: '0 0 10px 10px',
            border: '1px solid #66625c',
            borderTop: 'none',
            position: 'absolute',
            width: '207px',
            maxHeight: '205px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .option-container-category::-webkit-scrollbar, &.post-opportunity-container .option-container-collection::-webkit-scrollbar, &.post-opportunity-container .horizontal-scroll::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .menu-item:last-child': {
            borderRadius: '0 0 10px 10px',
        },
        '&.post-opportunity-container .link-text-data': {
            color: '#528ADF',
        },
        '&.post-opportunity-container .no-border': {
            border: 'none !important',
        },
        '&.post-opportunity-container .menu-open': {
            borderRadius: '10px 10px 0 0',
            height: '40px',
            border: '1px solid #5f5f5f',
            borderBottom: 'none',
        },
        '&.post-opportunity-container .select-container': {
            position: 'relative',
        },
        '&.post-opportunity-container .search-container': {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            paddingLeft: '22px',
            paddingRight: '22px',
        },
        '&.post-opportunity-container .skill-container': {
            borderRadius: '16.5px',
            border: '1px solid #323232',
            maxWidth: '952px',
        },
        '&.post-opportunity-container .skill-Suggestion': {
            fontSize: '12px',
            color: '#666',
            lineHeight: '14.52px',
            fontFamily: 'Inter, sans-serif',
            marginBottom: '16px',
            paddingTop: '22px',
            borderTop: '1px solid #2f2f2f',
            paddingLeft: '22px',
        },
        '&.post-opportunity-container .active-skill-list-container': {
            paddingTop: '16px',
            marginBottom: '8px',
        },
        '&.post-opportunity-container .skill-list-container, &.post-opportunity-container .active-skill-list-container': {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            paddingLeft: '22px',
            paddingRight: '22px',
            marginBottom: '22px',
            overflow: 'auto',
            maxHeight: '100px',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .skill-list-container::-webkit-scrollbar, &.post-opportunity-container .active-skill-list-container::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .tags': {
            width: 'max-content',
        },
        '&.post-opportunity-headerData': {
            display: 'flex',
            flexDirection: 'column',
        },
        '&.post-opportunity-container .banner-data': {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: '446px',
            marginTop: '76px',
        },
        '&.post-opportunity-container .discardBtn': {
            fontFamily: 'Inter-SemiBold, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            height: 'max-content',
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
            color: '#fff2e3',
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none',
        },
        '&.post-opportunity-container .create-project:active': {
            opacity: '0.5',
        },
        '&.post-opportunity-container .pointer-cursor': {
            cursor: 'pointer',
        },
        '&.post-opportunity-container .question-data': {
            maxWidth: '908px',
            width: '100%',
            height: '74px',
            borderRadius: '11.97px',
            backgroundColor: '#222',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            justifyContent: 'space-between',
        },
        '&.post-opportunity-container .mt-50': {
            marginTop: '50px',
        },
        '&.post-opportunity-container .mt-16': {
            marginTop: '16px',
        },
        '&.post-opportunity-container .question-data input': {
            height: '74px',
            backgroundColor: 'transparent',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '21.2px',
            width: '95%',
            color: '#fff2e3',
            border: 'none',
        },
        '&.post-opportunity-container .flex-center': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&.post-opportunity-headerData .fixed-top': {
            position: 'fixed',
            width: '100%',
            zIndex: '999',
        },
        '&.post-opportunity-container .location-container .option-container>div, &.post-opportunity-container .hours-container .option-container>div': {
            width: '156px !important',
        },
        '&.parent-element': {
            backgroundColor: "#111111"
        },
        [theme.breakpoints.down(1440)]: {
            '&.post-opportunity-container': {
                '& .form-container,& .question-container': {
                    maxWidth: "811px"
                },
                '& .header-container': {
                    maxWidth: "1081px",
                    marginLeft: "-90px"
                }
            }
        }
    }
}))(Box);

const CustomTextField = withStyles({
    root: {
        ...customStyle.customStyle.inputField
    }
})(Input);

const CustomButton = withStyles({
    root: {
        ...customStyle.customStyle.buttonField
    }
})(Button);

const LocationWrapper = styled(Box)({
    display: "flex"
})

const CustomRadio = withStyles({
    root: {
        color: '#fff2e3',
        '&.MuiTypography-body1': {
            color: '#fff2e3'
        }
    },
    checked: {
        '&.Mui-checked': {
            color: '#fff2e3'
        }
    },

})(Radio);

const TextFieldData = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: "48.68px",
            borderColor: '#66625C',
            borderWidth: "1.62px",
            borderRadius: "8.11px",
            '& fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&:hover fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&.Mui-focused fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            }
        },
        '& p.MuiFormHelperText-root': {
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#FFF2E3",
            fontFamily: "Inter, sans-serif"
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#66625C"
        }
    },
})(TextField);

const PlaceAutoCompleteWrapper = styled(Box)({
    "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#FFF2E3', fontSize: "14px", lineHeight: "16.94px" },
    "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: 800 },
    "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
    "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
});

const CustomCheckBox = withStyles({
    root: {
        color: '#d9d9d9',
        width: '20px',
        height: '20px',
        borderRadius: '2.3px',
        border: 'none'
    },
    checked: {
        '&.Mui-checked': {
            color: '#111111'
        }
    },
})(Checkbox);

const BoxStyle = styled(Box)({
    '@media (max-width: 1330px)': {
        flexDirection: 'column'
    },
    '@media (max-width: 1279px)': {
        flexDirection: 'row'
    },
})

const webStyle = {
    imgContainer: { height: 'auto', maxWidth: '100%', marginTop: '77px' },
    modal: {
        overflowY: "auto",
        maxHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "1490px",
        maxHeight: "90vh",
        backgroundColor: "#222222",
        borderRadius: "8px",
        overflowY: "auto",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
    },
    modalTitle: {
        fontSize: "20px",
        color: "#FFF2E3",
        fontWeight: 700,
        fontFamily: "Inter",
    },
    descriptionBox: {
        padding: "61px",
    },
    description: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        linehHeigth: "30px",
        color: "#FFF2E3",
        marginTop: "11px",
    },
    descriptionTitle: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    titleBox: {
        padding: "20px 26px 20px 58px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    btnStyle: {
        fontFamily: "Inter",
        marginTop: "24px",
        backgroundColor: "rgb(50, 50, 50)",
        fontSize: "12px",
        fontWeight: 600,
        linehHeigth: "14.52px",
        color: "#FFF2E3",
        borderRadius: "21px",
        border: " 1px solid #FFF2E3",
        padding: "11px 22px 11px 22px",
        width: "max-content",
        height: "37px",
    },
    locationBtnStyle: {
        marginTop: 0,
    },
    skillTag: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    btnContainer: {
        display: "flex",
        gap: "16px",
        width: "100%",
    },
    locationContainer: {
        display: "flex",
        gap: "11px",
        width: "inherit",
        paddingRight: 51
    },
    DeliverablesBox: {
        padding: "54px",
    },
    btnBox: {
        display: "flex",

        alignItems: "flex-start",

        width: "100%",

        padding: "50px 70px 50px 70px",
    },
    projectBox: {
        width: "400px",
    },
    DeliverableList: {
        marginTop: "18px",
    },

    list: {
        listStyleType: "disc",
        paddingLeft: "20px",
    },
    dividStyle: {
        backgroundColor: "rgba(84, 84, 84, 1)",
    },

    listItem: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "30px",

        color: "#FFF2E3",
    },
    deliverableUl: {
        color: "#fff2e3", fontFamily: "inter", margin: "0px 0px 0px -15px"
    },
    saveBtn: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF2E3",
        borderRadius: "7px",
        linehHeigth: "24px",
        backgroundColor: "#0D0D0D",
        padding: "10px 30px 10px 30px",
        width: "max-content",

        height: "44px",
    },
    sendBtn: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        color: "#121212",
        borderRadius: "7px",
        linehHeigth: "24px",
        backgroundColor: "#FFF2E3",
        padding: "10px 30px 10px 30px",
        width: "max-content",
        height: "44px",
    },
    buttonBox: { display: "flex", gap: "16px" },
    workBox: {
        display: "flex",
        width: "100%",
        padding: "51px",
        gap: "51px",
    },
    titleStyle: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Inter",
        linehHeigth: "24.2px",
        color: "#FFF2E3",
    },
    workBtn: {
        fontFamily: "Inter",
        fontSize: "12px",
        linehHeigth: "14.52px",
        color: "#FFF2E3",
        fontWeight: 400,
        borderRadius: "18.2px",
        border: " 0.5px solid #FFF2E3",
        padding: "11px 22px 11px 22px",
        height: "37px",
    },
    locationBox: {
        marginTop: "61px",
    },
    priceHour: {
        fontFamily: "Inter",
        fontSize: "16px",
        linehHeigth: "19.36px",
        fontWeight: 600,
        color: "#1CBAE0",
        padding: "6px 16px 6px 16px",
        borderRadius: "24px",
        width: "145px",
        backgroundColor: "#1D1D1D",
        display: "flex",
        height: "31px",
        justifyContent: "center",
        alignItems: "center",
    },
    overallHour: {
        fontFamily: "Inter",
        fontSize: "16px",
        linehHeigth: "19.36px",
        fontWeight: 600,
        color: "#1CBAE0",
        padding: "12px 16px 12px 16px",
        borderRadius: "24px",
        width: "145px",
        backgroundColor: "#1D1D1D",
        display: "flex",
        height: "31px",
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center'
    },
    month: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        linehHeigth: "19.36px",
        color: "#FFB9C7",
        padding: "6px 16px 6px 16px",
        borderRadius: "24px",
        backgroundColor: "#1D1D1D",
        width: "105px",
        height: "31px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    overMonth: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        linehHeigth: "19.36px",
        color: "#FFB9C7",
        padding: "12px 16px 12px 16px",
        borderRadius: "24px",
        backgroundColor: "#1D1D1D",
        width: "105px",
        height: "31px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    btnHourBox: {
        display: "flex",
        gap: "19px",
    },
    listNumber: {
        display: "flex",
        alignItems: "center",
        jutifyContent: "center",
        color: "515151",
    },
    listItem1: {
        fontFamily: "Inter",
        fontSize: "16px",
        minWidth: "215px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#FFF2E3",
        maxWidth: "max-content",
        height: "auto",
        display: "block",
        padding: "6px 15px 6px 15px",
        borderRadius: "10px",
        backgroundColor: "#333231",
        overflow: "hidden",
    },
    commonBox: {
        display: "flex", alignItems: "flex-start"
    },
    indexNumberStyle: {
        paddingRight: "10px", color: "#7d7d7d", fontSize: "20px"
    },
    listItem2: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#3a5680",
        marginLeft: "10px",
        lineBreak: "anywhere"

    },
    location: {
        display: "flex",
        gap: "6px",
        alignItems: "center",
        marginBottom: 24
    },
    listImg: {
        borderRadius: "4px",
        width: "350px",
    },
    imgTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        linehHeigth: "24px",
        color: "#FFF2E3",
    },
    imgUser: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        linehHeigth: "24px",
        color: "#FFF2E3",
    },
    refBtn: {
        color: "#FFF2E3",
        lineHight: "13.33px",
        fontFamily: "Inter",
        fontWeight: 600,
        padding: "4px 13px 4px 13px",
        width: "51px",
        height: "21px",
        borderRadius: "13.99px",
        marginTop: "2px",
        backgroundColor: "828181",
    },
    DeliverItem: {
        display: "flex",
        gap: "14px",
        alignItems: "center",
    },
    DeliverItem2: { marginTop: "24px", display: "flex", gap: "14px" },
    DeliverItem3: {
        marginTop: "10px",
        display: "flex",
        gap: "14px",
        alignItems: "start",
        marginLeft: "10px",
    },
    imgDes: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4px",
    },
    iconNumber: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "30px",
        color: "#FFF2E3",
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    imageIcon: {
        display: "flex",
        justifyContent: "center",
        gap: "6px",
        alignItems: "center",
    },
    imgeContainers: { display: "flex", gap: "15px", alignItems: "start" },
    refContainers: { display: "flex", gap: "55px", marginTop: "17px" },
    paddingBox: { padding: "50px 70px 50px 70px" },
    closeIconBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        width: "90%",
        maxWidth: "1490px",
        alignItems: "end",
        marginTop: "10px",
    },
    container: { width: "100%", height: "auto", overflow: "hidden" },
    closeIconcss: {
        cursor: "pointer",
        marginBottom: 20
    },
    offerContractButton: {
        backgroundColor: "#FFF2E3",
        color: "#121212",
        padding: "10px 30px",
        textTransform: 'none',
        "&:hover": {
          backgroundColor: "#FFF2E3",
        }
      },
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#fff2e3',
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222',
                    color: '#fff2e3'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#fff2e3 !important',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#fff2e3 !important'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#7d7d7d !important',
                    '&.Mui-selected': {
                        color: '#222 !important',
                        backgroundColor: '#fff2e3 !important'
                    },
                    '&.MuiPickersDay-today': {
                        border: '1px solid #fff2e3 !important'
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    backgroundColor: "transparent",
                    overflowY: 'auto',
                    boxShadow: "none",
                    overflowX: "hidden",
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0',
                        height: '0',
                    }
                }
            }
        }
    },
});
// Customizable Area End