import React from "react";
// Customizable Area Start
import ActiveContractController, {
  Props,
} from "./ActiveContractController.web";
import {
  Box, Grid, Step,
  StepLabel, Button, withStyles,
  StepContent, Typography, styled
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const config = require("../../../framework/src/config.js")
import StarIcon from '@material-ui/icons/Star';
import {avatar} from "../../catalogue/src/assets"
import { StepperStyledComponentWithNumber } from '../../cfproposalmanagement/src/UnderReviewProposal.web'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CalenderIcon } from "../../../components/src/ProposalComponent";
const customStyle = require('../../dashboard/src/CustomStyles.js');
import { Proposals } from "./Proposals.web";

// Customizable Area End

export class ActiveContract extends ActiveContractController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cardDetail = () => {
    const { contractDtailsProposal: { data } } = this.state;
    let final = {
      id: 0,
      created_at: "",
      updated_at: "",
      service_rate: 0,
      give_your_pitch: "",
      profile_photo: "",
      background_color: "",
      location: "",
      user_name: "",
      likes_count: 0,
      view_count: 0,
      rating: 0,
      received_time_ago: ""
    }
    if (data?.attributes) {
      final = data?.attributes;
    }
    return (
      <CardBox className="opportunity_card_main_containerChangeColorNew" data-test-id="navigate">
        <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem" }}>
          <Box>
            {this.renderPhoto(final.profile_photo, final.background_color)}
          </Box>
          <Box><Typography style={{
            ...webStyle.title,
            textTransform: "capitalize",
            width: "100%",
            wordBreak: "break-all"
          }}>{final.user_name}</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              textTransform: "capitalize",
              paddingTop: "0.5rem"
            }}>{final.location}</Typography></Box>
        </Box>
        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "0px 0.5rem" }}>
          <Box>
            <Typography style={webStyle.gray14}>Views</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textTransform: "capitalize",
              textAlign: "center"
            }}>{final.view_count}</Typography>
          </Box>
          <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
            <Typography style={webStyle.gray14}>Likes</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.likes_count}</Typography>
          </Box>
          <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 10px" }}>
            <Typography style={webStyle.gray14}>Submitted rate</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.service_rate.toLocaleString('en-IN')} pr/hr</Typography>
          </Box>
          <Box>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#7D7D7D",
              textTransform: "capitalize",
              textAlign: "center"
            }}>Ratings</Typography>
            <Box style={{
              display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
              height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
            }}>
              <Typography style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.36px",
                textTransform: "capitalize",
                textAlign: "center",
                color: "#FFF2E3",
              }}>{final.rating}
              </Typography>
              <Box><StarIcon style={{ color: "white", width: "14px", height: "13px" }} /></Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
          <Box style={{ padding: "1rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "11px",
              lineHeight: "13.31px",
              fontWeight: 400,
              color: "#66625C",
              textTransform: "capitalize",
              textAlign: "left",
            }}>{final.received_time_ago}</Typography>
          </Box>
          <Box style={{
            padding: "1rem"
          }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              color: "#FFF2E3",
              lineHeight: "16.94px",
              textTransform: "capitalize",
              textAlign: "left",
            }}>Pitch</Typography>
          </Box>
          <Box style={{ padding: "1rem", height: "6rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#FFF2E3",
              textTransform: "capitalize",
              textAlign: "left",
              display: "-webkit-box",
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word"

            }}>{final.give_your_pitch}</Typography>
          </Box>
        </Box>

      </CardBox>
    )
  }

  renderPhoto = (mainPhoto: string, backgroundColor: string) => {
    let content;
    if (mainPhoto) {
      content = (
        <img style={{ width: "100px", height: "100px", borderRadius: "50%" }}
          src={`${config.baseURL}${mainPhoto}`}
        />
      );
    } else if (backgroundColor) {
      content = (
        <Box style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img src={avatar} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="avatar" />
      );
    }
    return <>
      {content}
    </>;
  }

  setpperFirst = () => {
    const { contractMilestone } = this.state;
    return (
      contractMilestone.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        return (
          index < 4 && <Step
            className={index !== contractMilestone.length - 1 ? 'step-with-line' : ''}
            expanded={checkExapanded} key={index} >
            <StepLabel >
              <Box style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box
                  data-test-id="handleAccordionToggle"
                  style={OfferReceivedWebStyle.accordianSummaryStyle}
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        display: "flex",
                        gap: '6px',
                        alignItems: 'center',
                        flex: 1
                      }}>
                      <Typography style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon
                              onClick={() => this.toggleTerm(index)}
                              data-test-id="expandIconLess"
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon
                              data-test-id="expandIcon" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box
                      style={{ display: 'flex', gap: '40px' }}>
                      <Box style={{ display: 'flex', gap: '10px', marginLeft: '20px' }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>
                          {this.formatDateShort(label.due_date)}
                        </Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>&#8377; {label.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                  <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                    {label.description}
                  </Typography>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography className="deliveerable-style">Deliverables:</Typography>
                    {label.deliverables.map((item, index) => {
                      return (item &&
                        <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                          {index + 1}.{" "}{item}
                        </Typography>
                      )
                    })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperLast = (saved?: boolean) => {
    const { contractMilestone } = this.state;
    return (
      this.state.moreMilestone && contractMilestone.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        return (
          index > 3 &&
          <Step
            className={index !== contractMilestone.length - 1 ? 'step-with-line' : ''}
            key={index}
            expanded={checkExapanded}>
            <StepLabel >
              <Box
                style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box style={OfferReceivedWebStyle.accordianSummaryStyle}
                  data-test-id="handleAccordionToggle"
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        gap: '6px',
                        alignItems: 'center',
                        display: "flex",
                        flex: 1
                      }}>
                      <Typography
                        style={{ ...webStyle.font16White, lineBreak: 'anywhere' }}>{label.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon onClick={() => this.toggleTerm(index)}
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '40px' }}>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          marginLeft: '20px'
                        }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>{this.formatDateShort(label.due_date)}</Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>
                        &#8377; {label.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{
                  marginTop: '10px'
                }}
                >
                  <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>
                    {label.description}</Typography>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box
                  style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography
                      className="deliveerable-style">
                      Deliverables:
                    </Typography>
                    {label.deliverables.map((item, index) => {
                      return (item && <Typography style={{ ...webStyle.font14White400, lineBreak: 'anywhere' }}>{index + 1}.{" "}{item}
                      </Typography>
                      )
                    })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperDetail = () => {
    const { contractMilestone } = this.state;
    const length = contractMilestone.length;
    return (
      <StepperStyledComponentWithNumber
        style={{
          padding: '40px 0'
        }}
        activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper-transparent"
      >
        {this.setpperFirst()}
        {!this.state.moreMilestone && length - 4 > 0 &&
          <Step>
            <StepLabel
              StepIconComponent={() => (
                <CustomIconContainer>
                  <Typography
                    style={{ fontSize: '14px', fontWeight: 'normal' }} variant="caption" component="div">
                    {`+${length - 4}`}
                  </Typography>
                </CustomIconContainer>
              )}
            >
              <Typography data-test-id="checkMoreMilestone" onClick={this.checkMoreMilestone} style={{ marginTop: '3px', textDecoration: 'underline', cursor: 'pointer' }}>{length - 4}  more milestones</Typography>
            </StepLabel>
          </Step>
        }
        {this.stepperLast()}
      </StepperStyledComponentWithNumber>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box sx={webStyle.paper}>
         {this.state.contentType === "awaiting" &&
         <Box>
          <Grid container style={{marginTop: '-20px'}}>
            <Grid item xl={4} lg={4} sm={6} xs={12}
              style={{ padding: '20px 20px', backgroundColor: '#171717' }}>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Typography
                  data-testid="allProposals"
                  onClick={this.navigateViewWork}
                  style={webStyle.font16WhiteUnderline}>View work details</Typography>
              </Box>
              <Box
                style={{
                  marginTop: "45px"
                }}>
                <Typography
                  style={webStyle.title}>Selected Proposal
                </Typography>
              </Box>
              {this.cardDetail()}
              <Box mt={5}>
                <Typography style={webStyle.font16WhiteUnderline} data-test-id="displayAllProposal" onClick={this.displayAllProposal}>
                  View all Proposals
                </Typography>
              </Box>
            </Grid>
            <Grid
              style={{ padding: '0 0 0 32px' }}
              item
              xl={8}
              lg={8}
              sm={6}
              xs={12}
            >
              <CustomBox className="padding-right-15">
                <CustomBox className="no-offer">
                  <CustomBox className="noOffer-text-wrap">
                    <Typography style={this.state.contractDtailsContract.contract_status?.toLowerCase().includes('ongoing') ? webStyle.font36Green : webStyle.font36Gray}>{this.state.contractDtailsContract.contract_status}</Typography>
                    <Typography className="top-15" style={webStyle.gray12}>Contract activated on {this.state.contractDtailsContract.activate_on}.</Typography>
                  </CustomBox>
                  <CustomButton data-test-id='create-work' style={{ width: '180px' }}
                    onClick={this.goToContract}
                    className={`button-elm secondary-btn medium-text`}>Go to contract</CustomButton>
                </CustomBox>
              </CustomBox>
              <Box>
                <Box style={webStyle.editTitleButtonWrapNew}>
                  <Typography
                    style={webStyle.title}>Contract Milestones
                  </Typography>
                  <Box display="flex" gridGap={40} alignItems="flex-end">
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} mb={2} pt={0} pr={2} pb={0} pl={2}>
                        <Typography style={webStyle.gray12}>Project timeline</Typography>
                        <Typography style={webStyle.gray12}>Overall budget:</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={25} pt={0} pr={2} pb={0} pl={2}>
                        <Box display="flex" alignItems="center" gridGap={25}>
                          <Box bgcolor="#363636" color="#FFB9C7" px={1.5} py={0.5} borderRadius={16} mr={1}>
                            <Typography style={webStyle.font14600}>{this.state.contractDtailsContract.project_timeline} {this.state.contractDtailsContract.project_timeline_type}</Typography>
                          </Box>
                          <Box bgcolor="#363636" color="#1CBAE0" px={1.5} py={0.5} borderRadius={16} mr={1}>
                            <Typography style={webStyle.font14600}>&#8377; {this.state.contractDtailsContract.overall_budget}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {this.state.contractMilestone.length > 0 &&
                  <Box style={{ padding: '20px' }}>
                    <Box style={{ marginTop: '40px' }}>
                      {this.stepperDetail()}
                    </Box>
                  </Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
  }

        {this.state.contentType === "proposalList" &&
          <Proposals data-test-id="proposal" cardId={this.props.cardId}
            onOfferAction={this.displayAwaiting}
            dropDownId={this.props.dropDownId} navigation={this.props.navigation} displayTitle={false} />
        }
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CardBox = styled(Box)({
  maxWidth: "398px",
  width: '100%',
  margin: "18px 0",
  borderRadius: "11.54px",
  position: 'relative',
  overflow: 'hidden',
  "&.opportunity_card_main_containerChangeColorNew": {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '13px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMaskComposite: 'xor',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'exclude',
    }

  },
  "@media (max-width:960px)": {
      height: "533px",
  },
  "@media (min-width:720px)": {

  },
});

const CustomIconContainer = styled('div')(({ theme }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "#FFF2E3",
  color: 'rgba(17, 17, 17, 1)',
}));

const CustomBox = withStyles((theme) => ({
  root: {
    '&.no-offer':{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '30px',
      backgroundColor: '#222222',
      borderRadius: '14px',
      gap: '40px',
      '& .medium-text': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 30px'
      },
      '& .medium-text-new': {
        fontFamily: 'Inter-Medium, sans-serif !important',
        padding: '10px 20px'
      }
    },
    '&.noOffer-text-wrap': {
      maxWidth: '443px',
      width: '100%',
      '& .top-15':{
        marginTop: '15px'
      }
    },
  },
}))(Box);

const OfferReceivedWebStyle = {
  accordianSummaryStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin:0, 
    width: '100%' as '100%',
    borderBottom: "1px solid rgba(84, 84, 84, 1)",
    lineHeight: "24.2px",
    textAlign: "left" as 'left',
    color: "#fff2e3",
    paddingLeft: "0px" as '0px',
  },
  downloadIconStyle: {
    borderRadius: '50%',
    background: "rgba(84, 84, 84, 1)",
    width: '30px', height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
}

const CustomButton = withStyles({
  root: {
    ...customStyle.customStyle.buttonField
  }
})(Button);

const webStyle: Record<string, CSSProperties> = {
  paper: {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  gray12: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
    color: '#7D7D7D'
  },
  gray14: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.94px",
    textTransform: "capitalize",
    color: "#7D7D7D",
    textAlign: "left"
  },
  font16White: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  },
  font16WhiteUnderline: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  font14White400: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font36Gray: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#7D7D7D"
  },
  font36Green: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "43.57px",
    color: "#61CA78"
  },
  modalStepBoxNew: {
    marginTop: '35px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    backgroundColor: '#2F2F2F',
    borderRadius: '20px'
  },
  editTitleButtonWrapNew: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '20px 28px 0'
  },
  font14White: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font14600: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
  },
  font16Gray: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#545454'
  },
}
// Customizable Area End