Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.tabsData = [
  {
    tabName: "Designer",
    tabId: "designer",
  },
  {
    tabName: "Projects",
    tabId: "projects",
  },
  {
    tabName: "Clients",
    tabId: "client",
  },
  {
    tabName: "Inspiration boards",
    tabId: "inspiration",
  },
];
exports.searchText = "Search";
exports.suggestedUsers = "Suggested search";
exports.listOfUsers = [
  "Arul Ramdas",
  "Arul Yadav",
  "Suggestions",
  "Suggestions",
  "Suggestions",
  "Suggestions",
];
exports.getNotificationEndpoint = "/bx_block_notifications/notifications?per_page=15&page=1&filter=all";
exports.notificationsText = "Notifications";
exports.viewAllText = "View All";
exports.notificationCount = 0;
exports.noNotificationsFoundText = "No notifications found."

// Customizable Area End