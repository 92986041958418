// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { getToken } from "../../../../components/src/Base64Conversion";
import axios from "axios";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";

import { setStorageData } from "../../../../framework/src/Utilities"
export const configJSON = require("../config");
import { IBlock } from "../../../../framework/src/IBlock";

let tokenData = "";
export interface Props {
  navigation: any;
  id: string;
}



interface Response {
  work_opportunity_id: number | string,
  account_id: number | string,
  title: string,
  rate_type:string,
  hourly_rate: string | number,
  project_timeline: string | number,
  project_timeline_type?: string,
  description: string,
  name: string,
  location: string,   
  posted_time_ago: string
}

interface S {
  message: string,
  page: number,
  open: boolean,
  openOppertunity:boolean,
  action: string,
  selectedOpportunityId:number | string,
  opportunityList: Response[];
  route:string;
  errorMsg:string;
}

interface SS {
  id: any;
}

export default class WorkOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendProposalApiId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      page: 1,
      message: "",
      open: false,
      openOppertunity:false,
      selectedOpportunityId:0,
      action: "",
      route:"",
      opportunityList: [],
      errorMsg:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if(apiRequestCallId==this.sendProposalApiId){
        if(responseJson?.message==="You can create proposal"){
this.handleNavigation("Cfproposalmanagement")
        }
        else{
          this.setState(
            {
              open: true,
              openOppertunity:false,
              message: responseJson?.error,
              action: "danger"
            },
            () => {
              setTimeout(() => {
                this.setState({
                  open: false,
                  openOppertunity:false,
                  message: "",
                  action: "danger"
                });
              }, 2000);
            }
          );
        }
        } 
      }
    }
  formatToLocaleString = (data: Response) => {
  const  rate_types  = data.rate_type;
    if (rate_types && rate_types.includes( "hourly")) {
      return "pr/hr";
    }
    return ""; 
    }

  formatProjectTimeline = (data: Response) => {
    return `${data.project_timeline} ${data.project_timeline_type || "Months"}`;
  }

  handleViewMoreShaow = () => {
    return (this.state.opportunityList.length > 0) ? true: false;
  }

  getListData = () => {
    axios.get(`${configJSON.baseURL.baseURL}/${configJSON.workOpportunityEndPoint}`, { headers: {
      "token": tokenData,
      "Content-Type": "application/json"
    }})
    .then((res: any) => {
      if (res.data.work_opportunities) {
        this.setState({ opportunityList: [...res.data.work_opportunities] });
      }
    }).catch((error: any) => {
      this.setState({
        open: true,
        message: Object.values(error?.response?.data?.errors[0]).join(),
        action: "danger"
      })
    });
  }

  async componentDidMount() {
    super.componentDidMount();
    tokenData = await getToken();
    this.getListData();
  }
  handleOpen = (id: number | string) => {
    this.setState({ openOppertunity: true, selectedOpportunityId: id });   
  };
  handleClose = () => {
    this.setState({ openOppertunity: false });
  }
  handleSendProposal=(route: string)=>{
    this.checkProposalVaild(this.state.selectedOpportunityId)
  }
  handleNavigation = async (route: string) => {  
    await setStorageData("sendPraposalID", this.state.selectedOpportunityId)   
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }


  HandleNavigationitem(e:any,items:any) {
    e.stopPropagation()
    let localdata = {
      account_id: items.account_id,
      user_role : items.user_role
    }
    
    setStorageData("PublicId",JSON.stringify(localdata));
    const message: Message =
    new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage),
    "PublicUserProfile");
  message.addData(getName(MessageEnum.NavigationPropsMessage),
  this.props)
  this.send(message);
  }
  checkProposalVaild = async (id:number|string) => {
    const header = {
      "Content-Type": "application/json",
      token: tokenData,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendProposalApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/proposals/check_proposal?work_opportunity_id=${id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
}

// Customizable Area End