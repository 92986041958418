import React, { Component } from "react";
import {
    Box,
    Typography,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel
} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import { dropIcon } from "../../blocks/advancedsearch/src/assets"

const CustomOutlineBox = styled(Box)(
    {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        border: `1px solid #66625c`,
        width: "fit-content",
        padding: ".2rem 0.5rem .2rem 1.5rem",
        borderRadius: "6px",
        height: 40,
        boxSizing: "border-box !important" as "border-box",
    }
)

const CustomInnerBox = styled(Box)({
    backgroundColor: "#111111",
    border: "1px solid #5F5F5F",
    borderRadius: "10px",
    padding: 0,
    boxSizing: "border-box !important" as "border-box",
    position: "absolute" as "absolute",
    top: -2,
    left: -1,
    zIndex: 100,
    "& *": {
        boxSizing: "border-box !important" as "border-box",
    },
    "& .subHeading": {
        fontFamily: "Inter-SemiBold",
        color: "#ffffff"
    },
    "& .newResponsive": {
        padding: "1rem 2rem 1rem 1rem",
    },
    "& .newResponsiveTwo": {
        padding: "1rem 2rem 1rem 1rem",
    },
    "& .bottomBorder": {
        borderBottom: "1px solid #5F5F5F",
    },
    "& .count": {
        marginLeft: "1.5rem",
        color: "#ffffff"
    }
})

const CustomRadio = styled(Radio)({
    color: "#8C8C8C",
    "&.Mui-checked": {
        color: "#FFF2E3",
    }

})

const CustomFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "0.875rem",
        lineHeight: "16.94px",

    }
})
interface IGroupList {
    value: string;
    label: string;
}

interface Props {
    title?: string;
    dropdownTitle?: string;
    groupList: IGroupList[];
    isMenuOpened: boolean;
    selectedValue?: string;
    handleOpenMenu: () => void;
    handleOnChange: (value: string) => void;
}

interface S {
}

class CustomRadioGroup extends Component<Props, S> {
    constructor(props: Props) {
        super(props)
    }

    state: S = {

    }

    handleModalOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        this.props.handleOpenMenu()
    }

     handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        this.props.handleOnChange((event.target as HTMLInputElement).value);
      };
    

    render() {
        const {
            title,
            groupList,
            isMenuOpened,
            dropdownTitle,
            selectedValue
        } = this.props;
        return (
            <CustomOutlineBox
                onClick={this.handleModalOpen}
            >
                <Typography variant="body2">{title || "Opportunity"}</Typography>
                <IconButton>
                    <img loading="lazy" src={dropIcon} />
                </IconButton>
                {isMenuOpened &&
                    <CustomInnerBox >
                        <Box className="newResponsive bottomBorder">
                            <Typography data-testid="ratingHeading"
                                variant="body2"
                                className="subHeading">
                                {dropdownTitle || "Opportunities"}&#58;
                            </Typography>
                        </Box>
                        <Box className="newResponsiveTwo">
                            <RadioGroup
                                onChange={this.handleChange}
                                value={selectedValue || groupList[0].value}
                                >
                                {
                                    groupList.map((opportunity) =>
                                        <CustomFormControlLabel
                                            key={opportunity.value}
                                            value={opportunity.value}
                                            control={<CustomRadio />}
                                            label={opportunity.label}
                                        />
                                    )
                                }
                            </RadioGroup>
                        </Box>
                    </CustomInnerBox>}
            </CustomOutlineBox>
        )
    }
}




export default CustomRadioGroup