import React from "react";
// Customizable Area Start
import ContractDetailsController, {
  Props,
} from "./ContractDetailsController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
const config = require("../../../framework/src/config.js")
import {
  Box,
  Typography,
  Divider,
  withStyles,
  Grid,
  Slider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  leftArrowIcon
} from '../../dashboard/src/assets';
import './style.css';
import '../../cfproposalmanagement/src/style.css'
import { webStyle2 } from "../../cfproposalmanagement/src/Cfproposalmanagement.web";
import { returnMsgIcon ,returnCount, returnNotificationIcon} from "../../../components/src/ProposalComponent";
// Customizable Area End

export class ContractDeatils extends ContractDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  menuIcon = () => {
    return <svg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
      <circle cx="2" cy="11" r="2" fill="#D9D9D9" />
      <circle cx="2" cy="20" r="2" fill="#D9D9D9" />
    </svg>
  }

  renderDropdown = () => {
    if (this.state.menuOpen) {
      return (
        <div className="menu_icon_box">
          <Typography data-test-id="View_proposal_id_1" className="View_proposal_css" style={{ color: "rgba(255, 242, 227, 1)" }}
            onClick={() => {
              this.setState({ menuOpen: false })
            }}>View proposal</Typography>
          <Typography data-test-id="View_proposal_id_2" className="View_proposal_css" style={{ color: "rgba(255, 242, 227, 1)" }}
            onClick={() => {
              this.setState({ menuOpen: false })
            }}>View work post</Typography>
          <Typography data-test-id="View_proposal_id_3" className="View_proposal_css" style={{ color: "rgba(255, 242, 227, 1)", background: "rgba(34, 34, 34, 1)" }}
          >Raise Dispute</Typography>
          <Typography data-test-id="View_proposal_id_4" className="View_proposal_css" style={{ color: "rgba(255, 120, 120, 1)", borderBottom: 'unset' }}
          >End contract</Typography>
        </div>
      )
    }
  };

   renderMultiValueProgress = (values: number[], colors: string[], backgroundColor = "#000") => {
    const total = 100; 
    const sumOfValues = values.reduce((sum, value) => sum + value, 0); 
    const remainingPercentage = total - sumOfValues; 
  
    return (
      <Box
        style={{
          display: "flex",
          border: '1px solid rgba(125, 125, 125, 1)',
          position: "relative",
          height: 10,
          width: "100%",
          background: 'rgba(255, 242, 227, 1)', 
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        {values.map((value, index) => (
          <Box
            key={index}
            style={{
              width: `${(value / total) * 100}%`, 
              background: colors[index],
          borderRadius: '0px 5px 5px 0px',
            }}
          />
        ))}
        {remainingPercentage > 0 && (
          <Box
            style={{
              width: `${remainingPercentage}%`, 
              background: backgroundColor
              ,cursor:'pointer'
            }}
          />
        )}
      </Box>
    );
  };

  showNotification = (notificationdata: any[]) => {
    return notificationdata.map((item) => {
      return <Grid container style={{ padding: "20px 24px", gap: '20px', backgroundColor: item.attributes.is_read ? "unset" : "rgba(23, 23, 23, 1)" }}>
        <Grid xs={7} lg={7} md={7} sm={7}>
          <Typography className="notification_title_css">
            {item.attributes.contents}
          </Typography>
        </Grid>
        <Grid xs={4} lg={4} md={4} sm={4} style={{ textAlign: "end" }}>
          <Typography className="notification_timeline_css">
            {item.attributes.created_at}
          </Typography>
        </Grid>

      </Grid>
    })
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const progressValues = [40, 30,30]; 
    const segmentColors = ["linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)",
         "rgba(255, 242, 227, 1)", "rgba(17, 17, 17, 1)"]; 
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box
            style={webStyle.newResponseCon}
            data-testid="outside">
            <NavigationMenu {...this.props} activeTab="contracts" />
            <div style={webStyle.mainClientWrap}>
              <Box style={webStyle.responsiveCon}>
                <Grid container style={{ gap: '50px' }}>
                  <Grid lg={7} md={12} sm={12} xs={12}>
                    <Box style={webStyle.revisedAllPropsCon}>
                      <Box data-testid="allProposals"
                        style={webStyle.allPropsCon}>
                        <img src={leftArrowIcon} style={webStyle.cursonPointer} alt="left-icon" />
                        <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>gdfbhxgbxd</Typography>
                      </Box>
                      <Box style={{ justifyContent: 'flex-end' }} className="flexBox">
                        <Typography className="submessiongPendingCSS">
                          {this.state.mainDetails.contracts?.data.attributes?.active_milestone_details[0].milestone_status &&
                            this.state.mainDetails.contracts?.data.attributes?.active_milestone_details[0].milestone_status.charAt(0).toUpperCase() +
                            this.state.mainDetails.contracts?.data.attributes?.active_milestone_details[0].milestone_status.slice(1)}
                        </Typography>
                        <div style={{ position: 'relative', cursor: "pointer", padding: '10px' }}>
                          <span className="cursor"
                            data-test-id="menu_icon_click"
                            onClick={() => this.handleMenuIcon()}
                          >
                            {this.menuIcon()}
                          </span>
                          {this.renderDropdown()}
                        </div>
                      </Box>
                    </Box>
                    <Box className="firstFirstGridPart">
                      <Box style={{ borderBottom: '1px solid rgba(73, 73, 73, 1)' }}>
                        <Grid container className="GridFirstPartCss" >
                          <Grid lg={5} md={5} sm={5} xs={5} style={{ paddingBottom: '38px' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                              <Box className="sub-box-part">
                                <Box className="walletBoxCSSCircle">
                                </Box>
                                <Typography className="sub-part-heading">
                                  Wallet:
                                  <span className="span">
                                    &#8377; 1000
                                  </span>
                                </Typography>
                              </Box>

                              <Box className="sub-box-part">
                                <Box className="earningBoxCSSCircle">

                                </Box>
                                <Typography className="sub-part-heading">
                                  Earnings:
                                  <span className="span">
                                    &#8377; 2000
                                  </span>
                                </Typography>
                              </Box>

                              <Box className="sub-box-part">
                                <Box className="contractBoxCSSCircle">

                                </Box>
                                <Typography className="sub-part-heading">
                                  Contract Budget:
                                  <span className="span">
                                    &#8377;  3000
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                            <Box style={{ marginTop: '20px' }}>
                              {this.renderMultiValueProgress(progressValues, segmentColors)}
                            </Box>

                          </Grid>
                          <Grid lg={1} md={1} sm={1} xs={1} className="flexBox" style={{ justifyContent: "center" }}>

                            <Divider style={{ background: "rgba(73, 73, 73, 1)", height: "100%", width: "1px", marginBlock: 'unset' }} />
                          </Grid>

                          <Grid lg={6} md={6} sm={6} xs={6} style={{ paddingBottom: '38px' }}>
                            <Typography className="main-heading-first-box ">
                              Timeline
                            </Typography>
                            <Typography className="contract_start" style={{ marginTop: '24px' }}>
                              contract_started
                            </Typography>
                            <Box className="start_box">
                              <Typography className="start_date">
                                Start date: contract_start_date
                              </Typography>
                              <Box className="sub-box-part">
                                <Typography className="contract_start" style={{ marginTop: '0px' }}>
                                  Duration
                                </Typography>
                                <Typography style={webStyle2.month}>
                                  project_timeline{" "}
                                  project_timeline_type
                                </Typography>
                              </Box>

                            </Box>
                            <TimelineSlider
                              className="TimelineSlider"
                              valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={11} disabled />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box style={{ justifyContent: 'space-between', alignItems: 'center' }} className="flexBox GridFirstPartCss">
                        <Typography className="active_heading">
                          Active milestone
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid lg={4} md={12} sm={12} xs={12}>
                    <Grid lg={4} md={12} sm={12} xs={12}>

                      <Box className="second_grid_main">
                        <Box className="for_profile_location" >
                          <Box style={{ display: 'flex', gap: '10px' }}>
                            <Box>
                              <Typography className="profile_name_css">
                                profile_user_name
                              </Typography>
                              <Typography className="location_name_css">
                                profile_location

                              </Typography>
                            </Box>
                          </Box>
                          <Box style={{ position: "relative" }}>

                            {returnMsgIcon(2)}
                            {returnCount(
                              33
                            )}

                          </Box>
                        </Box>
                        <Box style={{ maxHeight: this.state.showNotification ? "750px" : "unset", overflow: 'scroll', scrollbarWidth: 'none' }}>

                          <Box className="notifcation_box_css" style={{
                            borderBottom: this.state.showNotification ? "unset" : "1px solid rgba(73, 73, 73, 1) "
                          }}>
                            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                              {returnNotificationIcon(2)}
                              <Typography className="notification_css">
                                Notifications
                              </Typography>
                              <Box className="notification_count_css">
                                {this.state.unread_notification_count}
                              </Box>
                            </Box>
                            {this.state.notification_count > 2 && <Typography className="view_all_heading"
                              data-test-id="showNotificationId"
                            >
                              {this.state.showNotification ? "view less" : "view more"}
                            </Typography>}
                          </Box>
                          <Box style={{ marginBottom: '6px' }}>
                          </Box>
                        </Box>

                      </Box>

                      <Box className="second_grid_main" style={{ marginTop: '35px' }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '28px 24px' }}>
                          <Typography className="notification_css">
                            Submissions
                          </Typography>
                          <Typography className="view_all_heading"
                            data-test-id="view_all_Submissions"
                            onClick={() => {
                            }}>
                            view all
                          </Typography>
                        </Box>
                        <Box style={{ padding: '0px 24px ', borderBottom: "1px solid rgba(73, 73, 73, 1)", paddingBottom: '20px' }}>
                        </Box>
                        <Box>
                          <Typography className="notification_css" style={{ padding: '28px 24px' }}>
                            Resource links
                          </Typography>
                          <Box style={{ padding: '0px 24px', gap: '20px', display: 'flex', flexDirection: 'column', }}>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
        </ThemeProvider>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  overflowY: 'auto',
                  boxShadow: "none",
                  overflowX: "hidden",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});

const TimelineSlider = withStyles({
  root: {
    color: 'unset',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,

    '&.MuiSlider-thumb.Mui-disabled':{
      width:"16px",
      height:'16px'
        },
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    background: 'rgba(255, 242, 227, 1)',
    borderRadius: 4,
  },
  rail: {
    height: 8,
    border:"1px solid rgba(86, 86, 86, 1)",
    borderRadius: 4,
  },
})(Slider);

const webStyle: Record<string, React.CSSProperties> = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  mainClientWrap: {
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%,1745px)',
    margin: 'auto',
  },
  revisedAllPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: 'space-between'
  },
  cursonPointer: {
    cursor: "pointer",
  },
  responsiveCon: {
    // margin: "0 auto",
    marginTop: "50px",
    padding: "5rem 1rem",
    backgroundColor: '#111'
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
}

export default ContractDeatils
// Customizable Area End