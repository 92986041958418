// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateApiMethodType='PUT';
exports.deleteApiMethodType = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PortfolioManagement";
exports.labelBodyText = "PortfolioManagement Body";
exports.getPortfolioList = 'bx_block_portfolio_management/portfolios/get_projects_based_collection?'
exports.getcollectionlist ="bx_block_portfolio_management/portfolios/get_collections/";
exports.DeleteCollectionList = "bx_block_portfolio_management/portfolios/delete_collection/"
exports.UpdateCollectionName='bx_block_portfolio_management/portfolios/update_collection_name/'
exports.btnExampleTitle = "CLICK ME";
exports.baseURL = require('../../../framework/src/config');
exports.InspirationUserDetails='bx_block_dashboard/inspirations?account_id=';
exports.InspirationUserProjects='bx_block_dashboard/inspirations/';
exports.DeleteInspirationProfile='bx_block_dashboard/inspirations/';
exports.updateInspirationProfilename='bx_block_dashboard/inspirations/';
exports.DeleteInspirationSingleProject='bx_block_dashboard/inspirations/delete_project_from_inspiration?';
exports.InspirationFollowingBoards='bx_block_dashboard/inspirations/user_followings_inspirations?';
exports.FollowingboardUnFollow='bx_block_dashboard/follow_inspirations/';
exports.getCategoriesList="bx_block_projectportfolio/get_categories";
exports.getSkillEndPoint='bx_block_projectportfolio/get_skills';
exports.getAllSkillsbyid="bx_block_projectportfolio/skills_by_category?category_id="
exports.AddProfessionalWorkExp='bx_block_projectportfolio/professional_experiences';
exports.getallListsofProfessionalWxp='bx_block_projectportfolio/professional_experiences?account_id='
exports.deletesingleProfessionalExp = 'bx_block_projectportfolio/professional_experiences/';
exports.searchSkillEndPoint = "bx_block_projectportfolio/search_skill";
exports.followApiEndPoint ='bx_block_dashboard/follow_inspirations';
exports.PublicProfileEndPoint = 'bx_block_dashboard/inspirations/public_inspirations?'
exports.getServiceListEndPoint = '/bx_block_profile/services?per_page=50&page=1'
exports.getCollectionEndPoint = 'bx_block_portfolio_management/portfolios'
exports.getUserCollectionEndPoint = '/bx_block_projectportfolio/get_user_collections'
exports.AddServiceEndPoint = "/bx_block_profile/services";
exports.workDescriptionText = "Work description";
exports.skillTagText = "Skill tags";
exports.deliverableText = "Deliverables";
exports.locationText = "Location";
exports.timelineText = "Timeline";
exports.rateText = "Rate";
exports.viewServiceText = "View Service";
exports.referenceLinksText = "Reference Links";
exports.remoteAnyWhereText = "Anywhere / Remote";
exports.projectsFromText = "Projects from DK";
exports.yourServiceHubText = "Your Service Hub Awaits";
exports.addServiceText = "Add Service";
exports.editServiceText = "Edit Service";
exports.cancelText = "Cancel";
exports.uploadCoverImageText= "Upload cover image";
exports.uploadCoverImage = "Upload cover";
exports.yourServiceHubDes = "Service Hub Empty! Add a service now. Begin customizing your offerings and creating a tailored service portfolio instantly.";
// Customizable Area End
