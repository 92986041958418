Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.arrowAltText = "arrow";
exports.bellIconText = "bell icon";
exports.notificationsText = "Notifications";
exports.tabsData = [
  {
    tabName: "All",
    tabId: "all",
    count: "all_notification_count",
  },
  {
    tabName: "Contract",
    tabId: "contract",
    count: "contract_notification_count",
  },
  {
    tabName: "Portfolio",
    tabId: "portfolio",
    count: "portfolio_notification_count",
  }
];
exports.todayText = "Today";
exports.yesterdayText = "Yesterday";
exports.lastWeekText = "Last week";
exports.noNotificationsFoundText = "No notifications found."
exports.getNotificationEndpoint = "/bx_block_notifications/notifications";
exports.notificationCountEndpoint = "/bx_block_notifications/notifications/notifications_count"
// Customizable Area End