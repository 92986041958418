// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}
export interface ICollectionList {
  id: number | null;
  name: string,
  count: number
}
export interface ImetaObj {
  project_count:number,
  like_count:number;
  save_count:number;
  view_count: number;
}
export interface IProtfolio {
  attributes :{
    account_id: number;
    background_color: string;
    collection_name: string;
    cover_image_url:string;
    description:string;
    end_date: null
    is_liked: false;
    is_saved: false;
    likes: number;
     profile_photo: string;
    project_images: any [];
   project_name: string;
    start_date: null;
    username:string;
    
  }
 
}
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  Isedit:boolean;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  indexArr: IListItem[];
  videoArr: IListItem[];
  audioArr: IListItem[];
  documentsArr: IListItem[];
  imageAll: boolean;
  audioAll: boolean;
  videoAll: boolean;
  documentsAll: boolean;
  tabsValues:number;
  CollectionList: ICollectionList[];
  ActiveCollection:string,
  CollectionListTabs :ICollectionList[],
  metaObj : ImetaObj,
  PortfolioData:IProtfolio[];
  currentIndex: number;
  itemsPerPage: number;
  collection_id:number |string;
  collection_page:number;
  anchorEl:any;
  Openmenu:boolean;
  openCollection:boolean;
  collection_Name:string;
  isLoading:boolean;
  hasMore:boolean;
  portFolioPage:number;
  PaginationData:IProtfolio[];
  moreBtn:boolean;
  activecard:string;
  totalCollectionCount:number;
  showDetails:boolean;
  specificProjectList: Response[];
  token:string;
  selectedProject:string|number
}

interface SS {
  id: any;
}

interface Response {
  id: string | number, 
  cover_image_url: string, 
  likes: string | number, 
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}
export default class PortfolioManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  indexAPICallID: string="";
  videosAPICallID: string="";
  audiosAPICallID: string="";
  documentsAPICallID: string="";
  GetCollectionListApiCallId : string='';
  GetCollectionPortpofolioApiCallId : string = '';
  DeleteCollectionApiCallId:string='';
  UpdateCollectionNameApiCallId:string ='';
  baseUrlStirng:string = configJSON.baseURL.baseURL;

  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      indexArr: [],
      videoArr: [],
      audioArr: [],
      PortfolioData:[],
      documentsArr: [],
      imageAll: false,
      audioAll: false,
      videoAll: false,
      documentsAll: false,
      tabsValues:0,
      ActiveCollection:"All",
      CollectionList:[],
      CollectionListTabs:[],
      metaObj :{
        project_count:0,
        like_count:0,
        save_count:0,
        view_count:0
      },
      currentIndex: 1,
    itemsPerPage: 3,
    collection_id:'nil',
    collection_page:0,
    anchorEl:"",
    Openmenu:false,
    openCollection:false,
    collection_Name:"",
    isLoading:false,
    hasMore:true,
    portFolioPage:1,
    PaginationData:[],
    moreBtn :false,
    activecard:"Portfolio",
    totalCollectionCount:0,
    showDetails:false,
    selectedProject:"",
    specificProjectList: [],
    token:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      this.getApiResponse(apiRequestCallId1, responseJson1)
      if (apiRequestCallId1 === this.indexAPICallID && !responseJson1.errors) {
        this.setState({
          indexArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.videosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          videoArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.audiosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          audioArr: responseJson1.data,
        });
      }
      if (
        apiRequestCallId1 === this.documentsAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          documentsArr: responseJson1.data,
        });
      }
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };
  getApiResponse =(apiRequestCallId1:string,responseJson1:any) =>{
    if (apiRequestCallId1 === this.GetCollectionListApiCallId) {
      if (!responseJson1.error) {
        this.setState({CollectionListTabs:responseJson1.data.collections,totalCollectionCount:responseJson1.data.collection_count,moreBtn:true})
      }else {
        this.setState({moreBtn:false,currentIndex:this.state.currentIndex-1,CollectionListTabs:[],totalCollectionCount:0})
        this.handleCollectionListEmpty()
      }
    }
    if (apiRequestCallId1 === this.GetCollectionPortpofolioApiCallId) {
      
      if (!responseJson1.error) {
        
        this.setState({metaObj:responseJson1.meta,PortfolioData:responseJson1.data,hasMore:true,isLoading:true},()=>{
          
          this.handlePaginationdata(this.state.PortfolioData)
        })
      }else {

        this.setState({hasMore:false,metaObj:{project_count:0,
          like_count:0,
          save_count:0,
          view_count:0}})
      }
    }
    if (apiRequestCallId1 === this.DeleteCollectionApiCallId) {
      this.handledeleteapiresp(apiRequestCallId1,responseJson1)
      

    }
    if (apiRequestCallId1 === this.UpdateCollectionNameApiCallId) {
      this.getcollectionlist()
      this.setState({ActiveCollection:responseJson1.data.name,collection_id:responseJson1.data.id,openCollection:false})
      this.handleCloseMenuList()
    }
  }
  handleCollectionListEmpty =()=>{
    if (this.state.currentIndex >1) {
      this.setState({CollectionListTabs:[]})
    }
  }
  handledeleteapiresp=(requestid:any,response:any)=>{
    if(!response.error){
      
      if (this.state.CollectionListTabs.length == 2 && this.state.currentIndex >1) {
        this.setState({currentIndex:this.state.currentIndex -1})
      }
      this.setState({ActiveCollection:"All"})
      this.handleActivecollection({name:'All',id:'nil'});
      this.handlePaginationdata(this.state.PaginationData)
      this.getcollectionlist() 
      this.handleCloseMenuList()
      this.getPortfolioList('nil')
      
    }else {
      this.setState(()=>({ActiveCollection:"All",}))
      this.getcollectionlist() 
      
      this.handlePaginationdata(this.state.PaginationData)
      this.handleCloseMenuList()
    }

  }
  handlePaginationdata =(PortfolioData:IProtfolio[])=>{
    const newPortfolioData = [...PortfolioData];
    
    this.setState((prev) => ({
      ...prev,
      PaginationData: [...prev.PaginationData, ...newPortfolioData],isLoading: false
  }));
  
  }
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  touchableViewContainer = {
    onPress: () => this.hideKeyboard(),
  };
  handleActivecollection = (data:any) =>{
    
    this.setState({ActiveCollection:data.name,collection_id:data.id,collection_Name:data.name,PaginationData:[],portFolioPage:1})
    this.getPortfolioList(data.id)
  }
  handleUpdateCollectionName =(event:any)=>{
    this.setState({collection_Name:event.target.value})
  }

  closeModal = () => {
    this.setState({
      selectedProject: '',
      showDetails: false,
      PaginationData:[],
      portFolioPage: 1,
    })
    this.getPortfolioList(this.state.collection_id);
  }
  handleDeleteProject = () => {
    this.setState({ PaginationData: [], portFolioPage: 1 }, () => {
      setTimeout(async () => {
        await this.getPortfolioList(this.state.collection_id);
        await this.getcollectionlist();
      }, 200);
    });
  }
  navigateTo = (to: string, id: string) => {
    this.props.navigation.navigate(to, { id });
 }
  openModal = (data: Response) => {
    this.setState({
      showDetails: true,
      selectedProject:data.id
    });
    this.handleSelectedProject(data);
  }
  mapDataToResponse = (data:any): Response[] => {
    return data.map((item:any) => ({
      id: item.id,
      cover_image_url: configJSON.baseURL.baseURL+item?.attributes?.cover_image_url,
      likes: item?.attributes?.likes ?? 0,
      views: item?.attributes?.views ?? 0,
      project_name: item?.attributes?.project_name,
      username: item?.attributes?.username,
      saved_count: item?.attributes?.saved_count,
    }));
  };
  handleSelectedProject = (data: Response) => {
    const allData = [];
    const dataProject = this.mapDataToResponse(this.state.PortfolioData)
    for (const project of dataProject) {
      if (project.id !== data.id) {
        allData.push(project);
      }
      if (allData.length === 3) {
        break;
      }
    }

    this.setState({
      selectedProject: data.id,
      specificProjectList: [...allData],
      token: this.state.token
    });
  }
  videoSeeAllContainer = {
    onPress: () => {
      this.setState({videoAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "video",
      });
    },
  };

  imageSeeAllContainer = {
    onPress: () => {
      this.setState({imageAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "image",
      });
    },
  };

  audioSeeAllContainer = {
    onPress: () => {
      this.setState({audioAll: true})
      this.props.navigation.navigate("SelectedFile", {
        type: "audio",
      });
    },
  };

  documentSeeAllContainer = {
    onPress: () => {
      this.setState({documentsAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "application",
      });
    },
  };

  imageAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.indexAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=image`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  audioAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.audiosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=audio`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  documentsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.documentsAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=application`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  videosAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.videosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=video`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentDidMount() {
    super.componentDidMount();
    this.imageAPI();
    this.videosAPI();
    this.audioAPI();
    this.documentsAPI();
    this.getcollectionlist();
    this.getPortfolioList(this.state.collection_id);
    
  }
 
  handleNavigateToSharePrjt =()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ShareProject');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  ProjectCount =() =>{
  
    const ProjectCount = this.state.metaObj.project_count ? this.state.metaObj.project_count : 0;
    return ProjectCount; 
  }

  LikesCount = () =>{
    const LikesCount = this.state.metaObj.like_count ? this.state.metaObj.like_count : 0;
    return LikesCount;
  }
  viewCount = (meta:any)=>{
    const viewCount = meta.view_count ? meta.view_count : 0;
    return viewCount;
  }

  saveCount =() =>{
    const saveCount = this.state.metaObj.save_count ? this.state.metaObj.save_count : 0;
    return saveCount;
  }

  handleallcount = () =>{
    const allcount = this.state.CollectionListTabs[0]?.count ? this.state.CollectionListTabs[0]?.count :0;
    return allcount;
  }

  handleCloseProfile = () => {
    this.setState({ openCollection: false });
  };
  HandleNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  getPortfolioList=async(collection_id:any)=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    this.setState({token:token})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCollectionPortpofolioApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPortfolioList+`collection_id=${collection_id||'nil'}&account_id=${this.props.id}&page=${this.state.portFolioPage}&per_page=6`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }

  handlePagination =() =>{
    this.setState({portFolioPage:this.state.portFolioPage+1},()=>{
      this.getPortfolioList(this.state.collection_id)
    })
  }

  handleCloseMenuList =()=>{
    this.setState({  anchorEl: null,Openmenu: false,})
  }
  handleOpenMenuList = (event:any) =>{
 
    this.setState({ anchorEl: event.currentTarget,
      Openmenu: true,})
  }
  handleRenameCollection=()=>{
   
    this.setState({ openCollection: true });
  }
  hanleupdateCollectionName =async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const Collection = {
      "collection": {
        "name": this.state.collection_Name
      }
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateCollectionNameApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateCollectionName+this.state.collection_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Collection)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   
  }
  handleDeleteCollection =async()=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteCollectionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteCollectionList+this.state.collection_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }

    

  showMoreItems = () => {
    if ((this.state.CollectionListTabs.length > 3 ) && this.state.moreBtn) {
      this.setState((prevState) => ({
        currentIndex: prevState.currentIndex + 1,
      }),()=>this.getcollectionlist());
      this.handleActivecollection({name:'All',id:'nil'});
    }else {
      this.setState({moreBtn:false})
    }
    
  };
  showLessItems =()=>{
    
      this.setState((prevState) => ({
        currentIndex: prevState.currentIndex - 1,
      }),()=>this.getcollectionlist());
      
    
    
  }
  getcollectionlist=async()=>{

    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token ,id} } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCollectionListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getcollectionlist+this.props.id+`?page=${this.state.currentIndex}&per_page=3`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } 
  handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
   
    this.setState({tabsValues:newValue,PaginationData:[]})
    
  };

  handleactivePort =(active:string) =>{
    this.setState({activecard:active})
    if (active === 'Portfolio') {
        this.getcollectionlist();
      this.getPortfolioList(this.state.collection_id);
    }
  }

  navigateInsitePage = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Analytics');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigationPage = (path:string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  handleSetting = () => {
    this.navigationPage("Settings2")
  }

}

// Customizable Area End
