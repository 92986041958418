import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider.web";
import React, { createRef, RefObject } from "react";
import { userLogo } from "./assets";
interface IProjectAttribute {
  id: number;
  project_name: string;
  username: string;
  account_id: number;
  cover_image_url: string;
  likes: number;
  views: number;
  saved_count: number;
}
interface IDesignAttribute {
  id: number;
  country: string;
  address: null;
  city: null;
  postal_code: string;
  account_id: number;
  photo: null;
  background_color: null;
  website_link: null;
  about: null;
  expertise_category: [];
  skills: [];
  languages: [];
  min_per_hour: null;
  max_per_hour: null;
  dribble: null;
  instagram: null;
  linkedin: null;
  member_since: string;
  followers_count: number;
  following_count: number;
  work_experience: never[];
  average_rate: null;
  user_role: {
    role_id: number;
    role_name: string;
  };
  profile_image_id: null;
  profile_cover_image: null;
  name: string;
  ratings: number;
  opportunities_count: number;
  views: number;
  likes: number;
  user_name: string;
  average_rate_per_hour: number;
  company_details: null;
}

interface IIDData {
  id: string;
  type: string;
}
interface IDesignerList extends IIDData {
  attributes: IDesignAttribute
}

interface IProjectList extends IIDData {
  attributes: IProjectAttribute
}

interface ISpecificProject {
  id: string | number, 
  cover_image_url: string, 
  likes: string | number, 
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}

interface IClientCardProps {
  id: string;
  type: string;
  attributes: {
      user_name: string;
      cover_image: string;
      profile_photo: string;
      company_name: string;
      location: string;
      full_name: string;
      rating: number;
      job_posted_count: number;
      background_color: string;
      new_opportunity_count: number;
  };
}

interface IInspirationData  {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      color_code: string;
      account_id: number;
      updated_at: string;
      created_at: string;
      user_name: string;
      user_role: string;
      profile_photo:string;
      profile_background_color: string;
      project_count: number;
      inspiration_cover_image: string;
  };
}

interface IInspirationToggleData {
  projects: number,
  followers: number,
  isFollowing: boolean,
  userName: string,
  backgroundColor: string,
  profilePhoto: string,
  accountId: number,
  inspirationId: number,
  inspirationName: string,
  userRole: string,
  followId?: string;
};

interface IPreviouseFilterData {
  isSliderChanged: boolean,
  selectedCreativeFeilds: RowInt[];
  selectedSkills: RowInt[];
  selectedElm: string | number,
  rateMin: number;
  rateMax: number;
  selectedRatings: number[];
  selectedLocation: string[];
  selectedOpportunity: string;
}
interface RowInt {
  id: string | number, 
  name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  openMulitCreateFeild: boolean;
  openMulitSkills: boolean;
  openSingle: boolean;
  allCategory: RowInt[];
  allSkillsList: RowInt[];
  dezinerToken: string;

  selectedCreativeFeilds: RowInt[];
  selectedSkills: RowInt[];
  selectedElm: string | number,
  rateMin:number;
  rateMax:number;
  isSliderChanged: boolean;
  selectedRatings: number[];
  selectedLocation: string[];
  selectedOpportunity: string;
  
  ratingObject: Record<string,string>;
  userRole: string;
  tabsList: {
    tabName: string;
    tabId: string;
  }[],
  activeTab: string;
  totalDesignersProfileCount: number;
  currentDesignerPage: number;
  totalDesingersPages: number;
  desingerProfileList:IDesignerList[];
  isOpen: boolean;
  openedData: null | IDesignAttribute ;
  isIdSelected: number;
  ratingOpen: boolean;
  rateOpen: boolean;
  opportunitiesOpen: boolean;
  search: string;
  initialLoading: boolean;
  projectsList: IProjectList[];
  inspirationProjects: IProjectList[];
  specificProjectList: ISpecificProject[];
  selectedProject: string | number;
  showDetails: boolean;
  clientCardList: IClientCardProps[];
  inspirationCardList: IInspirationData[];
  locationListingData:string[];
  isFiltersAlreadyApplied: boolean;
  isSkillsAlreadyChanged: boolean;
  isApplyButtonClickedOnce: boolean;
  previousFilterData: IPreviouseFilterData;
  insprirationBoardToggleDetails: IInspirationToggleData | null;
  backgroundColor: string;
  open: boolean;
  message: string;
  isInspirationProjectsLoading: boolean;
  action: "danger" | "success";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getCreativeFeildsId: string = "";
  getLocationsId: string = "";
  getSkillsId: string = "";
  designersSearchResultsId: string = "";
  getInspirationProjectsId: string = "";
  inspirationUnfollowId: string = "";
  inspirationFollowId: string = "";
  private perPage: number = 12;
  designerCardRef:  RefObject<HTMLDivElement> = createRef();
  onClickAwayRef: RefObject<HTMLDivElement> = createRef();
  onClickAwayRateRef: RefObject<HTMLDivElement> = createRef();
  inspirationsConRef: RefObject<HTMLDivElement> = createRef();
  private designersProfileObserver?: IntersectionObserver;
  debounceTimerId: null | NodeJS.Timeout = null;
  debounceTime: number = 350;
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      tabsList: configJSON.tabsData,
      openMulitCreateFeild: false,
      openMulitSkills: false,
      openSingle: false,
      selectedCreativeFeilds: [],
      selectedSkills: [],
      selectedElm: "",
      allCategory: [],
      allSkillsList: [],
      dezinerToken: "",
      rateMin:1000,
      rateMax:4000,
      isSliderChanged: false,
      selectedRatings: [],
      selectedLocation: [],
      selectedOpportunity: configJSON.opportunitiesListDesigner[0].value,
      ratingObject: {},
      userRole: "",
      totalDesignersProfileCount: 0,
      currentDesignerPage: 1,
      totalDesingersPages: 1,
      desingerProfileList: [],
      isOpen: false,
      openedData: null,
      isIdSelected: 0,
      search: "",
      ratingOpen: false,
      rateOpen: false,
      opportunitiesOpen: false,
      initialLoading: true,
      projectsList: [],
      selectedProject: '',
      specificProjectList: [],
      showDetails: false,
      clientCardList: [],
      inspirationCardList: [],
      locationListingData: [],
      inspirationProjects: [],
      isFiltersAlreadyApplied: true,
      isApplyButtonClickedOnce: false,
      isSkillsAlreadyChanged: false,
      previousFilterData: {
        selectedCreativeFeilds: [],
        selectedSkills: [],
        selectedElm: "",
        rateMin:1000,
        rateMax:4000,
        isSliderChanged: false,
        selectedRatings: [],
        selectedLocation: [],
        selectedOpportunity: configJSON.opportunitiesListDesigner[0].value,
      },
      insprirationBoardToggleDetails: null,
      backgroundColor: "",
      open: false,
      message: "",
      action: "success",
      isInspirationProjectsLoading: false,
      activeTab: "designer"
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getTokenFromLocalStorage();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.advancedsearchApiCallId) {
            this.showAlert("Alert", "API Error", "");
          }
        }
      }
      this.handleMessages(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getActiveTabBorder = (tabName: string) => {
    return this.state.activeTab === tabName ? "tabOuterCon gradientCon" : "tabOuterCon"
  }

  getActiveTabBackground = (tabName: string) => {
    return this.state.activeTab === tabName ? "activeTab tab" : "tab"
  }

  getActiveTabFont = (tabName: string) => {
    return this.state.activeTab === tabName ? "activeFont" : "inActiveFont"
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    return ({ requestCallId, response, apiErrorMessageData })
  }

  handleMessages = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message);
    if (requestCallId === this.getCreativeFeildsId) {
      this.handleSkillsData(message);
      return;
    }
    if(requestCallId === this.designersSearchResultsId){
      this.handleResults(message);
      return;
    }

    if(requestCallId === this.getSkillsId){
      this.handleSkillsList(message);
      return;
    }

    if(requestCallId === this.getLocationsId) {
      this.handleLocations(message);
      return;
    }

    if (requestCallId === this.getInspirationProjectsId) { 
      this.handleInspirationProjects(message);
      return
    }

    if(requestCallId === this.inspirationFollowId) {
      this.handleFollowInspirationMessage(message);
      return
    }

    if(requestCallId === this.inspirationUnfollowId) {
      this.handleUnFollowInspirationMessage(message);
    }
  }

  toggleMultiCreativeFeild = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openMulitCreateFeild: !this.state.openMulitCreateFeild,
      openSingle: false,
      openMulitSkills: false,
    });
    event.stopPropagation();
  }

  toggleMultiSkills = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openMulitSkills: !this.state.openMulitSkills,
      openMulitCreateFeild: false,
      openSingle: false,
    });
    event.stopPropagation();
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.onClickAwayRef.current && !this.onClickAwayRef.current.contains(event.target as Node)) {
      this.setState({
        ratingOpen: false,
        rateOpen: false,
        opportunitiesOpen: false,
      });
    }
  }

  handleClickOutSideOfMultiSelect = () => {
    this.setState({ 
      openMulitCreateFeild: false,
      openMulitSkills: false,
      openSingle: false,
      opportunitiesOpen: false,
    })
  }

  getTokenFromLocalStorage = async () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    let tokenDetail = await getStorageData("userInfo");
    let advancedSearch = await getStorageData("advancedSearch");
    const tokenParseData = JSON.parse(tokenDetail);
    const rolesData = JSON.parse(advancedSearch);
    let token: string = "";
    let userRole: string = "";
    let activeTab: string = "designer";
    let search: string = "";
    rolesData.role && (activeTab = rolesData.role);
    rolesData.search && (search = rolesData.search);
    if(tokenParseData && tokenParseData.data && tokenParseData.data.attributes) {
      userRole = tokenParseData.data.attributes.role_name;
    }
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);

      this.setState(prev => ({
        dezinerToken: token,
        userRole,
        activeTab,

        selectedElm: this.initializeSelectedElement(activeTab,userRole),
        previousFilterData: { ...prev.previousFilterData, selectedElm: this.initializeSelectedElement(activeTab, userRole), },
        search
      }), () => {
        this.getCreativeFeilds();
        this.getSkillsList();
        this.getDesingerSearchResults();
        this.getLocations()
      })
    } 
  }

  getLocations = () => {
    const message = this.createMessage(configJSON.locationList, configJSON.httpGetMethod)
    this.getLocationsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCreativeFeilds = () => {
    const message = this.createMessage(configJSON.getCategories, configJSON.httpGetMethod)
    this.getCreativeFeildsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  } 

  getSkillsList = () => {
    const message = this.createMessage(configJSON.skillsListEndPoint, configJSON.httpGetMethod)
    this.getSkillsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getInspirationProjectsDetails = (inspirationId: number) => {
    const endpoint = `${configJSON.inspirationUserProjects}/${inspirationId}`
    const message = this.createMessage(endpoint, configJSON.httpGetMethod)
    this.getInspirationProjectsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  toQueryParams = (object: Record<string , string | number | string[] | number[]>) => {
    const queryParams = Object.entries(object).
    map(([keyName,value]:[string,string | number | string[] | number[]])=>{ 
      if(Array.isArray(value)){
        return (value as (number | string)[]).map((each: number | string) => (`${keyName}[]=${each}`)).join("&");
      }
      return `${keyName}=${value}`
    })
    return queryParams.join("&");
  }

  getDesingerSearchResults = () => {
    const queryObject = Object.create({});
    queryObject["per_page"] = this.perPage;
    queryObject["page"] = this.state.currentDesignerPage;
    queryObject["search"] = this.state.search;
    (this.state.rateMin !== null && this.state.isSliderChanged) && (queryObject["rate[min]"] = this.state.rateMin);
    (this.state.rateMax !== null && this.state.isSliderChanged) && (queryObject["rate[max]"] = this.state.rateMax);
    (this.state.selectedCreativeFeilds.length > 0) && (queryObject["categories"] = this.state.selectedCreativeFeilds.map(category => category.id));
    (this.state.selectedSkills.length > 0) && (queryObject["skills"] = this.state.selectedSkills.map(skill => skill.id));
    (this.state.selectedRatings.length > 0) && (queryObject["rating"] = this.state.selectedRatings);
    (this.state.selectedElm !== "") && (queryObject["sort"] = this.state.selectedElm.toString().replace("Service Rate: ","").replace(/ /g,"_").toLowerCase());
    if(this.state.activeTab === "client") {
      queryObject["opportunities"] = this.state.selectedOpportunity;
      (this.state.selectedLocation.length > 0) && (queryObject["location"] = this.state.selectedLocation);
    }

    const queryString = this.toQueryParams(queryObject);
    const endPoint = `${this.getEndPoint(configJSON.designerEndPoint)}?${queryString}`;
    const message = this.createMessage(endPoint, configJSON.httpGetMethod);
    this.designersSearchResultsId = message.messageId;
    this.storeInLocalStorage();
    runEngine.sendMessage(message.id,message);
  }

  getEndPoint = (endPoint:string) => {
    switch (this.state.activeTab) {
      case "designer":
        return `${endPoint}/designer_search`;
      case "projects":
        return `${endPoint}/project_search`;
      case "client":
          return `${endPoint}/client_search`;
      default:
        return `${endPoint}/inspiration_search`;
    }
  }

  handleSkillsData = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (Array.isArray(responseJson.data)) {
        this.setState({ 
          allCategory: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string
        }) => ({id: item.id, name: item.name }))]})
      }
    }
  }

  handleSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      if (!responseJson.errors) {
      if (Array.isArray(responseJson.data)) {
        this.setState({ 
          allSkillsList: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string
        }) => ({id: item.id, name: item.name }))]})
      }
    }
  }

  handleLocations = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (Array.isArray(responseJson.locations)) {
      this.setState({
        locationListingData: responseJson.locations
      });
    }
  }

  handleResults = (message: Message) => {
    if (["projects", "inspiration"].includes(this.state.activeTab)) {
      this.handleProjectsResults(message);
      return;
    }

    if(this.state.activeTab === "client") {
      this.handleClientCardsResult(message);
      return;
    }

    this.handleDesingerSearchResults(message);
  }

  handleProjectsResults = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(!!responseJson.project_data && Array.isArray(responseJson.project_data.data)) {
      this.setState(prev => ({
        projectsList: [...prev.projectsList,...responseJson.project_data.data],
        initialLoading: false,
      }))
    }

    if(!isNaN(responseJson.projects_count)){
      this.setState({ totalDesignersProfileCount: responseJson.projects_count },() => {
        this.setState(prev=>({totalDesingersPages: Math.ceil(prev.totalDesignersProfileCount/this.perPage)}))
      })
    }

    if(!!responseJson.inspirations && Array.isArray(responseJson.inspirations.data)) {
      this.setState(prev => ({
        initialLoading: false,
        inspirationCardList: [...prev.inspirationCardList,...responseJson.inspirations.data],
      }))
    }

    if(!isNaN(responseJson.inspiration_count)){
      this.setState({ totalDesignersProfileCount: responseJson.inspiration_count },() => {
        this.setState(prev=>({totalDesingersPages: Math.ceil(prev.totalDesignersProfileCount/this.perPage)}))
      })
    }

    if(responseJson.status === 500 || !!responseJson.errors){
      this.setState(prev => ({
        projectsList: [],
        totalDesingersPages: 1,
        inspirationCardList: [],
        currentDesignerPage: 1,
        initialLoading: false,
      }))
    }
  }

  handleClientCardsResult = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!!responseJson.client_profiles && Array.isArray(responseJson.client_profiles.data)) {
      this.setState(prev => (
        { 
          clientCardList: prev.currentDesignerPage === 1 ? 
          responseJson.client_profiles.data : 
          [...prev.clientCardList,...responseJson.client_profiles.data],
          initialLoading: false,
         }
      )
      );
    }
    this.handleDesignerClientErrors(message); 
  }

  handleDesignerClientErrors = (message: Message) => {
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!!responseJson.rating_data) {
      this.setState({ ratingObject: responseJson.rating_data })
    }

    if (!!responseJson.profile_count) {
      this.setState({ totalDesignersProfileCount: responseJson.profile_count },() => {
        this.setState(prev=>({totalDesingersPages: Math.ceil(prev.totalDesignersProfileCount/this.perPage)}))
      })
    }

    if(responseJson.status === 500 || !!responseJson.errors){
      this.setState(prev => ({
        clientCardList: [],
        ratingObject: !prev.ratingOpen  ? {} : prev.ratingObject,
        totalDesingersPages: 1,
        currentDesignerPage: 1,
        initialLoading: false,
        desingerProfileList: [],
      }))
    }
  }

  handleDesingerSearchResults = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!!responseJson.designers_profiles && Array.isArray(responseJson.designers_profiles.data)) {
      this.setState(prev => (
        { 
          desingerProfileList: prev.currentDesignerPage === 1 ? 
          responseJson.designers_profiles.data : 
          [...prev.desingerProfileList,...responseJson.designers_profiles.data],
          initialLoading: false,
         }
      )
      );
    }

    this.handleDesignerClientErrors(message); 

  }

  handleInspirationProjects = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.inspirationsConRef.current) {
      this.inspirationsConRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
    }  
    if (!!responseJson.data) {
      let projects: IProjectList[] = [];
      if (!!responseJson.data.attributes && !!responseJson.data.attributes.project && Array.isArray(responseJson.data.attributes.project.data)) {
        projects = responseJson.data.attributes.project.data;
      }
      const newData = this.getInspirationBoardDetails(responseJson.data);
      if (!!responseJson.meta) {
        responseJson.meta.project_count && (newData.projects = responseJson.meta.project_count as number);
        responseJson.meta.follwers_count && (newData.followers = responseJson.meta.follwers_count);
        responseJson.meta.is_followed && (newData.isFollowing = responseJson.meta.is_followed);
        responseJson.meta.follow_id && (newData.followId = responseJson.meta.follow_id);
      }
      
      this.setState(prev => {
        return ({
          inspirationProjects: projects,
          insprirationBoardToggleDetails: { ...prev.insprirationBoardToggleDetails, ...newData },
          isInspirationProjectsLoading: false,
        })
      })  
      return;
    }

    if(!!responseJson.error || !!responseJson.errors) {
      this.setState({
        inspirationProjects: [],
        isInspirationProjectsLoading: false,
      })
    }
    
  }

  handleFollowInspirationMessage = (message:Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!!responseJson.meta && !!responseJson.meta.message) {
      if(!!this.state.insprirationBoardToggleDetails){
        this.setState({
          insprirationBoardToggleDetails: {
            ...this.state.insprirationBoardToggleDetails,
            followers: this.state.insprirationBoardToggleDetails.followers + 1,
            isFollowing: true,
          },
          open: true,
          message: responseJson.meta.message,
          action: "success",
        })
      }
      return;
    }
    this.handleFollowUnFollowErrorMessage(message);
   
  }

  handleUnFollowInspirationMessage = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!!responseJson.message) {
      if(!!this.state.insprirationBoardToggleDetails){
        this.setState({
          insprirationBoardToggleDetails: {
            ...this.state.insprirationBoardToggleDetails,
            followers: this.state.insprirationBoardToggleDetails.followers - 1,
            isFollowing: false,
          },
          open: true,
          message: responseJson.message,
          action: "success",
        })
      }
      return;
    }

    this.handleFollowUnFollowErrorMessage(message);
  }

  handleFollowUnFollowErrorMessage = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(Array.isArray(responseJson.errors)) {
      this.setState({
        open: true,
        message: responseJson.errors[0].message,
        action: "danger"
      })
    }
  }
  handleCloseToast =() => {
    this.setState({
      open: false,
      message: ""
    })
  }

  handleRatingFilter = (count:number) => {
    this.setState(prev => {
      let selectedRatings =  prev.selectedRatings.includes(count) ?  
      prev.selectedRatings.filter(rating => rating !== count) :
       [...prev.selectedRatings,count].sort((valueOne,valueTwo) => valueOne - valueTwo)
      return({
        selectedRatings,
        isFiltersAlreadyApplied: this.areFiltersChanged({
          ...prev.previousFilterData,
          selectedRatings
        }),
      })
    })
  }

  isRatingSelected = (count:number) => {
    return this.state.selectedRatings.includes(count);
  }

  areFiltersChanged = (newFilterData: IPreviouseFilterData) => {
    return JSON.stringify(this.state.previousFilterData) === JSON.stringify(newFilterData)
  }

  handleChangeCreativeFeilds = (newData: { id: string | number, name: string }[]) => {
    this.setState({
      selectedCreativeFeilds: newData,
      isFiltersAlreadyApplied: this.areFiltersChanged({ ...this.state.previousFilterData, selectedCreativeFeilds: newData, })
    });
  }

  handleChangeSkills = (newData: {id: string | number, name: string}[]) => {
    this.setState({
      selectedSkills: newData,
      isFiltersAlreadyApplied: this.areFiltersChanged({ ...this.state.previousFilterData, selectedSkills: newData, })
    });
  }

  filterFunction = (newData: RowInt) => (skill: RowInt) => skill.id !== newData.id;

  findFuntion = (newData: RowInt) => (skill: RowInt) => skill.id === newData.id;

  isSkillSelected = (newData: RowInt) => !!this.state.selectedSkills.find(this.findFuntion(newData));

  handleSkillsSelect = (newData: RowInt) => {
    this.setState(prev => {
      const selectedSkills = !!prev.selectedSkills.find(this.findFuntion(newData)) ?
        prev.selectedSkills.filter(this.filterFunction(newData)) :
        [...prev.selectedSkills, newData]
      return ({ 
        selectedSkills,
        isFiltersAlreadyApplied: this.areFiltersChanged({
          ...prev.previousFilterData,
          selectedSkills,
        })
      })
    })
  }

  handleSelectedProject = (data: IProjectList) => {
    const allData = [];
    for (const project of this.state.projectsList) {
      if (project.id !== data.id) {
        allData.push(project);
      }
      if (allData.length === 3) {
        break;
      }
    }
    allData.push(data);
    const newData:ISpecificProject[] = allData.map(project => (
      {
        cover_image_url: this.baseUrlStirng+project.attributes.cover_image_url,
        id: project.attributes.id,
        likes: project.attributes.likes,
        project_name: project.attributes.project_name,
        saved_count: project.attributes.saved_count,
        username: project.attributes.username,
        views: project.attributes.views
      }
    ))
    this.setState({
      showDetails: true,
      selectedProject: data.id,
      specificProjectList: [...newData],
    });
  }

  handleNavigateProfileScreen = (comment: { userId: string, user_role: string }, userId: string) => {
    let path = userId === comment.userId ? "UserProfile" : "PublicUserProfile"
    let localdata = {
      account_id: comment.userId,
      user_role: comment.user_role
    }
    if (path == "PublicUserProfile") {
      setStorageData("PublicId", JSON.stringify(localdata));
    }
    this.handleNavigation(path);
  }

  handleNavigation = (path: string) => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), 
      this.props);
    this.send(message);
  }

  handleNavigatePublicProfileScreen = async(clientData:IClientCardProps) => {
    let localdata = {
      account_id: clientData.id,
      user_role: clientData.type
    }
     await setStorageData("PublicId", JSON.stringify(localdata));
     this.handleNavigation("PublicUserProfile");
  }

  closeModal = (likes:number | string, views:number | string) => {
    this.handleChangeLikeCount(likes,views)
    this.setState({
      specificProjectList: [],
      selectedProject: '',
      showDetails: false
    })
  }

  handleChangeLikeCount = (likes:number | string, views:number | string) =>{
    let value = this.state.projectsList.map((element:IProjectList)=>{
      if(element.id === this.state.selectedProject){
        element.attributes.likes = likes as number
        element.attributes.views = views as number
      }
      return element
    })
    this.setState({projectsList:value})
  }

  handleRatingMenuOpen = () => { 
    this.setState({ 
      openSingle: false, 
      openMulitCreateFeild: false, 
      openMulitSkills: false,
      opportunitiesOpen: false,
      ratingOpen: true,
    })
  }

  handleRateMenuOpen = () => { 
    this.setState({ 
      openSingle: false,
      openMulitCreateFeild: false, 
      openMulitSkills: false,
      opportunitiesOpen: false,
      rateOpen: true,
     })
  }

  handleOpportunitiesMenuOpen = () => { 
    this.setState({ 
      openSingle: false,
      openMulitCreateFeild: false, 
      openMulitSkills: false,
      rateOpen: false,
      opportunitiesOpen: true,
     })
  }

  handleRangeSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    const newValue = value as number[]
    this.setState({
      rateMin: newValue[0],
      rateMax: newValue[1],
      isSliderChanged: true,
      isFiltersAlreadyApplied: this.areFiltersChanged({
        ...this.state.previousFilterData,
        rateMin: newValue[0],
        rateMax: newValue[1],
        isSliderChanged: true,
      }),
    })
  }

  handleOpportunitiesChange = (value: string) => {
    this.setState({
      selectedOpportunity: value,
      isFiltersAlreadyApplied: this.areFiltersChanged(
        {
          ...this.state.previousFilterData,
          selectedOpportunity: value,
        }
      ),
    })
  }

  toggleSingle = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ 
      openSingle: !this.state.openSingle, 
      openMulitCreateFeild: false,
      openMulitSkills: false, 
    });
    event.stopPropagation();
  }

  handleNavigateToLandingPage = () => {
    this.handleNavigation("LandingPage")
  }

  handleSelectChange = (newData: string | number) => {
    this.setState({
      selectedElm: newData,
      openSingle: false,
      isFiltersAlreadyApplied: this.areFiltersChanged({
        ...this.state.previousFilterData,
        selectedElm: newData,
      }),
    })
  }

  handleNavigations = async (item: { account_id: number, user_role: { role_name: string } }) => {
    let localdata = {
      account_id: item.account_id,
      user_role: item.user_role.role_name
    }

    await setStorageData("PublicId", JSON.stringify(localdata));
    this.handleNavigation("PublicUserProfile")
  }

  handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, idIndex: number) => {
    event.stopPropagation()
    if (this.state.isOpen) {
      return
    }
    this.setState({
      isIdSelected: idIndex,
      openedData: this.state.desingerProfileList[idIndex].attributes
    }, () => {
      this.setState({ isOpen: true })
    })
  }


  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter" && !event.shiftKey){
      this.handleSearchButtonClick();
    }
  }

  handleSearchButtonClick = () => {
    if(this.state.search.length > 2) {
      this.setState({
        currentDesignerPage: 1,
        desingerProfileList: [],
        initialLoading: true,
        projectsList: [],
        clientCardList: [],
        inspirationCardList: [],
      });
      setTimeout(()=> {
        this.getDesingerSearchResults();
      },100)
    }
  }

  handleTabChange = (tabName: string) => {
    this.setState({ 
      activeTab: tabName,
      desingerProfileList: [],
      currentDesignerPage: 1,
      totalDesingersPages: 1,
      totalDesignersProfileCount: 0,
      projectsList: [],
      clientCardList: [],
      initialLoading: true,
      isFiltersAlreadyApplied: true,
      insprirationBoardToggleDetails: null,
      inspirationProjects: [],
      inspirationCardList: [],
    }, () => {
      this.storeInLocalStorage();
      this.handleResetClick()
    })
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      search: event.target.value,
    },() => {
      if(this.state.search.length === 0) {
        this.handleCloseButton();
      }
    })
  }

  handleCloseButton = () => {
    this.setState({
      search: "",
      currentDesignerPage: 1,
      projectsList: [],
      clientCardList: [],
      inspirationCardList: [],
      desingerProfileList: [],
      initialLoading: true,
  
    },this.getDesingerSearchResults)
  }

  handleDesingersScrool = () => {
    if (!!this.designersProfileObserver) {
      this.designersProfileObserver.disconnect();
    }

    const userListCallBack = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (
          (this.state.activeTab === "desinger" && this.state.desingerProfileList.length < this.state.totalDesignersProfileCount) ||
          (this.state.activeTab === "projects" && this.state.projectsList.length < this.state.totalDesignersProfileCount) || 
          (this.state.activeTab === "client" && this.state.clientCardList.length < this.state.totalDesignersProfileCount) || 
          (this.state.activeTab === "inspiration" && this.state.inspirationCardList.length < this.state.totalDesignersProfileCount) 
        ) {
          this.setState(prev => ({ currentDesignerPage: prev.currentDesignerPage + 1, initialLoading: true }), () => {
            this.getDesingerSearchResults();
          })
        }
     }
    }

    this.designersProfileObserver = new IntersectionObserver(userListCallBack, { threshold: 0 });
    if (this.designerCardRef.current) {
      this.designersProfileObserver.observe(this.designerCardRef.current)
    }
  }

  storeInLocalStorage = async () => {
    await storage.set("advancedSearch", JSON.stringify({ role: this.state.activeTab, search: this.state.search }));
  }

  handleFilterApply = () => {
    const {
      isSliderChanged,
      rateMax,
      rateMin,
      selectedCreativeFeilds,
      selectedElm,
      selectedLocation,
      selectedOpportunity,
      selectedRatings,
      selectedSkills
    } = this.state;
    this.setState({
      desingerProfileList: [],
      currentDesignerPage: 1,
      totalDesingersPages: 1,
      totalDesignersProfileCount: 0,
      projectsList: [],
      initialLoading: true,
      isApplyButtonClickedOnce: true,
      isFiltersAlreadyApplied: true,
      isSkillsAlreadyChanged: false,
      clientCardList: [],
      inspirationCardList: [],
      previousFilterData: {
        isSliderChanged,
        rateMax,
        rateMin,
        selectedCreativeFeilds,
        selectedElm,
        selectedLocation,
        selectedOpportunity,
        selectedRatings,
        selectedSkills
      }
    },
      this.getDesingerSearchResults
    )
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.desingerProfileList.length > 0 && 
      (prevState.desingerProfileList.length !== this.state.desingerProfileList.length)){
      this.handleDesingersScrool()
    }

    if(this.state.projectsList.length > 0 && 
      (prevState.projectsList.length !== this.state.projectsList.length)){
      this.handleDesingersScrool()
    }

    if(this.state.clientCardList.length > 0 && 
      (prevState.clientCardList.length !== this.state.clientCardList.length)){
      this.handleDesingersScrool()
    }

    if(this.state.inspirationCardList.length > 0 && 
      (prevState.inspirationCardList.length !== this.state.inspirationCardList.length)){
      this.handleDesingersScrool()
    }
  }

  handleClose = () =>{
    this.setState({isOpen:false})
  }

  async componentWillUnmount(){
    document.removeEventListener("mousedown",this.handleClickOutside)
  }

  handleDisableResetButton = () => {
    const {
      selectedCreativeFeilds,
      selectedRatings,
      selectedSkills,
      selectedElm,
      isSliderChanged,
      search,
      selectedOpportunity,
      selectedLocation,
      isApplyButtonClickedOnce
    } = this.state;

   const isChanged = [
    selectedLocation.length,
    search.length,
    selectedCreativeFeilds.length,
    selectedRatings.length,
    selectedSkills.length].some(each => each !== 0)
    
    return (!isApplyButtonClickedOnce || !(
      isChanged || 
      ![configJSON.designerSortData[0],configJSON.clientSortData[0]].includes(selectedElm) || 
      isSliderChanged ||
      selectedOpportunity !== configJSON.opportunitiesListDesigner[0].value
    ) );

  }

  initializeSelectedElement = (activeTab: string = this.state.activeTab, role: string = this.state.userRole) => {
    if (activeTab === "client") return configJSON.clientSingleSelect[0];
    if (activeTab === "projects") {
      return configJSON.projectsSingleSelect[0];
    }
    if(activeTab === "inspiration") {
      return configJSON.inspirationSingleSelectData[0];
    }
    return role === "designer" ? configJSON.designerSortData[0] : configJSON.clientSortData[0];
  }

  getInspirationBoardDetails = (inspiration: IInspirationData) => {
    const insprirationBoardToggleDetails:IInspirationToggleData = Object.create({});
    if(inspiration.attributes){
      inspiration.attributes.account_id && (insprirationBoardToggleDetails.accountId = inspiration.attributes.account_id);
      inspiration.attributes.id && (insprirationBoardToggleDetails.inspirationId = inspiration.attributes.id);
      inspiration.attributes.user_name && (insprirationBoardToggleDetails.userName = inspiration.attributes.user_name);
      inspiration.attributes.profile_background_color && (insprirationBoardToggleDetails.backgroundColor = inspiration.attributes.profile_background_color);
      inspiration.attributes.project_count && (insprirationBoardToggleDetails.projects = inspiration.attributes.project_count);
      inspiration.attributes.profile_photo && (insprirationBoardToggleDetails.profilePhoto = inspiration.attributes.profile_photo);
      inspiration.attributes.name && (insprirationBoardToggleDetails.inspirationName = inspiration.attributes.name);
      inspiration.attributes.user_role && (insprirationBoardToggleDetails.userRole = inspiration.attributes.user_role);
      insprirationBoardToggleDetails.followers = 0;
      insprirationBoardToggleDetails.isFollowing = false;
      insprirationBoardToggleDetails.followId = "";
    }
    return insprirationBoardToggleDetails;
  }

  handleInspirationCardClick = (inspiration: IInspirationData) => {
    const insprirationBoardToggleDetails = this.getInspirationBoardDetails(inspiration);
      this.setState({
      insprirationBoardToggleDetails,
      isInspirationProjectsLoading: true,
    },
    this.getInspirationProjectsDetails.bind(this,insprirationBoardToggleDetails.inspirationId))
  }

  handleInspirationCardClose = () => {
    this.setState({
      insprirationBoardToggleDetails: null,
      inspirationProjects: [],
      isInspirationProjectsLoading: false,
    })
  }


  handleNavigateUserProfile = async (inspiration: IInspirationToggleData) => {
    let localdata = {
      account_id: inspiration.accountId,
      user_role: inspiration.userRole
    }

    await setStorageData("PublicId", JSON.stringify(localdata));
    this.handleNavigation("PublicUserProfile")
  }

  handleImageError = (event: React.SyntheticEvent<HTMLImageElement >) => {
    if(!!this.state.insprirationBoardToggleDetails && !!this.state.insprirationBoardToggleDetails.backgroundColor) {
      this.setState({
        backgroundColor: this.state.insprirationBoardToggleDetails.backgroundColor
      })
      return;
    }

    event.currentTarget.src = userLogo;
  }

  handleUnFollowInspiration = () => {
    const endpoint = `${configJSON.followUnFollowInspirationEndPoint}/${this.state.insprirationBoardToggleDetails?.inspirationId}`;
    const message = this.createMessage(endpoint,configJSON.httpDeleteMethod);
    this.inspirationUnfollowId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }

  handleFollowBtn = (data:number)=>{
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      "token": this.state.dezinerToken
    };
    const raw = JSON.stringify({
      "data": {
        "attributes": {
          "inspiration_id": data
        }
      }
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inspirationFollowId  = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  

  handleResetClick = () => {
    this.setState({
      selectedCreativeFeilds: [],
      selectedSkills: [],
      selectedRatings: [],
      rateMin: 1000,
      rateMax: 4000,
      search: "",
      isSliderChanged: false,
      currentDesignerPage: 1,
      initialLoading: true,
      desingerProfileList: [],
      projectsList: [],
      clientCardList: [],
      selectedElm: this.initializeSelectedElement(),
      selectedOpportunity: configJSON.opportunitiesListDesigner[0].value,
      selectedLocation: [],
      isApplyButtonClickedOnce: false,
      inspirationCardList: [],
      inspirationProjects: [],
      insprirationBoardToggleDetails: null,
      previousFilterData: {
        selectedSkills: [],
        selectedCreativeFeilds: [],
        rateMin:1000,
        selectedElm: "",
        isSliderChanged: false,
        rateMax:4000,
        selectedRatings: [],
        selectedOpportunity: configJSON.opportunitiesListDesigner[0].value,
        selectedLocation: [],
      },
    },this.getDesingerSearchResults
    )
  }

  handleLocationApplyFn = (selectedLocations: string[]): void => {
    this.setState({
      selectedLocation: selectedLocations,
      isFiltersAlreadyApplied: this.areFiltersChanged(
        {
          ...this.state.previousFilterData,
          selectedLocation: selectedLocations,
        }
      )
    })
  }

  getOpportinuties = () => this.state.userRole === "designer" ? configJSON.opportunitiesListDesigner : configJSON.opportunitiesListForClient

  getInspirationToggleData = (keyId: keyof IInspirationToggleData) => {
    if(this.state.insprirationBoardToggleDetails && this.state.insprirationBoardToggleDetails[keyId]){
      if (keyId === "inspirationName") {
        return this.state.insprirationBoardToggleDetails[keyId][0].toUpperCase()+this.state.insprirationBoardToggleDetails[keyId].slice(1)
      }
     return this.state.insprirationBoardToggleDetails[keyId]
    }else {
      if( keyId === "followers" || keyId === "projects") {
        return 0;
      }

      if(keyId === "isFollowing") return false;

      return "";
    }
  }

  // Customizable Area End
}
