
import { Box, styled,Typography,Grid,Menu,MenuItem,Chip,Divider} from '@material-ui/core';
import {avatar} from "../../blocks/catalogue/src/assets"
import StarIcon from '@material-ui/icons/Star';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'
const config = require("../../framework/src/config.js");
const mainlogo=require('./Treesss.png')
import ProjectPortfolioExpCard from './ProjectPortfolioExpCard.web'
import MoreVertIcon from '@material-ui/icons/MoreVert';
export interface Props {
data:any
hadledeltemodal:any;
}

interface Data {
  
}

interface Skills {
  
}

interface S {
  dummyData:any;
  anchorEl:any;
  Openmenu:boolean;
  newportfoliodata:any;
  

}

interface SS {
}

export default class ProfileCard extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props)

    this.state = {
      dummyData:[{
        attributes:{
          project_images:['/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdllOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a4d8f4b31cda72c74853ddbc663c0f929f4148a/textlabel_3.png'],
          project_name:'GolbalSearch',
          saved_count:2,
          likes:1,
          username:"NoName",
          views:3,
        }
      }],
      anchorEl:'',
      Openmenu:false,
      newportfoliodata:[{
        
      }]
    }

  }

  

  MainWrapper = styled(Box)(({ theme }) => ({
   '& .CardHeaders' :{
    display:'flex',
    justifyContent:"space-between",
    margin:'10px 0px'
   },
   '& .Allcardsmargins' :{
    margin:"20px 0px"
   },

   '& .CardIdentity' :{
    fontSize:"20px",
    fontWeight:600,
    fontFamily:'Inter,sans-serif',
    color:'#FFF2E3'
   },
   '& .Company_Name':{
    fontFamily:'Inter,sans-serif',
    fontSize:'16px',
    fontWeight:500,
    color:'#757171'
   },
   '& .IdentityPlace':{
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Inter,sans-serif",
    color:"#FFFFFF",
    background:"#2F2F2F",
    borderRadius:"25px",
    padding:"2px 20px"
   },
   '& .IdentityActive':{
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Inter,sans-serif",
    color:"#FFC29F",
    background:"#2F2F2F",
    borderRadius:"25px",
    padding:"2px 20px"
   },
   '& .IdetitySince' :{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:"Inter,sans-serif",
    color:"#FFFFFF",
    textAlign:'end',
    
   },
   '& .RightsideIdentity':{
    display:"flex",
    gap:'10px'
   },
   '& .CardDescription':{
    fontSize:"16px",
    fontWeight:600,
    color:"#8C8C8C",
    fontFamily:"Inter,sans-serif",
   }
   ,
   '& .IdentitySkills':{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Inter,sans-serif",
    color:'#535353',
    marginBottom:"10px"
   },
   '& .SkillListStyles' :{
    display:'flex',
    gap:'10px',
    flexWrap:'wrap',
    height:'140px',
    overflowY:'scroll',
    scrollbarWidth:'none',

   },
   '& .IdentitySkillList':{
    fontSize:'12px',
    fontWeight:600,
    fontFamily:"Inter,sans-serif",
    background:'#1F1F1F',
    borderRadius:'25px',
    padding:"10px 10px",
    width:'fit-content',
    whiteSpace:'nowrap',
    height:'20px'
   },
   '& .Identitylinks':{
    fontSize:"20px",
    fontWeight:600,
    fontFamily:"Inter,sans-serif",
    color:"#498EFF",
    textDecorationLine:"underline"
   },
   '& .cursor-pointer' : {
    cursor: 'pointer',
  },


  }));
  handleOpenListMenu = (event:any) =>{
    this.setState({ anchorEl: event.currentTarget,
      Openmenu: true,})
  }

  handleCloseListMenu =()=>{
    this.setState({  anchorEl: null,Openmenu: false,})
  }



  handlePosition =()=>{
    let position = this.props.data?.attributes.position ? this.props.data.attributes.position : 'No Position';
    return position
  }

  handleCompanyName =() =>{
    let companyName = this.props.data?.attributes.company_name ? this.props.data?.attributes.company_name :"Company Name"
    return companyName
  }
  handleLocation =()=>{
    let city = this.props.data?.attributes.city
    let locaton = city ? city.charAt(0).toUpperCase() + city.slice(1) :"location"
    return locaton
  }
  handleStartDate =() =>{
    let startYear = this.props.data?.attributes.starting_from_year ? this.props.data.attributes.starting_from_year :"start"
    return startYear
  }
  handleEndYear =() =>{
    let endYear = this.props.data?.attributes.ending_in_year ? this.props.data?.attributes.ending_in_year : 'Present';
    return endYear
  }
  handleDetails =() =>{
    let details = this.props.data?.attributes.details ? this.props.data?.attributes.details :'--'
    return details
  }

  handledeleteclick =()=>{
    this.props.hadledeltemodal(this.props.data)
    this.handleCloseListMenu()
  }
  
  render() {
    console.log('tttttttttttttttt',this.props.data)
     return (
      <this.MainWrapper>
        <Box>
            <Box className='CardHeaders'>
                <Box >
                <Typography className='CardIdentity'>{this.handlePosition()}</Typography>
                <Typography className='Company_Name'>{this.handleCompanyName()}</Typography>
                </Box>
                <Box style={{display:'flex'}}>
                  <Box>
                  <Box className='RightsideIdentity'>
                        <Typography className='IdentityPlace'>{this.handleLocation()}</Typography>
                        <Typography className='IdentityActive'>{this.handleStartDate()}-{this.handleEndYear()}</Typography>
                        
                        
                    </Box>
                    <Box style={{margin:'15px 0px'}}>
                        <Typography className='IdetitySince'>{this.props.data.attributes.calculate_years} </Typography>
                    </Box>
                  </Box>
                   
                    <MoreVertIcon onClick={this.handleOpenListMenu} className="cursor-pointer"/>
                    <Menu
                  id="long-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={this.state.Openmenu}
                  onClose={this.handleCloseListMenu}
                  data-testId="closeMenuTestId"
                  PaperProps={{
                    style: {
                      width: '200px',
                      border: '1px solid rgb(89, 89, 89)',
                      color: 'rgba(255, 242, 227, 1)',
                      background: 'rgba(17, 17, 17, 1)'
                    },
                  }}
                >
                  <MenuItem data-testId="rename_collectionBtn" >
                   Edit Experience
                  </MenuItem>
                  <MenuItem onClick={()=>this.handledeleteclick()} data-testId="delete_collectionBtn"  >
                  Delete Experience
                  </MenuItem>
                </Menu>
                </Box>

            </Box>
            <Box className='Allcardsmargins'>
                <Typography className='CardDescription'>{this.handleDetails()}</Typography>
            </Box>
            <Box className='Allcardsmargins'>
              <Typography className='IdentitySkills'>Skills</Typography>
              <Box className='SkillListStyles'>
              {this.props.data?.attributes.skills.length > 0 ?
            (
              this.props.data?.attributes.skills.map((items:any,index:number)=>{
                return (
                  <Typography key={index} className='IdentitySkillList'>{items.name}</Typography>
                )
              })
            )  : (
              <>
               <Typography className='IdentitySkillList'>No skills added</Typography>
              </>
            )
            }
            </Box>

           
            </Box>
            <Box className='Allcardsmargins' >
              {this.props.data?.attributes.projects.length > 0 ? 
              <>
              <Typography className='IdentitySkills' >Project from DK</Typography>  
              <Box style={{display:'flex',gap:'25px',overflowX:'scroll',scrollbarWidth:'none',overflowY:'hidden'}}>
                {this.props.data?.attributes.projects.map((item:any,index:number)=>{
                 
                  return (
                    <>
                    {item.projects.length > 0 ?
                    <>
                    {item.projects.map((childdata:any,index:number)=>{
                     
                      return (
                        <> 
                         <Grid key={index} style={{maxHeight:"300px"}} >
                    <ProjectPortfolioExpCard  data={childdata} deleteIcon={false} handledeletefn={()=>console.log("")} />
                    </Grid>
                        </>
                      )
                    })}
                    </>
                    
                  :''}

                    </>
                  )
                })}
              </Box>
             
              </>
              :""}
              

            </Box>
            <Box className='Allcardsmargins' >
              {this.props.data?.attributes.reference_links.length > 0 ?
            (<>
             <Typography className='IdentitySkills'>Reference Links</Typography>
             <Box style={{display:'flex',gap:'25px',flexWrap:'wrap'}}>
             {this.props.data?.attributes.reference_links.map((item:any,index:number)=>{
              return (
              <>
                <a 
                  href={item} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  key={index}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography className="Identitylinks">{item}</Typography>
                </a>
              </>)
             })}
             </Box>
             
            </>)
            :""  
            }
             
            </Box>
            <Box style={{marginTop:"3%",marginBottom:"3%"}}>

            <hr />
            </Box>
            
        </Box>
      </this.MainWrapper>
    )
  }
}
