export const searchText = require("../assets/searchText.svg");
export const leftIcon = require("../assets/leftIcon.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const checkIcon = require("../../assets/checkIcon.svg");
export const unCheckIcon = require("../../assets/uncheckIcon.svg");
export const dropIcon = require("../../assets/solidDropIcon.svg");
export const musicIcon = require("../assets/musicIcon.svg");
export const noResultsFound = require("../assets/noResultsFound.svg");
export const closeIcon = require("../../assets/closeIcon.svg");
export const featureIcon = require("../../assets/saveIcon.svg");
export const likeIcon = require("../../assets/likeIcon.svg");
export const eyeIcon = require("../../assets/viewIcon.svg");
export const rightBackIcon = require("../assets/rightArrow.svg");
export const followIcon = require("../assets/followImg.png");
export const unfollowIcon = require("../assets/UnfollowIcon.png");
export const userLogo = require("../assets/ic_userlogo.png");
