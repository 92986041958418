// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import storage from "../../../../framework/src/StorageProvider.web";
import dayjs, { Dayjs } from "dayjs";
import { compressedImageData } from "../../../../components/src/Base64Conversion";
import React, { RefObject } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { NumberArray } from "react-native-svg";

export const configJSON = require("../config");

let tokenData = "";
export interface Props {
  navigation: string;
  id: string;
  activeTab?: string
}

interface ListType {
  id: string | number,
  name: string
}

interface FileWithType {
  filename: string;
  data: string;
}
interface CreateWorkOpp {
  work_opportunity:{
    title: string;
    work_type: string;
    files: FileWithType[];
    files_or_links: string;
    description: string;
    deliverables: string;
    experience_level: string;
    rate_type: string;
    required_hours_per_week: number | string; 
    project_timeline: number |string; 
    project_timeline_type: string;
    location: string | string[];
    rate_amount: number|string;
    agree_to_terms_and_conditions: boolean;
    project_ids:  number[];
    question: string;
    start_date: Dayjs;
    skill_ids: string| number[]; }
}
export interface WorkType {
  name: string;
  value: string;
  details: string
}

export interface ExperienceLevel {
  name: string;
  value: string;
  details: string
}

export interface Response {
  id: string | number,
  cover_image_url: string,
  likes: string | number,
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number,
  board_id?: number,
  attributes?: {cover_image_url?:string,project_name?:string,username?:string,views?:number,likes?:number,savedCount?:number}
}

export interface ProjectCountsType {
  [key: number ]: number ;
};

interface S {
  errorMessage: string,
  warningMessage: string,
  message: string,
  action: string,
  title: string,
  titleError: string
  description: string,
  selectedSkillTags: ListType[],
  selectLocation: ListType[],
  skillList: ListType[],
  open: boolean,
  searchText: string,
  searchSkillList: ListType[],
  workType: string,
  openSingle: boolean,
  selectedElm: string,
  openLocation: boolean,
  selectedLocation: string,
  conditionChecked: boolean,
  deliverables: string,
  workExperience: string,
  files: File[],
  files_or_links: string,
  location: string,
  locationError : string,
  projectTimeline: string,
  workHour: string,
  rateData: string,
  overallRate: any,
  dateData: Dayjs,
  showModal: boolean,
  projectList: Response[],
  selectedProjectList: Response[],
  openBoard: string,
  tempSelectedProjectList: Response[],
  projectCounts: ProjectCountsType,
  activeTab: string,
  linkList: string[],
  questionList: string[],
  openCalender: boolean,
  postOpportunityLoader: boolean,
  boardName: string;
  locationList: ListType[];
  address: string;
  locationDisable: boolean;
  ProjectTimeError:string,
  multiLocationData: Array<string>;
  BudgetErrorMsg:number | string;
  boardListing : object[]
  boardProjects : {id:string,type:string,attributes:{project:{data:object[]}}} | null,
  isModelOpen: boolean;
  descCountCharacter: number;
  deliverableCountCharacter: number;
}

interface SS {
  id: string;
}

export default class PostOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  delayTimer: any;
  refElm: RefObject<HTMLInputElement>;
  modalRef: RefObject<HTMLDivElement>;
  apiSearchSkillsCallId: string = "";
  apiSkillsCallId: string = "";
  apiGetBoards: string = "";
  apiGetBoardsProjects: string = ""
  apiDiscoverListCallId: string = "";
  apiPostOpportunityCallId: string = "";
  apiSaveDraftCallId: string = '';
  InspirationFollowingBoardsApiCallId:string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      message: "",
      action: "",
      title: "",
      titleError: '',
      description: "",
      selectedSkillTags: [],
      skillList: [],
      open: false,
      searchText: "",
      searchSkillList: [],
      workType: "",
      selectedElm: "Weeks",
      openSingle: false,
      openLocation: false,
      selectedLocation: "",
      conditionChecked: false,
      deliverables: "",
      workExperience: "",
      files: [],
      files_or_links: "",
      location: "location",
      locationError:"",
      projectTimeline: "",
      workHour: "",
      rateData: "",
      overallRate: "",
      dateData: dayjs(),
      showModal: false,
      projectList: [],
      selectedProjectList: [],
      openBoard: "",
      tempSelectedProjectList: [],
      projectCounts:{},
      activeTab: "myBoard",
      linkList: [],
      questionList: [],
      openCalender: false,
      errorMessage: '',
      warningMessage: '',
      ProjectTimeError:'',
      postOpportunityLoader: false,
      boardName: "",
      selectLocation: [],
      locationList: [],
      address: "",
      locationDisable: false,
      multiLocationData: [],
      BudgetErrorMsg : "",
      boardListing: [],
      boardProjects: {id:"",type:"",attributes:{project:{data:[]}}},
      isModelOpen:false,
      descCountCharacter:0,
      deliverableCountCharacter:0
    };
    this.refElm = React.createRef<HTMLInputElement>();
    this.modalRef = React.createRef();
    this.delayTimer = null;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiSkillsCallId) {
          this.handleSkillsListVal(message);
        } else if (apiRequestCallId === this.apiSearchSkillsCallId) {
          this.handleSearchSkillsListVal(message);
        } else if (apiRequestCallId === this.apiDiscoverListCallId) {
          this.handleDiscoverProjectList(message);
        } else if (apiRequestCallId === this.apiPostOpportunityCallId) {
          this.handlePostOpportunity(message);
        } else if (apiRequestCallId === this.apiGetBoards){
          this.handleBoardList(message)
        } else if (apiRequestCallId === this.apiGetBoardsProjects){
          this.handleBoardProjects(message)
        } else if (apiRequestCallId === this.apiSaveDraftCallId) {
          this.handleSaveDraft(message);
        } else if (apiRequestCallId === this.InspirationFollowingBoardsApiCallId) {
          this.followinspiration(message)
        } 
      }
    }
  }

  followinspiration =(message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.errors){
      this.setState({boardListing: responseJson.data})
    }else {
      this.handleErrorMSg(message)
      this.setState({boardListing:[], open: true,
        message: 'Not following any boards',
        action: "danger"})
    }
  }
  handleErrorMSg = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      open: true,
      postOpportunityLoader: false,
      message: responseJson.errors[0].error,
      action: "danger"
    });
  }

  handleErrorOnly = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      open: true,
      postOpportunityLoader: false,
      message: responseJson.error,
      action: "danger"
    });
  }


  handleSearchSkillsListVal = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ searchSkillList: dataVal.map((item: { id: string | number, name: string }) => ({ id: item.id, name: item.name })) });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleSkillsListVal = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ skillList: [...dataVal.map((item: { id: string | number, name: string }) => ({ id: item.id, name: item.name }))] });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleBoardList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors){
      this.setState({boardListing: responseJson.data})
    }else {
      
      this.setState({boardListing:[],open: true,
        message: 'No boards created',
        action: "danger"})
    }
  }

  handleBoardProjects = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!responseJson.error){
      this.setState({boardProjects:responseJson.data})
    }else {
      console.log("dddddddddddddddddddd")
      this.handleErrorOnly(message)
    }
  }

  handleSaveDraft = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors){
      this.setState(() => ({
        ...this.state,
        open: true,
        postOpportunityLoader: false,
        action: "success",
        message: "Work opportunity drafted sucessfully",
        title: ""
      }), async () => {
        await setStorageData("dropdown", JSON.stringify({
          "id": 5,
          "name": "Drafts",
          "color": "grey",
          "value": "draft"
        }))
        setTimeout(() => {
          this.redirectTo("MyWork");
        }, 2000);
      });
    }else {
      this.handleErrorMSg(message)
    }
  }

  handlePostOpportunity = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      this.setState(() => ({
        ...this.state,
        open: true,
        postOpportunityLoader: false,
        action: "success",
        message: "Work opportunity posted sucessfully",
        title: ""
      }), () => {
        setTimeout(() => {
          this.redirectTo("LandingPage");
        }, 2000);
      });
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleDiscoverProjectList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson || '';
      if (dataVal) {
        this.setState({ projectList: [...dataVal.projects] });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  commonGetApiCall = (variableName: string, urlEndPoint: string) => {
    const headerObj = {
      "token": tokenData,
      "Content-Type": "application/json"
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (variableName === "discoverList") {
      this.apiDiscoverListCallId = requestMessage.messageId;
    }
    if (variableName === "skills") {
      this.apiSkillsCallId = requestMessage.messageId;
    }
    if (variableName === 'searchSkills') {
      this.apiSearchSkillsCallId = requestMessage.messageId;
    }
    if (variableName === 'boards'){
      this.apiGetBoards = requestMessage.messageId
    }
    if (variableName === 'boardProjects') {
      this.apiGetBoardsProjects = requestMessage.messageId
    }
    if (variableName === 'handleFollowBoard') {
      this.InspirationFollowingBoardsApiCallId=requestMessage.messageId
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  commonPostApiCall = (urlEndPoint: string, bodyData: CreateWorkOpp) => {
    const headerObj = {
      "token": tokenData,
      'Content-Type':'application/json'
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostOpportunityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiPostMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ postOpportunityLoader: true });
  }

  
  handleFollowingBoard = async() =>{
    const tokens = await getStorageData("userInfo");  
    let newToken = JSON.parse(tokens)
    const {meta: { id} } = newToken;
    this.commonGetApiCall('handleFollowBoard', `${configJSON.InspirationFollowingBoards}+page=1&per_page=8&account_id=${id}`);
   
   
  }


  handleTab = (data: string) => {
    if (data=='myBoard') {

      this.commonGetApiCall('boards', configJSON.getBoardsEndPoint)
    }
    if (data == 'followingBoard') {
      this.handleFollowingBoard()
    }
    this.setState({
      activeTab: data
    })
  }

  openCalender = (event: any) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }

  isnumericVal = (data: string) => data === "" || /^[0-9\b]+$/.test(data);

  handlelinkData = (event: any) => {
    const keyData = event.keyCode ? event.keyCode : event.which
    if (keyData === 13 && this.isUrlValid(this.state.files_or_links)) {
      this.setState({
        linkList: [...this.state.linkList, this.state.files_or_links],
        files_or_links: ""
      })
    }
  }

  toggleCondition = () => {
    this.setState({ conditionChecked: !this.state.conditionChecked })
  }

  handleSelectChange = (data: string) => {
    this.setState({ selectedElm: data, projectTimeline: '' });
  }

  updateLink = (data: string) => {
    this.setState({
      files_or_links: data
    });
  }



  updateWorkHours = (data: string) => {
    if (this.isnumericVal(data)) {
      if (data.length <= 2) {
        const hours = parseInt(data, 10);
        if (data === '' || (hours >= 0 && hours <= 99)) {
          this.setState({
            warningMessage: hours > 56 ? 'You cannot enter more than 56 hours.' : '',
            workHour: data
          });
        }
      }
    }
  };
  
  updateRate = (data: string) => {
    let budgetdata = Number(data)
    if (this.isnumericVal(data)) {
      if (budgetdata > 5000) {
        this.setState({
          overallRate: "",
          BudgetErrorMsg: 'Hourly rate must be up to 5000'
        })
      } else {
        this.setState({
          rateData: data,
          overallRate: "",
          BudgetErrorMsg: ''
        })
      }
    }
  }

  updateOverallRate = (data: string) => {
    if (this.isnumericVal(data)) {
      if (Number(data) > 9999999){
        this.setState({
          rateData: "",
          BudgetErrorMsg : 'Overall budget must be less than 99,999,99'
        });
      }else{
        this.setState({
          overallRate: data,
          rateData: "",
          BudgetErrorMsg : '',
        });
      }
      
    }
  }

  validFileFormat = (fileData: File) => {
    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "video/mp4",
      "video/mov",
      "video/mkv",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    if (!validFileTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid file (jpg, png, mp4, mov, mkv, pdf, doc, docx).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleWorkType = (value: string) => {
    this.setState({ workType: value });
  };

  handleWorkExperience = (value: string) => {
    this.setState({ workExperience: value });
  };

  handleClassess = () => {
    return this.state.selectedSkillTags.length ? "search-field main-text" : "search-field main-text h-63";
  }

  handleViewCheck = (item: Response,data:number) => {
    const matchedData = this.state.tempSelectedProjectList.find(project => project.id === item.id && project.board_id === data);
    return matchedData ? true : false
  }

  showModalData = () => {
    this.setState({
      showModal: true,
      tempSelectedProjectList: this.state.selectedProjectList
    }, () => {
      if (this.modalRef.current) {
        this.modalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      this.commonGetApiCall('boards', configJSON.getBoardsEndPoint)
    })
  }
  getBoardsProjects = (boardId:string) =>{
    this.commonGetApiCall("boardProjects",`${configJSON.getBoardsEndPoint}/${boardId}`)
  }
  
  
  toggleSelectProject = (item: Response, data: number) => {
    const { tempSelectedProjectList, projectCounts } = this.state;
  
    const isSelected = tempSelectedProjectList.some(project => project.id === item.id && project.board_id === data);
  
    const updatedProjectCounts = {
      ...projectCounts,
      [data]: isSelected
        ? Math.max(projectCounts[data] - 1, 0)  
        : (projectCounts[data] || 0) + 1         
    };
  
    const updatedTempSelectedProjectList = isSelected
      ? tempSelectedProjectList.filter(project => project.id !== item.id || project.board_id !== data) 
      : [...tempSelectedProjectList, { ...item, board_id: data }]; 
  
    
    this.setState({
      tempSelectedProjectList: updatedTempSelectedProjectList,
      projectCounts: updatedProjectCounts
    });
  };
  

  addProject = () => {
    this.setState({
      selectedProjectList: [...this.state.tempSelectedProjectList],
      tempSelectedProjectList: [],
      projectCounts:{},
      showModal: false
    });
    this.handleAccordian("");
  }

  isFileAdded = () => {
    return this.state.files.length > 0 ? true : false;
  }

  isProjectSelected = () => {
    return this.state.selectedProjectList.length > 0 ? true : false;
  }

  isSkillAdded = () => {
    return this.state.selectedSkillTags.length > 0 ? true : false;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      tempSelectedProjectList: [],
      projectCounts:{}
    });
    this.handleAccordian("");
  }

  async getDataList() {
    this.commonGetApiCall('skills', configJSON.getSkillEndPoint);
    this.commonGetApiCall('discoverList', `${configJSON.discoverList}?page=1`);
  }

  handleTitleChange = (data: string) => {
    this.setState({ title: data, titleError: '' })
  }

  handleChange = (data: string) => {
    this.setState({ searchText: data });
    // Clear previous delay timer
    clearTimeout(this.delayTimer);
    // Set delay for the next event
    this.delayTimer = setTimeout(() => {
      // Make API call only if searchTerm is not empty
      if (data.trim() !== "" && data.trim().length >= 2) {
        this.search(data.trim());
      } else {
        this.setState({ searchSkillList: [] })
      }
    }, 300); // Delay time: 300 milliseconds
  };
  
  handleCancel = () =>{
    this.setState({ searchText: "",searchSkillList:[] })
  }
  search = (data: string) => {
    this.commonGetApiCall('searchSkills', `${configJSON.searchSkillEndPoint}?search_term=${data}`);
  };

  handleDescriptionChange = (data: string) => {
    this.setState({ description: data })
  }

  handleDeliverablesChange = (data: string) => {
    this.setState({ deliverables: data })
  }

  toggleSingle = (event: any) => {
    this.setState({ openSingle: !this.state.openSingle, openLocation: false });
    this.handlePropagation(event);
  }

  toggleLocation = (event: any) => {
    this.setState({ openLocation: !this.state.openLocation, openSingle: false });
    this.handlePropagation(event);
  }

  handleAddSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: [data, ...this.state.selectedSkillTags], skillList: this.state.skillList.filter(item => item.id !== data.id) });
  }

  handleRemoveSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: this.state.selectedSkillTags.filter(item => item.id !== data.id), skillList: [data, ...this.state.skillList] });
  }

  handleShowDiscard = () => {
    if (
      this.state.title ||
      this.state.description ||
      this.state.selectedSkillTags.length ||
      this.state.workType ||
      this.state.conditionChecked ||
      this.state.location != "location" ||
      this.state.deliverables ||
      this.state.workExperience ||
      this.state.files.length ||
      this.state.linkList.length ||
      this.state.projectTimeline ||
      this.state.workHour ||
      this.state.rateData ||
      this.state.overallRate ||
      this.checkForDate() ||
      this.state.selectedProjectList.length ||
      this.state.questionList.length
    ) {
      return "flex";
    } else {
      return "none"
    }
  }

  checkForDate = () => {
    const currentData = dayjs().format("L");
    const existData = this.state.dateData.format("L");
    if (existData !== currentData) {
      return true
    } else {
      return false
    }
  }

  locationSelectedElm = (data: string) => {
    return this.state.location ? (this.state.location === data) : false;
  }

  enablePostWorkOpportunity = () => {
    if (
      this.state.title &&
      this.state.description &&
      this.state.selectedSkillTags.length &&
      (Number(this.state.rateData) > 0 || this.state.overallRate > 0) &&
      Number(this.state.projectTimeline) > 0 &&
      Number(this.state.workHour) > 0 &&
      this.state.location &&
      this.state.selectLocation &&
      this.state.workExperience &&
      this.state.deliverables &&
      this.state.conditionChecked
      && !this.state.warningMessage
      && !(this.state.multiLocationData.length === 0 && this.state.location === "location")
      && this.state.BudgetErrorMsg === ""
    ) {
      return true
    } else {
      return false
    }
  }

  enablebuttonText = () => {
    return this.enablePostWorkOpportunity() ? "secondary-btn" : "disabled-btn";
  }

  enableProjectAddBtn = () => {
    return this.state.tempSelectedProjectList.length ? false : true
  }

  enableTabBG = (data: string) => {
    return (this.state.activeTab === data) ? "secondary-text-active" : "disabled-text";
  }

  enableProjectAddBG = () => {
    return this.enableProjectAddBtn() ? "disabled-btn" : "secondary-btn";
  }

  isUrlValid = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      this.setState({
        open: true,
        message: "Please enter valid link",
        action: "danger"
      })
      return false;
    }
  }

  discardChanges = () => {
    this.setState({
      title: "",
      description: "",
      selectedSkillTags: [],
      message: "",
      action: "",
      open: false,
      searchText: "",
      searchSkillList: [],
      workType: "",
      conditionChecked: false,
      selectedLocation: "",
      workExperience: "",
      deliverables: "",
      selectedElm: "Weeks",
      files: [],
      files_or_links: "",
      location: "location",
      projectTimeline: "",
      workHour: "",
      rateData: "",
      overallRate: "",
      dateData: dayjs(),
      selectedProjectList: [],
      linkList: [],
      questionList: []
    });
  }

  addQuestion = () => {
    this.setState({
      questionList: [...this.state.questionList, ""]
    })
  }

  handleQuestionData = (data: string, index: number) => {
    const indexData = [...this.state.questionList];
    indexData[index] = data;
    this.setState({
      questionList: [...indexData]
    })
  }

  removeQuestion = (data: string, index: number) => {
    this.setState({
      questionList: this.state.questionList.filter((item, index1) => index1 !== index)
    })
  }

  handleWorkHoursValidation = () => {
    if (this.state.workHour && (Number(this.state.workHour) > 168)) {
      return false
    }
    return true
  }

  handleProjectTimelineValidation = () => {
    const timeLineData = this.state.projectTimeline ? Number(this.state.projectTimeline) : 0;
    if (timeLineData && (this.state.selectedElm === "Weeks") && (timeLineData > 52)) {
      return false
    }
    if (timeLineData && (this.state.selectedElm !== "Weeks") && (timeLineData > 12)) {
      return false
    }
    return true
  }
   fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1]; 
        resolve(base64String || '');
      };
      reader.onerror = error => reject(error);
    });
  }
  handleModelClose = () => {
    this.setState({isModelOpen:false})
  }
  handleModelOpen = () => {
    this.setState({isModelOpen:true})
  }
  createWorkOpportunity = async () => {
    this.setState({isModelOpen:false})
    if (!this.handleWorkHoursValidation()) {
      return
    }
    if (!this.handleProjectTimelineValidation()) {
      return
    }
    const skillListData = this.state.selectedSkillTags.map(item => item.id)  as number[];
    
    const projectData = this.state.selectedProjectList.map(item => item.id)  as number[]
    const linkListData = this.state.linkList.join();
    const questionListData = this.state.questionList.join();   
    const filesDataPromises =  this.state.files.map(async (file: File) => ({
      filename: file.name,
      data: await this.fileToBase64(file)
    })) ;

    let newLocation = this.state.multiLocationData.map((value)=>{
      let arr = value.split(',')
      return arr[0].toLowerCase()
    })
    const filesData: FileWithType[] = await Promise.all(filesDataPromises);
    const formData:CreateWorkOpp = {
      work_opportunity:{
        work_type: this.state.workType,
        files:  filesData,
        description: this.state.description,
        deliverables: this.state.deliverables,
        title: this.state.title,
        rate_amount: this.state.rateData || this.state.overallRate,
        rate_type: this.state.rateData ? "hourly" : "overall",
        required_hours_per_week: this.state.workHour,
        project_timeline: this.state.projectTimeline,
        files_or_links: linkListData,
        start_date:this.state.dateData,
        project_timeline_type: this.state.selectedElm,
        location: this.state.location === "Remote" ? "Remote" : (newLocation).toString() ,
        experience_level: this.state.workExperience,
        agree_to_terms_and_conditions: this.state.conditionChecked,
        project_ids: projectData,
        question: questionListData,
        skill_ids: skillListData
      }
  };     

    this.commonPostApiCall(configJSON.createWorkOpportunity, formData);
  }

  handleResourceFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxSizes = {
      video: 15 * 1024 * 1024,
      image: 2 * 1024 * 1024
    };
    const fileTypes = {
      video: ["video/mp4", "video/mkv", "video/mov"],
      image: ["image/png", "image/jpg", "image/jpeg"]
    };
    if (!event.target.files) return;
    const tempsFile = event.target.files[0];
    const issImage = fileTypes.image.includes(tempsFile.type);
    const issVideo = fileTypes.video.includes(tempsFile.type);
    if(!this.validFileFormat(tempsFile)) {  this.refElm.current && (this.refElm.current.value = "");
      return;
    }
    const maxFilesSize = issVideo ? maxSizes.video : maxSizes.image;
    if (tempsFile.size > maxFilesSize) {
      this.setState({
        action: "danger",
        message: `File size exceeds ${issVideo ? "15MB" : "2MB"}. Please upload a smaller file.`,
        open: true,
      });
      this.refElm.current && (this.refElm.current.value = "");
      return;
    }
    if (issImage) {
      const compresedFile = await compressedImageData(tempsFile, 0, 0, 0.7);
      this.setState(prevState => ({ files: [...prevState.files, compresedFile]
      }));
    } else {
      this.setState(prevState => ({ files: [...prevState.files, tempsFile]
      }));
    }
    this.refElm.current && (this.refElm.current.value = "");
  }

  handleAddClass = (index: number) => {
    return `question-data ${index === 0 ? "mt-50" : "mt-16"}`;
  }

  handleSkillView = () => {
    return (this.state.searchText.trim() && this.state.searchSkillList.length) || (!this.state.searchText.trim() && this.state.skillList.length);
  }

  handleSkillORSearchList = () => {
    return this.state.searchSkillList.length ? this.state.searchSkillList : this.state.skillList;
  }

  handleWorkTypeCheck = (data: string) => {
    return (this.state.workType === data);
  }

  handleWorkExpCheck = (data: string) => {
    return (this.state.workExperience === data);
  }

  handleAccordian = (data: string) => {
    this.setState({
      openBoard: this.state.openBoard === data ? "" : data,
      boardProjects : null
    });

    this.getBoardsProjects(data)
  }

  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  closeCalender = (event?: any) => {
    this.setState({
      openCalender: false
    });
    if (event) {
      this.handlePropagation(event);
    }
  }

  handlePropagation = (event: any) => {
    event.stopPropagation();
  }

  hidemenu = () => {
    this.setState({
      openSingle: false,
      openLocation: false,
      openCalender: false
    });
  }

  handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      location: event.target.value,
      multiLocationData : [],
      locationError:""
    })
  }

  locationTitle = () => {
    return this.state.selectedLocation || "Select location";
  }

  handleCheckBg = () => {
    return this.state.conditionChecked ? "#d9d9d9" : "#111";
  }

  updateProjectTimeline = (data: string) => {
    let Timeline = Number(data)
    if (Timeline >=0 ) {
      if (this.state.selectedElm == "Months") {
        this.setState({
          projectTimeline: data,
          ProjectTimeError : Timeline > 12? 'You cannot enter more than 12 months' : ""
        })
      } else {
        this.setState({
          projectTimeline: data,
          ProjectTimeError : Timeline > 52? 'You cannot enter more than 52 weeks' : ""
        })
      }
     
    }
   
   
  }

  handleDate = (data: Dayjs | null) => {
    if(data){
      this.setState({ dateData: data })
    }
  }

  handleCurrentDate = (): Dayjs => {
    return dayjs();
  }

  async componentDidMount() {
    super.componentDidMount();
    storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.meta) {
        tokenData = storeData.meta.token;
        this.getDataList();
      }
    })
    this.getBoardName();
  };

  getBoardName = async () => {
    let boardName = await getStorageData("boardName");
    if (boardName) {
      this.setState({ boardName: boardName });
    };
  }

  handleRemoveLocation = (data: string) => {
    this.setState({ multiLocationData: this.state.multiLocationData.filter(item => item !== data)});
  };

  handleChangeLocation = (data: string) => {
    this.setState((prevState):any => {
      const isAlreadyAdded = prevState.multiLocationData.some(
        (location: string) => location === data
      );
      if (isAlreadyAdded) {
        this.setState({locationError:"Location is already selected"})
        return { selectedLocation: "" };
      } else {
        this.setState({locationError:""})
        return {
          selectedLocation: data,
          multiLocationData: [data, ...prevState.multiLocationData],
        };
      }
    }, () => {
      this.setState({ selectedLocation: "" });
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      event.target.value = value;
    } else {
      event.preventDefault();
      event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
    }
  };

  handleChangeLocationText = (data: string) => {
   
    this.setState({ selectedLocation: data})
    
  };

  handleDisableButton =() => {
    if(this.state.location === "Remote"){
      return true;
    }
    else{
      return false;
    }
  }

  handleCountCharacter = (e:any) =>{
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ descCountCharacter: characterCount });
  }

  handleDeliverableCountCharacter = (e:any) =>{
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ deliverableCountCharacter: characterCount });
  }

  checkSaveDraft = () => {
    if (this.state.title.length === 0) {
      this.setState({titleError:'Project title is required'})
      return false;
    }
    this.saveDraftApi()
    return true;
  }

  saveDraftApi = async() => {
    const check = this.state.title.length
    if (check) {
      this.setState({postOpportunityLoader: true})
      const headerObj = {
        "token": tokenData,
        "Content-Type": "application/json"
      };

      const linkListData = this.state.linkList.join();
      const projectDatas = this.state.selectedProjectList.map(item => item.id)  as number[]
      const questionListDatas = this.state.questionList.join();   
      const skillListData = this.state.selectedSkillTags.map(item => item.id)  as number[];
    const filesDataPromis =  this.state.files.map(async (file: File) => ({
      filename: file.name,
      data: await this.fileToBase64(file)
    })) ;

    let newLocations = this.state.multiLocationData.map((value)=>{
      let arrs = value.split(',')
      return arrs[0].toLowerCase()
    })
    const filesData: FileWithType[] = await Promise.all(filesDataPromis);
    const formData:CreateWorkOpp = {
      work_opportunity:{
      title: this.state.title,
      work_type: this.state.workType,
      files:  filesData,
      project_timeline: this.state.projectTimeline ? this.state.projectTimeline : '1',
      description: this.state.description,
      deliverables: this.state.deliverables,
      experience_level: this.state.workExperience,
      files_or_links: linkListData,
      rate_type: this.state.rateData ? "hourly" : "overall",
      required_hours_per_week: this.state.workHour ? this.state.workHour : '1',
      agree_to_terms_and_conditions: this.state.conditionChecked,
      project_timeline_type: this.state.selectedElm,
      question: questionListDatas,
      location: this.state.location === "Remote" ? "Remote" : (newLocations).toString() ,
      rate_amount: this.state.rateData || this.state.overallRate,
      project_ids: projectDatas,
      start_date:this.state.dateData,
      skill_ids: skillListData
    }
  };
      const headerData = JSON.stringify(headerObj);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage));
      this.apiSaveDraftCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.savedraftApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headerData );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiPostMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formData)
      );
      runEngine.sendMessage(requestMessage.id, 
        requestMessage);
    }
  }
}

// Customizable Area End

