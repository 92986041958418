import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/core';

export interface SelectedSort {
    name:string;
    value:string;
}

interface Props {
    selectedSort:SelectedSort;
    handleApply: (name:string,value:string) => void;
    sortData?:{value:string;name:string;key:number}[]
}

interface S {
    anchorEl: HTMLElement | null;
    selected: SelectedSort
    
}

class SortBy extends Component<Props, S>{
  constructor(props:Props) {
    super(props);
    this.state = {
      anchorEl: null,
      selected:{
        name:'',
        value:''
      }
    };
  }

    SoryByWrapper = styled("div")(({ theme }) => ({
        "& .sort_by_text":{
            fontWeight: 600,
            fontSize: 14,
            fontFamily: "Inter , sans-serif",
            color:'#FFF2E3',
            textTransform: "none",
            textAlign: "start"
        }, "& .sort_by_text_container":{
            display:'flex',
            justifyContent:'start',
            alignItems:'center'
        }, "& .sort_by_text_expand_more":{
            fontSize:14,
            color:'#FFF2E3',
        }, "& .sort_by_text_result" : {
            fontWeight: 400,
            fontSize: 14,
            fontFamily: "Inter , sans-serif",
            color:'#FFF2E3',
            textTransform: "none",
            textAlign: "start"
        }
    }));

    StyledMenu = styled(Menu)(({ theme }) => ({
        '& .MuiPaper-root': {
          backgroundColor: 'black',
          color: '#FFF2E3',
        },
      }));
      
      StyledMenuItem = styled(MenuItem)(({ theme }) => ({
        color: '#FFF2E3',
        '&:hover': {
          backgroundColor: '#2a2a2a',
        },
      }));

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, selected:this.props.selectedSort });
  };

  handleClose = (name:string,value:string) => {
    this.setState({selected:{name,value}})
    this.props.handleApply(name,value)
    this.handleSortByClose()
  };

  handleSortByClose = () =>{
    this.setState({ anchorEl: null });
  }

  async componentDidMount(){
      this.setState({selected:this.props.selectedSort})
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <this.SoryByWrapper>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={this.handleClick}
          variant='text'
        >
         <Box>
            <Box className="sort_by_text_container" >
            <Typography className='sort_by_text'>Sort </Typography>
            <ExpandMoreIcon className='sort_by_text_expand_more'/> 
            </Box>
            <Typography className='sort_by_text_result'>{this.props.selectedSort.name}</Typography>
         </Box>
        </Button>
        <this.StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleSortByClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >

          {
            this.props.sortData?
            this.props.sortData.map((item:{value:string;name:string;key:number})=>{
              return   <this.StyledMenuItem onClick={()=>this.handleClose(item.name,item.value)}>{item.name}</this.StyledMenuItem>
            })
            :
<>
<this.StyledMenuItem onClick={()=>this.handleClose('Recommended','recommended')}>Recommended</this.StyledMenuItem>
<this.StyledMenuItem onClick={()=>this.handleClose("Recently Listed",'recently_listed')}>Recently Listed</this.StyledMenuItem>
<this.StyledMenuItem onClick={()=>this.handleClose("Client Rating",'client_rating')}>Client Rating</this.StyledMenuItem>
</>
          }
        </this.StyledMenu>
      </this.SoryByWrapper>
    );
  }
}

export default SortBy;
