import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Plugin,
  ChartData,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderRadius: number;
    hoverBackgroundColor: string;
  }[];
  barThickness?: number;
  xYTick?: string;
  xTickColor: string;
  yTickColor: string;
  viewingMonth: string;
  stepSize: number;
  label: string
}

const UpdatedBarChart: React.FC<BarChartProps> = ({
  labels,
  datasets,
  barThickness,
  xYTick,
  xTickColor,
  yTickColor,
  viewingMonth,
  stepSize,
  label
}) => {

  const monthIndexToHighlight = labels.indexOf(viewingMonth) +1;

  const data: ChartData<"bar"> = React.useMemo(() => ({
    labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      barThickness: barThickness || "flex",
    })),
  }), [labels, datasets, barThickness, monthIndexToHighlight]);

  const maxValue = Math.max(
    ...datasets.map((dataset) => Math.max(...dataset.data))
  );
  const roundedMaxValue = Math.ceil(maxValue / stepSize) * stepSize;
  
  const customPlugin: Plugin<"bar"> = React.useMemo(() => ({
    id: "customUSDLabel",
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const yPosition = chart.scales.y.getPixelForValue(chart.scales.y.max);
      ctx.save();
      ctx.setLineDash([5, 5]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(83, 83, 83, 1)";
      ctx.beginPath();
      ctx.moveTo(chart.chartArea.left, yPosition);
      ctx.lineTo(chart.chartArea.right, yPosition);
      ctx.stroke();
      ctx.restore();
    },
  }), [datasets,monthIndexToHighlight, xYTick, stepSize]);

  const options: ChartOptions<"bar"> = React.useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // position: "top",
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
      tooltip: {
        enabled: false,
        external: (context:any) => {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.borderRadius = '8px';
            tooltipEl.style.padding = '10px';
            tooltipEl.style.fontSize = '16px';
            tooltipEl.style.fontWeight = '600';
            tooltipEl.style.fontFamily = 'Inter, sans-serif';
            tooltipEl.style.textAlign = 'center';
            tooltipEl.style.transition = 'opacity 0.1s ease';
            tooltipEl.style.background = 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)';
            tooltipEl.style.color = 'transparent';
            tooltipEl.style.backgroundClip = 'text';
            tooltipEl.style.backgroundClip = 'text';
            document.body.appendChild(tooltipEl);
          }
      
          const tooltipModel = context.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }
          
          if (label) {
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map((b: any) => b.lines).join('<br>');
              tooltipEl.innerHTML = bodyLines;
            }
          } else {
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map((b: any) => {
                const value = b.lines[0];
                return `${value}$`;
              }).join('<br>');
              tooltipEl.innerHTML = bodyLines;
            }            
          }

          const { chart } = context;
          const canvasRect = chart.canvas.getBoundingClientRect();

          const dataPointIndex = tooltipModel.dataPoints[0].dataIndex;
          const datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
          const hoveredBar = chart.getDatasetMeta(datasetIndex).data[dataPointIndex];
          const hoveredBarProps = hoveredBar.getProps(['x', 'y'], true);

          const meta = chart.getDatasetMeta(0);
          const maxBar = meta.data.reduce((max:any, bar:any) => (bar.y < max.y ? bar : max));
          const maxBarProps = maxBar.getProps(['y'], true);

          tooltipEl.style.left = `${canvasRect.left + hoveredBarProps.x - tooltipEl.offsetWidth / 2}px`;
          tooltipEl.style.top = `${canvasRect.top + maxBarProps.y - 40}px`;
          tooltipEl.style.opacity = '1';
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          color: xTickColor,
          font: { size: 13, weight: "bold" },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: window.innerWidth < 768,
        },
      },
      y: {
        beginAtZero: true,
        max: roundedMaxValue,
        ticks: {
          color: yTickColor,
          font: { size: 13, weight: "bold" },
          precision: 0,
          stepSize: stepSize,
          callback: (value) => (Number(value) % 5 === 0 ? `${value}` : '')
        },
        title: {
          display: true,
          text: viewingMonth,
          color: "rgba(140, 140, 140, 1)",
          font: {
            size: 16,
            weight: "600",
            fontFamily: 'Inter, sans-serif'
          },
          padding: {
            bottom: 20,
          },
        },
      },
    },
  }), [xTickColor, yTickColor, datasets]);

  return (
    <div style={{ overflowX: "auto", minWidth: "320px", minHeight: "270px", marginTop: '40px' }}>
      <Bar data={data} options={options} plugins={[customPlugin]} />
    </div>
  );
};

export default UpdatedBarChart;