import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
let tokenData = "";
let cardId = "";

export interface ContractDetailMain {
  contracts?: ContractDetailContracts
  dispute_raise_details?: {}
}

export interface ContractDetailContracts {
  data: ContractDetailContractsData
}

export interface ContractDetailContractsData {
  id: string
  type: string
  attributes: ContractDetailContractsDataAttributes
}

export interface ContractDetailContractsDataAttributes {
  wallet: number
  transactions: number
  work_budget: number
  duration: any
  start_date: string
  created_time_ago: string
  active_milestone_details: ContractDetailContractsDataAttributesActiveMilestoneDetail[]
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetail {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  milestone_status: string
  activate: boolean
  deliverables: ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable[]
  milestone_type: string
}

export interface ContractDetailContractsDataAttributesActiveMilestoneDetailDeliverable {
  id: number
  deliverable: string
  deliverable_type: string
}


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  menuOpen:boolean;
  showNotification:boolean;
  notification_count:number;
  unread_notification_count:number;
  mainDetails: ContractDetailMain
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiActiveContrcatDetail: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
    menuOpen:false,
    showNotification: false,
    notification_count: 41,
    unread_notification_count: 44,
    mainDetails: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiActiveContrcatDetail) {
        this.handleActiveContrcatDetailData(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    cardId = this.props.navigation.getParam("id")
    this.activeContractAPI()
  }

  handleActiveContrcatDetailData = (responseJson: ContractDetailMain) => {
    if (responseJson.contracts) {
      this.setState({ mainDetails: responseJson })
    }
  }

  handleMenuIcon=()=>{
    this.setState({menuOpen:!this.state.menuOpen})
  }

  activeContractAPI = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': configJSON.ApiContentType,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiActiveContrcatDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activeContractApiEndPoint + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}