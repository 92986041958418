Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getCategories = "bx_block_projectportfolio/get_categories";
exports.errorTitle = 'Error';
exports.searchResultText = "Search Results";
exports.searchText = "Search";
exports.creativeFeildHeading = "Creative fields";
exports.skillsHeading = "Skills";
exports.ratingText = "Rating";
exports.rateText = "Rate";
exports.minPerHour = "Min (per hour)";
exports.maxPerHour = "Max (per hour)";
exports.toText = "to";
exports.zeroCount = 0;
exports.sortTitle = "Sort";
exports.resetButton = "Reset";
exports.applyButton = "Apply";
exports.tabsData = [
  {
    tabName: "Designer",
    tabId: "designer",
  },
  {
    tabName: "Projects",
    tabId: "projects",
  },
  {
    tabName: "Client",
    tabId: "client",
  },
  {
    tabName: "Inspiration",
    tabId: "inspiration",
  },
];
exports.ratingsData = [
  {
    count: 5,
    keyValue: "five_star"
  },
  {
    count: 4,
    keyValue: "four_star"
  },
  {
    count: 3,
    keyValue: "three_star"
  },
  {
    count: 2,
    keyValue: "two_star"
  },
  {
    count: 1,
    keyValue: "one_star"
  },
];
exports.clientSortData = [
  "Oldest to latest",
  "Latest to oldest",
  "Service Rate: Low to high",
  "Service Rate: High to low",
  "Ratings"
];
exports.designerSortData = [
  "Recommended",
  "Followers",
  "Low to high",
  "High to low"
];
exports.projectsSingleSelect = [
  "Recommended",
  "Most viewed",
  "Most liked"
];
exports.clientSingleSelect = [
  "Recommended",
  "Jobs posted"
];
exports.inspirationSingleSelectData = [
  "Popularity",
  "Number of projects",
  "Recommended"
]
exports.opportunitiesListDesigner = [
  {
    value: "show_all",
    label: "Show All"
  },
  {
    value: "show_active",
    label: "Show Active"
  },
];

exports.opportunitiesListForClient = [
  {
    value: "show_all",
    label: "All Opportunities"
  },
  {
    value: "show_active",
    label: "Active Opportunities"
  },
  {
    value: "ongoing_opportunities",
    label: "Ongoing Opportunities"
  },
];

exports.baseURL = require('../../../framework/src/config');
exports.designerEndPoint = "bx_block_advanced_search/search";
exports.skillsListEndPoint = "bx_block_projectportfolio/get_skills";
exports.projectsEndPoint = "/bx_block_advanced_search/search/project_search";
exports.locationList = "/bx_block_advanced_search/search/user_location_listing?role=client";
exports.inspirationUserProjects='/bx_block_dashboard/inspirations';
exports.followUnFollowInspirationEndPoint = "bx_block_dashboard/follow_inspirations";
exports.followApiEndPoint ='/bx_block_dashboard/follow_inspirations';
exports.exampleAPiMethod = "POST";

exports.noResultsFoundHeading = "No search results found";
exports.noResultsFoundDescription = "Please change the filters if applied or try again with another term.";
exports.searchNoResultFoundDes = "did not match any results for designer.";
exports.opportunityText = "Opportunity";
exports.opportunitiesText = "Opportunities";
exports.locationTitle = "Location";
exports.boardName = "Board Name";
exports.projectTitle = "Project";
exports.projectsTitle = "Projects";
exports.followerTitle = "Follower";
exports.followersTitle = "Followers";
exports.followText = "Follow";
exports.unfollowText = "Following";
exports.viewProfileText = "View profile";
exports.userNameText = "user name";
exports.noProjectsFoundText = "No Projects Found"
// Customizable Area End
