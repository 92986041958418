// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import storage from "../../../../framework/src/StorageProvider.web";
import { DialogProps } from '@mui/material/Dialog';
import { setStorageData } from "framework/src/Utilities";
export const configJSON = require("../config");

let tokenData = "";
export interface Props {
  id: string;
}

interface Response {
  id: string | number, 
  cover_image_url: string, 
  likes: string | number, 
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}

interface RowInt {
  id: string | number, 
  name: string
}

interface S {
  activeTab: string;
  selected: RowInt[],
  selectedElm: string | number,
  message: string,
  page: number,
  open: boolean,
  action: string,
  openMulti: boolean,
  openSingle: boolean,
  projectList: Response[],
  tags: string[],
  allTags: string[],
  allCategory: RowInt[],
  project_count: number,
  specificProjectList: Response[],
  showDetails: boolean,
  scroll: DialogProps['scroll'],
  selectedProject: string | number,
  token: string
}

interface SS {
  id: string;
}

export default class DiscoverProjectController extends BlockComponent<
  Props,
  S,
  SS
> {
  delayTimer: ReturnType<typeof setTimeout>;
  apiDiscoverList: string;
  apiTagsList: string;
  apiSkillsList: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      activeTab: "",
      selected: [],
      selectedElm: "",
      page: 1,
      message: "",
      open: false,
      action: "",
      openMulti: false,
      openSingle: false,
      projectList: [],
      tags: [],
      allTags: [],
      allCategory: [],
      project_count: 0,
      specificProjectList: [],
      showDetails: false,
      scroll: 'paper',
      selectedProject: '',
      token: ''
    };
    this.apiDiscoverList = "";
    this.apiTagsList = "";
    this.apiSkillsList = "";
    this.delayTimer = setTimeout(() => {}, 0);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiDiscoverList) {
          this.handleListData(message);
        } else if (apiRequestCallId === this.apiSkillsList) {
          this.handleSkillsData(message);
        } else if (apiRequestCallId === this.apiTagsList) {
          this.handleTagsData(message);
        }
      }
    }
  }

  handleSkillsData = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ allCategory: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string
        }) => ({id: item.id, name: item.name }))]})
      }
    } else {
      this.handleErrorMSG(Object.values(responseJson.errors[0]).join());
    }
  }

  handleTagsData = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ allTags: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string,
          category_id: string | number
        }) => item.name)]})
      }
    } else {
      this.handleErrorMSG(Object.values(responseJson.errors[0]).join());
    }
  }

  handleListData = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (responseJson.projects) {
        let value = [...this.state.projectList,...responseJson.projects]
        let distinctProjects = value.reduce<Response[]>((acc, project) => {
          if (!acc.some(p => p.id === project.id)) {
            acc.push(project);
          }
          return acc;
        }, [])
        this.setState({ projectList: distinctProjects, project_count: responseJson.project_count});
      }
    } else {
      this.handleErrorMSG(Object.values(responseJson.errors[0]).join());
    }
  }
  

  handleErrorMSG = (messageData: string) => {
    this.setState({ projectList: []});
  }

  handleChange(data: {id: string | number, name: string}[]) {
    this.setState({selected: data, projectList: [], page: 1}, () => {
      this.getList();
    });
  }

  handleSelectChange(data: string | number) {
    this.setState({selectedElm: data})
  }

  handleMoreProject = () => {
    const pageData = this.state.page + 1;
    this.getList(pageData);
  }

  showMore = () => {
    if (this.state.projectList.length === 0) {
      return false;
    }
    return this.state.project_count !== this.state.projectList.length ? true: false;
  }
  
  getList(pageNo?:number) {
    const tagsData = (this.state.tags.length > 0) ? '&tags=' + this.state.tags.join(): "";
    const skillsData = (this.state.selected && this.state.selected.length) ? '&creative_fields=' + this.state.selected.map(item => item.name).join(): "";
    const combineData = tagsData + skillsData;
    this.getDiscoverList(`${configJSON.discoverList}?page=${pageNo || 1}${combineData}`);
    this.setState({
      page: pageNo || 1,
      openMulti:false
    });
  }

  handleSelectedProject = (data: Response) => {
    const allData = [];
    for (const project of this.state.projectList) {
      if (project.id !== data.id) {
        allData.push(project);
      }
      if (allData.length === 3) {
        break;
      }
    }
    allData.push(data);
    this.setState({
      selectedProject: data.id,
      specificProjectList: [...allData],
      token: tokenData
    });
  }

  getTagsList = (urlEndPoint: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTagsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSkillsList = (urlEndPoint: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSkillsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDiscoverList = (urlEndPoint: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDiscoverList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeModal = (likes:number | string, views:number | string) => {
    this.handleChangeLikeCount(likes,views)
    this.setState({
      specificProjectList: [],
      selectedProject: '',
      showDetails: false
    })
  }

  handleChangeLikeCount = (likes:number | string, views:number | string) =>{
    let value = this.state.projectList.map((element:Response)=>{
      if(element.id === this.state.selectedProject){
        element.likes = likes
        element.views = views
      }
      return element
    })
    this.setState({projectList:value})
  }

  openModal = (data: Response) => {
    this.setState({
      showDetails: true
    });
    this.handleSelectedProject(data);
  }

  getTagsAndSkills = () => {
    this.getSkillsList(configJSON.getCategoryEndPoint);
    this.getTagsList(configJSON.getSkillEndPoint);
  }

  handleCloseData = () => {
    this.setState({
      openMulti: false,
      openSingle: false
    });
  }

  tagsSelectionChange = (rowData: string) => {
    let storedData:string[] = [...this.state.tags];
    let dataExist = storedData.find((item) => item === rowData);
    if (dataExist) {
      storedData = storedData.filter((item: string) => item !== dataExist);
    } else {
      storedData = [...storedData, rowData];
    }
    if (storedData.length > 15) {
      this.setState({
        open: true,
        message: "Maximum 15 tags can be selected at a time.",
        action: "danger"
      })
      return;
    }
    this.setState({ tags: [...storedData], projectList: [], page: 1 })
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.getList();
    }, 300);

  }

  handleSelected(row: string) {
    const isSelected = this.state.tags.find(selectedList => selectedList === row);
    return isSelected ? true: false
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  toggleMulti = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openMulti: !this.state.openMulti,
      openSingle: false
    });
    event.stopPropagation();
  }
  HandleNavigation =  (comment:any,userId:any) =>{
    let path = userId == comment.userId ?  "UserProfile":"PublicUserProfile"
    let localdata = {
      account_id: comment.userId,
      user_role : comment.user_role
    }
      if (path == "PublicUserProfile") {
        setStorageData("PublicId",JSON.stringify(localdata));
      }
      
    const message = new Message(getName(MessageEnum.NavigationMessage));
message.addData(getName(MessageEnum.NavigationTargetMessage), path);
message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(message);
  }

  toggleSingle = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openSingle: !this.state.openSingle, openMulti: false });
    event.stopPropagation();
  }

  handleNavigation = (path: string) => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), 
      this.props);
    this.send(message);
  }

  async componentDidMount() {
    super.componentDidMount();
    storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.meta) {
        tokenData = storeData.meta.token;
        this.getList();
        this.getTagsAndSkills();
      }
    })
  }
}

// Customizable Area End