// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData,setStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  EditProfile:boolean;

  
}

interface S {
  Addform:boolean;
  workActiveBord:string;
  professionalExplist:any;
  deletemodal:boolean;
  selectedId:number;
  ToastStatusdelet:boolean;
  ToastMessage:string;
  ToastActiondelete:string;
  DkContracts:any;
}

interface SS {
  id: any;
}

export default class ProfessionalExperience extends BlockComponent<
  Props,
  S,
  SS
> {
  
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  getclientProfileworkExperience:string = '';
  deleteclientProfessionalExperience:string = '';
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      Addform:false,
      workActiveBord:'DK Contracts',
      professionalExplist:[],
      deletemodal:false,
      selectedId:0,
      ToastStatusdelet:false,
  ToastMessage:'',
  ToastActiondelete:'',
  DkContracts:[]

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      if (apiRequestCallId1 === this.getclientProfileworkExperience){
        if (!responseJson1.error){
          this.setState({professionalExplist:responseJson1.data})
        }
      }
      if(apiRequestCallId1 === this.deleteclientProfessionalExperience) {
        if(!responseJson1.error) {
          this.setState({ToastStatusdelet:true,ToastActiondelete:"success",ToastMessage:`Sucessfully Deleted`})
          this.handleCloseDeleteModal()
          this.handleWorkExperience()

        }else {
          this.setState({ToastStatusdelet:true,ToastActiondelete:"danger",ToastMessage:`Something went wrong`})
        }

      }
    }
  }



  async componentDidMount() {

    this.handleWorkExperience()
  }
  
  handleWorkactiveBoard =(active:string)=>{
    this.setState({workActiveBord:active})
  }
  openformmodal = ()=>{
    this.setState({Addform:true})

  }
  closeFormModal =()=>{
    this.setState({Addform:false})
    this.handleWorkExperience()

  }

  HandledeleteModal = (id:any)=>{
    
    this.setState({deletemodal:true,selectedId:id.id})
  }


  handleCloseDeleteModal=()=>{
    this.setState({deletemodal:false})
  }
  deleteprofessionalexp=async()=>{
    const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteclientProfessionalExperience = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.deletesingleProfessionalExp+this.state.selectedId
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  }
  handlenavigatetoFindwork=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'FindWork');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
handleWorkExperience = async () =>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getclientProfileworkExperience = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getallListsofProfessionalWxp+this.props.id
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
handleToastClose = () => {
  this.setState({ ToastStatusdelet: false });
}
}

// Customizable Area End
